const isJsonStringValid = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const isHostnameValid = (hostname) => {
  return /^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)*([A-Za-z0-9]|[A-Za-z0-9][A-Za-z0-9\-]*[A-Za-z0-9])$/.test(
    hostname
  );
};

const isClientName = (clientName) => {
  return /^[a-zA-Z\_\s]*$/.test(clientName);
};

const isMessageValid = (str) => {
  // allowed letter, numbers, spaces and '-', '_'
  // const re = /^[a-zA-Z\s0-9_.-]*$/;
  // return re.test(str);
  return true;
};

export { isJsonStringValid, isHostnameValid, isClientName, isMessageValid };
