import React, { FC, useEffect, useState } from "react";
import "./TimePicker.css";
import { prepareTime } from "../../utils/formatTime";
import { timeOptions } from "./intex";

interface TimePickerProps {
  onChange: (time: string) => void;
  defaultTime: string;
}

const TimePicker: FC<TimePickerProps> = ({ onChange, defaultTime }) => {
  const [time, setTime] = useState(defaultTime);

  useEffect(() => {
    setTime(defaultTime);
  }, [defaultTime]);

  const validateValue = (value, range) => {
    const parsedValue = +value;
    const max = range.at(-1);
    const min = range[0];
    let preparedValue = null;
    if (parsedValue <= min) {
      preparedValue = prepareTime(min);
    } else if (parsedValue >= max) {
      preparedValue = prepareTime(max);
    } else if (parsedValue) {
      preparedValue = prepareTime(parsedValue);
    }

    return preparedValue;
  };

  const handleOnChange = (e) => {
    const key = e.target.dataset.key;
    const newValue = e.target.value;

    let hours = time.split(":")[0];
    let minutes = time.split(":")[1];
    let seconds = time.split(":")[2];

    if (key === timeOptions.hours.key) {
      const value = validateValue(newValue, timeOptions.hours.range);
      if (value) {
        hours = value;
      }
    } else if (key === timeOptions.minutes.key) {
      const value = validateValue(newValue, timeOptions.minutes.range);
      if (value) {
        minutes = value;
      }
    } else if (key === timeOptions.seconds.key) {
      const value = validateValue(newValue, timeOptions.seconds.range);
      if (value) {
        seconds = value;
      }
    }

    const preparedTime = `${hours}:${minutes}:${seconds}`;
    setTime(preparedTime);
    onChange(preparedTime);
  };

  const handleValue = (key) => {
    let targetValue = "";
    const hours = time.split(":")[0];
    const minutes = time.split(":")[1];
    const seconds = time.split(":")[2];

    if (key === timeOptions.hours.key) {
      targetValue = hours;
    } else if (key === timeOptions.minutes.key) {
      targetValue = minutes;
    } else if (key === timeOptions.seconds.key) {
      targetValue = seconds;
    }
    return targetValue;
  };

  const handleKeyPress = (e) => {
    const value = parseInt(e.target.value);
    const key = e.target.dataset.key;
    const keyCode = `${e.keyCode}`;
    let range = [];
    if (key === timeOptions.hours.key) {
      range = timeOptions.hours.range;
    } else if (key === timeOptions.minutes.key) {
      range = timeOptions.minutes.range;
    } else if (key === timeOptions.seconds.key) {
      range = timeOptions.seconds.range;
    }

    const max = range.at(-1);
    const min = range[0];

    if (keyCode === "40") {
      const nextValue = value - 1;
      if (nextValue < min) {
        e.target.value = max;
      } else {
        e.target.value = nextValue;
      }
      handleOnChange(e);
    } else if (keyCode === "38") {
      const nextValue = value + 1;
      if (nextValue > max) {
        e.target.value = min;
      } else {
        e.target.value = nextValue;
      }
      handleOnChange(e);
    }
  };

  return (
    <div className="time-picker">
      <div className="time-picker__time">
        {Object.values(timeOptions).map((item) => {
          return (
            <div className="time-picker__wrapper" key={item.key}>
              <div className="time-picker__field">
                <input
                  id={`time-picker__${item.key}`}
                  name={`time-picker__${item.key}`}
                  data-key={item.key}
                  value={handleValue(item.key)}
                  onKeyDown={handleKeyPress}
                  onChange={handleOnChange}
                />
                <label htmlFor={`time-picker__${item.key}`}>{item.label}</label>
              </div>
              {item.divider && <div className="time-picker__divider" />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TimePicker;
