import moment from "moment/moment";

export const helperParseDate = (date) => {
  let dateArray;
  let returnDate;
  if (date) {
    dateArray = date.split("/").map(Number);
    returnDate = new Date();
    returnDate.setMonth(dateArray[1] - 1, dateArray[0] - 1);
    returnDate.setFullYear(dateArray[2]);
    return returnDate;
  }
};

export const toDateMaker = (toDate, option) => {
  let localDatetime = moment(toDate, "yyyy-MM-DD").toDate();
  localDatetime.setHours(0, 0, 0, 0);
  localDatetime.setDate(localDatetime.getDate());

  localDatetime.setHours(0, 0, 0, 0);
  localDatetime.setDate(localDatetime.getDate());
  let preparedDatetime = localDatetime;
  if (option === "Last Week") {
    preparedDatetime = localDatetime.setDate(localDatetime.getDate() - 6);
  } else if (option === "Last Month") {
    preparedDatetime = localDatetime.setMonth(localDatetime.getMonth() - 1);
  }
  return new Date(preparedDatetime);
};

export const dateMaker = (option) => {
  let dateNow = new Date();
  dateNow.setHours(0, 0, 0, 0);
  dateNow.setDate(dateNow.getDate());
  if (option === "Last Week") return dateNow.setDate(dateNow.getDate() - 7);
  else if (option === "Last Month")
    return dateNow.setMonth(dateNow.getMonth() - 1);
  else {
    return dateNow;
  }
};

export const helperGetDateString = (date) => {
  const dateString = new Date(date);
  const targetTime = new Date(dateString.getTime()).toLocaleString("en-UK");
  const arrayDateTime = targetTime.split(", ");
  const [day, month, year] = arrayDateTime[0].split("/");
  return day + "/" + month + "/" + year;
};

export const helperGetHourString = (date) => {
  const dateString = new Date(date);
  return new Date(dateString.getTime())
    .toLocaleString("en-UK")
    .substr(-8)
    .substr(0, 5);
};

export const helperGetHour12Format = (date) => {
  const dateString = new Date(date).toLocaleString();
  const arrayDateTime = dateString.split(", ");
  return arrayDateTime[1];
};

export const helperGetTimeString = (date) => {
  const dateString = new Date(date);
  const targetTime = new Date(dateString.getTime()).toLocaleString();
  const arrayDateTime = targetTime.split(", ");
  return arrayDateTime[1];
};

export const datesSetter = (value) => {
  const date = new Date();
  date.setDate(date.getDate() - value);
  return helperGetDateString(date);
};

export const groupDateByWorkingHours = (time, workingHours = null) => {
  let date = new Date(time);
  if (workingHours) {
    let dayOfWeek = date.getDay() + 1;
    let foundDate = workingHours.find((value) => value.day === dayOfWeek);
    if (foundDate) {
      let fromDate = new Date(time).setHours(
        foundDate.startTime.split(":")[0],
        foundDate.startTime.split(":")[1]
      );
      let endDate = new Date(time).setHours(
        foundDate.endTime.split(":")[0],
        foundDate.endTime.split(":")[1]
      );
      if (!(fromDate <= date && date <= endDate)) {
        return "Beyond working hours";
      }
    }
  }
  date = new Date(date.getTime());
  const datePlusTwo = new Date(time);
  date.setHours(date.getHours(), 0, 0, 0);
  datePlusTwo.setHours(date.getHours() + 1, 0, 0, 0);
  return (
    helperGetHour12Format(date) + " to " + helperGetHour12Format(datePlusTwo)
  );
};

export const timeRounderByOne = (time) => {
  let date = new Date(time);
  date = new Date(date.getTime());
  let hours = date.getHours();
  date.setHours(hours, 0, 0, 0);
  return helperGetHourString(date);
};

export const dateForParams = (option) => {
  let dateNow = new Date();
  if (option === "Last Week") {
    let dateWeekAgo = new Date();
    dateNow.setDate(dateNow.getDate() - 1);
    dateWeekAgo.setDate(dateWeekAgo.getDate() - 7);
    dateWeekAgo.setHours(0, 0, 0);
    return {
      startDate: dateWeekAgo.toLocaleDateString("en-UK"),
      endDate: dateNow.toLocaleDateString("en-UK"),
    };
  } else if (option === "Last Month") {
    let dateMonthAgo = new Date();
    dateNow.setDate(dateNow.getDate() - 1);
    dateMonthAgo.setMonth(dateMonthAgo.getMonth() - 1);
    dateMonthAgo.setHours(0, 0, 0);
    return {
      startDate: dateMonthAgo.toLocaleDateString("en-UK"),
      endDate: dateNow.toLocaleDateString("en-UK"),
    };
  } else {
    let date24HoursAgo = new Date();
    date24HoursAgo.setHours(0, 0, 0);
    return {
      startDate: date24HoursAgo.toLocaleDateString("en-UK"),
      endDate: dateNow.toLocaleDateString("en-UK"),
    };
  }
};

export const secondsToTime = (e) => {
  const m = Math.floor((e % 3600) / 60)
      .toString()
      .padStart(2, "0"),
    s = Math.floor(e % 60)
      .toString()
      .padStart(2, "0");

  return m + ":" + s;
};
