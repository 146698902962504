import React, { useContext, useState } from "react";
import { Button, Modal } from "semantic-ui-react";

import LanguageContext from "../../context/LanguageContext";

import "./ModalWelcomePage.css";

function ModalWelcomePage() {
  const [open, setOpen] = useState(true);
  const { language, languageDirection } = useContext(LanguageContext);

  const getCurrentClient = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        window.location.pathname.replace("/", "")
      );
    }
  };

  const handleModalClick = () => {
    setOpen(false);
    getCurrentClient();
  };

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      className="ModalWelcomePage"
    >
      {/* <Modal.Header>Welcome Agent!</Modal.Header> */}
      <Modal.Content>
        <Modal.Description>
          <p style={{ direction: languageDirection }} className="p-modal">
            {language.console.modalWelcomePage.header}
          </p>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button
          content={language.console.modalWelcomePage.button}
          style={{ direction: languageDirection, color: "#00b5ad" }}
          labelPosition="right"
          icon="checkmark"
          onClick={() => handleModalClick()}
          className="button-proceed"
        />
      </Modal.Actions>
    </Modal>
  );
}

export default ModalWelcomePage;
