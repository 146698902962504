type RangeType = number[];

interface TimeOption {
  key: string;
  label: string;
  range: RangeType;
  divider: boolean;
}

interface TimeOptions {
  hours: TimeOption;
  minutes: TimeOption;
  seconds: TimeOption;
}

const timeOptions: TimeOptions = {
  hours: {
    key: "hours",
    label: "Hour",
    range: [...Array(24).keys()],
    divider: true,
  },
  minutes: {
    key: "minutes",
    label: "Minute",
    range: [...Array(60).keys()],
    divider: true,
  },
  seconds: {
    key: "seconds",
    label: "Second",
    range: [...Array(60).keys()],
    divider: false,
  },
};

export { timeOptions };
