import React, { useContext, useState } from "react";

import { resetPassword } from "../../../../../apis/serviceApis";
import EnvContext from "../../../../../context/EnvContext";
import UsersContext from "../../../../../context/UsersContext";
import { useAuth0 } from "../../../../../react-auth0-spa";
import {
  FieldButton,
  FilledVoiceableButton,
  ListItems,
} from "../../../../../UI-components";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const { user } = useContext(UsersContext);
  const env = useContext(EnvContext);

  const { getTokenSilently } = useAuth0();

  const handleResetPassword = async () => {
    if (newPassword === confirmNewPassword) {
      try {
        const token = await getTokenSilently();
        const body = {
          password: confirmNewPassword,
          userId: user.id,
        };

        await resetPassword(token, env, body);
      } catch (e) {
        let reason = e?.response?.data?.reason;
        if (!reason) {
          reason = "An error occurred when reseting password.";
        }
        console.error(reason);
      }
    } else {
      console.error("Passwords are not the same.");
    }
  };

  return (
    <ListItems>
      <FieldButton
        label="Password"
        placeholder="New password"
        item={newPassword}
        setItem={setNewPassword}
      />
      <FieldButton
        label="Confirm Password"
        placeholder="Confirm new password"
        item={confirmNewPassword}
        setItem={setConfirmNewPassword}
      />
      <FilledVoiceableButton onClick={handleResetPassword}>
        Change Password
      </FilledVoiceableButton>
    </ListItems>
  );
};

export default ResetPassword;
