import React from "react";
import { Checkbox } from "semantic-ui-react";

import { helperGetDateString } from "../../Functions/DateFunctions";

import { connectionDescriptions, endCallDescriptions } from "./constants";

const getFilteredConnections = (attendances) => {
  let currentAttendanceNumber = 0;
  const attendanceslength = attendances.length;

  const filteredAttendances = [];
  const agentToConnectionAttendances = {};

  while (currentAttendanceNumber < attendanceslength) {
    const agentId = attendances[currentAttendanceNumber].agend_id;
    const newDescription =
      attendances[currentAttendanceNumber].description;
    const alreadyWrited = agentToConnectionAttendances.hasOwnProperty(agentId);
    if (
      newDescription === "Agent Connected" ||
      newDescription === "Agent Disconnected"
    ) {
      if (!alreadyWrited) {
        agentToConnectionAttendances[agentId] = [
          attendances[currentAttendanceNumber],
        ];
      } else {
        agentToConnectionAttendances[agentId].push(
          attendances[currentAttendanceNumber]
        );
      }
    }
    currentAttendanceNumber++;
  }

  for (const connectionAttendances of Object.values(
    agentToConnectionAttendances
  )) {
    filteredAttendances.push(
      ...connectionAttendances.filter((attendance, i, array) => {
        if (array.length - 1 === i || i === 0) {
          return true;
        }

        if (attendance.description === "Agent Disconnected") {
          if (array[i + 1].description === "Agent Disconnected") {
            return false;
          } else if (
            Math.abs(
              new Date(array[i + 1].ts).getTime() -
                new Date(attendance.ts).getTime()
            ) > 3000
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          if (array[i - 1].description === "Agent Connected") {
            return false;
          }
          if (
            Math.abs(
              new Date(attendance.ts).getTime() -
                new Date(array[i - 1].ts).getTime() >
                3000
            )
          ) {
            return true;
          } else {
            return false;
          }
        }
      })
    );
  }

  return filteredAttendances;
};

const getFilteredEndCall = (attendances) => {
  let currentAttendanceNumber = 0;
  const attendanceslength = attendances.length;

  const filteredAttendances = [];
  const agentToLastDescription = {};
  while (currentAttendanceNumber < attendanceslength) {
    const agentId = attendances[currentAttendanceNumber].agend_id;
    const newDescription =
      attendances[currentAttendanceNumber].description;
    const alreadyWrited = agentToLastDescription.hasOwnProperty(agentId);
    if (
      endCallDescriptions.some((descr) => descr === newDescription) &&
      !(
        alreadyWrited &&
        ((newDescription === "Agent Unassigned" &&
          agentToLastDescription[agentId] === "Agent Finished Call") ||
          agentToLastDescription[agentId] === "Client Finished Call")
      )
    ) {
      filteredAttendances.push(attendances[currentAttendanceNumber]);
    }
    agentToLastDescription[agentId] = newDescription;
    currentAttendanceNumber++;
  }

  return filteredAttendances;
};

export const baseAttendancesFilter = (attendances) => {
  let currentAttendanceNumber = 0;
  const attendanceslength = attendances.length;

  const filteredAttendances = [];
  while (currentAttendanceNumber < attendanceslength) {
    const newDescription =
      attendances[currentAttendanceNumber].description;
    if (
      !connectionDescriptions.some((descr) => descr === newDescription) &&
      !endCallDescriptions.some((descr) => descr === newDescription)
    ) {
      filteredAttendances.push(attendances[currentAttendanceNumber]);
    }
    currentAttendanceNumber++;
  }
  filteredAttendances.push(
    ...getFilteredConnections(attendances),
    ...getFilteredEndCall(attendances)
  );

  return filteredAttendances.sort(
    (f, s) => new Date(f.ts).getTime() - new Date(s.ts).getTime()
  );
};

export const fillContentToOptions = (options) =>
  options.map((option) => {
    return {
      ...option,
      content: (
        <div key={option.key} className="multiselect-option">
          <Checkbox checked={option.checked} />
          <span className="option-text">{option.value}</span>
        </div>
      ),
    };
  });

export const helperGetTimeString = (date) => {
  const dateString = new Date(date);
  const targetTime = new Date(
    dateString.getTime() - dateString.getTimezoneOffset() * 60 * 1000
  ).toLocaleString();
  const arrayDateTime = targetTime.split(", ");
  return arrayDateTime[1];
};

export const convertAttendanceToCSVData = (attendances) => {
  let datesToAgentActiveMap = {};
  return attendances.map((attendance) => {
    const agentId = attendance.agend_id;
    const description = attendance.description;
    const attendanceDate = helperGetDateString(attendance.ts);
    if (!datesToAgentActiveMap.hasOwnProperty(attendanceDate)) {
      datesToAgentActiveMap[attendanceDate] = {};
    }
    if (!datesToAgentActiveMap[attendanceDate].hasOwnProperty(agentId)) {
      const initState = { connected: false, available: true, onCall: false };
      datesToAgentActiveMap[attendanceDate][agentId] = initState;
    }

    const setState = (newState) => {
      const state = datesToAgentActiveMap[attendanceDate][agentId];
      datesToAgentActiveMap[attendanceDate][agentId] = {
        ...state,
        ...newState,
      };
    };
    if (description === "Agent Connected" || description === "logged in") {
      setState({ connected: true, onCall: false });
    } else if (
      description === "Agent Disconnected" ||
      description === "logged out"
    ) {
      setState({ connected: false, onCall: false });
    } else if (description === "Agent available") {
      setState({ connected: true, available: true, onCall: false });
    } else if (description === "Agent Available") {
      setState({ available: true });
    } else if (description === "Agent Unavailable") {
      setState({ available: false });
    } else if (description === "Agent unavailable") {
      setState({ connected: true, available: false, onCall: false });
    } else if (
      description === "Agent Unassigned" ||
      description === "Agent Finished Call" ||
      description === "Client Finished Call"
    ) {
      setState({ onCall: false });
    } else if (description === "Agent Assigned") {
      setState({ onCall: true });
    }

    if (attendance.available) {
      setState({ available: attendance.available });
    }

    const countOfAvailableAgents = [
      ...Object.values(datesToAgentActiveMap[attendanceDate]),
    ].filter(
      ({ available, connected, onCall }) => available && connected && !onCall
    ).length;

    return {
      date: attendanceDate,
      time: helperGetTimeString(attendance.ts),
      agentName: attendance.agent_name,
      process: attendance.process,
      description: attendance.description,
      reason: attendance.reason,
      availableAgents: countOfAvailableAgents,
    };
  });
};
