import React, { useContext, useEffect, useState } from "react";
import { Header, List, Loader, Segment } from "semantic-ui-react";

import { getUsersByGroup } from "../../apis/serviceApis";
import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import LanguageContext from "../../context/LanguageContext";
import { useAuth0 } from "../../react-auth0-spa";
import { getAgentsStatuses } from "../../views/Functions/GetAgents";
import SupportActionButton from "../SupportActionButton/SupportActionButton";

import "./InviteAgentButton.css";

const agentAvailabilityStatuses = {
  AVAILABLE: { key: "AVAILABLE", className: "available", iconName: "add user" },
  AWAY: { key: "AWAY", className: "not-available", iconName: "add user" },
  ON_CALL: { key: "ON_CALL", className: "on-call", iconName: "call" },
  RINGING: { key: "RINGING", className: "on-call", iconName: "call" },
};

const InviteAgentButton = ({
  onAgentSelected = () => {},
  onGroupAgentSelected = () => {},
}) => {
  const [showGroups, setShowGroups] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [agentStatuses, setAgentStatuses] = useState([]);
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [groupedAgents, setGroupedAgents] = useState([]);
  const env = useContext(EnvContext);
  const { client } = useContext(ClientContext);
  const { language, languageDirection } = useContext(LanguageContext);
  const { user: targetUser, getTokenSilently } = useAuth0();

  const fetchGroupedAgents = async () => {
    const token = await getTokenSilently();
    const agents = await getUsersByGroup(token, env, client);
    setGroupedAgents(agents);
  };

  useEffect(() => {
    refreshData();
    fetchGroupedAgents();

    const interval = setInterval(refreshData, 10000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const refreshData = () => {
    getAgentsStatuses(getTokenSilently, env, client).then((statuses) => {
      setAgentStatuses(statuses);
      setIsLoading(false);
    });
  };

  const onAgentSelect = (e) => {
    const agentId = e.currentTarget.dataset.id;
    const agentStatus = e.currentTarget.dataset.status;
    if (agentStatus === agentAvailabilityStatuses.AWAY.key) {
      setMessage("Selected agent is unavailable.");
      setShowMessage(true);
    } else if (agentStatus === agentAvailabilityStatuses.ON_CALL.key || agentStatus === agentAvailabilityStatuses.RINGING.key) {
      setMessage("Selected agent is on call.");
      setShowMessage(true);
    } else {
      setShowMessage(false);
      onAgentSelected(agentId);
    }
  };

  const onGroupSelect = (e) => {
    const agents = [];
    const childNodes = e.currentTarget.parentElement.childNodes;
    for (const node of childNodes) {
      if (node.dataset.status) {
        agents.push(node.dataset.id);
      }
    }
    onGroupAgentSelected(agents);
  };

  const filterOutUnavailableAgents = (agents) => {
    const outUnavailableAgents = [];
    for (const user of agents) {
      for (const agentStatus of agentStatuses) {
        if (user.id === agentStatus.id && user.id !== targetUser.sub) {
          outUnavailableAgents.push({
            id: agentStatus.id,
            name: agentStatus.name,
            status: agentStatus.status.value,
          });
        }
      }
    }

    return outUnavailableAgents;
  };

  const renderAgents = () => {
    if (client !== "demoGlasses") {
      return renderAgentsWithoutUsingGroups(agentStatuses);
    }

    const toRender = [];
    let numberOfActiveAgents = 0;
    for (const [group, agents] of Object.entries(groupedAgents)) {
        const activeAgents = filterOutUnavailableAgents(agents);
        numberOfActiveAgents += activeAgents;
    }
    if (numberOfActiveAgents === 0) {
        return renderAgentsWithoutUsingGroups(agentStatuses);
    }

    for (const [group, agents] of Object.entries(groupedAgents)) {
      const activeAgents = filterOutUnavailableAgents(agents);
      if (activeAgents.length === 0) {
        continue;
      }
      toRender.push(
        <div key={group} className="list-of-agents">
          <List selection>
            <p
              className="list-of-agents_group-name"
              data-group={group}
              onClick={onGroupSelect}
            >
              {group}
            </p>
            {activeAgents.map((user) => {
              return (
                <List.Item
                  key={user.id}
                  data-id={user.id}
                  data-status={agentAvailabilityStatuses[user.status].key}
                  onClick={onAgentSelect}
                  className={agentAvailabilityStatuses[user.status].className}
                >
                  <List.Icon
                    name={agentAvailabilityStatuses[user.status].iconName}
                  />
                  <List.Content as="a">{user.name}</List.Content>
                </List.Item>
              );
            })}
          </List>
        </div>
      );
    }
    return toRender;
  };

  const renderAgentsWithoutUsingGroups = (agentStatuses) => {
    if (!agentStatuses.length) {
      return <Header as="h5">No agents found</Header>;
    }

    return (
      <div className="list-of-agents">
        <List selection>
          {agentStatuses.map((agent) => {
            if (targetUser.sub !== agent.id) {
              return (
                <List.Item
                  key={agent.id}
                  data-id={agent.id}
                  onClick={onAgentSelect}
                  className={
                    agentAvailabilityStatuses[agent.status.value].className
                  }
                >
                  <List.Icon
                    name={
                      agentAvailabilityStatuses[agent.status.value].iconName
                    }
                  />
                  <List.Content as="a">{agent.name}</List.Content>
                </List.Item>
              );
            }
          })}
        </List>
      </div>
    );
  };

  return showGroups ? (
    <Segment raised padded id="groupsToUpdate-list-container">
      <Header style={{ direction: languageDirection }} as="h4">
        {language.console.invitation.header}
      </Header>
      {showMessage && <Header as="h5">{message}</Header>}
      {isLoading ? <Loader active inline="centered" /> : renderAgents()}
      <span
        onClick={() => {
          setShowGroups(false);
        }}
        id="cancel-group-selection"
        style={{ direction: languageDirection }}
      >
        {language.common.cancel}
      </span>
    </Segment>
  ) : (
    <SupportActionButton
      onClick={() => {
        setShowGroups(true);
      }}
      icon="add user"
      content={language.console.invitation.addAgentToCall}
    />
  );
};

export default InviteAgentButton;
