import React, { useContext } from "react";

import UsersContext from "../../../../context/UsersContext";
import { FilledVoiceableButton } from "../../../../UI-components";
import { UpdateMethods } from "../index";

const CopyUser = ({ setUpdateMethod }) => {
  const { user } = useContext(UsersContext);
  return (
    <FilledVoiceableButton
      disabled={!user}
      style={{ marginRight: "25px", marginLeft: "12.5px" }}
      onClick={() => {
        setUpdateMethod(UpdateMethods.copyUser.key);
      }}
    >
      {UpdateMethods.copyUser.text}
    </FilledVoiceableButton>
  );
};

export default CopyUser;
