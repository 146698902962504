import React from "react";
import { DispatchAction } from "../../../../Dashboard/Settings/types";

type DayIndexType = 1 | 2 | 3 | 4 | 5 | 6 | 7;

type DayOfWeek =
  | "Monday"
  | "Tuesday"
  | "Wednesday"
  | "Thursday"
  | "Friday"
  | "Saturday"
  | "Sunday";

type NameDaysTypes = {
  [i in DayIndexType]: DayOfWeek;
};

type OnDispatchWorkDaysType = (value: {
  dayIndex: DayIndexType;
  enabled?: boolean;
  startTime?: string;
  endTime?: string;
}) => void;

interface WorkDay {
  name: DayOfWeek;
  day: DayIndexType;
  enabled: boolean;
  startTime: string;
  endTime: string;
}

interface WorkDaysFieldProps {
  fieldKey: string;
  fieldValue: WorkDay[];
  dispatch: React.Dispatch<DispatchAction>;
  isLazyDispatch: boolean;
}

const workingDaysIndexes: DayIndexType[] = [1, 2, 3, 4, 5];
const weekendIndexes: DayIndexType[] = [6, 7];
const nameDays: NameDaysTypes = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

export {
  NameDaysTypes,
  OnDispatchWorkDaysType,
  WorkDaysFieldProps,
  workingDaysIndexes,
  weekendIndexes,
  nameDays,
  WorkDay,
};
