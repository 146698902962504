import {
  dateForParams,
  groupDateByWorkingHours,
  helperGetDateString,
  helperGetTimeString,
} from "./DateFunctions";

export const convertDataToConversations = (
  conversations,
  getAnsweredCalls = true,
  clientConfig = {}
) => {
  if (conversations.length === 0) {
    return [];
  }
  if (getAnsweredCalls) {
    conversations = conversations.filter((conversation) => {
      return (
        conversation.messages.filter(
          (message) => message.messageType === "takeCall"
        ).length > 0
      );
    });
  }
  return conversations.map((conversation) => {
    let lookForTakeCall = conversation.messages.filter(
      (message) => message.messageType === "takeCall"
    );
    let lookForAcceptInvite = conversation.messages.filter(
      (message) => message.messageType === "acceptInvite"
    ).length;
    let lookForClientConnected = conversation.messages.filter(
      (message) => message.messageType === "clientConnected"
    );
    let oneClientConnected =
      lookForClientConnected.length > 0 && lookForClientConnected[0];
    let checkoutValue =
      conversation["checkout"] && conversation["checkout"]["checkout_value"];
    let navigationAttempt = conversation.messages.filter(
      (message) => message.messageType === "navigation"
    );
    let productList = [oneClientConnected, ...navigationAttempt]
      .map((message) => {
        let productNumber =
          message && message.page && message.page.match(/[0-9]{5,}/);
        return productNumber && productNumber[0];
      })
      .filter((value) => value);
    let workingHours = clientConfig.workDays;
    return {
      fullDate: conversation.startedAt,
      groupDate:
        conversation.startedAt &&
        groupDateByWorkingHours(conversation.startedAt, workingHours),
      date: helperGetDateString(conversation.startedAt),
      time: helperGetTimeString(conversation.startedAt),
      declined: conversation.declined,
      agentName:
        lookForTakeCall.length > 0 ? lookForTakeCall[0].agentName : null,
      transferedCall: lookForAcceptInvite,
      agentId: lookForTakeCall.length > 0 ? lookForTakeCall[0].agentId : null,
      userId: conversation._va_id,
      sessionId: conversation.session,
      waitTime:
        lookForTakeCall.length > 0
          ? parseInt(
              (new Date(lookForTakeCall[0].ts) -
                new Date(conversation.startedAt)) /
                1000
            )
          : null,
      duration:
        parseInt(
          (new Date(conversation.finishedAt) -
            new Date(conversation.startedAt)) /
            1000
        ) || 0,
      callStatus: conversation.callStatus,
      productList: productList,
      converted:
        conversation["checkout"] &&
        conversation.checkout.hasOwnProperty("successProducts"),
      checkoutValue: checkoutValue ? parseInt(checkoutValue) : 0, //returning user which bought something in the past
      returningUser: conversation.returningUser,
      checkout: conversation["checkout"],
      upsellAttempt: conversation["up-sell"],
      crossSellAttempt: conversation["cross-sell"],
      navigationAttempt: navigationAttempt.length,
      successCheckout:
        conversation["checkout"] && conversation["checkout"]["successProducts"],
      ...(conversation["intentBody"] || { intent: conversation["intent"] }),
    };
  });
};

export const convertDataToConversation = (conversation) => {
  let lookForTakeCall = conversation.messages.filter(
    (message) => message.messageType === "takeCall"
  );
  let lookForClientConnected = conversation.messages.filter(
    (message) => message.messageType === "clientConnected"
  );
  let oneClientConnected =
    lookForClientConnected.length > 0 && lookForClientConnected[0];
  let checkoutValue =
    conversation["checkout"] && conversation["checkout"]["checkout_value"];
  let navigationAttempt = conversation.messages.filter(
    (message) => message.messageType === "navigation"
  );
  let productList = [oneClientConnected, ...navigationAttempt]
    .map((message) => {
      return message.params.id;
    })
    .filter((value) => value);
  return {
    fullDate: conversation.startedAt,
    groupDate: groupDateByWorkingHours(conversation.startedAt),
    date: helperGetDateString(conversation.startedAt),
    time: helperGetTimeString(conversation.startedAt),
    agentName: lookForTakeCall.length > 0 ? lookForTakeCall[0].agentName : null, //must be a better way
    firstPage: oneClientConnected
      ? oneClientConnected.page
      : navigationAttempt.length > 0
      ? navigationAttempt[0].page
      : null,
    firstPageTitle: oneClientConnected
      ? oneClientConnected.pageTitle
      : navigationAttempt.length > 0
      ? navigationAttempt[0].pageTitle
      : null,
    waitTime:
      lookForTakeCall.length > 0
        ? parseInt(
            (new Date(lookForTakeCall[0].ts) -
              new Date(conversation.startedAt)) /
              1000
          )
        : null,
    duration: parseInt(
      (new Date(conversation.finishedAt) - new Date(conversation.startedAt)) /
        1000
    ),
    sessionId: conversation.session,
    productList: productList.join(", "),
    converted:
      conversation["checkout"] &&
      conversation.checkout.hasOwnProperty("successProducts"),
    checkoutValue: checkoutValue ? parseInt(checkoutValue) : 0, //returning user which bought something in the past
    returningUser: conversation.returningUser,
    agentId: lookForTakeCall.length > 0 ? lookForTakeCall[0].agentId : null,
    checkout:
      conversation["checkout"] &&
      conversation["checkout"]["order_items"] &&
      conversation["checkout"]["order_items"]
        .map((product) => product.item)
        .join(", "),
    upsellAttempt: conversation["up-sell"],
    crosssellAttempt: conversation["cross-sell"],
    navigationAttempt: navigationAttempt.length,
    successCheckout:
      conversation["checkout"] &&
      conversation["checkout"]["successProducts"] &&
      conversation["checkout"]["successProducts"]
        .map((product) => product.product_number)
        .join(", "),
    intent:
      conversation.intent ||
      (conversation.intentBody && conversation.intentBody.intent),
    description: conversation.description,
  };
};

export const convertDataToProducts = (
  conversations,
  option,
  startDate = null,
  endDate = null
) => {
  let allProducts = {};
  conversations.map((conversation) => {
    if (conversation.checkout && conversation.checkout.order_items) {
      conversation.checkout.order_items.map((product) => {
        if (!(product.item in allProducts)) {
          allProducts[product.item] = {
            item: product.item,
            userCount: 1,
            unitPrice: product.price / product.units,
            totalUnits: product.units || 0,
            converts: 0,
            convertsUnits: 0,
            variants: 0,
            variantsUnits: 0,
            similar: 0,
            similarUnits: 0,
            totalConvertedPrice: 0,
            totalPrice: +product.price,
          };
        } else {
          allProducts[product.item].userCount += 1;
          allProducts[product.item].totalUnits += product.units || 0;
          allProducts[product.item].totalPrice += product.price;
        }
      });
      if ("successProducts" in conversation.checkout) {
        conversation.checkout.successProducts.map((product) => {
          allProducts[product.item].unitPrice = product.price
            ? product.price / product.units
            : allProducts[product.item].unitPrice;
          allProducts[product.item].converts += product.conversion;
          allProducts[product.item].convertsUnits += product.conversion
            ? product.units
            : 0;
          allProducts[product.item].variants += product.variant;
          allProducts[product.item].variantsUnits +=
            product.variant * product.units;
          allProducts[product.item].similar += product.similar;
          allProducts[product.item].similarUnits +=
            product.similar * product.units;
          allProducts[product.item].totalConvertedPrice +=
            +product.conversion && product.price;
        });
      }
    }
  });
  let startDateToReturn;
  let endDateToReturn;
  if (option) {
    let params = dateForParams(option);
    startDateToReturn = params.startDate;
    endDateToReturn = params.endDate;
  } else {
    startDateToReturn = startDate;
    endDateToReturn = endDate;
  }
  Object.keys(allProducts).forEach((key) => {
    allProducts[key] = {
      ...allProducts[key],
      startDate: startDateToReturn,
      endDate: endDateToReturn,
    };
  });
  return allProducts;
};

export const mapByAgents = (results) => {
  let resultsConfigured = {};
  results.map((value) => {
    if (!(value.agentId in resultsConfigured)) {
      resultsConfigured[value.agentId] = [];
    }
    resultsConfigured[value.agentId].push(value);
  });
  return resultsConfigured;
};
