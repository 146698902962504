import "./useShareScreen.css";

const useShareScreen = () => {
  let isDown = false;
  let offset = [0, 0];

  const mouseDownListener = function (e, div) {
    isDown = true;
    offset = [div.offsetLeft - e.clientX, div.offsetTop - e.clientY];
  };

  const mouseUpListener = function () {
    isDown = false;
  };

  const mouseMoveListener = function (event, div) {
    event.preventDefault();
    if (isDown) {
      const mousePosition = {
        x: event.clientX,
        y: event.clientY,
      };
      const v1 = mousePosition.x + offset[0];
      const v2 = mousePosition.y + offset[1];

      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      const height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;

      const vw = width / 100;

      const maxLeft = width - div.offsetWidth;
      const maxTop = height - div.offsetHeight - 2 * vw;

      div.style.left = (v1 < 0 ? 0 : v1 > maxLeft ? maxLeft : v1) + "px";
      div.style.top = (v2 < 0 ? 0 : v2 > maxTop ? maxTop : v2) + "px";
    }
  };

  const detectMobile = () => {
    const MobileDeviceWords = [
      /Android/i,
      /webOS/i,
      /iPhone/i,
      /iPad/i,
      /iPod/i,
      /BlackBerry/i,
      /Windows Phone/i,
    ];

    return MobileDeviceWords.some((mobileWord) => {
      return navigator.userAgent.match(mobileWord);
    });
  };

  const enterFullScreen = (element, videoEl) => {
    if (element.requestFullscreen) {
      return element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      return element.webkitRequestFullscreen();
    } else if (videoEl.webkitRequestFullscreen) {
      return videoEl.webkitRequestFullscreen();
    } else if (videoEl.webkitRequestFullScreen) {
      return videoEl.webkitRequestFullScreen();
    } else if (element.mozRequestFullScreen) {
      return element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      return element.msRequestFullscreen();
    }
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      return document.exitFullscreen();
    } else if (document.msExitFullscreen) {
      return document.msExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      return document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      return document.webkitExitFullscreen();
    }
  };

  const fullScreenSupported = () => {
    return (
      document.fullscreenEnabled ||
      document.mozFullscreenEnabled ||
      document.webkitFullscreenEnabled ||
      document.webkitFullScreenEnabled ||
      document.msFullscreenEnabled
    );
  };

  const addSimpleFullScreenButton = (conatiner, videoBlock) => {
    const fullScreenButton = document.createElement("div");
    fullScreenButton.classList.add("voiceable-full-screen-button");

    fullScreenButton.onclick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (conatiner.classList.contains("voiceable-full-screen-simple")) {
        conatiner.classList.remove("voiceable-full-screen-simple");
        if (conatiner.classList.contains("voiceable-mobile")) {
          conatiner.classList.remove("voiceable-mobile");
        } else {
          conatiner.classList.remove("voiceable-desktop");
        }
      } else {
        conatiner.classList.add("voiceable-full-screen-simple");
        if (detectMobile()) {
          conatiner.classList.add("voiceable-mobile");
        } else {
          conatiner.classList.add("voiceable-desktop");
        }
      }
    };
    videoBlock.appendChild(fullScreenButton);
  };

  const fullscreenchanged = (event, conatiner) => {
    if (
      !document.fullscreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement &&
      !document.mozFullscreenElement
    ) {
      conatiner.classList.remove("voiceable-full-screen");
      conatiner.style = "";
    }
  };

  const addFullScreenButton = (conatiner, videoBlock) => {
    if (!fullScreenSupported()) {
      addSimpleFullScreenButton(conatiner, videoBlock);
      return;
    }
    const fullScreenButton = document.createElement("div");
    fullScreenButton.classList.add("voiceable-full-screen-button");

    const videoEl =
      document.querySelector("#voiceable-share-screen-id video") || {};

    fullScreenButton.onclick = (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (conatiner.classList.contains("voiceable-full-screen")) {
        exitFullScreen();
        conatiner.classList.remove("voiceable-full-screen");
      } else {
        enterFullScreen(videoBlock, videoEl);
        conatiner.classList.add("voiceable-full-screen");
      }
    };

    videoBlock.addEventListener("fullscreenchange", (event) =>
      fullscreenchanged(event, conatiner)
    );
    videoBlock.addEventListener("mozfullscreenchange", (event) =>
      fullscreenchanged(event, conatiner)
    );
    videoBlock.addEventListener("webkitfullscreenchange", (event) =>
      fullscreenchanged(event, conatiner)
    );
    if (videoEl.addEventListener) {
      videoEl.addEventListener("webkitfullscreenchange", (event) =>
        fullscreenchanged(event, conatiner)
      );
    }
    videoBlock.addEventListener("msfullscreenchange", (event) =>
      fullscreenchanged(event, conatiner)
    );
    videoBlock.appendChild(fullScreenButton);
  };

  const getShareScreenVideoContainer = () => {
    removeShareScreenContainer();
    const shareScreenContainer = document.createElement("div");
    shareScreenContainer.id = "voiceable-share-screen-id";
    shareScreenContainer.classList.add("voiceable-share-screen");

    const shareScreenContent = document.createElement("div");
    shareScreenContent.id = "voiceable-share-screen-video-id";
    shareScreenContent.classList.add("voiceable-share-screen-video");

    const shareScreenPowered = document.createElement("div");
    shareScreenPowered.classList.add(
      "voiceable-share-screen-powered-by-voiceable"
    );

    addFullScreenButton(shareScreenContainer, shareScreenContent);

    shareScreenContainer.appendChild(shareScreenContent);
    shareScreenContainer.appendChild(shareScreenPowered);

    document.body.appendChild(shareScreenContainer);
    addMovabilityForContainer(shareScreenContainer);
    return shareScreenContent;
  };

  const addMovabilityForContainer = (div) => {
    div.addEventListener(
      "mousedown",
      (event) => mouseDownListener(event, div),
      true
    );
    document.addEventListener("mouseup", mouseUpListener, true);
    document.addEventListener(
      "mousemove",
      (event) => mouseMoveListener(event, div),
      true
    );
  };

  const removeShareScreenContainer = () => {
    const existedContainer = document.getElementById(
      "voiceable-share-screen-id"
    );
    if (existedContainer) {
      existedContainer.remove();
    }
    document.removeEventListener("mouseup", mouseUpListener);
    document.removeEventListener("mousemove", (event) =>
      mouseMoveListener(event, existedContainer)
    );
  };

  return { removeShareScreenContainer, getShareScreenVideoContainer };
};

export default useShareScreen;
