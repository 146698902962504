import React, { useEffect, useState } from "react";
import { Loader } from "semantic-ui-react";

import { OutlineDropdown } from "../index";

import { conversionPeriodOptions } from "./constant";

import "./ConversionPeriod.css";

const getDefaultConversionPeriod = () => {
  const conversionPeriodValueFromLocalStorage = parseInt(
    localStorage.getItem("conversionPeriodValue")
  );

  return conversionPeriodOptions.filter((item) => {
    if (conversionPeriodValueFromLocalStorage) {
      return conversionPeriodValueFromLocalStorage === item.value;
    }
    return item.default;
  })[0];
};

const ConversionPeriod = ({
  loading,
  reFetchData,
  label = "Conversion Period",
}) => {
  const [conversionPeriod, setConversionPeriod] = useState(
    getDefaultConversionPeriod()
  );

  useEffect(() => {
    setConversionPeriod(getDefaultConversionPeriod());
  }, []);

  useEffect(() => {
    localStorage.setItem(
      "conversionPeriodValue",
      String(conversionPeriod.value)
    );
    reFetchData();
  }, [conversionPeriod.value]);

  if (loading) {
    return (
      <div className="conversion-period__loader">
        <Loader active inline="centered" size="small" />
      </div>
    );
  }

  return (
    <OutlineDropdown
      label={label}
      options={conversionPeriodOptions}
      defaultValue={conversionPeriod.label}
      dataAttributes={["value"]}
      onClick={(e) => {
        const conversionPeriodValue = parseInt(e.target.dataset.value);
        const conversionPeriod = conversionPeriodOptions.filter((item) => {
          return conversionPeriodValue === item.value;
        })[0];
        setConversionPeriod(conversionPeriod);
      }}
    />
  );
};

export default ConversionPeriod;
