import React, { useEffect, useState } from "react";
import { Form, Input, TextArea } from "semantic-ui-react";

import { getPropertyValueByPath } from "../../../../../utils/helpers";
import { getTextFieldHandler } from "../../../../../utils/reducerHelpers";

const TextField = ({
  field,
  dispatch,
  textArea,
  state,
  placeholder,
  onChange = null,
}) => {
  const textFieldHandler = getTextFieldHandler(dispatch);
  const [value, setValue] = useState(field.value);

  useEffect(() => {
    setValue(getPropertyValueByPath(state, field.key, ""));
  }, [state]);

  const preparePlaceholder = (placeholder) => {
    return (
      placeholder[0].toUpperCase() + placeholder.substring(1).toLowerCase()
    );
  };

  const isError = (value) => {
    if (value && field.validation) {
      return !field.validation(value);
    }
    return false;
  };

  const handleOnChange = (e, { value }) => {
    if (onChange) {
      onChange(e);
    } else {
      textFieldHandler(e, { name: field.key, value });
    }
  };
  if (textArea) {
    return (
      <Form>
        <TextArea
          style={{ width: "60%" }}
          placeholder={preparePlaceholder(placeholder)}
          error={isError(value)}
          value={value}
          onChange={handleOnChange}
        />
      </Form>
    );
  }
  return (
    <Input
      style={{ width: "60%" }}
      placeholder={preparePlaceholder(placeholder)}
      error={isError(value)}
      size="small"
      value={value}
      onChange={handleOnChange}
    />
  );
};

export default TextField;
