import React, { useEffect, useRef, useState } from "react";
import { Button } from "semantic-ui-react";
import Video from "twilio-video";

import "./ShareScreen.css";

const ShareScreen = ({ room, desktop }) => {
  const [screenTrack, setScreenTrack] = useState(null);
  const shareScreenRef = useRef();

  const shareScreenHandler = async () => {
    let stream;
    if (desktop) {
      stream = await navigator.mediaDevices.getDisplayMedia({video: {frameRate: 15}})
    } else {
      stream = await navigator.mediaDevices.getUserMedia({video: {frameRate: 15}});
    }
    console.log('create a new local video track screen sharing')
    const screenTrack = new Video.LocalVideoTrack(stream.getTracks()[0], {
      name: "screenshare",
    });
    setScreenTrack(screenTrack);
    room.localParticipant.publishTrack(screenTrack);

    screenTrack.once("stopped", () => {
      room.localParticipant.unpublishTrack(screenTrack);
    });

    screenTrack.mediaStreamTrack.onended = () => stopSharing(screenTrack);
  };

  const stopSharing = (screenTrack) => {
    screenTrack.detach().forEach((element) => {
      room.localParticipant.unpublishTrack(screenTrack);
      screenTrack.stop();
      setScreenTrack(null);
      element.remove();
    });
  };

  useEffect(() => {
    if (screenTrack) {
      const videoEl = shareScreenRef.current;
      videoEl.muted = true;
      const trackElement = screenTrack.attach();
      videoEl.appendChild(trackElement);
    }
  }, [screenTrack]);

  return (
    <div className="share-screen-container">
      <div className="video-screen-wrapper" ref={shareScreenRef} />
      <Button
        icon="desktop"
        color={screenTrack ? "red" : "teal"}
        onClick={
          screenTrack ? () => stopSharing(screenTrack) : shareScreenHandler
        }
        content={screenTrack ? "Stop sharing screen" : "Share screen"}
      />
    </div>
  );
};

export default ShareScreen;
