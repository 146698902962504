import React, { useContext, useEffect, useState } from "react";

import LanguageContext, {
  languagesOptions,
} from "../../context/LanguageContext";
import { SimpleDropdown } from "../index";

import "./LanguageSwitcher.css";

const LanguageSwitcher = () => {
  const [items, setItems] = useState([]);
  const { setLanguageCode, defaultLanguageLabel } = useContext(LanguageContext);

  useEffect(() => {
    setItems(Object.values(languagesOptions));
  }, []);

  return (
    <div className="language-switcher">
      <SimpleDropdown
        items={items}
        label="Select language"
        defaultValue={defaultLanguageLabel}
        dataAttributes={["code"]}
        onClick={(e) => {
          setLanguageCode(e.target.dataset.code);
        }}
      />
    </div>
  );
};

export default LanguageSwitcher;
