import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {preloadScript} from "opentok-react";
import {Segment} from "semantic-ui-react";
import Video from "twilio-video";

import micIllo from "../../assets/img/micrphone illo.svg";
import phoneTube from "../../assets/img/phone tube.svg";
import config from "../../config.json";
import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import LanguageContext from "../../context/LanguageContext";
import {useAuth0} from "../../react-auth0-spa";
import InviteAgentButton from "../InviteAgentButton/InviteAgentButton";

import LocalParticipant from "./LocalParticipant/LocalParticipant";
import Participant from "./Participant/Participant";

import "./OpenTokenCall.css";

const OpenTokenCall = ({
                         headsetInputId,
                         ringtoneOutputId,
                         headsetOutputId,
                         setAnswerLoading,
                         roomName,
                         supportRecording,
                         inviteActive,
                         setInviteActive,
                         room,
                         onGroupInvite,
                         setRoom,
                         ...props
                       }) => {
  const env = useContext(EnvContext);
  const [token, setToken] = useState(null);
  const {user, getTokenSilently} = useAuth0();
  const {language, languageDirection} = useContext(LanguageContext);
  const {client} = useContext(ClientContext);
  const [otherAgentsStreams, setOtherAgentsStreams] = useState([]);
  const [customerStream, setCustomerStream] = useState(null);
  const [localParticipant, setLocalParticipant] = useState(null);
  const [cameras, setCameras] = useState([]);

  useEffect(() => {
    const cameraInitialization = async () => {
      let devices = await navigator.mediaDevices.enumerateDevices();
      let inputVideoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setCameras(inputVideoDevices);
    };
    cameraInitialization();
  }, []);

  const apiService = axios.create({baseURL: config["api-service"][env]});
  const supportAPI = axios.create({
    baseURL: config["liveSupport"][env].replace("ws", "http"),
  });

  const fetchToken = async (username, roomName, name) => {
    const token = await getTokenSilently();
    const res = await apiService.post("video/token", {
        identity: JSON.stringify({username, role: "Agent", client, name}),
        roomName,
        recording: supportRecording,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      }
    );
    return res.data.token;
  };

  useEffect(() => {
    return () => {
      if (room) {
        room.disconnect();
      }
    };
  }, [room]);

  useEffect(() => {
    if (room) {
      room.disconnect();
    }
    fetchToken(user.sub, roomName, user.name).then((token) => {
      setToken(token);
    });
  }, [user, roomName]);

  const otherAgentsParticipants = otherAgentsStreams.map(
    (participant, index) => (
      <Participant
        key={participant.sid}
        participant={participant}
        videoEnabled={false}
        className={
          "remote-agent " +
          (index === otherAgentsStreams.length - 1 ? "rounded-top" : "")
        }
      />
    )
  );
  let customerParticipant;
  if (customerStream !== null) {
    customerParticipant = (
      <Participant
        videoEnabled={false}
        key={customerStream.sid}
        id="customer"
        participant={customerStream}
      >
        <label id="indicator"/>{" "}
        <label id="connection-text" style={{direction: languageDirection}}>
          {language.console.openTokenCall.connectionText}
        </label>
      </Participant>
    );
  } else {
    customerParticipant = "";
  }

  const connectToRoom = () => {
    const participantConnected = (participant) => {
      let identity = JSON.parse(participant.identity);
      if (identity.role === "Agent") {
        setOtherAgentsStreams((prevParticipants) => [
          ...prevParticipants,
          participant,
        ]);
        setInviteActive(false);
      } else if (identity.role === "Customer") {
        setCustomerStream(participant);
      }
    };
    const participantDisconnected = (participant) => {
      let identity = JSON.parse(participant.identity);
      if (identity.role === "Agent") {
        setOtherAgentsStreams((prevParticipants) =>
          prevParticipants.filter((p) => p !== participant)
        );
      } else if (identity.role === "Customer") {
        setCustomerStream(null);
      }
    };
    const constraints = {
      aspectRatio: 4 / 3,
    };

    if (cameras.length > 0) {
      constraints.deviceId = cameras[0].deviceId;
    }

    Video.createLocalTracks({
      audio: {deviceId: headsetInputId, noiseSuppression: true, echoCancellation: true},
      video: false,
    })
      .then((localTracks) => {
        return Video.connect(token, {name: roomName, tracks: localTracks})
      })
      .then((room) => {
        setLocalParticipant(room.localParticipant);
        setRoom(room);
        room.on("participantConnected", participantConnected);
        room.on("participantDisconnected", participantDisconnected);
        room.on("trackSubscribed", (track) => {
          if (track.kind === "audio") {
            const audioElement = track.attach();
            audioElement.setSinkId(headsetOutputId);
          }
        });
        room.on("disconnected", (currentRoom) => {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
        });
        room.participants.forEach(participantConnected);
      });
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    connectToRoom();

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === "connected") {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [token, roomName]);

  const disconnectSession = () => {
    if (otherAgentsStreams.length === 0) {
      props.onChatEnd();
    }
    setOtherAgentsStreams([]);
    setCustomerStream(null);
    setLocalParticipant(null);
    if (room) {
      room.disconnect();
    }
  };

  const onAgentSelected = (agentId) => {
    setInviteActive(true);
    props.onInvite(agentId);
  };

  const onGroupAgentSelected = (agentIds) => {
    setInviteActive(true);
    onGroupInvite(agentIds);
  };

  const onInviteCancel = () => {
    setInviteActive(false);
    props.onInviteCancel();
  };

  const isAnotherAgentConnected = () => {
    return otherAgentsStreams.length > 0;
  };

  useEffect(() => {
    if (token && room && room.state !== "disconnected") {
      setAnswerLoading(false);
    }
  }, [token, room]);

  if (!token || !room) {
    return false;
  }

  if (room.state === "disconnected") {
    return (
      <>
        <div
          className="answer-call-btn"
          onClick={connectToRoom}
          style={{direction: languageDirection}}
        >
          <img src={phoneTube} alt=""/>
          {language.console.openTokenCall.resumeCall}
        </div>
        <div
          className="answer-call-btn leave-btn"
          onClick={props.onChatLeave}
          style={{direction: languageDirection}}
        >
          <img src={phoneTube} alt=""/>
          {language.console.openTokenCall.leaveCall}
        </div>
      </>
    );
  }

  return (
    <div
      id="call-container"
      style={{...props.style}}
      className={"room" + (isAnotherAgentConnected() ? " multigroup" : "")}
    >
      <div className="open-token-call_invite">
        {!inviteActive && (
          <InviteAgentButton
            onAgentSelected={onAgentSelected}
            onGroupAgentSelected={onGroupAgentSelected}
          />
        )}
        {inviteActive && (
          <Segment raised padded style={{borderRadius: "12px"}}>
            <img src={micIllo} alt=""/>
            <span onClick={onInviteCancel} id="cancel-invite">
              {language.common.cancel}
            </span>
          </Segment>
        )}
      </div>

      <div className="remote-participants">{otherAgentsParticipants}</div>

      <LocalParticipant
        headsetOutputId={headsetOutputId}
        cameras={cameras}
        id="local-agent"
        videoEnabled={props.videoEnabled}
        key={localParticipant.sid}
        participant={localParticipant}
        onDisconnect={disconnectSession}
      >
        {!customerStream && (
          <h3
            className="customer_connection_status_header"
            style={{direction: languageDirection}}
          >
            {language.console.openTokenCall.waitingStatus}
          </h3>
        )}
      </LocalParticipant>

      <div className="customer-participant">{customerParticipant}</div>
    </div>
  );
};

OpenTokenCall.defaultProps = {
  videoEnabled: false,
  audioEnabled: true,
  onInvite: () => {
  },
  onInviteCancel: () => {
  },
  onChatStart: () => {
  },
  onChatEnd: () => {
  },
  onChatLeave: () => {
  },
};
export default preloadScript(OpenTokenCall);
