import React, { useEffect, useRef } from "react";

import "./PopUp.css";

const PopUp = ({
  children,
  error = false,
  hidePopupClose = false,
  onClick = () => {},
}) => {
  const ref = useRef();
  const closeRef = useRef();

  useEffect(() => {
    if (hidePopupClose) {
      closeRef.current.style.display = "none";
    }
  }, []);

  const handleClick = () => {
    ref.current.style.display = "none";
    onClick();
  };

  return (
    <div className="pop-up" ref={ref}>
      <p
        style={{ color: error ? "#e92b2b" : "#4bb5ab" }}
        className="pop-up__text"
      >
        {children}
      </p>
      <button ref={closeRef} className="pop-up__close" onClick={handleClick}>
        OK
      </button>
    </div>
  );
};

export default PopUp;
