import React from "react";
import { Table } from "semantic-ui-react";

import { helperGetTimeString } from "../../Functions/DateFunctions";

import "./NavigationsTable.css";

const NavigationsTable = ({ navigations, progress, duration }) => {
  let activeNavigationTs = null;
  navigations.forEach(({ time, ts }) => {
    if (duration * (progress / 100) >= time) {
      activeNavigationTs = ts;
    }
  });
  const mapNavigationsToTable = (navigations) => {
    return navigations.map(({ link, title, ts }) => {
      return (
        <Table.Row
          key={ts}
          className={activeNavigationTs === ts && "active-row"}
        >
          <Table.Cell>{helperGetTimeString(new Date(ts))}</Table.Cell>
          <Table.Cell>{title || link}</Table.Cell>
        </Table.Row>
      );
    });
  };
  return (
    <Table
      className="navigation-table"
      size="large"
      basic="very"
      textAlign="center"
      selectable
      unstackable
    >
      <Table.Header>
        <Table.HeaderCell>Time</Table.HeaderCell>
        <Table.HeaderCell>Page Title</Table.HeaderCell>
      </Table.Header>
      <Table.Body className="navigation-table-body">
        {navigations &&
          navigations.length > 0 &&
          mapNavigationsToTable(navigations)}
      </Table.Body>
    </Table>
  );
};

export default NavigationsTable;
