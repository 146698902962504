const prepareTime = (number: number): string => {
  if (`${number}`.length === 1) {
    return `0${number}`;
  }
  return `${number}`;
};

const convertLocalTimeToUTC = (time: string): string => {
  // in 14:17:00 => out 12:17:00
  const hours: string = time.split(":")[0];
  const minutes: string = time.split(":")[1];
  const seconds: string = time.split(":")[2];

  return new Date(new Date().setHours(+hours, +minutes, +seconds))
    .toUTCString()
    .split(" ")[4];
};

const convertUTCTimeToLocal = (time: string): string => {
  // in 14:17:00 => out 16:17:00
  const hours: string = time.split(":")[0];
  const minutes: string = time.split(":")[1];
  const seconds: string = time.split(":")[2];

  const offset = new Date().getTimezoneOffset();
  const date = new Date().setHours(+hours, +minutes, +seconds);
  return new Date(date - offset * 60 * 1000).toTimeString().split(" ")[0];
};

export { prepareTime, convertLocalTimeToUTC, convertUTCTimeToLocal };
