import React, { FC, memo, useEffect, useState } from "react";
import _ from "lodash";

import { ClientConfig } from "../../../../common/types/clientConfig";
import { ConfigMetadata, DispatchAction } from "../types";

import Generator from "./Generator";
import { Checkbox } from "semantic-ui-react";

interface GeneratedConfigProps {
  title: string;
  config: ClientConfig;
  dispatch: React.Dispatch<DispatchAction>;
}

const GeneratedConfig: FC<GeneratedConfigProps> = ({
  title,
  config,
  dispatch,
}) => {
  const [lastConfig, setLastConfig] = useState({});
  const [metadata, setMetadata] = useState<ConfigMetadata>({
    renderButtons: false,
  });

  useEffect(() => {
    if (_.isEqual(config, lastConfig)) {
      return;
    }
    setLastConfig(config);
  }, [config]);

  const handleShowButtons = () => {
    setMetadata((prev) => ({ ...prev, renderButtons: !prev["renderButtons"] }));
  };

  return (
    <div className="settings-section">
      <h4 className="settings-title">{title}</h4>
      <div className="settings-inputs-container config-deep-0">
        {title === "Button Configuration" && (
          <Checkbox
            onChange={handleShowButtons}
            checked={!!metadata["renderButtons"]}
            label="Show buttons"
          />
        )}
      </div>
      {!_.isEmpty(lastConfig) && (
        <Generator
          subConfig={lastConfig}
          path=""
          metadata={metadata}
          deep={0}
          dispatch={dispatch}
        />
      )}
    </div>
  );
};

export default memo(GeneratedConfig);
