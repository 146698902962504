import React, { useContext, useEffect, useRef, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import { Grid, Segment } from "semantic-ui-react";

import {
  downloadAccountPerformanceReport,
  getAccountPerformanceCallRates,
  getAccountPerformanceStats,
} from "../../../apis/serviceApis";
import AgentAvailabilitChart from "../../../components/AgentAvailabilitChart/AgentAvailabilitChart";
import ConversionsChart from "../../../components/ConversionsChart/ConversionsChart";
import EventsCharts from "../../../components/EventsCharts/EventsCharts";
import HourlyWaitTimeChart from "../../../components/HourlyWaitTimeChart/HourlyWaitTimeChart";
import ProtectedWrapper from "../../../components/ProtectedWrapper/ProtectedWrapper";
import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";
import SimpleCheckbox from "../../../UI-components/SimpleCheckbox/SimpleCheckbox";
import {
  legendTooltipOnHover,
  legendTooltipOnLeave,
} from "../../../utils/chart";
import { groupDailyCallStats } from "../../../utils/dataCollectors";
import { formatToConvenientDates } from "../../../utils/formatDate";
import DateSelectors from "../DateSelectors/DateSelectors";
import { stateOptions } from "../FromToDate/utils";

import {
  hourlyCallsStatsSchema,
  intentsStatsSchema,
  todayHourlyCallsStatsSchema,
  totalCallsSchema,
} from "./ChartsSchema";
import {
  graphHeight,
  graphHeightWithCheckbox,
  graphsLayoutPatterns,
  legendTooltips,
} from "./constants";

import "./AccountPerformance.css";
import FeedbackCharts from "../../../components/FeedbackCharts/FeedbackCharts";

const AccountPerformance = () => {
  const { getTokenSilently } = useAuth0();
  const [showTodayCalls, setShowTodayCalls] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [dailyCallStats, setDailyCallStats] = useState({});
  const [netPromoterScore, setNetPromoterScore] = useState({});
  const [hourlyCallsStats, setHourlyCallsStats] = useState([]);
  const [intentsStats, setIntentsStats] = useState([]);
  const [todayHourlyCallsStats, setTodayHourlyCallsStats] = useState([]);
  const [graphsLayoutPattern, setGraphsLayoutPattern] = useState(
    graphsLayoutPatterns.smallScreen
  );

  const env = useContext(EnvContext);
  const { client, localTimezone } = useContext(ClientContext);

  const totalCallsTooltipRef = useRef(null);
  const hourlyCallsTooltipRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 2000) {
        setGraphsLayoutPattern(graphsLayoutPatterns.bigScreen);
      } else {
        setGraphsLayoutPattern(graphsLayoutPatterns.smallScreen);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (client && dateRange.from && dateRange.to) {
      const startDate = dateRange.from.toLocaleDateString("en-UK");
      const endDate = dateRange.to.toLocaleDateString("en-UK");
      getTokenSilently()
        .then((token) => {
          return getAccountPerformanceStats(
            client,
            startDate,
            endDate,
            token,
            env
          );
        })
        .then((data) => {
          const groupedDailyCallState = groupDailyCallStats(data, dateRange);
          setIntentsStats(data.intents);
          setDailyCallStats(groupedDailyCallState);
          setNetPromoterScore(data.netPromoterScore);
        });

      getTokenSilently()
        .then((token) => {
          return getAccountPerformanceCallRates(
            client,
            startDate,
            endDate,
            token,
            env
          );
        })
        .then((data) => {
          setHourlyCallsStats(data);
        });
    }
  }, [dateRange, env, client]);

  useEffect(() => {
    if (!client) {
      return;
    }
    const today = new Date().toLocaleDateString("en-UK");
    getTokenSilently()
      .then((token) => {
        return getAccountPerformanceCallRates(client, today, today, token, env);
      })
      .then((data) => {
        setTodayHourlyCallsStats(data);
      });
  }, [client]);

  const callsTodayCheckbox = () => {
    setShowTodayCalls(!showTodayCalls);
  };

  const getHourlyCallsDatasets = () => {
    if (showTodayCalls) {
      return todayHourlyCallsStatsSchema(
        hourlyCallsStats,
        todayHourlyCallsStats
      );
    } else {
      return hourlyCallsStatsSchema(hourlyCallsStats);
    }
  };

  const ToDateDropdownFunc = async (fromDate, toDate) => {
    setDateRange({ from: new Date(fromDate), to: new Date(toDate) });
  };

  const exportAccountPerformance = async (fromDate, toDate) => {
    const token = await getTokenSilently();
    const params = {
      clientName: client,
      startDate: new Date(fromDate).toLocaleDateString("en-UK"),
      endDate: new Date(toDate).toLocaleDateString("en-UK"),
      timeZone: localTimezone,
    };
    await downloadAccountPerformanceReport(params, token, env);
  };

  return (
    <Grid style={{ margin: "50px 0px" }}>
      <div className="account-performance__toolbar">
        <DateSelectors
          clickFetchData={ToDateDropdownFunc}
          clickExporData={exportAccountPerformance}
          defaultDatePeriod={stateOptions[1].value}
        />
      </div>
      <Grid.Row>
        <Grid.Column columns={graphsLayoutPattern.row1.columns}>
          <Segment>
            <div className="graph-tooltip" ref={totalCallsTooltipRef} />
            <Line
              data={{
                labels: formatToConvenientDates(Object.keys(dailyCallStats)),
                datasets: totalCallsSchema(Object.values(dailyCallStats)),
              }}
              height={graphHeight}
              options={{
                title: {
                  display: true,
                  text: "Total Calls",
                  fontSize: 20,
                },
                legend: {
                  display: true,
                  position: graphsLayoutPattern.row1.legendPosition,
                  onHover: legendTooltipOnHover(
                    totalCallsTooltipRef,
                    legendTooltips,
                    graphsLayoutPattern.row1.legendPosition
                  ),
                  onLeave: legendTooltipOnLeave(totalCallsTooltipRef),
                },
                maintainAspectRatio: false,
                scales: {
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                      },
                    },
                  ],
                },
              }}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={graphsLayoutPattern.row2.columns}>
        <Grid.Column>
          <Segment>
            <div style={{ width: "100%" }}>
              <div className="graph-tooltip" ref={hourlyCallsTooltipRef} />
              <Bar
                data={{
                  labels: hourlyCallsStats.map((item) => item.time),
                  datasets: getHourlyCallsDatasets(),
                }}
                height={graphHeightWithCheckbox}
                options={{
                  title: {
                    display: true,
                    text: "Hourly Calls Rate Average",
                    fontSize: 20,
                  },
                  legend: {
                    display: true,
                    position: graphsLayoutPattern.row2.legendPosition,
                    onHover: legendTooltipOnHover(
                      hourlyCallsTooltipRef,
                      legendTooltips,
                      graphsLayoutPattern.row2.legendPosition
                    ),
                    onLeave: legendTooltipOnLeave(hourlyCallsTooltipRef),
                  },
                  maintainAspectRatio: false,
                  scales: {
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                        },
                      },
                    ],
                  },
                }}
              />
            </div>
            <SimpleCheckbox
              label={"Show today's calls"}
              onChange={callsTodayCheckbox}
              checked={showTodayCalls}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <AgentAvailabilitChart
              fromDate={dateRange.from}
              toDate={dateRange.to}
              legendPosition={graphsLayoutPattern.row2.legendPosition}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={graphsLayoutPattern.row3.columns}>
        <Grid.Column>
          <Segment>
            <ConversionsChart
              fromDate={dateRange.from}
              toDate={dateRange.to}
              legendPosition={graphsLayoutPattern.row3.legendPosition}
            />
          </Segment>
        </Grid.Column>
        <Grid.Column>
          <Segment>
            <Bar
              data={intentsStatsSchema(intentsStats)}
              height={graphHeight}
              options={{
                legend: {
                  display: false,
                },
                title: {
                  display: true,
                  text: "Intents",
                  fontSize: 20,
                },

                maintainAspectRatio: false,
              }}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={graphsLayoutPattern.row4.columns}>
        <Grid.Column>
          <Segment>
            <HourlyWaitTimeChart
              fromDate={dateRange.from}
              toDate={dateRange.to}
              legendPosition={graphsLayoutPattern.row4.legendPosition}
            />
          </Segment>
        </Grid.Column>
        <ProtectedWrapper permissions={["write:user-config"]}>
          <Grid.Column>
            <Segment>
              <EventsCharts
                fromDate={dateRange.from}
                toDate={dateRange.to}
                legendPosition={graphsLayoutPattern.row4.legendPosition}
              />
            </Segment>
          </Grid.Column>
        </ProtectedWrapper>
      </Grid.Row>
      <ProtectedWrapper permissions={["read:nps"]}>
        <Grid.Row columns={1}>
          <FeedbackCharts netPromoterScore={netPromoterScore} />
        </Grid.Row>
      </ProtectedWrapper>
    </Grid>
  );
};

export default AccountPerformance;
