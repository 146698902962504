import React, {FC, useContext, useEffect, useState} from "react";
import {Button, Container, Segment} from "semantic-ui-react";

import {getPageForAssistant, removeAssistantPage, savePageForAssistant,} from "../../apis/serviceApis";
import EnvContext from "../../context/EnvContext";
import {useAuth0} from "../../react-auth0-spa";
import {Page, PageBackend} from "../types";

import AssistantList from "./AssistantList/AssistantList";

import "./Assistant.css";

const defaultPages: Page[] = [
  {
    id: "1",
    content: "",
    url: "",
    customPrompt: false,
    encoding: "",
    new: true,
    collapsed: false,
    sampleRate: 16000,
    language: ""
  },
];
const Assistant: FC = () => {
  const [pages, setPages] = useState<Page[]>(defaultPages);

  const env = useContext(EnvContext);
  const {getTokenSilently} = useAuth0();
  const fetchPages = async () => {
    const token = await getTokenSilently();
    const res = await getPageForAssistant(token, env);
    const parsedPages: Page[] = res.map((pageBackend): Page => {
      const url = `https://${pageBackend.hostname}${pageBackend.pathname}`;
      return {
        content: pageBackend.content,
        customPrompt: pageBackend.customPrompt,
        encoding: pageBackend.encoding,
        id: pageBackend.id,
        new: false,
        url: url,
        collapsed: true,
        language: pageBackend.language,
        sampleRate: pageBackend.sampleRate
      };
    });
    setPages([...parsedPages]);
  };

  const saveContent = async () => {
    const requestBody: PageBackend[] = [];
    pages.forEach((page) => {
      try {
        const url = new URL(page.url);

        const requestItem = {
          id: page.new ? null : page.id,
          hostname: url.hostname,
          pathname: url.pathname,
          content: page.content,
          customPrompt: !!page.customPrompt,
          encoding: page.encoding,
          sampleRate: page.sampleRate,
          language: page.language
        };
        console.log(`Adding to request ${requestItem.id} with encoding: ${requestItem.encoding} and sample rate: ${requestItem.sampleRate}, url: ${requestItem.hostname} ${requestItem.pathname}`)
        requestBody.push(requestItem);
      } catch (e) {
        console.error("Invalid URL", e);
      }
    });
    const token = await getTokenSilently();
    await savePageForAssistant(token, env, requestBody);
    await fetchPages();
  };

  const updatePageById = (id: string, newValues: any): void => {
    const newPages = pages.map((item) => {
      if (item.id === id) {
        if ("content" in newValues) {
          item.content = newValues.content;
        }
        if ("url" in newValues) {
          item.url = newValues.url;
        }
        if ("customPrompt" in newValues) {
          item.customPrompt = newValues.customPrompt;
        }
        if ("encoding" in newValues) {
          console.log(`Changing encoding for new value ${newValues.encoding}, old value: ${item.encoding}`)
          item.encoding = newValues.encoding;
        }
        if ("collapsed" in newValues) {
          item.collapsed = newValues.collapsed;
        }
        if ("language" in newValues) {
          console.log(`Changing language for new value ${newValues.language}, old value: ${item.language}`)
          item.language = newValues.language;
        }
        if ("sampleRate" in newValues) {
          console.log(`Changing sample rate for new value ${newValues.sampleRate}, old value: ${item.sampleRate}`)
          item.sampleRate = newValues.sampleRate;
        }
      }
      return item;
    });

    setPages(newPages);
  };

  useEffect(() => {
    void fetchPages();
  }, []);

  return (
    <Container className="assistant">
      <Segment className="assistant__submit">
        <Button color="teal" onClick={saveContent}>
          Save
        </Button>
      </Segment>

      <Segment>
        <AssistantList
          pages={pages}
          updatePageById={updatePageById}
          onClickX={async (e) => {
            const id = e.target.dataset.id;
            setPages(pages.filter((page) => page.id !== id));
            const token = await getTokenSilently();
            if (id) {
              await removeAssistantPage(token, env, id);
            }
          }}
          onAddNewItem={() => {
            setPages([
              ...pages,
              {
                id: `${pages.length + 1}`,
                content: "",
                url: "",
                customPrompt: false,
                encoding: "",
                new: true,
                collapsed: false,
                language: "",
                sampleRate: 16000
              },
            ]);
          }}
        />
      </Segment>
    </Container>
  );
};

export default Assistant;
