const labelsPlugin = {
  id: "labels",
  afterDatasetsDraw: (chart) => {
    const width = chart.chart.width;
    const ctx = chart.chart.ctx;
    // remove 66 if there are no legends
    const cy = width / 1.2 - 66;
    ctx.save();

    ctx.fillStyle = "rgb(144, 144, 144)";
    ctx.textAlign = "center";
    ctx.font = "20px Lato";
    ctx.fillText("0", 10, cy);
    ctx.fillText("100", width - 118, cy);
  },
};

const calculateDoughnutCenterY = (height, offsetY, outerRadius, guageSize) => {
  const offsetYFromBottomToChartStart = height - offsetY;
  const offsetYFromBottomToChartEnd =
    offsetYFromBottomToChartStart - outerRadius;
  return height - offsetYFromBottomToChartEnd + guageSize;
};

const guageNeedlePlugin = () => {
  return {
    id: "guageNeedle",
    afterDatasetsDraw: (chart) => {
      let speed = chart.config.options.speed;
      const guageSize = chart.config.options.guageSize;
      const offset = 0.8;

      if (speed === 0) {
        speed = offset;
      } else if (speed === 100) {
        speed = 100 - offset;
      }

      const ctx = chart.chart.ctx;
      const width = chart.chart.width;
      const height = chart.chart.height;
      const offsetY = chart.chart.offsetY;
      const outerRadius = chart.chart.outerRadius;
      ctx.save();
      const angle = Math.PI + (1 / 100) * speed * Math.PI;

      // remove 26 if there are no legends
      const cy =
        calculateDoughnutCenterY(height, offsetY, outerRadius, guageSize) + 26;
      // remove 60 if there are no legends
      const cx = width / 2 - 60;
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -guageSize);
      ctx.lineTo(cx, 0);
      ctx.lineTo(0, guageSize);
      ctx.fillStyle = "grey";
      ctx.fill();
      ctx.rotate(-angle);

      ctx.translate(-cx, -cy);
      ctx.beginPath();
      ctx.arc(cx, cy, guageSize, 0, guageSize);
      ctx.fill();
      ctx.restore();
    },
  };
};

const speedLabelPlugin = () => {
  return {
    beforeDraw: function (chart) {
      const guageSize = chart.config.options.guageSize;
      const speed = chart.config.options.speed;
      const content = `+${speed}%`;

      const width = chart.chart.width;
      const height = chart.chart.height;
      const offsetY = chart.chart.offsetY;
      const outerRadius = chart.chart.outerRadius;
      const ctx = chart.chart.ctx;
      ctx.textAlign = "center";
      ctx.font = "26px Lato";
      const centerY = calculateDoughnutCenterY(
        height,
        offsetY,
        outerRadius,
        guageSize
      );
      // remove 60 and 24 if there are no legends
      ctx.fillText(content, width / 2 - 60, centerY + 35 + 24);
      ctx.save();
    },
  };
};

export {
  labelsPlugin,
  calculateDoughnutCenterY,
  guageNeedlePlugin,
  speedLabelPlugin,
};
