import React, { FC } from "react";
import { Button, Icon, Input, TextArea } from "semantic-ui-react";

import SimpleCheckbox from "../../../UI-components/SimpleCheckbox/SimpleCheckbox";
import { Page } from "../../types";
import {encoding, getValueByKey, languages, sampleRates} from "../../index";
import { OutlineDropdown } from "../../../UI-components";
import "./AssistantList.css";

interface AssistantListProps {
  pages: Page[];
  updatePageById: (id, newValues) => void;
  onClickX: (e) => void;
  onAddNewItem: (e) => void;
}

const AssistantList: FC<AssistantListProps> = ({
  pages,
  onClickX,
  onAddNewItem,
  updatePageById,
}) => {
  return (
    <ul className="assistant__list">
      {pages.map((page) => {
        const collapsedClass = page.collapsed ? "collapsed" : "not-collapsed";
        const labelUrlContent = page.collapsed
          ? `URL - ${page.url}`
          : `URL - ${page.id}`;
        return (
          <li key={page.id} className={collapsedClass}>
            <label htmlFor="assistant__label">{labelUrlContent}</label>
            <div className="assistant__list__input-wrapper">
              <Input
                value={page.url}
                onChange={(e) => {
                  updatePageById(page.id, { url: e.target.value });
                }}
              />
              <a href={page.url} target="_blank" rel="noreferrer">
                Go
              </a>
            </div>

            <label htmlFor="assistant__label">Content</label>
            <TextArea
              rows={12}
              value={page.content}
              onChange={(e) => {
                const target = e.target as HTMLTextAreaElement;
                updatePageById(page.id, { content: target.value });
              }}
            />
            <SimpleCheckbox
              label="Custom Prompt"
              onChange={() => {
                updatePageById(page.id, { customPrompt: !page.customPrompt });
              }}
              checked={page.customPrompt}
            />
            <OutlineDropdown
              options={languages}
              indexOfLabel={0}
              dataAttributes={["key"]}
              label="Languages"
              isSupportFilter={true}
              defaultValue={getValueByKey(page.language) || ""}
              onClick={(e) => {
                updatePageById(page.id, { language: e.target.dataset.key });
              }}
            />
            <OutlineDropdown
              options={sampleRates}
              label="Sample Rates"
              isSupportFilter={false}
              assistantTab={true}
              defaultValue={sampleRates[1]}
              onClick={(e) => {
                updatePageById(page.id, { sampleRate: e.target });
              }}
            />
            <OutlineDropdown
              options={encoding}
              label="Encoding"
              isSupportFilter={false}
              assistantTab={true}
              defaultValue={encoding[0]}
              onClick={(e) => {
                updatePageById(page.id, { encoding: e.target });
              }}
            />
            <Icon
              className={page.new ? "voiceable-hide" : " "}
              name="x"
              data-id={page.id}
              onClick={onClickX}
            />
            <Icon
              size="large"
              name={`angle ${page.collapsed ? "down" : "up"}`}
              onClick={() => {
                updatePageById(page.id, { collapsed: !page.collapsed });
              }}
            />
          </li>
        );
      })}
      <li key="save-button">
        <Button onClick={onAddNewItem}>Add new URL</Button>
      </li>
    </ul>
  );
};

export default AssistantList;
