import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import "./ClientVideoButton.css";

const ClientShareScreen = ({ userSupportClientVideo, onEnable, onDisable }) => {
  const [clientVideoEnabled, setClientVideoEnabled] = useState(false);

  return (
    <div className="video-button-container">
      <Button
        color={
          userSupportClientVideo
            ? !clientVideoEnabled
              ? "teal"
              : "red"
            : "grey"
        }
        disabled={!userSupportClientVideo}
        onClick={() => {
          setClientVideoEnabled(!clientVideoEnabled);
          if (!clientVideoEnabled) {
            onEnable();
          } else {
            onDisable();
          }
        }}
      >
        {userSupportClientVideo
          ? `${
              !clientVideoEnabled ? "Enable" : "Disable"
            } video for user`
          : "User doesn't support video"}
      </Button>
    </div>
  );
};

export default ClientShareScreen;
