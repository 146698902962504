import React, { useState } from "react";

import { ClientConfig } from "../common/types/clientConfig";

interface ClientContextType {
  client: "";
  localTimezone: "UTC";
  clientConfig: ClientConfig;
  stateConfig: ClientConfig;
  configUpdated: boolean;
  updateClientConfig: (updatedConfig: ClientConfig) => void;
  setConfigUpdated: (configUpdated: boolean) => void;
  localVideoTrack: null;
  setLocalVideoTrack: (videoTracks: any[]) => void;
}

const ClientContext = React.createContext<ClientContextType>({
  client: "",
  localTimezone: "UTC",
  clientConfig: {},
  stateConfig: {},
  configUpdated: true,
  updateClientConfig: () => {},
  setConfigUpdated: () => {},
  localVideoTrack: null,
  setLocalVideoTrack: () => {},
});

export const ClientProvider = ({ value, children }) => {
  const [configUpdated, setConfigUpdated] = useState(false);
  const [localVideoTrack, setLocalVideoTrack] = useState(null);

  return (
    <ClientContext.Provider
      value={{
        configUpdated,
        setConfigUpdated,
        client: value.client,
        stateConfig: value.stateConfig,
        clientConfig: value.clientConfig,
        updateClientConfig: value.updateClientConfig,
        localTimezone: value.localTimezone,
        localVideoTrack,
        setLocalVideoTrack,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export default ClientContext;
