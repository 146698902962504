import React from "react";
import "./OutlineDropdownFilter.css";

const OutlineDropdownFilter = ({ setIsShow, value, onChange }) => {
  return (
    <input
      className="outline-dropdown-filter"
      onChange={onChange}
      value={value}
      onMouseEnter={() => {
        setIsShow(true);
      }}
    />
  );
};
export default OutlineDropdownFilter;
