import { getAverageArray, getMinMaxWorkHours } from "../../utils/chart";
import { fetchAgentsAttendances } from "../../views/Functions/GetAgents";

import {
  ADDITIONAL_TIME,
  availabilityAttendance,
  DATA_SAMPLE_STEP_IN_MINUTES,
} from "./constants";

const updateAgentsStates = (
  connection,
  availability,
  onCall,
  agentId,
  description
) => {
  switch (description) {
    case "Agent Connected":
      connection[agentId] = true;
      onCall[agentId] = false;
      break;
    case "Agent Disconnected":
      connection[agentId] = false;
      onCall[agentId] = false;
      break;
    case "Agent Available":
      availability[agentId] = true;
      break;
    case "Agent Unavailable":
      availability[agentId] = false;
      break;
    case "Agent Assigned":
      onCall[agentId] = true;
      break;
    case "Agent Unassigned":
      onCall[agentId] = false;
      break;
  }
};

export const accumulateAvailableAgents = (
  agentsConnection,
  agentsAvailability,
  agentsOnCall
) => {
  return (accumulator, agentId) => {
    let isConnected = false;
    let isAvaialble = true;
    let isOnCall = false;

    if (agentId in agentsConnection) {
      isConnected = agentsConnection[agentId];
    }
    if (agentId in agentsAvailability) {
      isAvaialble = agentsAvailability[agentId];
    }
    if (agentId in agentsOnCall) {
      isOnCall = agentsOnCall[agentId];
    }

    return accumulator + Number(isConnected && isAvaialble && !isOnCall);
  };
};

export const accumulateOnCallAgents = (agentsOnCall) => {
  return (accumulator, agentId) => {
    let isOnCall = false;

    if (agentId in agentsOnCall) {
      isOnCall = agentsOnCall[agentId];
    }

    return accumulator + Number(isOnCall);
  };
};

const assignedFilter = (attendances) => {
  const filteredAttendances = [];
  const onCallAttendances = ["Agent Assigned", "Agent Unassigned"];
  const lastAssignAttendanceMap = {};
  for (const attendance of attendances) {
    const { description } = attendance.logs;
    if (!onCallAttendances.includes(description)) {
      filteredAttendances.push(attendance);
      continue;
    }
    const agentId = attendance.agend_id;
    if (description === "Agent Unassigned") {
      filteredAttendances.push(attendance);
    }
    if (
      lastAssignAttendanceMap[agentId] &&
      lastAssignAttendanceMap[agentId].logs.description === "Agent Assigned" &&
      description === "Agent Unassigned"
    ) {
      filteredAttendances.push(lastAssignAttendanceMap[agentId]);
    }
    lastAssignAttendanceMap[agentId] = attendance;
  }
  return filteredAttendances.sort(
    (f, s) => new Date(f.logs.ts).getTime() - new Date(s.logs.ts).getTime()
  );
};

const getAvailabilityStatsForDay = (
  attendances,
  minutesStep,
  startDate,
  endDate
) => {
  const prepearedAttendances = assignedFilter(attendances)
    .filter(({ logs }) => {
      return availabilityAttendance.some(
        (description) => logs.description === description
      );
    })
    .map(({ logs, agend_id: agent_id }) => ({
      description: logs.description,
      ts: logs.ts,
      agent_id,
    }));
  const selectedDate = new Date(startDate);
  let currentHours = selectedDate.getHours();
  let currentMinutes = selectedDate.getMinutes();
  let currentDay = selectedDate.getDate();
  const agentsAvailability = {};
  const agentsConnection = {};
  const agentsOnCall = {};
  const agentsSet = [];
  const availabilityStats = [];
  const onCallStats = [];
  const currentTime = new Date();
  for (const { ts, description, agent_id } of prepearedAttendances) {
    const utcString = ts + "Z";
    const attendanceTime = new Date(utcString);
    if (attendanceTime > selectedDate) {
      do {
        const availableAgents = agentsSet.reduce(
          accumulateAvailableAgents(
            agentsConnection,
            agentsAvailability,
            agentsOnCall
          ),
          0
        );
        const onCallAgents = agentsSet.reduce(
          accumulateOnCallAgents(agentsOnCall),
          0
        );
        onCallStats.push(onCallAgents);
        availabilityStats.push(availableAgents);
        currentMinutes += minutesStep;
        if (currentMinutes >= 60) {
          currentMinutes = 0;
          currentHours += 1;
        }
        if (currentHours >= 24) {
          currentHours = 0;
          currentDay++;
        }
        selectedDate.setMinutes(currentMinutes);
        selectedDate.setHours(currentHours);
        selectedDate.setDate(currentDay);
      } while (attendanceTime > selectedDate && endDate >= selectedDate);
    }
    if (endDate < selectedDate) {
      break;
    }
    updateAgentsStates(
      agentsConnection,
      agentsAvailability,
      agentsOnCall,
      agent_id,
      description
    );
    agentsSet.indexOf(agent_id) === -1 && agentsSet.push(agent_id);
  }

  while (endDate >= selectedDate) {
    let availableAgents = 0;
    let onCallAgents = 0;
    if (currentTime > selectedDate) {
      availableAgents = agentsSet.reduce(
        accumulateAvailableAgents(
          agentsConnection,
          agentsAvailability,
          agentsOnCall
        ),
        0
      );
      onCallAgents = agentsSet.reduce(accumulateOnCallAgents(agentsOnCall), 0);
    } else {
      break;
    }
    onCallStats.push(onCallAgents);
    availabilityStats.push(availableAgents);
    currentMinutes += minutesStep;
    if (currentMinutes >= 60) {
      currentMinutes = 0;
      currentHours += 1;
    }
    if (currentHours >= 24) {
      currentHours = 0;
      currentDay++;
    }
    selectedDate.setMinutes(currentMinutes);
    selectedDate.setHours(currentHours);
    selectedDate.setDate(currentDay);
  }

  return { availabilityStats, onCallStats };
};

const getAvailabilityStats = (data, workHours, minutesStep = 5) => {
  const attendancesMap = {};
  data.forEach((attendance) => {
    const utcString = attendance.logs.ts + "Z";
    const dataKey = new Date(utcString).toLocaleDateString("en-UK");
    if (attendancesMap[dataKey]) {
      attendancesMap[dataKey].push(attendance);
    } else {
      attendancesMap[dataKey] = [attendance];
    }
  });

  const [startWorkHours, endWorkHours] = getMinMaxWorkHours(
    workHours,
    ADDITIONAL_TIME
  );

  const availabilityDaysData = [];
  const onCallDaysData = [];
  for (const [dataKey, attendances] of Object.entries(attendancesMap)) {
    const [day, month, year] = dataKey.split("/");
    const [startHours, startMinutes] = startWorkHours.split(":");
    const [endHours, endMinutes] = endWorkHours.split(":");
    const startTime = new Date(year, month - 1, day, startHours, startMinutes);
    const endTime = new Date(year, month - 1, day, endHours, endMinutes);
    const { availabilityStats, onCallStats } = getAvailabilityStatsForDay(
      attendances,
      minutesStep,
      startTime,
      endTime
    );
    availabilityDaysData.push(availabilityStats);
    onCallDaysData.push(onCallStats);
  }
  let averageAvailabilityStats = getAverageArray(availabilityDaysData, 1);
  let averageOnCallStats = getAverageArray(onCallDaysData, 1);

  return { averageAvailabilityStats, averageOnCallStats };
};

const getPeriodBetweenDate = (firstDate, secondDate) => {
  const preparedFirstDate = new Date(firstDate).setHours(0, 0, 0, 0);
  const preparedSecondDate = new Date(secondDate).setHours(0, 0, 0, 0);
  const millisecondsDifference = preparedSecondDate - preparedFirstDate;
  return Math.ceil(millisecondsDifference / (1000 * 3600 * 24));
};

const fetchAttendancesDatasets = async (
  getTokenSilently,
  env,
  client,
  workHours,
  startDate,
  endDate
) => {
  try {
    const data = await fetchAgentsAttendances(
      getTokenSilently,
      env,
      client,
      null,
      startDate,
      endDate
    );
    const minTime = new Date(startDate).setHours(0, 0, 0);
    const maxTime = new Date(endDate).setHours(23, 59, 59);

    const filteredData = data.filter(({ logs }) => {
      const utcString = logs.ts + "Z";
      const attendanceTime = new Date(utcString);
      return attendanceTime >= minTime && attendanceTime <= maxTime;
    });

    const { averageAvailabilityStats, averageOnCallStats } =
      getAvailabilityStats(
        filteredData,
        workHours,
        DATA_SAMPLE_STEP_IN_MINUTES
      );
    return {
      availabilityData: averageAvailabilityStats,
      onCallData: averageOnCallStats,
    };
  } catch {
    return {
      availabilityData: [],
      onCallData: [],
    };
  }
};

// minutesStep MUST be able to divide without remainder sum of minutes between startWorkTime and endWorkTime
const getLabelesTickLimit = (workHours, minutesStep = 30) => {
  const [minTime, maxTime] = getMinMaxWorkHours(workHours, ADDITIONAL_TIME);
  const minHours = +minTime.split(":")[0];
  const minMinutes = +minTime.split(":")[1];
  const maxHours = +maxTime.split(":")[0];
  const maxMinutes = +maxTime.split(":")[1];
  const minutesInDay =
    maxHours * 60 + maxMinutes - (minHours * 60 + minMinutes);

  return minutesInDay / minutesStep;
};

export { fetchAttendancesDatasets, getLabelesTickLimit };
