import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Loader } from "semantic-ui-react";

import UsersContext from "../../context/UsersContext";
import AddUser from "../../views/Dashboard/Users/AddUser/AddUser";
import { FilledVoiceableButton } from "../index";
import Input from "../Input/Input";

import "./UserTable.css";

const UserTable = () => {
  const {
    users,
    loading,
    setUser,
    showAddUserPage,
    setShowAddUserPage,
    user: targetUser,
  } = useContext(UsersContext);
  const [sortedUsers, setSortedUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const userListRef = useRef(null);

  useEffect(() => {
    if (userListRef.current && users.length && !loading) {
      const user = userListRef.current.firstChild;
      if (user) {
        const userItemHeight = user.clientHeight + user.clientHeight * 0.05;
        const childCount = userListRef.current.childElementCount;
        const userListHeight = userItemHeight * childCount;
        const heightForScroll = `calc(100% - 26px - 60px)`;

        userListRef.current.style.height = `min(${heightForScroll}, ${userListHeight}px)`;
      }
    }
  }, [users, loading, userListRef]);

  useMemo(() => {
    if (searchValue) {
      setSortedUsers(
        users.filter((user) =>
          user.name
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      );
    } else {
      setSortedUsers(users);
    }
  }, [searchValue, users]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  const hideShowList = () => {
    setShowAddUserPage(() => !showAddUserPage);
  };

  const selectUser = (e) => {
    const userId = e.target.dataset.id;
    const currentUser = users.filter((user) => {
      return user.id === userId;
    });

    if (currentUser.length) {
      setUser(currentUser[0]);
    }
  };

  return (
    <div className="user-table">
      <div className="user-table__btn" onClick={hideShowList}>
        <FilledVoiceableButton>
          {!showAddUserPage ? "Create" : "Users"}
        </FilledVoiceableButton>
      </div>
      {!showAddUserPage ? (
        <>
          <div className="user-table__search__btn">
            <Input
              size="large"
              placeholder="Type here ..."
              defaultValue={searchValue}
              onChange={handleSearchChange}
            />
          </div>
          {loading ? (
            <Loader active inline="centered" />
          ) : (
            <ul className="user-table__list" ref={userListRef}>
              {sortedUsers.map((user) => {
                return (
                  <li
                    key={user.id}
                    data-id={user.id}
                    className={`user-table__list__item${
                      user.id === targetUser?.id ? " active" : ""
                    }`}
                    onClick={selectUser}
                  >
                    <img
                      className="user-table__list__item__img"
                      data-id={user.id}
                      src={user.picture}
                      alt=""
                    />
                    <div
                      className="user-table__list__item__info"
                      data-id={user.id}
                    >
                      <p
                        className="user-table__list__item__full-name"
                        data-id={user.id}
                      >
                        {user.name}
                      </p>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </>
      ) : (
        <AddUser />
      )}
    </div>
  );
};

export default UserTable;
