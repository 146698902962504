import React from "react";
import Input from "../../../UI-components/Input/Input";
import { Button } from "semantic-ui-react";

const Experiment = () => {
  const sessionIdChange = (e) => {
    const value = e.target.value;
    console.log("Entered session ID", value);
  };

  const processButtonClicked = () => {};

  return (
    <div className="settings">
      <Input size="large" placeholder="Session ID" onChange={sessionIdChange} />
      <Button
        text="Process"
        action="Process"
        id="Process"
        onClick={processButtonClicked}
      >
        Transcribe
      </Button>
    </div>
  );
};

export default Experiment;
