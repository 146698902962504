const getConversionsSchema = (totalCheckouts, totalConvertedItems) => {
  return [
    {
      label: "Checkout",
      data: totalCheckouts,
      fill: false,
      backgroundColor: "rgb(75,192,192)",
      borderColor: "rgb(75,192,192)",
    },
    {
      label: "Converted",
      data: totalConvertedItems,
      fill: false,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
    },
  ];
};

export { getConversionsSchema };
