const totalCallsSchema = (callsStats) => {
  return [
    {
      label: "Total Calls",
      data: callsStats.map((item) => item.totalCalls || 0),
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(75,192,192)",
      borderColor: "rgb(75,192,192)",
      hoverBackgroundColor: "rgba(76 180 170 0.75)",
      hoverBorderColor: "rgba(76 180 170 0.75)",
    },
    {
      label: "Successful Calls",
      data: callsStats.map((item) => item.successfulCalls || 0),
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(54,162,235)",
      borderColor: "rgb(54,162,235)",
      hoverBackgroundColor: "rgba(52 150 243 0.75)",
      hoverBorderColor: "rgba(52 150 243 0.75)",
    },
    {
      label: "Unsuccessful Calls",
      data: callsStats.map((item) => item.unsuccessfulCalls || 0),
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
    {
      label: "Abandoned Calls",
      data: callsStats.map((item) => item.abandonedCalls || 0),
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(255,205,86)",
      borderColor: "rgb(255,205,86)",
      hoverBackgroundColor: "rgba(52 150 223 0.75)",
      hoverBorderColor: "rgba(52 150 223 0.75)",
    },
    {
      label: "Missed Calls",
      data: callsStats.map((item) => item.missedCalls || 0),
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(153,102,255)",
      borderColor: "rgb(153,102,255)",
      hoverBackgroundColor: "rgba(107 193 184 0.75)",
      hoverBorderColor: "rgba(107 193 184 0.75)",
    },
  ];
};

const averageWaitTimeSchema = (averageWaitTime) => {
  return [
    {
      label: "Avg. Wait Time",
      data: averageWaitTime,
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(255 116 116)",
      borderColor: "rgb(255 116 116)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
  ];
};

const todayHourlyCallsStatsSchema = (
  hourlyCallsStats,
  todayHourlyCallsStats
) => {
  return [
    {
      label: "Today's Answered Calls",
      data: todayHourlyCallsStats.map((item) => item.answered),
      fill: false,
      backgroundColor: "rgb(75,192,192)",
      borderColor: "rgb(75,192,192)",
      hoverBackgroundColor: "rgba(76 180 170 0.75)",
      hoverBorderColor: "rgba(76 180 170 0.75)",
    },
    {
      label: "Today's Declined Calls",
      data: todayHourlyCallsStats.map((item) => item.declined),
      fill: false,
      backgroundColor: "rgb(255,205,86)",
      borderColor: "rgb(255,205,86)",
      hoverBackgroundColor: "rgba(52 150 223 0.75)",
      hoverBorderColor: "rgba(52 150 223 0.75)",
    },
    {
      label: "Answered Calls",
      data: hourlyCallsStats.map((item) => item.answered),
      fill: false,
      backgroundColor: "rgb(54,162,235)",
      borderColor: "rgb(54,162,235)",
      hoverBackgroundColor: "rgba(52 150 243 0.75)",
      hoverBorderColor: "rgba(52 150 243 0.75)",
    },
    {
      label: "Declined Calls",
      data: hourlyCallsStats.map((item) => item.declined),
      fill: false,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
  ];
};

const hourlyCallsStatsSchema = (hourlyCallsStats) => {
  return [
    {
      label: "Answered Calls",
      data: hourlyCallsStats.map((item) => item.answered),
      fill: false,
      backgroundColor: "rgb(54,162,235)",
      borderColor: "rgb(54,162,235)",
      hoverBackgroundColor: "rgba(52 150 243 0.75)",
      hoverBorderColor: "rgba(52 150 243 0.75)",
    },
    {
      label: "Declined Calls",
      data: hourlyCallsStats.map((item) => item.declined),
      fill: false,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
  ];
};

const intentsStatsSchema = (intentsStats) => {
  let colors = [
    "rgba(0, 114, 107, 1)",
    "rgba(255, 242, 163, 1)",
    "rgba(255, 234, 109, 1)",
    "rgba(254, 222, 30, 1)",
    "rgba(255,241,241)",
    "rgba(255, 219, 219, 1)",
    "rgba(230, 229, 255, 1)",
    "rgba(200, 197, 255, 1)",
    "rgba(146, 140, 243, 1)",
    "rgba(100, 92, 246, 1)",
    "rgba(49, 42, 177, 1)",
    "rgba(229, 252, 250, 1)",
    "rgba(205, 249, 245, 1)",
    "rgba(128, 232, 221, 1)",
    "rgba(75, 180, 170, 1)",
    "rgba(0, 114, 107, 1)",
    "rgba(255, 242, 163, 1)",
    "rgba(255, 234, 109, 1)",
    "rgba(254, 222, 30, 1)",
    "rgba(255,241,241)",
    "rgba(255, 219, 219, 1)",
  ];

  return {
    labels: Object.keys(intentsStats),
    datasets: [
      {
        data: Object.values(intentsStats),
        fill: false,
        backgroundColor: colors,
        borderColor: colors,
        hoverBackgroundColor: colors,
        hoverBorderColor: colors,
      },
    ],
  };
};

export {
  totalCallsSchema,
  averageWaitTimeSchema,
  todayHourlyCallsStatsSchema,
  hourlyCallsStatsSchema,
  intentsStatsSchema,
};
