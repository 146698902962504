import React from "react";

import "./DateCalendar.css";

const DateCalendar = ({ maxDate, onChange, value, label = "Choose date:" }) => {
  const onDateChange = (e) => {
    onChange(e);
  };

  return (
    <div className="date-calendar">
      <label htmlFor="date-calendar">{label}</label>
      <input
        type="date"
        name="date-calendar"
        onChange={onDateChange}
        value={value || ""}
        min="2021-01-01"
        max={maxDate}
      />
    </div>
  );
};

export default DateCalendar;
