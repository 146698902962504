import React, { useContext } from "react";

import { addGroups, updateUser } from "../../../../../apis/serviceApis";
import ClientContext from "../../../../../context/ClientContext";
import EnvContext from "../../../../../context/EnvContext";
import UsersContext from "../../../../../context/UsersContext";
import { useAuth0 } from "../../../../../react-auth0-spa";
import ToggleList from "../../ToggleList/ToggleList";

const UpdateGroups = () => {
  const { groups, user, setUserGroups, userGroups, setGroups } =
    useContext(UsersContext);
  const { getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);
  const env = useContext(EnvContext);

  const handleToggleRole = async (e) => {
    let selectedGroup = e.target.dataset.id;
    let updatedGroups;
    if (userGroups.includes(selectedGroup)) {
      updatedGroups = userGroups.filter((group) => group !== selectedGroup);
    } else {
      updatedGroups = [...userGroups, selectedGroup];
    }
    setUserGroups(updatedGroups);
    await handleUpdateUser(updatedGroups);
  };

  const handleUpdateUser = async (updatedGroups) => {
    try {
      const token = await getTokenSilently();

      const body = {
        clientId: client,
        userId: user.id,
        name: user.name,
        username: user.username,
        groups: updatedGroups,
      };

      await updateUser(token, env, body);
    } catch (e) {
      let reason = e?.response?.data?.reason;
      if (!reason) {
        reason = "An error occurred when updating user.";
      }
      console.error(reason, e);
    }
  };

  const handleAddGroup = async (group) => {
    const usersGroup = [...userGroups, group];
    setUserGroups(usersGroup);

    await handleUpdateUser(usersGroup);

    const token = await getTokenSilently();
    await addGroups(token, env, client, [group]);
  };

  return (
    <ToggleList
      selectedItems={userGroups}
      items={groups}
      handleToggle={handleToggleRole}
      headerInputProps={{
        enabled: true,
        placeholder: "Create group",
        onHeaderInputPressEnter: (newGroup) => {
          if (!groups.includes(newGroup)) {
            setGroups([...groups, newGroup]);
            handleAddGroup(newGroup);
          }
        },
      }}
    />
  );
};

export default UpdateGroups;
