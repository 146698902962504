const convertFromSeconds = (sec) => {
  let hour = sec / 3600;
  let fullHour = Math.floor(hour);

  let min = (hour - fullHour) * 60;
  let fullMin = Math.floor((hour - fullHour) * 60);

  let fullSec = Math.round((min - fullMin) * 60);

  const HH_MM_SS = `${fullHour}h ${fullMin}m ${fullSec}s`.split(" ");
  for (const item of ["0h", "0m", "0s"]) {
    if (HH_MM_SS.includes(item)) {
      HH_MM_SS.splice(HH_MM_SS.indexOf(item), 1);
    }
  }
  return HH_MM_SS.join(" ");
};

export default convertFromSeconds;
