export const legendTooltips = {
  "Today Available": "Average number of today's available agents",
  "Today On Call": "Number of today's agents on call",
  "Average On Call": "Average number of agents on call",
  "Average Available": "Average number of available agents",
};

export const availabilityAttendance = [
  "Agent Connected",
  "Agent Disconnected",
  "Agent Available",
  "Agent Unavailable",
  "Agent Assigned",
  "Agent Unassigned",
];

export const DATA_SAMPLE_STEP_IN_MINUTES = 1;

export const ADDITIONAL_TIME = "00:30";
