import React, { useContext, useEffect, useState } from "react";

import {
  getGroups,
  getRoles,
  getUserRoles,
  getUsers,
} from "../apis/serviceApis";
import { useAuth0 } from "../react-auth0-spa";
import Users from "../views/Dashboard/Users/Users";

import ClientContext from "./ClientContext";
import EnvContext from "./EnvContext";

const UsersContext = React.createContext({
  loading: false,
  users: [],
  roles: [],
  userRoles: [],
  userGroups: [],
  groups: [],
  user: undefined,
  showAddUserPage: false,
  isCopyUser: false,
  setUser: (param) => {},
  setShowAddUserPage: () => {},
  setIsCopyUser: () => {},
  setUserRoles: () => {},
  setUserGroups: () => {},
  setGroups: () => {},
  fetchUsers: async () => {},
});

const UsersProvider = () => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState(undefined);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [showAddUserPage, setShowAddUserPage] = useState(false);
  const [isCopyUser, setIsCopyUser] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userGroups, setUserGroups] = useState([]);

  const { getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);
  const env = useContext(EnvContext);

  const getUserGroups = (user) => {
    let groups = [];
    if (user) {
      const userMetadataGroups = user.metadata.groups;
      if (userMetadataGroups) {
        groups = userMetadataGroups[client] || [];
      }
    }
    return groups;
  };

  const fetchUsers = async () => {
    try {
      setLoading(true);
      const token = await getTokenSilently();
      const data = await getUsers(token, env, client);
      setUsers(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const token = await getTokenSilently();
      const data = await getRoles(token, env);
      setRoles(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserRoles = async () => {
    try {
      const token = await getTokenSilently();

      const data = await getUserRoles(token, env, user.id);
      setUserRoles(data);
    } catch (e) {
      let reason = e?.response?.data?.reason;
      if (!reason) {
        reason = "An error occurred when getting user roles.";
      }
      console.error(reason);
    }
  };

  const fetchGroups = async () => {
    try {
      const token = await getTokenSilently();
      const data = await getGroups(token, env, client);
      setGroups(data);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchUsers();
    fetchGroups();
    fetchRoles();
  }, []);

  useEffect(() => {
    if (user) {
      fetchUserRoles();
      setUserGroups(getUserGroups(user));
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      user.metadata.groups[client] = userGroups;
    }
  }, [userGroups]);

  return (
    <UsersContext.Provider
      value={{
        fetchUsers,
        loading,
        users,
        roles,
        setGroups,
        groups,
        user,
        setUser,
        setShowAddUserPage,
        showAddUserPage,
        isCopyUser,
        setIsCopyUser,
        userRoles,
        setUserRoles,
        setUserGroups,
        userGroups,
      }}
    >
      <Users />
    </UsersContext.Provider>
  );
};
export { UsersProvider };
export default UsersContext;
