import React, { useContext, useEffect, useMemo, useState } from "react";
import { Card, Icon, Input, Loader } from "semantic-ui-react";

import { getUsers } from "../../../apis/serviceApis";
import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";

import "./AgentsList.css";

const AgentsList = (props) => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);

  const [searchTerm, setSearchTerm] = useState("");
  const { getTokenSilently, user } = useAuth0();
  const env = useContext(EnvContext);
  const { client } = useContext(ClientContext);

  useEffect(() => {
    getTokenSilently()
      .then((token) => getUsers(token, env, client))
      .then((agents) => {
        setAgents(agents);
        setLoading(false);
      });
  }, [env, getTokenSilently, client]);

  const clientAgents = useMemo(() => {
    if (!user) {
      return [];
    }

    const admin = user.authorization.roles.includes("Admin");
    if (admin) {
      return agents;
    }
    return agents.filter((agent) => {
      if (!agent.email.includes("@voiceable.co")) {
        return agent;
      }
    });
  }, [agents, user]);

  return (
    <div className="AgentsList">
      <Input
        style={{ marginLeft: "5px", marginBottom: "25px", marginTop: "25px" }}
        size="big"
        transparent
        placeholder="Search..."
        onChange={({ target }) => setSearchTerm(target.value)}
      />

      {loading ? (
        <Loader active inline="centered" />
      ) : (
        <Card.Group>
          {clientAgents.map((agent) => {
            const name = agent.name || agent.username;
            if (!name.toLowerCase().includes(searchTerm.toLowerCase()))
              return "";
            return (
              <Card
                key={agent.id}
                onClick={() =>
                  props.history.push(
                    `${window.location.pathname}/${agent.id.substring(6)}`
                  )
                }
                link
              >
                <Card.Content>
                  <Icon name="user circle" size="massive" />
                  <div className="enterer" />
                  <Card.Header>{name}</Card.Header>
                  <div className="enterer" />
                  <Card.Meta>{agent.id}</Card.Meta>
                </Card.Content>
              </Card>
            );
          })}
        </Card.Group>
      )}
    </div>
  );
};

export default AgentsList;
