export const initDescriptionOptions = [
  {
    key: "Agent Connected",
    text: "Agent Connected",
    value: "Agent Connected",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Disconnected",
    text: "Agent Disconnected",
    value: "Agent Disconnected",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Unavailable",
    text: "Agent Unavailable",
    value: "Agent Unavailable",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Available",
    text: "Agent Available",
    value: "Agent Available",
    checked: true,
    processId: 0,
  },
  {
    key: "Agent Assigned",
    text: "Agent Assigned",
    value: "Agent Assigned",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Unassigned",
    text: "Agent Unassigned",
    value: "Agent Unassigned",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Answered",
    text: "Agent Answered",
    value: "Agent Answered",
    checked: true,
    processId: 1,
  },
  {
    key: "Leave Group Call",
    text: "Leave Group Call",
    value: "Leave Group Call",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Declined",
    text: "Agent Declined",
    value: "Agent Declined",
    checked: true,
    processId: 1,
  },
  {
    key: "Join Group Call",
    text: "Join Group Call",
    value: "Join Group Call",
    checked: true,
    processId: 1,
  },
  {
    key: "Client Finished Call",
    text: "Client Finished Call",
    value: "Client Finished Call",
    checked: true,
    processId: 1,
  },
  {
    key: "Agent Finished Call",
    text: "Agent Finished Call",
    value: "Agent Finished Call",
    checked: true,
    processId: 1,
  },
];

export const initProcessOptions = [
  {
    key: "Agent Availability",
    text: "Agent Availability",
    value: "Agent Availability",
    checked: true,
    id: 0,
  },
  {
    key: "Support Call",
    text: "Support Call",
    value: "Support Call",
    checked: true,
    id: 1,
  },
];

export const stateOptions = [
  {
    key: 1,
    text: "Today",
    value: "Today",
  },
  {
    key: 2,
    text: "Last Week",
    value: "Last Week",
  },
  {
    key: 3,
    text: "Last Month",
    value: "Last Month",
  },
];

export const searchOptions = [
  {
    key: "Agent name",
    text: "Agent name",
    value: "Agent name",
  },
];

export const endCallDescriptions = [
  "Agent Unassigned",
  "Agent Finished Call",
  "Client Finished Call",
];
export const connectionDescriptions = ["Agent Connected", "Agent Disconnected"];
