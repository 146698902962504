import React, { useContext, useState } from "react";
import { Button, Checkbox, Form, Modal } from "semantic-ui-react";

import LanguageContext from "../../context/LanguageContext";

const ModalAvailability = ({
  openModal,
  setOpenModal,
  onClose,
  unavailableAgent,
}) => {
  const [reason, setReason] = useState(null);
  const { language, languageDirection } = useContext(LanguageContext);
  const handleChange = (e, { value }) => setReason(value);

  const onClickApply = (reason) => {
    unavailableAgent(reason);
    setOpenModal(false);
  };

  //only need to change the array when we want to
  //add reasons for the agent to choose from
  const arrayOfReasons = [
    ...Object.values(language.console.sidebar.availability.statuses),
  ];

  const radioMaker = (reasons) => {
    return reasons.map((value, index) => (
      <Form.Field key={index}>
        <Checkbox
          radio
          label={value}
          name="checkboxRadioGroup"
          value={value}
          checked={reason === value}
          onChange={handleChange}
        />
      </Form.Field>
    ));
  };

  return (
    <>
      <Modal closeIcon size="tiny" open={openModal} onClose={onClose}>
        <Modal.Header style={{ direction: languageDirection }}>
          {language.console.sidebar.availability.header}
        </Modal.Header>
        <Modal.Content>
          <Form style={{ direction: languageDirection }}>
            <Form.Field style={{ direction: languageDirection }}>
              {language.console.sidebar.availability.selectedValue}
              <b>{reason}</b>
            </Form.Field>
            {radioMaker(arrayOfReasons)}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button style={{ direction: languageDirection }} onClick={onClose}>
            {language.common.cancel}
          </Button>
          <Button
            style={{ direction: languageDirection }}
            color="teal"
            onClick={() => onClickApply(reason)}
          >
            {language.common.apply}
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default ModalAvailability;
