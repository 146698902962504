import React, { FC } from "react";

import { optionChart } from "../../types";

import "./NetPromoterScoreList.css";

interface NetPromoteScoreListProps {
  optionChartList: optionChart[];
}

const NetPromoteScoreList: FC<NetPromoteScoreListProps> = ({
  optionChartList,
}) => {
  return (
    <div className="net-promote-score__list">
      <ul>
        {optionChartList.map((item, index, arr) => {
          item = arr[optionChartList.length - 1 - index];

          return (
            <li key={item.id}>
              <p>{item.label}</p>
              <p style={{ color: item.color }}>
                {item.value} ({Math.round(item.per)}%)
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default NetPromoteScoreList;
