import React, { useContext, useEffect, useReducer, useState } from "react";
import _ from "lodash";
import { Button, Segment } from "semantic-ui-react";

import { ClientConfig } from "../../../common/types/clientConfig";
import ClientContext from "../../../context/ClientContext";
import {
  convertLocalTimeToUTC,
  convertUTCTimeToLocal,
} from "../../../utils/formatTime";
import { modifyNestedObject } from "../../../utils/helpers";

import ConfigurationGenerator from "./GeneretedConfig/GeneretedConfig";
import { baseConfig, sectionsSchemas } from "./constants";
import { DispatchAction } from "./types";
import { getSectionConfigs } from "./utils";

import "./Settings.css";

const reducer = (state: ClientConfig, action: DispatchAction): ClientConfig => {
  switch (action.type) {
    case "inputField": {
      return modifyNestedObject(
        state,
        action.payload.name,
        action.payload.value
      );
    }
    case "init": {
      return _.cloneDeep(action.payload);
    }
    default:
      throw new Error();
  }
};

const Settings = () => {
  const [isConverted, setIsConverted] = useState(true);
  const {
    clientConfig,
    stateConfig,
    updateClientConfig,
    configUpdated,
    setConfigUpdated,
  } = useContext(ClientContext);
  const [state, dispatch] = useReducer(reducer, {});

  const getClonedLocaleClientConfig = () => {
    const clonedClientConfig = _.cloneDeep({ ...baseConfig, ...clientConfig });
    clonedClientConfig.workDays.map((day) => {
      day.startTime = convertUTCTimeToLocal(day.startTime);
      day.endTime = convertUTCTimeToLocal(day.endTime);
      return day;
    });

    return clonedClientConfig;
  };

  useEffect(() => {
    let isConfigUpdated = !_.isEqual(clientConfig, state);
    if (!_.isEmpty(clientConfig)) {
      const clonedClientConfig = getClonedLocaleClientConfig();
      isConfigUpdated = !_.isEqual(clonedClientConfig, state);
    }

    setConfigUpdated(isConfigUpdated);
  }, [state, clientConfig, isConverted]);

  useEffect(() => {
    if (isConverted && !_.isEmpty(clientConfig)) {
      setIsConverted(false);
      const clonedClientConfig = getClonedLocaleClientConfig();
      dispatch({
        type: "init",
        payload: clonedClientConfig,
      });

      sessionStorage.removeItem("voiceable_config");

      sessionStorage.setItem(
        "voiceable-dashboard-client-hostname",
        clientConfig.cookieDomain as string
      );

      const tempPathname = "/ae/en/";

      sessionStorage.setItem(
        "voiceable-dashboard-client-pathname",
        tempPathname
      );
    }
  }, [isConverted, clientConfig]);

  const handleClickUpdate = () => {
    const clonedState = _.cloneDeep(state);
    clonedState.workDays.map((day) => {
      day.startTime = convertLocalTimeToUTC(day.startTime);
      day.endTime = convertLocalTimeToUTC(day.endTime);
      return day;
    });
    updateClientConfig(clonedState);
    setIsConverted(true);
  };

  const handleClickCancel = () => {
    setConfigUpdated(false);
    dispatch({
      type: "init",
      payload: _.cloneDeep(getClonedLocaleClientConfig()),
    });
  };

  const stateConfigDispatch = () => {};

  return (
    <div className="settings">
      <Segment className="buttons-section">
        <Button
          color="teal"
          disabled={!configUpdated}
          onClick={handleClickUpdate}
        >
          Save
        </Button>
        <Button disabled={!configUpdated} onClick={handleClickCancel}>
          Cancel
        </Button>
      </Segment>
      {getSectionConfigs(sectionsSchemas, state, "General configuration").map(
        ({ title, sectionConfig }, i) => {
          return (
            <Segment key={i}>
              <ConfigurationGenerator
                title={title}
                config={sectionConfig}
                dispatch={dispatch}
              />
            </Segment>
          );
        }
      )}
      <Segment>
        <ConfigurationGenerator
          title={"State configuration"}
          config={stateConfig}
          dispatch={stateConfigDispatch}
        />
      </Segment>
    </div>
  );
};

export default Settings;
