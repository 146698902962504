import React from "react";

import "./FieldButton.css";

const FieldButton = ({ label, item, setItem, ...props }) => {
  const renderValueOrPlacehoder = () => {
    let renderItem;
    if (props.placeholder) {
      renderItem = { placeholder: props.placeholder };
    } else {
      renderItem = { value: item };
    }
    return renderItem;
  };

  const handlChange = (e) => {
    setItem(e.target.value);
  };

  return (
    <div className="field-button">
      <label htmlFor="field-button__label">{label}</label>
      <input
        {...renderValueOrPlacehoder()}
        {...props}
        onChange={handlChange}
        className="field-button__input"
      />
    </div>
  );
};

export default FieldButton;
