import { useEffect, useState } from "react";

const useAudio = (
  audioFile,
  options = { loop: false, autoPlay: false, duration: -1 }
) => {
  const [audio] = useState(new Audio(audioFile));
  const [playing, setPlaying] = useState(false);
  const [sinkId, setSinkId] = useState("");

  const play = () => {
    audio.currentTime = 0;
    setPlaying(true);
  };

  const pause = () => {
    setPlaying(false);
    audio.currentTime = 0;
  };

  useEffect(() => {
    if (audio && audio.setSinkId) {
      audio.setSinkId(sinkId);
    }
  }, [sinkId]);

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", pause);
    audio.ontimeupdate = () => {
      if (options.duration > 0 && audio.currentTime > options.duration) {
        pause();
      }
    };
    audio.loop = options.loop;
    audio.autoplay = options.autoPlay;
    return () => {
      audio.pause();
      audio.src = "";
      audio.currentTime = 0;
      audio.volume = 0;
      audio.removeEventListener("ended", pause);
      audio.remove();
    };
  }, []);

  return { play, pause, playing, setSinkId };
};

export default useAudio;
