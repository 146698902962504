import React, { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import { getStatistics } from "../../apis/serviceApis";
import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";
import { legendTooltipOnHover, legendTooltipOnLeave } from "../../utils/chart";
import { formatToConvenientDates } from "../../utils/formatDate";
import { graphHeight } from "../../views/Dashboard/AccountPerformance/constants";

import eventsSchema from "./ChartsSchema";
import { legendLabels } from "./constants";

const EventsCharts = ({ fromDate, toDate, legendPosition }) => {
  const [eventStats, setEventStats] = useState([]);
  const eventsTooltipRef = useRef(null);

  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvContext);
  const { client } = useContext(ClientContext);

  useEffect(() => {
    if (client && fromDate && toDate) {
      fetchEvents();
    }
  }, [client, fromDate, toDate]);

  const fetchEvents = async () => {
    const token = await getTokenSilently();
    const startDate = new Date(fromDate).toLocaleDateString("en-UK");
    const endDate = new Date(toDate).toLocaleDateString("en-UK");
    const statistics = await getStatistics(
      token,
      env,
      client,
      startDate,
      endDate
    );
    setEventStats(statistics);
  };

  const groupData = () => {
    const labels = [];
    const events = [];

    const sortedEventsStats = Object.entries(eventStats).sort((a, b) => {
      return new Date(a[0]).getTime() - new Date(b[0]).getTime();
    });

    for (const [date, event] of sortedEventsStats) {
      labels.push(formatToConvenientDates([date], "YYYY-MM-DD")[0]);
      events.push(event);
    }

    const totalViews = events.map((item) => item.totalViews);
    const buttonViews = events.map((item) => item.buttonViews);
    const distinctUsers = events.map((item) => item.distinctUsers);
    const distinctPages = events.map((item) => item.distinctPages);
    return {
      labels,
      datasets: eventsSchema(
        totalViews,
        buttonViews,
        distinctUsers,
        distinctPages
      ),
    };
  };

  const groupLegendTooltip = () => {
    const legendTooltip = {};
    for (const [, value] of Object.entries(legendLabels)) {
      legendTooltip[value.label] = value.description;
    }
    return legendTooltip;
  };

  return (
    <div>
      <div className="graph-tooltip" ref={eventsTooltipRef} />
      <Line
        data={groupData()}
        height={graphHeight}
        options={{
          title: {
            display: true,
            text: "Pages & Users",
            fontSize: 20,
          },
          legend: {
            display: true,
            position: legendPosition,
            onHover: legendTooltipOnHover(
              eventsTooltipRef,
              groupLegendTooltip(),
              legendPosition
            ),
            onLeave: legendTooltipOnLeave(eventsTooltipRef),
          },
          maintainAspectRatio: false,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        }}
      />
    </div>
  );
};

export default EventsCharts;
