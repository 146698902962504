import React, { useEffect, useState } from "react";

import { usePagination } from "../../hooks/usePagination";

import "./Pagination.css";

const Pagination = ({
  items,
  setItemsByPage,
  onClick = (e) => {},
  pageSize = 10,
  pageNumber = 1,
}) => {
  const [currentPage, setCurrentPage] = useState(pageNumber);
  const range = usePagination({
    totalItemCount: items.length,
    pageSize,
    currentPage,
    siblingCount: 2,
  });

  useEffect(() => {
    setCurrentPage(pageNumber);
  }, [pageNumber, items, pageSize]);

  useEffect(() => {
    setItemsByPage(
      items.slice(
        (currentPage - 1) * pageSize,
        (currentPage - 1) * pageSize + pageSize
      )
    );
  }, [currentPage, items, pageSize]);

  const handleClick = (e) => {
    onClick(e);
    setCurrentPage(Number(e.target.dataset.id));
  };

  return (
    <div className="pagination">
      <ul className="pagination__list">
        {range.map((item, index) => {
          if (item === "...") {
            return (
              <li className="pagination__space" key={`${item}-${index}`}>
                ...
              </li>
            );
          }

          return (
            <li
              onClick={handleClick}
              key={Number(item)}
              data-id={item}
              className={`pagination__item ${
                currentPage === Number(item) ? "pagination__item__active" : ""
              }`}
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Pagination;
