import { legendLabels } from "./constants";

const eventsSchema = (
  totalViews,
  buttonViews,
  distinctUsers,
  distinctPages
) => {
  return [
    {
      label: legendLabels.totalViews.label,
      data: totalViews,
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(75,192,192)",
      borderColor: "rgb(75,192,192)",
      hoverBackgroundColor: "rgba(76 180 170 0.75)",
      hoverBorderColor: "rgba(76 180 170 0.75)",
    },
    {
      label: legendLabels.buttonViews.label,
      data: buttonViews,
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(54,162,235)",
      borderColor: "rgb(54,162,235)",
      hoverBackgroundColor: "rgba(52 150 243 0.75)",
      hoverBorderColor: "rgba(52 150 243 0.75)",
    },
    {
      label: legendLabels.distinctUsers.label,
      data: distinctUsers,
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
    {
      label: legendLabels.distinctPages.label,
      data: distinctPages,
      fill: false,
      lineTension: 0.4,
      backgroundColor: "rgb(255,205,86)",
      borderColor: "rgb(255,205,86)",
      hoverBackgroundColor: "rgba(52 150 223 0.75)",
      hoverBorderColor: "rgba(52 150 223 0.75)",
    },
  ];
};

export default eventsSchema;
