import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Header, Icon, Loader, Segment, Table } from "semantic-ui-react";

import ClientContext from "../../../context/ClientContext";
import { Pagination, PaginationPageSettings } from "../../../UI-components";
import ConversionPeriod from "../../../UI-components/ConversionPeriod/ConversionPeriod";
import convertFromSeconds from "../../../utils/convertFromSeconds";
import { sortConversations } from "../../Functions/SortFunctions";

import "./Conversations.css";

const ConversationsTable = ({
  dateRange,
  isLoading,
  conversations,
  onConversionPeriodChange,
  oneAgentOnly = false,
}) => {
  const [filteredConversations, setFilteredConversations] = useState([]);
  const [orderedByArrow, setOrderedByArrow] = useState(null);

  const [conversationByPage, setConversationByPage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const { client } = useContext(ClientContext);

  useEffect(() => {
    if (!conversations) {
      setFilteredConversations(null);
    } else
      sortConversations(
        conversations,
        "Date up",
        setFilteredConversations,
        setOrderedByArrow
      );
  }, [conversations]);

  useEffect(() => {
    setPageNumber(1);
  }, [conversations, dateRange]);

  const mapConversationsToTable = (conversations) => {
    return conversations.map((conversation) => {
      let agents = "-";
      if (conversation.agents && conversation.agents.length) {
        agents = conversation.agents.join(",\n");
      }
      return (
        <Table.Row
          key={conversation.sessionId}
          style={{
            cursor: "pointer",
            position: "relative",
            transform: "rotate(0)",
          }}
        >
          <Table.Cell>{conversation.date}</Table.Cell>
          <Table.Cell>{conversation.time}</Table.Cell>
          {!oneAgentOnly && <Table.Cell>{agents}</Table.Cell>}
          <Table.Cell>
            {convertFromSeconds(conversation.waitTime) || "-"}
          </Table.Cell>
          <Table.Cell>{convertFromSeconds(conversation.duration)}</Table.Cell>
          <Table.Cell>
            {conversation.converted ? (
              <span style={{ color: "teal" }}>Yes</span>
            ) : (
              "-"
            )}
          </Table.Cell>
          <Table.Cell>
            {Math.round(conversation.checkoutValue * 100) / 100}
          </Table.Cell>
          <Table.Cell>
            {conversation.returningUser ? (
              <span style={{ color: "teal" }}>
                Yes
                {conversation.userId && ` (${conversation.userId.slice(0, 6)})`}
              </span>
            ) : (
              "-"
            )}
          </Table.Cell>
          <Link
            to={`/${client}/dashboard/conversations/${conversation.sessionId}`}
            className="link"
          />
        </Table.Row>
      );
    });
  };

  const arrowFunctionHandler = (sortOption, conversations) => {
    if (orderedByArrow !== sortOption) {
      return sortConversations(
        conversations,
        sortOption,
        setFilteredConversations,
        setOrderedByArrow
      );
    }
    return sortConversations(
      conversations,
      sortOption + " up",
      setFilteredConversations,
      setOrderedByArrow
    );
  };

  const caretIconHandler = (sortOption) => {
    return orderedByArrow === sortOption ? (
      <Icon name="caret up" />
    ) : orderedByArrow === sortOption + " up" ? (
      <Icon name="caret down" />
    ) : null;
  };

  return (
    <div className="Conversations-Dashboard">
      <Segment raised padded className="segment-style flipped">
        <div className="flipped">
          <div className="conversation-table__top">
            <Header>Conversations</Header>
            <div className="conversation-table__top__left">
              <ConversionPeriod
                loading={isLoading}
                reFetchData={onConversionPeriodChange}
              />
              <PaginationPageSettings
                items={filteredConversations}
                pageNumber={pageNumber}
                pageSize={pageSize}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
              />
            </div>
          </div>
          <Table basic="very" textAlign="center" selectable unstackable>
            <Table.Header>
              <Table.HeaderCell
                onClick={() => arrowFunctionHandler("Date", conversations)}
                className="tooltip"
              >
                {caretIconHandler("Date")}
                Date
                <span className="tooltiptext">
                  The date of the converstion started
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell>Started At</Table.HeaderCell>
              {!oneAgentOnly && (
                <Table.HeaderCell
                  onClick={() =>
                    arrowFunctionHandler("agentName", conversations)
                  }
                  className="tooltip"
                >
                  {caretIconHandler("agentName")}
                  Agent Name
                  <span className="tooltiptext">
                    The name of the agent took the call
                  </span>
                </Table.HeaderCell>
              )}
              <Table.HeaderCell
                onClick={() => arrowFunctionHandler("waitTime", conversations)}
                className="tooltip"
              >
                {caretIconHandler("waitTime")}
                Wait Time
                <span className="tooltiptext">
                  how long the call has answered
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => arrowFunctionHandler("duration", conversations)}
                className="tooltip"
              >
                {caretIconHandler("duration")}
                Duration
                <span className="tooltiptext">
                  how long the agent talked with the client
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() => arrowFunctionHandler("converted", conversations)}
                className="tooltip"
              >
                {caretIconHandler("converted")}
                Converted
                <span className="tooltiptext">
                  Products dicussed in the call also bought by client
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() =>
                  arrowFunctionHandler("checkoutValue", conversations)
                }
                className="tooltip"
              >
                {caretIconHandler("checkoutValue")}
                Checkout Value
                <span className="tooltiptext">
                  The value of the checkout of the client
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                onClick={() =>
                  arrowFunctionHandler("returningUser", conversations)
                }
                className="tooltip"
              >
                {caretIconHandler("returningUser")}
                Returning Voiceable User
                <span className="tooltiptext">
                  Check if client was already called before this call
                </span>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {conversationByPage &&
                conversationByPage.length > 0 &&
                mapConversationsToTable(conversationByPage)}
            </Table.Body>
          </Table>
          {isLoading && <Loader active inline="centered" />}
          {!isLoading &&
            filteredConversations &&
            filteredConversations.length === 0 && (
              <div style={{ textAlign: "center", fontSize: "20px" }}>
                No Data
              </div>
            )}
          <Pagination
            pageSize={pageSize}
            items={filteredConversations || []}
            setItemsByPage={setConversationByPage}
            pageNumber={pageNumber}
            onClick={(e) => setPageNumber(+e.target.dataset.id)}
          />
        </div>
      </Segment>
    </div>
  );
};

export default React.memo(ConversationsTable);
