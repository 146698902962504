import React, { useContext, useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";

import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";
import { SimpleCheckbox } from "../../UI-components";
import { getLabelsByWorkHours } from "../../utils/chart";
import { graphHeightWithCheckbox } from "../../views/Dashboard/AccountPerformance/constants";

import { getWaitTimeStatsSchema } from "./ChartsSchema";
import { fetchWaitTimeStats, getDateLabelsBetweenDates } from "./utils";

const HourlyWaitTimeChart = ({ fromDate, toDate, legendPosition }) => {
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvContext);
  const { client, clientConfig } = useContext(ClientContext);
  const [selectedChartData, setSelectedChartData] = useState({
    hourlyAnsweredStats: [],
    hourlyDeclinedStats: [],
    averageAnsweredStats: [],
    averageDeclinedStats: [],
  });
  const [showHourlyStats, setShowHourlyStats] = useState(false);
  const hourlyLabels = getLabelsByWorkHours(clientConfig.workDays, 60);
  const datesLabels = getDateLabelsBetweenDates(fromDate, toDate);

  const isOneDaySelected =
    toDate && fromDate && toDate.getTime() == fromDate.getTime();

  const ChartComponent = isOneDaySelected || showHourlyStats ? Bar : Line;

  const onToggleChange = () => {
    setShowHourlyStats((checked) => !checked);
  };

  const getChartData = () => {
    const chartData = {
      labels: [],
      datasets: [],
    };

    if (!fromDate || !toDate) {
      return chartData;
    }

    if (isOneDaySelected || showHourlyStats) {
      chartData.labels = hourlyLabels;
      chartData.datasets = getWaitTimeStatsSchema(
        selectedChartData.hourlyAnsweredStats,
        selectedChartData.hourlyDeclinedStats
      );
    } else {
      chartData.labels = datesLabels;
      chartData.datasets = getWaitTimeStatsSchema(
        selectedChartData.averageAnsweredStats,
        selectedChartData.averageDeclinedStats
      );
    }

    return chartData;
  };

  useEffect(() => {
    if (client && clientConfig.workDays && fromDate && toDate) {
      fetchWaitTimeStats(
        client,
        getTokenSilently,
        env,
        clientConfig.workDays,
        fromDate,
        toDate
      ).then((stats) => {
        setSelectedChartData(stats);
      });
    }
  }, [fromDate, toDate, clientConfig, getTokenSilently, env, client]);

  return (
    <>
      <div>
        <ChartComponent
          data={getChartData()}
          height={graphHeightWithCheckbox}
          options={{
            title: {
              display: true,
              text: "Customers Average Wait Time In Seconds",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: legendPosition,
            },
            maintainAspectRatio: false,
          }}
        />
      </div>
      {!isOneDaySelected && (
        <SimpleCheckbox
          label={"Show as hourly average histogram"}
          onChange={onToggleChange}
          checked={showHourlyStats}
        />
      )}
    </>
  );
};

export default HourlyWaitTimeChart;
