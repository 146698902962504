import React, { useContext, useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";

import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";
import SimpleCheckbox from "../../UI-components/SimpleCheckbox/SimpleCheckbox";
import {
  getLabelsByWorkHours,
  legendTooltipOnHover,
  legendTooltipOnLeave,
} from "../../utils/chart";
import { graphHeightWithCheckbox } from "../../views/Dashboard/AccountPerformance/constants";

import {
  getAvailabilityStatsSchema,
  getTodaysAvailabilityStatsSchema,
} from "./ChartsSchema";
import {
  ADDITIONAL_TIME,
  DATA_SAMPLE_STEP_IN_MINUTES,
  legendTooltips,
} from "./constants";
import { fetchAttendancesDatasets, getLabelesTickLimit } from "./utils";

const AgentAvailabilitChart = ({ fromDate, toDate, legendPosition }) => {
  const env = useContext(EnvContext);
  const [selectedChartData, setSelectedChartData] = useState({
    availabilityData: [],
    onCallData: [],
  });
  const [todayChartData, setTodayChartData] = useState({
    availabilityData: [],
    onCallData: [],
  });
  const [showTodayData, setShowTodayData] = useState(false);
  const { client, clientConfig } = useContext(ClientContext);
  const { getTokenSilently } = useAuth0();
  const tooltipRef = useRef(null);

  const labels = getLabelsByWorkHours(
    clientConfig.workDays,
    DATA_SAMPLE_STEP_IN_MINUTES,
    ADDITIONAL_TIME
  );

  const getChartData = () => {
    const chartData = {
      labels: [],
      datasets: [],
    };
    const isSelectedDataFilled =
      selectedChartData.availabilityData.length &&
      selectedChartData.onCallData.length;

    if (!labels.length || !isSelectedDataFilled) {
      return chartData;
    }

    chartData.labels = labels;

    if (showTodayData) {
      chartData.datasets = getTodaysAvailabilityStatsSchema(
        selectedChartData.availabilityData,
        selectedChartData.onCallData,
        todayChartData.availabilityData,
        todayChartData.onCallData
      );
    } else {
      chartData.datasets = getAvailabilityStatsSchema(
        selectedChartData.availabilityData,
        selectedChartData.onCallData
      );
    }

    return chartData;
  };

  const onToggleChange = () => {
    setShowTodayData((checked) => !checked);
  };

  useEffect(() => {
    if (client && clientConfig.workDays && fromDate && toDate) {
      fetchAttendancesDatasets(
        getTokenSilently,
        env,
        client,
        clientConfig.workDays,
        fromDate,
        toDate
      ).then((res) => {
        setSelectedChartData(res);
      });

      const today = new Date();
      fetchAttendancesDatasets(
        getTokenSilently,
        env,
        client,
        clientConfig.workDays,
        today,
        today
      ).then((res) => {
        setTodayChartData(res);
      });
    }
  }, [fromDate, toDate, clientConfig, getTokenSilently, env, client]);

  return (
    <>
      <div>
        <div className="graph-tooltip" ref={tooltipRef} />
        <Line
          data={getChartData()}
          height={graphHeightWithCheckbox}
          options={{
            title: {
              display: true,
              text: "Average Agent Availability",
              fontSize: 20,
            },
            legend: {
              display: true,
              position: legendPosition,
              onHover: legendTooltipOnHover(
                tooltipRef,
                legendTooltips,
                legendPosition
              ),
              onLeave: legendTooltipOnLeave(tooltipRef),
            },
            elements: {
              point: {
                radius: 0,
              },
            },
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  ticks: {
                    autoSkip: true,
                    maxTicksLimit: getLabelesTickLimit(
                      clientConfig.workDays,
                      30
                    ),
                  },
                },
              ],
            },
          }}
        />
      </div>
      <SimpleCheckbox
        label={"Show today's availability"}
        onChange={onToggleChange}
        checked={showTodayData}
      />
    </>
  );
};

export default AgentAvailabilitChart;
