import React, { useContext, useEffect, useState } from "react";

import { addRoles, addUser } from "../../../../apis/serviceApis";
import ClientContext from "../../../../context/ClientContext";
import EnvContext from "../../../../context/EnvContext";
import UsersContext from "../../../../context/UsersContext";
import { useAuth0 } from "../../../../react-auth0-spa";
import {
  FieldButton,
  FilledVoiceableButton,
  ListItems,
} from "../../../../UI-components";
import MultipleSelect from "../../../../UI-components/MultipleSelect/MultipleSelect";

import "./AddUser.css";

const AddUser = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userGroups, setUserGroups] = useState([]);
  const [userRolesName, setUserRolesName] = useState([]);

  const { getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);
  const {
    fetchUsers,
    setShowAddUserPage,
    setIsCopyUser,
    setUser,
    isCopyUser,
    user,
    userRoles: currentUserRoles,
    roles,
    groups,
  } = useContext(UsersContext);
  const env = useContext(EnvContext);

  useEffect(() => {
    if (isCopyUser && user) {
      setFirstName(user.name.split(" ")[0]);
      setLastName(user.name.split(" ")[1]);
      setUsername(user.username);
      setEmail(user.email);
      setUserGroups(user.metadata.groups[client]);
      setUserRolesName(currentUserRoles.map((role) => role.name));
      setUser("");
      setIsCopyUser(false);
    }
  }, [isCopyUser, user]);

  const getRolesIdByName = (userRolesName) => {
    return roles
      .map((role) => {
        for (const userRoleName of userRolesName) {
          if (role.name === userRoleName) {
            return role.id;
          }
        }
      })
      .filter((item) => item);
  };

  const handleAddUser = async () => {
    const newUser = {
      clientId: client,
      email,
      name: `${firstName} ${lastName}`,
      username,
      password,
      groups: userGroups,
      rolesId: getRolesIdByName(userRolesName),
    };

    if (password === confirmPassword) {
      try {
        const token = await getTokenSilently();
        const addedUser = await addUser(token, env, newUser);
        await addRoles(token, env, addedUser.id, newUser.rolesId);
        await fetchUsers();
        setShowAddUserPage(false);
      } catch (e) {
        let reason = e?.response?.data?.reason;
        if (!reason) {
          reason = "An error occurred when creating user.";
        }
        console.error(reason);
      }
    } else {
      console.error("Passwords are not the same.");
    }
  };

  return (
    <div className="add-user__modal-screen">
      <ListItems>
        <FieldButton
          label="First Name"
          item={firstName}
          setItem={setFirstName}
        />
        <FieldButton label="Last Name" item={lastName} setItem={setLastName} />
        <FieldButton label="Username" item={username} setItem={setUsername} />
        <FieldButton label="Email" item={email} setItem={setEmail} />
        <FieldButton
          label="Password"
          item={password}
          setItem={setPassword}
          type="password"
        />
        <FieldButton
          label="Confirm password"
          item={confirmPassword}
          setItem={setConfirmPassword}
          type="password"
        />
        <MultipleSelect
          optionList={groups}
          options={userGroups || []}
          setOptions={setUserGroups}
        />
        <MultipleSelect
          optionList={roles.map((role) => role.name)}
          options={userRolesName || []}
          setOptions={setUserRolesName}
          label="Select roles"
        />
        <FilledVoiceableButton onClick={handleAddUser}>
          Add user
        </FilledVoiceableButton>
      </ListItems>
    </div>
  );
};

export default AddUser;
