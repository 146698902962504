import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {
  Button,
  Checkbox,
  Header,
  Icon,
  Image,
  Popup,
  Segment,
} from "semantic-ui-react";

import { getConversationsByAgentId } from "../../apis/serviceApis";
import notificationSound from "../../assets/audio/incomming_call_ringtone.mp3";
import AutoClickModal from "../../components/AutoClickModal/AutoClickModal";
import CallEndedForm from "../../components/CallEnded/CallEndedForm";
import ConversationsList from "../../components/ConversationsList/ConversationsList";
import IncomingCallScreen from "../../components/IncomingCallScreen/IncomingCallScreen";
import Invite from "../../components/Invite/Invite";
import ModalAvailability from "../../components/ModalAvailability/ModalAvailability";
import ModalWelcomePage from "../../components/ModalWelcomePage/ModalWelcomePage";
import OpenTokenCall from "../../components/OpenTokCall/OpenTokenCall";
import OrdersTable from "../../components/OrdersTable/OrdersTable";
import ScheduleSession from "../../components/ScheduleSession/ScheduleSession";
import ShareScreen from "../../components/ShareScreen/ShareScreen";
import Spinner from "../../components/Spinner/Spinner";
import SupportActionButton from "../../components/SupportActionButton/SupportActionButton";
import YesNoModal from "../../components/YesNoModal/YesNoModal";
import config from "../../config.json";
import { ClientProvider } from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import LanguageContext from "../../context/LanguageContext";
import useAudio from "../../hooks/useAudio";
import useWebNotification from "../../hooks/useWebNotification";
import logo from "../../img/logo.png";
import { useAuth0 } from "../../react-auth0-spa";
import CustomPopUp from "../../UI-components/PopUp/PopUp";
import { isJsonStringValid } from "../../utils/validation";
import { getCurrentAgentStatus } from "../Functions/GetAgents";

import Chat from "./Chat/Chat";
import SupportAudioOutputs from "./SupportAudioOutputs/SupportAudioOutputs";

import "./LiveSupport.css";
import "../../components/ConversationsList/ConversationsList.css";
import ClientShareScreen from "../../components/ClientShareScreen/ClientShareScreen";
import ClientVideoButton from "../../components/ClientVideoButton/ClientVideoButton";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
const MessageHandler = ({handleMessage}) => {
  useEffect(() => {
    const handleEvent = (event) => {
      const { message, data } = event;
      handleMessage(message || data);
    };

    window.addEventListener("message", handleEvent, false);
    return function cleanup() {
      window.removeEventListener("message", handleEvent);
    };
  });

  return <React.Fragment />;
};

let socketErrorsCount = 0;

function isMobile() {
  try {
    document.createEvent("TouchEvent");
    return true;
  } catch (e) {
    return false;
  }
}

const isSessionEnded = (sessions = {}, sessionId) => {
  return (
    !!sessionId &&
    sessions[sessionId] &&
    "Active" !== sessions[sessionId].status
  );
};

const openFullScreen = () => {
  var elem = document.documentElement;

  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    /* Firefox */
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Chrome, Safari and Opera */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE/Edge */
    elem.msRequestFullscreen();
  }
};

const LiveSupport = (props) => {
  const [state, setstate] = useState({
    client: null,
    activeUserId: null,
    activeSession: null,
    socket: null,
    conversations: {},
    chatMessages: [],
    chatActive: false,
    callActive: false,
    invite: null,
    intent: null,
    agentIsAvailable: false,
    loggedInOut: false,
    reason: "",
    currentSession: {
      latestPage: null,
    },
    callTime: 0,
    room: null,
  });

  const stateRef = useRef();
  stateRef.current = state;
  const env = useContext(EnvContext);
  const { language, languageDirection } = useContext(LanguageContext);
  const [error, setError] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const { user, getTokenSilently, logout } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [showOrders, setShowOrders] = useState(false);
  const { play, pause, setSinkId } = useAudio(notificationSound);
  const [navWindow, setNavWindow] = useState(null);
  const [room, setRoom] = useState(null);
  const navWindowRef = useRef();
  const [ringtoneOutputId, setRingtoneOutputId] = useState(
    localStorage.getItem("ringtoneOutputId") || ""
  );
  const [headsetOutputId, setHeadsetOutputId] = useState(
    localStorage.getItem("headsetOutputId") || ""
  );
  const [headsetInputId, setHeadsetInputId] = useState(
    localStorage.getItem("headsetInputId") || ""
  );
  const [showMissed, setShowMissed] = useState(
    localStorage.getItem("showMissed") === "true"
  );
  const [currentAgentStatus, setCurrentAgentStatus] = useState("");
  const [clientName, setClientName] = useState("");
  const [answerLoading, setAnswerLoading] = useState(false);
  const [clientConfig, setClientConfig] = useState({});
  const [clientConfigFeatures, setClientConfigFeatures] = useState({
    autoAnswer: false,
    supportAudio: false,
    recording: true,
  });
  const inactiveComponent = false;
  navWindowRef.current = navWindow;

  const [isIntervalReady, setIsIntervalReady] = useState(true);
  const [showSwitchConversationPopup, setShowSwitchConversationPopup] =
    useState(false);
  const [inviteActive, setInviteActive] = useState(false);
  const [isShowCustomPopup, setIsShowCustomPopup] = useState(false);
  const [customPopupMessage, setCustomPopupMessage] = useState("");
  const { triggerNotification } = useWebNotification();
  const apiService = axios.create({baseURL: config["api-service"][env]});
  const agentAPI = axios.create({baseURL: config["agent-api"][env]});

  const [userSupportScreenShare, setUserSupportScreenShare] = useState(false);
  const [userSupportClientVideo, setUserSupportClientVideo] = useState(false);

  const textChatSupported = Boolean(clientConfig?.features?.supportTextChat);

  useEffect(() => {
    const fetchClient = async () => {
      const token = await getTokenSilently();
      const response = await axios.get(
        `client/clients/${props.match.params.client}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientName(response.data.clientName);
    };

    const preloadScript = () => {
      const script = document.createElement("script");
      script.src = "https://static.opentok.com/v2/js/opentok.min.js";
      document.head.appendChild(script);
    };

    fetchClient();
    preloadScript();
  }, []);

  const isInitDeviceConfig = useRef(true);

  useEffect(() => {
    if (isInitDeviceConfig.current) {
      isInitDeviceConfig.current = false;
    }

    localStorage.setItem("ringtoneOutputId", ringtoneOutputId);
    localStorage.setItem("headsetOutputId", headsetOutputId);
    localStorage.setItem("headsetInputId", headsetInputId);
  }, [ringtoneOutputId, headsetOutputId, headsetInputId]);

  useEffect(() => {
    localStorage.setItem("showMissed", showMissed);
  }, [showMissed]);

  const refreshAgentStatus = () => {
    getCurrentAgentStatus(
      getTokenSilently,
      env,
      props.match.params.client
    ).then((response) => {
      const status = response?.status?.value;
      if (status === "AWAY" && stateRef.current.agentIsAvailable) {
        setIsShowCustomPopup(true);
        setCustomPopupMessage("You are unavailable. Reload the page, please.");
      } else {
        setIsShowCustomPopup(false);
      }
    });
  };

  useEffect(() => {
    let callTimerInterval = setInterval(function () {
      if (stateRef.current.callActive) {
        setState({
          callTime: stateRef.current.callTime + 1,
        });
      } else {
        setState({
          callTime: 0,
        });
      }
    }, 1000);
    return () => {
      clearInterval(callTimerInterval);
    };
  }, [stateRef.current.callActive]);

  useEffect(() => {
    const permissionRequest = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        stream.getTracks().forEach((track) => {
          track.stop();
        });
      } catch (e) {
        /* empty */
      }
    };

    permissionRequest();
  }, []);

  useEffect(() => {
    const interval = setInterval(refreshAgentStatus, 1000 * 60);

    const handleOnline = () => {
      setIsShowCustomPopup(false);
    };
    const handleOffline = () => {
      setIsShowCustomPopup(true);
      setCustomPopupMessage(
        "You are offline. Check the connection to the internet."
      );
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      clearInterval(interval);
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const statusValueToState = (value) => {
    if (value === "AVAILABLE") {
      setCurrentAgentStatus("available");
      setState({ agentIsAvailable: true });
    } else if (value === "ON_CALL") {
      setCurrentAgentStatus("on call");
    } else {
      setCurrentAgentStatus("unavailable");
      setState({ agentIsAvailable: false });
    }
  };

  const refreshData = () => {
    if (isIntervalReady) {
      setIsIntervalReady(false);
      getCurrentAgentStatus(
        getTokenSilently,
        env,
        props.match.params.client
      ).then((agent) => {
        statusValueToState(agent.status.value);
        setIsIntervalReady(true);
      });
    }
  };

  // long delay in getting the status of the current agent (getCurrentAgentStatus), temporarily disabled
  useEffect(() => {
    if (inactiveComponent) {
      let interval = setInterval(refreshData, 6000);
      return () => clearInterval(interval);
    }
  }, []);

  const checkIfAgentAvailable = async () => {
    try {
      const token = await getTokenSilently();
      return (
        await agentAPI.get(`/agents/availability/${stateRef.current.client}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      ).data;
    } catch (e) {
      console.log('Failed to retrive agents availability ')
      console.log(e);
      return false;
    }
  };

  const onCloseModalAvailability = () => {
    setOpenModal(false);
    setState({ agentIsAvailable: true, reason: "" });
  };

  const unavailableAgent = async (reason) => {
    let payload = {
      messageType: "unavailable",
      agentName: user.metadata.fullName,
      reason: reason,
    };
    sendMessage(JSON.stringify(payload));
  };

  const availableAgent = async () => {
    let payload = {
      messageType: "available",
      agentName: user.metadata.fullName,
    };

    sendMessage(JSON.stringify(payload));
  };

  const setState = (newState) => {
    setstate((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  const sendMessage = (msg) => {
    if (
      stateRef.current.socket &&
      stateRef.current.socket.readyState == WebSocket.OPEN
    ) {
      stateRef.current.socket.send(window.btoa(unescape(encodeURIComponent(msg))));
    } else {
      setTimeout(() => sendMessage(msg), 100);
    }
  };

  const fetchConversations = async () => {
    try {
      const token = await getTokenSilently();

      const conversations = await getConversationsByAgentId(
        stateRef.current.client,
        user.sub,
        token,
        env
      );
      const sessionIdToConversation = {};

      conversations.forEach((conversation) => {
        if (!sessionIdToConversation[conversation["id"]]) {
          sessionIdToConversation[conversation["id"]] = conversation;
        }
      });

      setState({ conversations: sessionIdToConversation });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const fetchClientConfig = async () => {
    const token = await getTokenSilently();

    const response = await axios.get(
      `client/clients/${stateRef.current.client}/config`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  };

  useEffect(() => {
    if (stateRef.current.client !== null) {
      fetchClientConfig().then((data) => {
        setClientConfig(data);
      });
      fetchConversations();
    }
  }, [stateRef.current.client]);

  useEffect(() => {
    const updatedClientFeatures = {
      ...clientConfigFeatures,
      ...clientConfig.features,
    };
    setClientConfigFeatures(updatedClientFeatures);
  }, [clientConfig]);

  //this effect is for add to mongo if the agent has been logged in
  useEffect(() => {
    if (!stateRef.current.loggedInOut) {
      setState({ loggedInOut: true });
    }
  }, []);

  const fetchConversation = (
    sessionId,
    force = false,
    onlyNew = false
  ) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (
          !force &&
          stateRef.current.conversations[sessionId] &&
          stateRef.current.conversations[sessionId].session === sessionId
        ) {
          resolve();
          return;
        }
        const token = await getTokenSilently();
        const conversation = (
          await apiService.get(`/sessions/${stateRef.current.client}?timeZone=${timeZone}&sessionId=${sessionId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        ).data;

        if (onlyNew && conversation.finishedAt) {
          resolve();
        }

        let newConversations = {};
        newConversations[sessionId] = stateRef.current.conversations[sessionId] || {};
        const navigationMessages = conversation.messages.filter(({ messageType }) => "navigation" === messageType);
        const connectMessage = conversation.messages.find(({ messageType }) => "clientConnected" === messageType) || {};

        let lastMessage = navigationMessages.slice(-1).pop() || {};
        const conversionDate = new Date(conversation.startedAt);

        const isIntentEmpty = (intent) => {
          if (!intent) {
            return true;
          }
          let empty = !intent.intents && !intent.description;
          for (const value of Object.values(intent.clientIntents)) {
            empty = empty && _.isEmpty(value);
          }
          return empty;
        };

        const getConversationStatus = (conversation) => {
          const answered = !!conversation.agentsOnCall.length;
          let duration = 0;
          if (conversation.finishedAt) {
            const finished = new Date(conversation.finishedAt);
            const started = new Date(conversation.startedAt);
            duration = parseInt((finished - started) / 1000) || 0;
          }
          if (!conversation.finishedAt) {
            return "Active";
          }
          if (answered && duration > 10) {
            return "Successful";
          } else if (answered && duration <= 10) {
            return "Unsuccessful";
          } else if (duration >= 59) {
            return "Missed";
          } else {
            return "Abandoned";
          }
        };

        newConversations[sessionId] = {
          ...newConversations[sessionId],
          date: moment(conversionDate).format("DD/MM/YYYY"),
          time: moment(conversionDate).format("HH:mm:ss"),
          id: conversation.session,
          userId: conversation.userId,
          firstPage: connectMessage.page,
          firstPageTitle: connectMessage.pageTitle,
          latestPage: lastMessage.page || connectMessage.page,
          latestPageTitle: lastMessage.pageTitle || connectMessage.pageTitle,
          status: getConversationStatus(conversation),
          navigations: navigationMessages,
          intent: isIntentEmpty(conversation.intent) ? null : conversation.intent,
        };
        let conversationsFiltered = _.omit({ ...stateRef.current.conversations }, [sessionId]);
        setState({
          conversations: { ...newConversations, ...conversationsFiltered },
        });
        resolve();
      } catch (e) {
        console.error("An error happened during fetching conversations", e);
        reject(e);
      }
    });
  };

  const prepareBody = (intentBody) => {
    const intentDescription = intentBody.intentDescription;
    const intent = intentBody.intent;

    delete intentBody.intent;
    delete intentBody.intentDescription;
    delete intentBody.intents;
    delete intentBody.description;
    return {
      intents: intent,
      description: intentDescription,
      clientIntents: {...intentBody}
    }
  }

  const updateIntent = async (sessionId, intentBody) => {
    const token = await getTokenSilently();
    await apiService.post(`/sessions/${stateRef.current.client}/intent?sessionId=${sessionId}`, prepareBody({...intentBody}), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    stateRef.current.conversations[sessionId].intent = intentBody;

    setState({ intent: sessionId });
  };

  const writeLog = async (params) => {
    // const token = await getTokenSilently();
    // const res = await supportAPI.post(`/chat/${stateRef.current.client}/log/`, params,);
    console.log('writing log of body', params)
  };

  const onConversationSelected = (userId, session) => {
    if (stateRef.current.callActive) {
      //show are you shure popup
      // console.log("conversation active");
      //setShowSwitchConversationPopup(`/${stateRef.current.client}/support/${userId}/${session}`)
    } else {
      props.history.push(
        `/${stateRef.current.client}/support/${userId}/${session}`
      );
    }
  };

  const getChatToken = async (userId, sessionId, role, name) => {
    const token = await getTokenSilently();
    const client = stateRef.current.client;

    const res = await apiService.post("video/token", {
        identity: JSON.stringify({username: userId, role, client, name}),
        roomName: sessionId,
        recording: clientConfigFeatures.recording,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        }
      },
    );

    return res.data.token;
  };

  const prepareAnswerCallPayload = async (
    userId,
    sessionId,
    messageType = "takeCall",
    actionType = "callStart"
  ) => {
    const token = await getChatToken(userId, sessionId, "Customer", "Customer");
    let payload = {
      messageType: messageType,
      userId: userId,
      actions: [
        {
          name: actionType,
          actionType: actionType,
          parameters: JSON.stringify({ token: token, roomName: sessionId }),
        },
      ],
      agentName: user.metadata.fullName,
      agentId: user.sub,
      session: sessionId,
    };
    return JSON.stringify(payload);
  };

  const onMessage = (e) => {
    const message = JSON.parse(e.data);
    console.log(`received message: ${e.data}`)
    const handleCustomerMessage = (msg) => {
      switch (msg.messageType) {
        case "ping":
          if (msg.source === "client") {
            if (stateRef.current.conversations[msg.session]) {
              stateRef.current.conversations[msg.session].customerLastSeen =
                msg["ts"];
            }
          }
          break;
        case "clientConnected":
          fetchConversation(msg.session)
            .then(() => {
              if (!stateRef.current.callActive) {
                props.history.push(
                  `/${stateRef.current.client}/support/${msg.userId}/${msg.session}`
                );
                notify(msg.session);
              }
            })
            .catch(() => {
              console.log("Failed to fetch session", msg);
            });
          break;
        case "navigation":
        case "actions":
        case "message":
          stateRef.current.conversations[msg.session].latestMessageTS = msg.ts;
          stateRef.current.conversations[msg.session].latestPageTitle = msg.pageTitle;

          stateRef.current.conversations[msg.session].latestPage = msg.page;
          if (msg.session === stateRef.current.activeSession) {
            setState({
              currentSession: stateRef.current.conversations[msg.session],
            });
          }
          break;
        case "TextChatMessage":
          const message = msg.params.message;
          addChatMessage(message);
          break;
        case "userSupportScreenShare":
          setUserSupportScreenShare(true);
          break;
        case "userSupportClientVideo":
          setUserSupportClientVideo(true)
          break
        default:
          break;
      }
    };
    const handleAgentMessage = (msg) => {
      switch (msg.messageType) {
        case "IncomingCall":
          if (message.call_type === "invites") {
            setState({ invite: msg });
          } else {
            fetchConversation(msg.session, false, true)
              .then(() => {
                if (
                  msg.userId &&
                  msg.session &&
                  isSessionEnded(stateRef.current.conversations, msg.session)
                ) {
                  console.log('session is ended already')
                  return;
                }
                if (!stateRef.current.callActive) {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage("incomingCall");
                  }
                  props.history.push(
                    `/${stateRef.current.client}/support/${msg.userId}/${msg.session}`
                  );
                  notify(msg.session);
                }
              })
              .catch(() => {
                console.log("Failed to fetch sessions", msg);
                stopNotify();
              });
          }
          break;
        case "declineInvite":
        case "cancelInvite":
          setInviteActive(false);
          setState({ invite: null });
          break;
        case "callDeclined":
        case "redirectToSIP":
        case "RouteTimedOut":
          if (
            message.call_type === "RouteTimedOut" &&
            msg.session &&
            isSessionEnded(stateRef.current.conversations, msg.session)
          ) {
            break;
          }
          setInviteActive(false);
          setAnswerLoading(false);
          stopNotify();

          props.history.push(`/${stateRef.current.client}/support/`);
          setState({
            invite: null,
            conversations: _.omit({ ...stateRef.current.conversations }, [
              msg.session,
            ]),
          });
          break;
        case "takeCall":
        case "acceptInvite":
          stopNotify();
          if (user.sub === msg.agentId) {
            if (msg.success) {
              fetchConversation(msg.session)
                .then(() => {
                  setState({
                    callActive: true,
                    chatMessages: [],
                    chatActive: false,
                  });
                  props.history.push(
                    `/${stateRef.current.client}/support/${msg.userId}/${msg.session}`
                  );
                })
                .finally(() => {
                  setState({ invite: null });
                });
            } else {
              //TODO: notify couldnt take call
              props.history.push(`/${stateRef.current.client}/support/`);
            }
          }

          break;
        case "callEnded":
          stopNotify();
          fetchConversation(msg.session, true).then(() => {
            if (msg.userId === stateRef.current.activeUserId) {
              setState({
                callActive: false,
                chatMessages: [],
                chatActive: false,
              });
              if (navWindow) {
                navWindow.close();
                setNavWindow(null);
              }
            }
          });
          break;
        case "unavailable":
          break;
        default:
          break;
      }
    };
    if (message.source === "support") {
      handleAgentMessage(message);
    } else {
      handleCustomerMessage(message);
    }
  };

  if (stateRef.current.socket) stateRef.current.socket.onmessage = onMessage;

  const notify = (sessionId) => {
    if (!stateRef.current.callActive && stateRef.current.agentIsAvailable) {
      setSinkId(ringtoneOutputId === "default" ? "" : ringtoneOutputId);
      triggerNotification("Ringing ...");
      play();

      setState({ notifySessionId: sessionId });
    }
  };

  const stopNotify = () => {
    pause();
  };

  useEffect(() => {
    if (
      !stateRef.current.notifySessionId ||
      !stateRef.current.conversations[stateRef.current.notifySessionId] ||
      isSessionEnded(
        stateRef.current.conversations,
        stateRef.current.notifySessionId
      )
    ) {
      stopNotify();
    }
  }, [stateRef.current.notifySessionId, stateRef.current.conversations]);

  const initSocket = async () => {
    if (stateRef.current.socket) {
      stateRef.current.socket.onclose = null;
      stateRef.current.socket.close();
    }
    const token = await getTokenSilently();
    try {
      let connectionSocket = new WebSocket(
        `${config["call-api"][env]}/agents?clientId=${stateRef.current.client}&token=${token}`
      );
      connectionSocket.onmessage = (e) => {
        onMessage(e);
      };

      connectionSocket.onerror = (e) => {
        if (socketErrorsCount === 10) {
          setIsShowCustomPopup(true);
          setCustomPopupMessage(
            "You are offline. Check the connection to the internet."
          );
        }

        if (socketErrorsCount >= 10 && socketErrorsCount % 4 === 0) {
          triggerNotification(
            "Connection is lost, please reload the page and make sure you have the Internet access."
          );
        }
        writeLog({
          process: "Socket Login Error",
          description:
            "Socket Error " + JSON.stringify(e, Object.getOwnPropertyNames(e)),
        });
        console.error("Socket error:\n" + e);
        socketErrorsCount++;
        connectionSocket.close();
      };

      connectionSocket.onclose = () => setTimeout(initSocket, 1000);

      connectionSocket.onopen = () => {
        setIsShowCustomPopup(false);
        let retries = 0;
        let interval = setInterval(function () {
          checkIfAgentAvailable().then((data) => {
            retries++;
            if (data.value === false) {
              setState({agentIsAvailable: false, reason: ""});
              if (retries > 5) {
                clearInterval(interval);
              }
            } else if (data.value === true) {
              clearInterval(interval);
              setState({agentIsAvailable: true});
            }
          });
        }, 1000);
        fetchConversations();
      };

      setState({ socket: connectionSocket });
    } catch (error) {
      writeLog({
        process: "Socket Login Error",
        description:
          "Socket Error " +
          JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
      window.location.reload();
    }
  };

  useEffect(() => {
    if (env !== null && stateRef.current.client !== null) {
      initSocket();
    }
  }, [env, stateRef.current.client]);

  useEffect(() => {
    const { client, userId, session } = props.match.params;
    if (stateRef.current.client == null && client) {
      setState({ client });
    }

    if (
      userId &&
      (stateRef.current.activeUserId == null ||
        stateRef.current.activeUserId !== userId)
    ) {
      setState({ activeUserId: userId });
    } else if (stateRef.current.activeUserId !== null && !userId) {
      setState({ activeUserId: null });
    }
    if (
      session &&
      (stateRef.current.activeSession == null ||
        stateRef.current.activeSession !== session)
    ) {
      setState({ activeSession: session });
    } else if (stateRef.current.activeSession != null && !session) {
      setState({ activeSession: null });
    }
  }, [props]);

  useEffect(() => {
    if (stateRef.current.activeUserId && stateRef.current.activeSession) {
      fetchConversation(stateRef.current.activeSession)
        .then(() => {
          setState({
            currentSession:
              stateRef.current.conversations[stateRef.current.activeSession],
          });
        });
    }
  }, [stateRef.current.activeUserId, stateRef.current.activeSession]);

  useEffect(() => {
    return () => {
      if (stateRef.current.socket !== null) {
        stateRef.current.socket.onerror = null;
        stateRef.current.socket.onclose = null;
        stateRef.current.socket.close();
      }
    };
  }, []);

  const onSendAction = (actions) => {
    let page =
      stateRef.current.conversations[stateRef.current.activeSession].latestPage;

    let payload = {
      messageType: "actions",
      actions: actions,
      userId: stateRef.current.activeUserId,
      agentName: user.metadata.fullName,
      agentId: user.sub,
      session: stateRef.current.activeSession,
    };

    let action = actions.filter((a) => a.actionType === "Navigation");
    if (action.length > 0) {
      action = action[0];
    } else {
      action = null;
    }

    if (action && action.actionType === "Navigation") {
      payload.page = action.parameters;
      payload.origin = page;
    } else {
      payload.page = page;
    }

    sendMessage(JSON.stringify(payload));
  };

  const onCallAnswer = async (type) => {
    setAnswerLoading(true);
    const payload = await prepareAnswerCallPayload(
      stateRef.current.activeUserId,
      stateRef.current.activeSession
    );
    sendMessage(payload);

    const viewerToken = await getChatToken(
      user.sub + "Viewer",
      stateRef.current.activeSession,
      "Viewer",
      "Viewer"
    );
    stopNotify();
    setState({
      callType: type,
      viewerToken,
    });
  };

  const onCallDecline = async () => {
    setAnswerLoading(true);
    let payload = {
      messageType: "callDeclined",
      userId: stateRef.current.activeUserId,
      agentName: user.metadata.fullName,
      session: stateRef.current.activeSession,
    };
    sendMessage(JSON.stringify(payload));
    stopNotify();
  };

  const handleCallLeave = async () => {
    const payload = await prepareAnswerCallPayload(
      stateRef.current.activeUserId,
      stateRef.current.activeSession,
      "leaveCall",
      "leaveCall"
    );
    sendMessage(payload);
    setState({
      callActive: false,
      chatMessages: [],
      chatActive: false,
      callType: null,
      viewerToken: null,
    });
    const conversationsFiltered = _.omit(
      { ...stateRef.current.conversations },
      [stateRef.current.activeSession]
    );

    setState({
      conversations: { ...conversationsFiltered },
    });
    props.history.push(`/${stateRef.current.client}`);
  };

  const handleCallEnd = () => {
    onSendAction([{ name: "callEnd", actionType: "callEnd", parameters: "" }]);
    setState({
      callActive: false,
      chatMessages: [],
      chatActive: false,
      callType: null,
      viewerToken: null,
    });
    const conversationsFlitered = _.omit(
      { ...stateRef.current.conversations },
      [stateRef.current.activeSession]
    );
    const oldConversation =
      stateRef.current.conversations[stateRef.current.activeSession];
    const newConversation = {
      ...oldConversation,
      status: "Success",
    };
    setState({
      conversations: {
        [stateRef.current.activeSession]: newConversation,
        ...conversationsFlitered,
      },
    });
    stopNotify();
  };

  const handleInviteToAgent = (agentId) => {
    let payload = {
      messageType: "invite",
      invited_agent: agentId,
      agentName: user.metadata.fullName,
      session: stateRef.current.activeSession,
      userId: stateRef.current.activeUserId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const handleGroupInviteToAgents = (agentIds) => {
    let payload = {
      messageType: "groupInvites",
      invited_agents_group: agentIds,
      agentName: user.metadata.fullName,
      session: stateRef.current.activeSession,
      userId: stateRef.current.activeUserId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const handleCancelInvite = () => {
    stopNotify();
    const session =
      stateRef.current?.invite?.session || stateRef.current.activeSession;
    const userId =
      stateRef.current?.invite?.userId || stateRef.current.activeUserId;
    let payload = {
      messageType: "cancelInvite",
      agentName: user.metadata.fullName,
      session: session,
      userId: userId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const handleChatStatus = () => {
    const chatActive = !stateRef.current.chatActive;

    setState({
      chatActive,
    });
    onSendAction([
      {
        actionType: chatActive ? "TextChatStart" : "TextChatStop",
        parameters: "",
      },
    ]);
  };

  const handleDeclineInvite = () => {
    stopNotify();
    const state = stateRef.current;
    const session = state?.invite?.session || state.activeSession;
    const userId = state?.invite?.userId || state.activeUserId;
    let payload = {
      messageType: "declineInvite",
      agentName: user.metadata.fullName,
      session: session,
      userId: userId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const handleAcceptInvite = () => {
    let payload = {
      messageType: "acceptInvite",
      agentName: user.metadata.fullName,
      session: stateRef.current.invite.session,
      userId: stateRef.current.invite.userId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const addChatMessage = (msg) => {
    const alreadyExist = stateRef.current.chatMessages.some(
      (message) => message.id === msg.id
    );

    if (alreadyExist) {
      return;
    }

    const chatMessages = [...stateRef.current.chatMessages, msg];

    setState({
      chatMessages: chatMessages,
    });
  };

  const sendChatMessage = (msg) => {
    addChatMessage(msg);

    onSendAction([
      {
        actionType: "TextChatMessage",
        parameters: { message: msg },
      },
    ]);
  };

  const openNavigationWindow = () => {
    if (navWindow) navWindow.close();
    let page = stateRef.current.currentSession.latestPage;
    if (stateRef.current.client === "sleepenvie") {
      let url = new URL(page);
      url.searchParams.set("geolizr", "off");
      page = url.href;
    }
    let newWindow = window.open(page + "#voiceableConsole", "_blank");

    setNavWindow(newWindow);
  };
  const handleNavigationWindowAction = (message) => {
    try {
      let action = {};
      if (isJsonStringValid(message)) {
        action =
          typeof message === "string" ? JSON.parse(message) : { ...message };
      }
      switch (action.name) {
        case "navigation":
          onSendAction([
            {
              actionType: "Navigation",
              parameters: action.parameters.replace("#voiceableConsole", ""),
            },
          ]);
          break;
        case "matterportSweep":
          console.log(
            "arrived here! ActionType: matterportSweep, action.parameters:",
            action.parameters
          );
          // handleSendDataToClient("matterportSweep", action.parameters);
          onSendAction([
            {
              actionType: "matterportSweep",
              parameters: action.parameters,
            },
          ]);
          break;
        case "back":
          navWindow.close();
          window.focus();
          setNavWindow(null);
          break;
        case "tokenRequest":
          navWindow.postMessage(
            JSON.stringify({
              name: "tokenResponse",
              parameters: {
                token: stateRef.current.viewerToken,
                roomName: stateRef.current.activeSession,
              },
            }),
            "*"
          );
          break;
        case "customerPageRequest":
          navWindow.postMessage(
            JSON.stringify({
              name: "customerPageResponse",
              parameters:
                stateRef.current.currentSession.latestPage +
                "#voiceableConsole",
            }),
            "*"
          );
          break;
        default:
          break;
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (navWindow && stateRef.current.currentSession) {
      navWindow.postMessage(
        JSON.stringify({
          name: "customerPageResponse",
          parameters:
            stateRef.current.currentSession.latestPage + "#voiceableConsole",
        }),
        "*"
      );
    }
  }, [stateRef.current.currentSession.latestPage]);

  useEffect(() => {
    if (
      isSessionEnded(
        stateRef.current.conversations,
        stateRef.current.activeSession
      )
    ) {
      setAnswerLoading(false);
    }
  }, [stateRef.current.conversations]);

  useEffect(() => {
    return () => {
      if (navWindow) {
        navWindow.close();
      }
    };
  }, []);

  if (!props.match.params.client) {
    return <Redirect to="/" />;
  }

  if (stateRef.current.socket == null) {
    return <Spinner text="Connecting to support system" />;
  }

  if (error) {
    console.log(error);
    return <Header size="large">Couldn't connect to support system</Header>;
  }
  if (loading) {
    return <Spinner text="Fetching messages" />;
  }

  const onClickLogout = () => {
    writeLog({
      process: "Logged out",
      description: "User logged out",
    });
    setState({ loggedInOut: false });
    logout();
  };

  return (
    <ClientProvider value={{ client: stateRef.current.client, clientConfig }}>
      {inactiveComponent && (
        <YesNoModal
          key={stateRef.current.activeSession}
          open={showSwitchConversationPopup}
          header="Conversation Still Active"
          onYesClick={() => {
            handleCallEnd();
            props.history.push(showSwitchConversationPopup);
            setShowSwitchConversationPopup(null);
          }}
          onNoClick={() => setShowSwitchConversationPopup(null)}
          content="Are you sure you wish to leave this conversation?"
        />
      )}

      <ModalWelcomePage />

      {inactiveComponent && stateRef.current.callActive && (
        <AutoClickModal
          key={stateRef.current.activeSession}
          autoClick="no"
          header="Call still active?"
          content="Is this call still active?"
          timeouts={[5 * 60, 5 * 60, 10 * 60]}
          onTimeout={() => navWindowRef.current && navWindowRef.current.close()}
          onNoClick={handleCallEnd}
        />
      )}
      <Segment raised padded id="conversations-list-container">
        <div id="logo-container">
          <Image size="small" src={logo} alt="Voiceable" />
          {/* <Link className="back" to={`/${stateRef.current.client}`}>
                            {"< Back to dashboard"}
                        </Link> */}
        </div>
        {clientName && (
          <div id="console-client-info">
            <Header as="h2" className="client-name">
              {clientName}
            </Header>
            <small className="client-version">1.0.0</small>
          </div>
        )}
        <ConversationsList
          activeSessionId={stateRef.current.activeSession}
          conversationsMap={stateRef.current.conversations}
          onConversationSelected={onConversationSelected}
          showMissed={showMissed}
        />
        <SupportAudioOutputs
          setRingtoneOutputId={setRingtoneOutputId}
          setHeadsetOutputId={setHeadsetOutputId}
          setHeadsetInputId={setHeadsetInputId}
          setShowMissed={setShowMissed}
          ringtoneOutputId={ringtoneOutputId}
          headsetOutputId={headsetOutputId}
          headsetInputId={headsetInputId}
          showMissed={showMissed}
          supportAudio={clientConfigFeatures.supportAudio}
        />
        <div id="signout">
          <Button onClick={() => onClickLogout()} icon inverted color="red">
            <Icon name="sign-out" />
          </Button>
          {/* onChange={() => muted? availableAgent(): unavailableAgent()} */}
          <Fragment>
            {stateRef.current.agentIsAvailable ? (
              <div
                className="available"
                style={{ direction: languageDirection }}
              >
                {language.console.sidebar.availability.available}
              </div>
            ) : (
              <div
                className="unavailable"
                style={{ direction: languageDirection }}
              >
                {language.console.sidebar.availability.unavailable}
              </div>
            )}
            <Checkbox
              toggle
              onChange={async (e) => {
                const checked = e.target.previousSibling.checked;
                setState({ agentIsAvailable: checked });
                checked ? await availableAgent() : setOpenModal(true);
              }}
              style={{ paddingLeft: "10px" }}
              checked={!stateRef.current.agentIsAvailable}
            />
          </Fragment>
          <ModalAvailability
            openModal={openModal}
            onClose={onCloseModalAvailability}
            setOpenModal={setOpenModal}
            unavailableAgent={unavailableAgent}
          />
        </div>
      </Segment>
      <Invite
        visible={!!stateRef.current.invite}
        onAccept={handleAcceptInvite}
        onDecline={handleDeclineInvite}
      />
      <MessageHandler handleMessage={handleNavigationWindowAction} />
      {!isSessionEnded(
        stateRef.current.conversations,
        stateRef.current.activeSession
      ) && (
        <div id="support-control-pane">
          {user.authorization.roles.includes("Admin") &&
            stateRef.current.conversations[stateRef.current.activeSession] &&
            stateRef.current.conversations[stateRef.current.activeSession]
              .archiveId && (
              <Popup
                style={{ position: "absolute" }}
                position="right center"
                on="click"
                pinned
                content={`Archive Id: ${
                  stateRef.current.conversations[stateRef.current.activeSession]
                    .archiveId
                }`}
                trigger={<Button icon="info" />}
              />
            )}

          <div id="view-placeholder">
            {stateRef.current.conversations[stateRef.current.activeSession] && (
              <>
                <h3
                  style={{
                    textAlign: "center",
                    marginBottom: "60px",
                    color: "#4bb4aa",
                    direction: languageDirection,
                  }}
                >
                  {language.console.others.customerAt +
                    stateRef.current.conversations[
                      stateRef.current.activeSession
                    ].latestPageTitle}
                </h3>
                <SupportActionButton
                  icon="compass"
                  onClick={openNavigationWindow}
                  content={language.console.navigation.header}
                  id="current_page_nav"
                />
              </>
            )}
          </div>
          {textChatSupported && (
            <div id="chat-button-block">
              {stateRef.current.conversations[stateRef.current.activeSession] &&
                stateRef.current.conversations[stateRef.current.activeSession]
                  .status === "Active" &&
                stateRef.current.callActive && (
                  <Chat
                    sendMessage={sendChatMessage}
                    chatActive={stateRef.current.chatActive}
                    handleChatStatus={handleChatStatus}
                    messages={stateRef.current.chatMessages}
                  />
                )}
            </div>
          )}
          {clientConfigFeatures.supportShareScreen && (
            <div id="screen-share-block">
              {stateRef.current.conversations[stateRef.current.activeSession] &&
                stateRef.current.conversations[stateRef.current.activeSession]
                  .status === "Active" &&
                stateRef.current.callActive && (
                  <>
                    <ShareScreen room={room} desktop={true}/>
                    <ClientShareScreen
                      userSupportScreenShare={userSupportScreenShare}
                      onEnable={() => {
                        onSendAction([
                          {
                            name: "enableClientShareScreen",
                            actionType: "enableClientShareScreen",
                          },
                        ]);
                      }}
                      onDisable={() => {
                        onSendAction([
                          {
                            name: "disableClientShareScreen",
                            actionType: "disableClientShareScreen",
                          },
                        ]);
                      }}
                    />
                    <ClientVideoButton
                      userSupportClientVideo={userSupportClientVideo}
                      onEnable={() => {
                        onSendAction([
                          {
                            name: "enableClientVideo",
                            actionType: "enableClientVideo",
                          },
                        ]);
                      }}
                      onDisable={() => {
                        onSendAction([
                          {
                            name: "disableClientVideo",
                            actionType: "disableClientVideo",
                          },
                        ]);
                      }}
                    />
                  </>
                )}
            </div>
          )}
          {inactiveComponent && (
            <Button
              content="View Orders"
              onClick={() => setShowOrders(true)}
              style={{ marginTop: "25px" }}
              color="blue"
              icon="cart"
              size="large"
              labelPosition="left"
            />
          )}
          {inactiveComponent && showOrders && (
            <OrdersTable userId={stateRef.current.activeSession} />
          )}
        </div>
      )}
      {isSessionEnded(
        stateRef.current.conversations,
        stateRef.current.activeSession
      ) && (
        <CallEndedForm
          setIntent={(intent) =>
            updateIntent(stateRef.current.activeSession, intent)
          }
          sessionId={stateRef.current.activeSession}
          params={
            stateRef.current.activeSession && {
              ...stateRef.current.conversations[stateRef.current.activeSession],
              clientId: stateRef.current.client,
              clientConfig,
            }
          }
        />
      )}
      {!isSessionEnded(
        stateRef.current.conversations,
        stateRef.current.activeSession
      ) && (
        <div id="chat">
          {answerLoading && <Spinner />}
          {stateRef.current.conversations[stateRef.current.activeSession] &&
            stateRef.current.conversations[stateRef.current.activeSession]
              .status === "Active" &&
            stateRef.current.callActive && (
              <div id="call-actions-container">
                <OpenTokenCall
                  inviteActive={inviteActive}
                  setInviteActive={setInviteActive}
                  headsetInputId={headsetInputId}
                  ringtoneOutputId={ringtoneOutputId}
                  headsetOutputId={headsetOutputId}
                  onInvite={handleInviteToAgent}
                  onGroupInvite={handleGroupInviteToAgents}
                  onInviteCancel={handleCancelInvite}
                  roomName={stateRef.current.activeSession}
                  videoEnabled={stateRef.current.callType === "video"}
                  onChatLeave={handleCallLeave}
                  onChatEnd={handleCallEnd}
                  setAnswerLoading={setAnswerLoading}
                  key={`support-controller-${stateRef.current.activeUserId}-${stateRef.current.activeSession}`}
                  supportRecording={clientConfigFeatures.recording}
                  room={room}
                  setRoom={setRoom}
                />
                {
                  <div>
                    Call duration:{" "}
                    {stateRef.current.callTime > 60
                      ? parseInt(stateRef.current.callTime / 60) +
                        "m " +
                        (stateRef.current.callTime % 60) +
                        "s"
                      : stateRef.current.callTime + "s"}
                  </div>
                }
              </div>
            )}
          {stateRef.current.conversations[stateRef.current.activeSession] &&
            stateRef.current.conversations[stateRef.current.activeSession]
              .status === "Active" &&
            !stateRef.current.callActive &&
            !answerLoading && (
              <IncomingCallScreen
                onCallAnswer={onCallAnswer}
                onCallDecline={onCallDecline}
                supportAutoAnswer={clientConfigFeatures.autoAnswer}
              />
            )}
        </div>
      )}
      {/* <Notification
                key={notificationOptions.body}
                ignore={!showNotification}
                timeout={5000}
                title={"New Support Message"}
                options={notificationOptions}
                onShow={handleNotificationOnShow}

            /> */}
      <Button
        style={{ direction: languageDirection }}
        id="full-screen-btn"
        size="tiny"
        onClick={openFullScreen}
        primary
      >
        {language.common.expand}
      </Button>
      {isShowCustomPopup && (
        <CustomPopUp error hidePopupClose>
          {customPopupMessage}
        </CustomPopUp>
      )}
    </ClientProvider>
  );
};

export default LiveSupport;
