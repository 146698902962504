import React from "react";
import _ from "lodash";
import { Dropdown, Input } from "semantic-ui-react";

export const mapFields = (
  configData,
  state,
  dispatch,
  params,
  errorFields,
  editMode = true
) => {
  return configData.map(({ title, fields }) => {
    return (
      <>
        <h1>{title}</h1>
        {fields.map((field) => {
          const isError = errorFields.includes(field.name);
          let fieldJsx;
          const updateFieldValue = (e, d) => {
            const value = (d && d.value) || e.target.value;
            const action = {
              type: "update",
              payload: { [field.name]: value },
            };
            dispatch(action);
          };

          switch (field.type) {
            case "multiselect": {
              const options = field.data.map((value, index) => ({
                key: index,
                text: value,
                value: value,
              }));

              fieldJsx = (
                <Dropdown
                  placeholder={
                    (!editMode && "The field is not filled") ||
                    field.placeholder ||
                    "Choose"
                  }
                  fluid
                  multiple
                  search
                  selection
                  error={isError}
                  disabled={!editMode}
                  value={state[field.name] || []}
                  options={options}
                  onChange={updateFieldValue}
                  style={{ fontSize: "16px", width: "70%", margin: "auto" }}
                />
              );
              break;
            }
            case "navigation-multiselect": {
              let options = [
                {
                  key: 999,
                  text: params.firstPageTitle,
                  value: params.firstPageTitle,
                },
              ];
              if (params && Array.isArray(params.navigations)) {
                const navigations = [
                  params.firstPageTitle,
                  ...params.navigations
                    .filter(({ messageType }) => messageType === "navigation")
                    .map(({ pageTitle }) => pageTitle),
                ];
                let nonRepeatedvalues = [];
                navigations.forEach((title) => {
                  if (!nonRepeatedvalues.includes(title)) {
                    nonRepeatedvalues.push(title);
                  }
                });
                options = nonRepeatedvalues.map((value, index) => ({
                  key: index,
                  text: value,
                  value: value,
                }));
              }
              fieldJsx = (
                <Dropdown
                  placeholder={
                    (!editMode && "The field is not filled") ||
                    field.placeholder ||
                    "Choose"
                  }
                  fluid
                  multiple
                  search
                  error={isError}
                  selection
                  disabled={!editMode}
                  value={state[field.name] || []}
                  options={options}
                  onChange={updateFieldValue}
                  style={{ fontSize: "16px", width: "70%", margin: "auto" }}
                />
              );
              break;
            }
            case "number": {
              fieldJsx = (
                <Input
                  type="number"
                  className="number-field"
                  disabled={!editMode}
                  error={isError}
                  value={state[field.name] || ""}
                  onChange={updateFieldValue}
                  placeholder={
                    (!editMode && "The field is not filled") ||
                    field.placeholder ||
                    "Enter number here..."
                  }
                />
              );
              break;
            }
            case "text-one-line":
              fieldJsx = (
                <Input
                  type="text"
                  className="text-one-line-field"
                  disabled={!editMode}
                  error={isError}
                  value={state[field.name] || ""}
                  onChange={updateFieldValue}
                  placeholder={
                    (!editMode && "The field is not filled") ||
                    field.placeholder ||
                    "Enter text here..."
                  }
                />
              );
              break;
            case "text":
            default: {
              fieldJsx = (
                <textarea
                  disabled={!editMode}
                  value={state[field.name] || ""}
                  className={isError ? "textarea-error" : "textarea-no-error"}
                  onChange={updateFieldValue}
                  placeholder={
                    (!editMode && "The field is not filled") ||
                    field.placeholder ||
                    "Enter text here..."
                  }
                  maxLength="300"
                />
              );
              break;
            }
          }
          return (
            <div className="custom-field-block" key={field.name}>
              {fieldJsx}
              <span className="error-message">
                {isError && "This field is required*"}
              </span>
            </div>
          );
        })}
      </>
    );
  });
};

const isValueEmpty = (value) => {
  if (typeof value === "undefined") {
    return true;
  }

  if (typeof value === "string") {
    return !value.length;
  }

  if (typeof value === "number") {
    return false;
  }

  if (_.isNull(value)) {
    return true;
  }

  if (Array.isArray(value) || _.isObject(value)) {
    return _.isEmpty(value);
  }

  return false;
};

export const getErrorFields = (state, config) => {
  const errorFields = [];
  for (const sections of Object.values(config)) {
    for (const section of sections) {
      for (const field of section.fields) {
        if (field.required && isValueEmpty(state[field.name])) {
          errorFields.push(field.name);
        }
      }
    }
  }
  return errorFields;
};
