import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { Header, Icon, Image, Menu } from "semantic-ui-react";

import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import logo from "../../img/logo.png";
import { useAuth0 } from "../../react-auth0-spa";

import "./Sidebar.css";

// function similar(a, b) {
//   // var lengthA = a.length;
//   // var lengthB = b.length;
//   var equivalency = 0;
//   var minLength = a.length > b.length ? b.length : a.length;
//   var maxLength = a.length < b.length ? b.length : a.length;
//   for (var i = 0; i < minLength; i++) {
//     if (a[i] == b[i]) {
//       equivalency++;
//     }
//   }

//   var weight = equivalency / maxLength;
//   return weight * 100;
// }

const Sidebar = (props) => {
  const { client } = useContext(ClientContext);
  const env = useContext(EnvContext);
  const history = useHistory();

  const { isAuthenticated, logout, loading, user, getTokenSilently } =
    useAuth0();
  // const [permission, setPermission] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const [clientName, setClientName] = useState("");

  useEffect(() => {
    const fetchClient = async () => {
      const token = await getTokenSilently();

      const response = await axios.get(`client/clients/${client}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClientName(response.data.clientName);
    };

    if (!loading && isAuthenticated && client) {
      fetchClient();
    }
  }, [loading, isAuthenticated, client]);

  // useEffect(() => {
  //   if (!loading && isAuthenticated) {
  //     setPermission(user.authorization.permissions);
  //   }
  // }, [loading, isAuthenticated]);

  if (loading) {
    return <div>loading</div>;
  }

  const toggleSideBar = () => {
    if (props.onCollapse && expanded) {
      props.onCollapse();
    }

    if (props.onExpand && !expanded) {
      props.onExpand();
    }

    setExpanded(!expanded);
  };
  const renderSideBarItems = () => {
    return props.items.map((item) => {
      const currentPathname = window.location.pathname;

      let isActive = currentPathname === item.path;

      if (item.extraPaths && !isActive) {
        isActive = !!item.extraPaths.find((path) => path === currentPathname);
      }

      return (
        <Menu.Item
          key={item.text}
          name={item.text}
          active={isActive}
          as={Link}
          to={item.path}
        >
          <span className={(item.greyout && "Sidebar-greyout") || ""}>
            {item.icon}
            {expanded && item.text}
            {!expanded && <div className="height-for-icon" />}
          </span>
        </Menu.Item>
      );
    });
    // });
  };

  return (
    <>
      <div className={"sidebar-container " + (expanded ? "" : "collapsed")}>
        <div id="logo-container" className={expanded ? "" : "collapsed"}>
          <Link to="/">
            <Image size="small" src={logo} alt="Voiceable"></Image>
          </Link>
        </div>

        <div className="Sidebar">
          <div className="top" onClick={toggleSideBar}>
            <Icon size="large" name="bars" />
            {expanded && clientName && (
              <div className="client-info">
                <Header as="h2" className="client-name">
                  {clientName}
                </Header>
                <small className="client-version">1.0.0</small>
              </div>
            )}
          </div>
          <div className="menu-section">
            <div className="top-menu">
              <Menu pointing secondary fluid vertical inverted>
                {renderSideBarItems()}
              </Menu>
            </div>
            <div className="bottom-menu">
              <Menu pointing secondary fluid vertical inverted>
                {isAuthenticated && (
                  <Menu.Item className="logout">
                    <Icon name="sign out" />

                    <Link to="/" onClick={() => logout()}>
                      {expanded && "Logout"}
                    </Link>
                  </Menu.Item>
                )}
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
