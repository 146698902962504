import React, { useEffect, useRef, useState } from "react";
import {nowUTCDate} from "../../../utils/formatDate";

const getSecondsSince = (timestamp) => {
  const start = new Date(timestamp);
  const now = nowUTCDate()

  return parseInt(now.getTime() - start.getTime()) / 1000;
};

const toTimeString = (seconds) => {
  return [
    parseInt(seconds / 60 / 60),
    parseInt((seconds / 60) % 60),
    parseInt(seconds % 60),
  ]
    .join(":")
    .replace(/\b(\d)\b/g, "0$1");
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const StatusTimer = ({ value }) => {
  const [secondsElapsed, setSecondsElapsed] = useState();
  useInterval(() => {
    setSecondsElapsed(secondsElapsed + 1);
  }, 1000);
  useEffect(() => {
    if (!value) {
      return;
    }
    setSecondsElapsed(getSecondsSince(value));
  }, [value]);

  return (
    <React.Fragment>
      {value ? toTimeString(secondsElapsed) : "--:--:--"}
    </React.Fragment>
  );
};

export default StatusTimer;
