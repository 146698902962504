import React, { useState } from "react";

import FromToDate from "../FromToDate/FromToDate";
import ToDateDropdown from "../FromToDate/ToDateDropdown";
import { stateOptions } from "../FromToDate/utils";

const DateSelectors = ({
  clickExporData,
  clickFetchData,
  defaultDatePeriod = stateOptions[0].value,
}) => {
  const [toDateDropdownRange, setToDateDropdownRange] = useState({
    to: "",
    from: "",
  });
  return (
    <div>
      <ToDateDropdown
        fetchDataByDate={clickFetchData}
        setToDateDropdownRange={setToDateDropdownRange}
        defaultDatePeriod={defaultDatePeriod}
      />
      <FromToDate
        toDateDefault={toDateDropdownRange.to}
        fromDateDefault={toDateDropdownRange.from}
        onClickExport={clickExporData}
      />
    </div>
  );
};

export default DateSelectors;
