import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Card, Container, Header, Icon, Image } from "semantic-ui-react";

import ProtectedWrapper from "../../components/ProtectedWrapper/ProtectedWrapper";
import Spinner from "../../components/Spinner/Spinner";
import logo from "../../img/logo.png";
import { useAuth0 } from "../../react-auth0-spa";

import "./ClientSection.css";

const ClientSelection = () => {
  const { loading, isAuthenticated, user, getTokenSilently } = useAuth0();
  const [fetchingData, setFetchingData] = useState(true);
  const [clients, setClients] = useState(null);
  const [filteredClients, setFilteredClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      const token = await getTokenSilently();

      const response = await axios.get("client/clients", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setClients(response.data);
    };

    if (!loading && isAuthenticated) {
      fetchClients();
    }
  }, [loading, isAuthenticated]);

  useEffect(() => {
    if (clients !== null && user.clients !== null) {
      let intersection = clients.filter((x) =>
        user.clients.includes(x.clientKey)
      );
      setFilteredClients(intersection);
      setFetchingData(false);
    }
  }, [user, clients]);

  function renderClients() {
    if (filteredClients.length === 0) {
      return <Header as="h2">Could not find clients</Header>;
    }

    return filteredClients.map((client) => {
      return (
        <Card
          className="client-card_wrapper"
          href={`/${client.clientKey}`}
          key={client.clientKey}
        >
          <Card.Content className="client-card">
            <Header as="h2" textAlign="center">
              {client.displayName}
            </Header>
          </Card.Content>
        </Card>
      );
    });
  }

  function renderDashboardClients() {
    if (
      user &&
      user["https://voiceable.co/user_authorization"] &&
      user["https://voiceable.co/user_authorization"]["roles"].filter(
        (role) => role === "Dashboard-Admin"
      ).length > 0
    ) {
      return filteredClients.map((client) => {
        return (
          <Card
            className="client-card_wrapper"
            href={`/${client.clientKey}/dashboard`}
            key={client.clientKey}
          >
            <Card.Content className="client-card">
              <Header as="h2" textAlign="center">
                {client.displayName}
              </Header>
            </Card.Content>
          </Card>
        );
      });
    }
  }

  if (filteredClients.length === 1) {
    if (
      !(
        user &&
        user["https://voiceable.co/user_authorization"] &&
        user["https://voiceable.co/user_authorization"]["roles"].filter(
          (role) => role === "Dashboard-Admin"
        ).length > 0
      )
    ) {
      return <Redirect to={`/${filteredClients[0].clientKey}/support`} />;
    }
  }

  if (fetchingData) {
    return <Spinner />;
  }

  return (
    <Container>
      <Image
        style={{ margin: "85px auto" }}
        src={logo}
        size="medium"
        centered
      />
      <Header style={{ marginBottom: "50px" }} as="h1" textAlign="center">
        Console - Select Client
      </Header>
      <Card.Group className="client-card_list" itemsPerRow={3}>
        {renderClients()}
        <ProtectedWrapper roles={["Admin"]}>
          <Card
            className="client-card_wrapper"
            href="/register"
            key="/register"
          >
            <Card.Content className="client-card" textAlign="center">
              <Icon name="plus" size="big" color="black" />
            </Card.Content>
          </Card>
        </ProtectedWrapper>
      </Card.Group>
      <Header style={{ paddingTop: "50px" }} as="h1" textAlign="center">
        Dashboard - Select Client
      </Header>
      <Card.Group className="client-card_list" itemsPerRow={3}>
        {renderDashboardClients()}
      </Card.Group>
      <Header style={{ paddingTop: "10px" }} as="h1" textAlign="center" />
      <ProtectedWrapper roles={["Admin"]}>
        <a className="assistant-page" href="/assistant">
          Assistant page
        </a>
      </ProtectedWrapper>
    </Container>
  );
};

export default ClientSelection;
