import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Header, Icon, Loader, Segment, Table } from "semantic-ui-react";

import { downloadProductsReport } from "../../../apis/serviceApis";
import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import ProductsListContext from "../../../context/ProductsListContext";
import { useAuth0 } from "../../../react-auth0-spa";
import { Pagination, PaginationPageSettings } from "../../../UI-components";
import ConversionPeriod from "../../../UI-components/ConversionPeriod/ConversionPeriod";
import { sortProducts } from "../../Functions/SortFunctions";
import DateSelectors from "../DateSelectors/DateSelectors";
import SearchComponent from "../SearchComponent/SearchComponent";

import "./ProductsList.css";

const stateOptions = [
  {
    key: "Today",
    text: "Today",
    value: "Today",
  },
  {
    key: "Last Week",
    text: "Last Week",
    value: "Last Week",
  },
  {
    key: "Last Month",
    text: "Last Month",
    value: "Last Month",
  },
];

const searchOptions = [
  { key: "Item name", text: "Item name", value: "Item name" },
  {
    key: "User count",
    text: "User count",
    value: "User count",
  },
];

const ProductsList = () => {
  const { clientConfig, localTimezone, client } = useContext(ClientContext);
  const { fetchProducts, products, loading, reFetchProducts } =
    useContext(ProductsListContext);
  const env = useContext(EnvContext);
  const { getTokenSilently } = useAuth0();
  const [orderedByArrow, setOrderedByArrow] = useState(null);
  const [productsToCsv] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const ref = useRef(null);
  const [prodcutsByPage, setProductsByPage] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const searchProductLink = clientConfig.searchProductLink;

  useEffect(() => {
    setFilteredProducts(products);
  }, [products]);

  const openProductPage = (id) => {
    if (searchProductLink) {
      window.open(searchProductLink + id, "_blank", "noreferrer");
    }
  };

  const searchProducts = (input, filterBy = "User Count") => {
    switch (filterBy) {
      case "Item name":
        setFilteredProducts(
          products.filter((product) =>
            product.item.toLowerCase().includes(input.toLowerCase())
          )
        );
        break;
      case "User Count":
      default:
        setFilteredProducts(
          products.filter((product) => product.userCount.includes(input))
        );
        break;
    }
  };

  const arrowFunctionHandler = (sortOption, products) => {
    return orderedByArrow !== sortOption
      ? sortProducts(
          products,
          sortOption,
          setFilteredProducts,
          setOrderedByArrow
        )
      : sortProducts(
          products,
          sortOption + " up",
          setFilteredProducts,
          setOrderedByArrow
        );
  };

  const caretIconHandler = (sortOption) => {
    return orderedByArrow === sortOption ? (
      <Icon name="caret up" />
    ) : orderedByArrow === sortOption + " up" ? (
      <Icon name="caret down" />
    ) : null;
  };

  const exportProducts = async (fromDate, toDate) => {
    const token = await getTokenSilently();

    const conversionPeriod =
      parseInt(localStorage.getItem("conversionPeriodValue")) || 0;
    const params = {
      clientName: client,
      timeZone: localTimezone,
      conversionPeriod,
      startDate: new Date(fromDate).toLocaleDateString("en-UK"),
      endDate: new Date(toDate).toLocaleDateString("en-UK"),
    };
    await downloadProductsReport(params, token, env);
  };

  const ToDateDropdownFunc = async (fromDate, toDate) => {
    const startDate = new Date(fromDate).toLocaleDateString("en-UK");
    const endDate = new Date(toDate).toLocaleDateString("en-UK");
    await fetchProducts(startDate, endDate);
    setPageNumber(1);
  };

  return (
    <div className="ProductsList-Dashboard">
      <div className="products-list__toolbar">
        <DateSelectors
          clickExporData={exportProducts}
          clickFetchData={ToDateDropdownFunc}
        />
        <div className="search-to-the-right">
          <SearchComponent
            options={searchOptions}
            searchFilter={searchProducts}
            optionExport={stateOptions[2].value}
          />
        </div>
      </div>
      <Segment raised padded className="segment-style">
        <div>
          <div className="products__top">
            <Header>Products</Header>
            <div className="products__top__left">
              <ConversionPeriod
                loading={loading}
                reFetchData={reFetchProducts}
              />
              <PaginationPageSettings
                items={filteredProducts}
                pageNumber={pageNumber}
                pageSize={pageSize}
                setPageNumber={setPageNumber}
                setPageSize={setPageSize}
              />
            </div>
          </div>
          <Table basic="very" textAlign="center" selectable unstackable>
            <Table.Header>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("itemId", products)}
              >
                {caretIconHandler("itemId")}
                Item
                <span className="tooltiptext">The item ID</span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("customers", products)}
              >
                {caretIconHandler("customers")}
                User Count
                <span className="tooltiptext">
                  How many users have bought this item
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("price", products)}
              >
                {caretIconHandler("price")}
                Unit Price
                <span className="tooltiptext">The price for one unit</span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("quantity", products)}
              >
                {caretIconHandler("quantity")}
                Total Units
                <span className="tooltiptext">How many units were sold</span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("totalPrice", products)}
              >
                {caretIconHandler("totalPrice")}
                Total Price
                <span className="tooltiptext">
                  Multiply "Total Units" with "Unit Price"
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("conversions", products)}
              >
                {caretIconHandler("converts")}
                Converts
                <span className="tooltiptext">
                  How many units converted with Voiceable
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("variants", products)}
              >
                {caretIconHandler("variant")}
                Variants
                <span className="tooltiptext">
                  How many units are variants with Voiceable
                </span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("similar", products)}
              >
                {caretIconHandler("similar")}
                Similar
                <span className="tooltiptext">
                  How many units are similar with Voiceable
                </span>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>
              {!loading &&
                prodcutsByPage.length > 0 &&
                prodcutsByPage.map((product) => {
                  return (
                    <Table.Row
                      className={searchProductLink && "link-row"}
                      onClick={() => {
                        openProductPage(product.itemId);
                      }}
                    >
                      <Table.Cell className="table-cell-width">
                        {product.itemId}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.customers}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.price}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.quantity}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.totalPrice}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.conversions}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.variants}
                      </Table.Cell>
                      <Table.Cell className="table-cell-width">
                        {product.similar}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          {loading && <Loader active inline="centered" />}
          {!loading && prodcutsByPage.length === 0 && (
            <div style={{ textAlign: "center", fontSize: "20px" }}>No Data</div>
          )}
        </div>
        <Pagination
          pageSize={pageSize}
          items={filteredProducts}
          setItemsByPage={setProductsByPage}
          pageNumber={pageNumber}
          onClick={(e) => setPageNumber(Number(e.target.dataset.id))}
        />
      </Segment>
      <CSVLink data={productsToCsv} filename="items_list.csv" ref={ref} />
    </div>
  );
};

export default ProductsList;
