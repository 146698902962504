import { EnvironmentType } from "../../../../../common/types/environment";

import { VoiceableButtonConfigState } from "./types";

const voiceableButtonConfigStates: VoiceableButtonConfigState[] = [
  "initial",
  "calling",
  "connecting",
  "connected",
  "permissionDenied",
  "notfound",
];

const voiceableButtonStatesMap: Record<VoiceableButtonConfigState, string> = {
  initial: "INITIAL",
  calling: "CALLING",
  connecting: "CONNECTING",
  connected: "CONNECTED",
  permissionDenied: "PERMISSION_DENIED",
  notfound: "NOTFOUND",
};

const voiceableButtonSrcipt: Record<EnvironmentType, string> = {
  local:
    "https://storage.voice-able.com/clients-dev/{client}/dist/liveagent.js",
  dev: "https://storage.voice-able.com/clients-dev/{client}/dist/liveagent.js",
  prod: "https://storage.voice-able.com/clients/{client}/dist/liveagent.js",
};

const voiceableButtonLink: Record<EnvironmentType, string> = {
  local:
    "https://storage.voice-able.com/clients-dev/{client}/dist/liveagent.css",
  dev: "https://storage.voice-able.com/clients-dev/{client}/dist/liveagent.css",
  prod: "https://storage.voice-able.com/clients/{client}/dist/liveagent.css",
};

export {
  voiceableButtonConfigStates,
  voiceableButtonSrcipt,
  voiceableButtonLink,
  voiceableButtonStatesMap,
};
