import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactPlayer from "react-player";
import uuid from "react-uuid";
import axios from "axios";
import _ from "lodash";
import { Grid, Icon, Segment } from "semantic-ui-react";

import { getConversationById } from "../../../apis/serviceApis";
import baseCustomFieldsConfig from "../../../components/CallEnded/config.json";
import config from "../../../config.json";
import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";
import convertFromSeconds from "../../../utils/convertFromSeconds";

import AddLabel from "./AddLabel";
import { arrayOfOptions } from "./ArrayOfLabels";
import { averageConnectionTime } from "./constant";
import NavigationsTable from "./NavigationsTable";

import "./Conversation.css";
import Feedback from "../../../components/Feedback/Feedback";

const Conversation = (props) => {
  const { getTokenSilently } = useAuth0();
  const { clientConfig } = useContext(ClientContext);
  const [note, setNote] = useState("");
  const [glow, setGlow] = useState([]);
  const [showPlayer, setShowPlayer] = useState(false);
  const [video, setVideo] = useState([]);
  const [arrayOfNavigation, setArrayOfNavigation] = useState([]);
  const [recordTime, setRecordTime] = useState(null);
  const [oneConversation, setOneConversation] = useState(null);
  const [progress, setProgress] = useState(0);

  const environment = useContext(EnvContext);
  const voiceableApiClient = axios.create({
    baseURL: config["api"][environment],
  });
  const voiceableApiServiceClient = axios.create({
    baseURL: config["api-service"][environment],
  });

  useEffect(() => {
    apiRequestVideo().then((value) => {
      const url = URL.createObjectURL(value);
      if (url) {
        setTimeout(() => {
          setShowPlayer(true);
        }, 100);
      }
      setVideo(url);
    });
  }, []);

  const getNavigationFromApi = (callStartDate, navigationMessages) => {
    let tempArray = [];
    navigationMessages.map((value) => {
      let navigationEventDate = new Date(value.ts);
      let time = parseInt((navigationEventDate - callStartDate) / 1000);
      time = time > 0 ? time : 0;
      return tempArray.push({
        link: value.link,
        time: time,
        title: value.pageTitle,
        ts: value.ts,
      });
    });
    setArrayOfNavigation(tempArray);
  };

  useEffect(() => {
    getConversationDetailsById().then((conversation) => {
      setOneConversation(conversation);

      const startDate = new Date(conversation.ts);
      startDate.setSeconds(startDate.getSeconds() + conversation.waitTime);
      getNavigationFromApi(startDate, conversation.navigations);
    });
  }, []);

  const apiRequestVideo = async () => {
    const token = await getTokenSilently();
    const sessionId = props.match.params.conversationId;
    const clientId = props.match.params.client;
    const videoFile = await voiceableApiClient.get(
      `/client/${clientId}/${sessionId}/get-video`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      }
    );
    return videoFile.data;
  };

  const updateValues = async (type, time, text) => {
    const token = await getTokenSilently();
    const sessionId = props.match.params.conversationId;
    let clientId = props.match.params.client;
    const body = {
      session: sessionId,
      type: type,
      time: time,
      option: text,
      text: text,
    };
    voiceableApiClient
      .post(`/client/${clientId}/post-mongo/`, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .catch((err) => {
        console.log(err.response.data);
      });
  };

  const getConversationDetailsById = async () => {
    const token = await getTokenSilently();
    const sessionId = props.match.params.conversationId;
    let clientName = props.match.params.client;
    return await getConversationById(sessionId, token, environment, clientName);
  };

  const ref = useRef();
  const refNotes = useRef();

  const timeFormatter = (time) => {
    let hours = Math.floor(time / 3600);
    let minutes = Math.floor((time - hours * 3600) / 60);
    let seconds = time - hours * 3600 - minutes * 60;
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds;
  };

  const onClickButtonNote = () => {
    let oneNote = note;
    if (oneNote.length !== 0) {
      updateValues("comments", parseInt(ref.current.getCurrentTime()), oneNote);
      setNote("");
    }
  };

  const onClickButtonLabel = (option) => {
    if (option.length !== 0) {
      updateValues(
        "timeline",
        parseInt(ref.current.getCurrentTime()),
        option.text
      );
    }
  };

  const checkIfEnter = (e) => {
    const keyCode = e.code || e.key;
    if (keyCode === "Enter") {
      onClickButtonNote();
    }
  };

  function scrollDiv(id) {
    document
      .getElementById("note-" + id)
      .scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" });
    document.getElementById("note-" + id).click();
  }

  const onClickGlow = (index) => {
    setGlow([...glow, index]);
    setTimeout(() => setGlow(glow.filter((item) => item !== index)), 1600);
  };

  const onProgress = (state) => {
    const calcluatedProgress = state.played.toFixed(3);
    if (recordTime && calcluatedProgress > 0) {
      setProgress(calcluatedProgress * 100);
    }
  };

  const onSeek = (seconds) => {
    if (recordTime) {
      setProgress((seconds / recordTime) * 100);
    }
  };

  const showAllNotes = () => {
    let arrayOfNotes =
      oneConversation && oneConversation.comments
        ? oneConversation.comments
        : [];
    let sortedNotes = arrayOfNotes.sort((a, b) => {
      return a.time - b.time;
    });

    function findLabel(oneNote, arrayOfLabels) {
      arrayOfLabels = arrayOfLabels || [];
      let sortedLabels = arrayOfLabels.sort((a, b) => {
        return a.time - b.time;
      });
      let labels = sortedLabels.filter(
        (oneLabel) => oneNote.time <= oneLabel.time
      );
      if (labels.length > 0) return labels[0].option;
      else return "No label yet";
    }

    return sortedNotes.map((oneNote, index) => (
      <Fragment key={uuid()}>
        <div
          className="one-note-container"
          id={"note-" + index}
          onClick={() => onClickGlow(index)}
          style={{
            animation: glow.includes(index)
              ? "glow 0.8s 2 ease-in-out"
              : "none",
          }}
        >
          <div className="one-note-label">
            {findLabel(
              oneNote,
              oneConversation && oneConversation.timeline
                ? oneConversation.timeline
                : []
            )}
          </div>
          <div key={uuid()} className="one-note">
            <div
              className="one-note-time"
              onClick={() => onClickTime(oneNote.time)}
            >
              {timeFormatter(oneNote.time)}&nbsp;&nbsp;
            </div>
            <div className="one-note-text">{oneNote.text}</div>
          </div>
        </div>
        <div className="lenterer" />
      </Fragment>
    ));
  };

  const showAllLabels = () => {
    let arrayOfLabels =
      oneConversation && oneConversation.timeline
        ? oneConversation.timeline
        : [];
    let sortedLabels = arrayOfLabels.sort((a, b) => {
      return a.time - b.time;
    });

    function calculateLeft(sortedLabels, index) {
      if (index === 0) {
        return "0";
      } else {
        return (sortedLabels[index - 1].time / recordTime) * 100 + "%";
      }
    }

    function calculateWidth(sortedLabels, index) {
      if (index === 0) {
        return (sortedLabels[index].time / recordTime) * 100 + "%";
      } else {
        return (
          ((sortedLabels[index].time - sortedLabels[index - 1].time) /
            recordTime) *
            100 +
          "%"
        );
      }
    }

    return sortedLabels.map((oneLabel, index) => (
      <div
        className="label-line-text"
        key={uuid()}
        style={{
          width: calculateWidth(sortedLabels, index),
          left: calculateLeft(sortedLabels, index),
        }}
      >
        <div className="label-text">{oneLabel.option}</div>
        <div className="horizontal-line-container">
          <div
            className="horizontal-line"
            style={{ height: index % 2 === 1 ? "40px" : "20px" }}
          />
        </div>
        <div
          className="label-line"
          style={{
            borderColor: arrayOfOptions.filter(
              (label) => label.text === oneLabel.option
            ).length
              ? arrayOfOptions.filter(
                  (label) => label.text === oneLabel.option
                )[0].color
              : "blue",
          }}
        />
      </div>
    ));
  };

  const showAllNotesInTimeline = () => {
    let arrayOfNotes =
      oneConversation && oneConversation.comments
        ? oneConversation.comments
        : [];
    let sortedNotes = arrayOfNotes.sort((a, b) => {
      return a.time - b.time;
    });

    function calculateLeftRight(sortedNotes, index) {
      return {
        left: (sortedNotes[index].time / recordTime) * 100 + "%",
        right: 100 - (sortedNotes[index].time / recordTime) * 100 + "%",
      };
    }

    return sortedNotes.map((oneNote, index) => (
      <div
        className="label-line-text-bottom"
        style={calculateLeftRight(sortedNotes, index)}
        key={uuid()}
      >
        <div
          className="horizontal-line"
          style={{
            height: "20px",
          }}
        />
        <div onClick={() => scrollDiv(index)}>
          <Icon name="large comment alternate outline" />
        </div>
      </div>
    ));
  };

  useEffect(() => {
    if (oneConversation && recordTime) {
      let extraTime =
        oneConversation.duration - recordTime - oneConversation.waitTime;
      const sessionBroken = extraTime > oneConversation.waitTime * 4;
      if (sessionBroken) {
        extraTime = averageConnectionTime;
      }
      setArrayOfNavigation((navigations) =>
        navigations.map((navigation) => {
          return { ...navigation, time: navigation.time - extraTime };
        })
      );
    }
  }, [recordTime, oneConversation]);

  const getValueFromRange = (value, startRange, endRange) => {
    return Math.max(startRange, Math.min(endRange, value));
  };

  const showAllNavigationInTimeline = (recordTime) => {
    let sortedNavigation = arrayOfNavigation.sort((a, b) => {
      return a.time - b.time;
    });

    function calculateLeftRight(sortedNavigation, index) {
      const calculatedOffset =
        (sortedNavigation[index].time / recordTime) * 100;
      const safeOffset = getValueFromRange(calculatedOffset, 0, 100);
      return {
        left: safeOffset + "%",
        right: 100 - safeOffset + "%",
      };
    }

    return sortedNavigation.map((oneNavigation, index) => (
      <div
        className="label-line-text-bottom"
        style={calculateLeftRight(sortedNavigation, index)}
        key={uuid()}
      >
        <div
          className="horizontal-line"
          style={{
            height: "40px",
          }}
        />
        <div
          onClick={() =>
            window.open(oneNavigation.link, "_blank", "noreferrer")
          }
          style={{ cursor: "pointer" }}
        >
          <Icon name="large chain" />
        </div>
      </div>
    ));
  };

  const onClickTime = (duration) => {
    ref.current.seekTo(duration, "seconds");
  };

  const getIntentNamesMap = (callFeedbackFields) => {
    const fields = Object.values(callFeedbackFields)
      .map((section) => section.map((subSection) => subSection.fields))
      .flat(2);

    const IntentNameMap = {};

    fields.forEach(({ displayName, name }) => {
      if (displayName) {
        IntentNameMap[name] = displayName;
      }
    });

    return IntentNameMap;
  };

  const prepareIntents = (intents) => {
    const preparedIntents = {};
    if (intents && intents.clientsIntents) {
      delete intents.clientsIntents;
    }
    Object.entries(intents || {})
      .filter(([key]) => {
        return isNaN(key);
      })
      .forEach(([key, value]) => {
        preparedIntents[key] = value;
      });
    return preparedIntents;
  };

  const mapTheConversation = (conversation) => {
    if (conversation) {
      const oneConversation = conversation;
      let clientIntents = prepareIntents(oneConversation.clientIntents);

      let IntentNameMap = {};
      if (!_.isEmpty(clientConfig)) {
        if (_.has(clientConfig, "callFeedbackFields")) {
          IntentNameMap = getIntentNamesMap(clientConfig.callFeedbackFields);
        } else {
          IntentNameMap = getIntentNamesMap(baseCustomFieldsConfig);
        }
      }

      let htmlHeaders = [
        <div key="agents" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Agent Name:</b> <br />
            {oneConversation.agents.length
              ? oneConversation.agents.join(", ")
              : "-"}
          </div>
        </div>,
        <div key="sessionId" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Session ID: </b>
            <br />
            {oneConversation.sessionId}
          </div>
        </div>,
        <div key="userId" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>User ID: </b>
            <br />
            {oneConversation.userId ? oneConversation.userId : "-"}
          </div>
        </div>,
        <div key="conversionPeriod" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Conversion Period: </b>
            <br />
            {oneConversation.conversionPeriod}
          </div>
        </div>,
        <div key="date" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Date: </b>
            <br />
            {oneConversation.date + " " + oneConversation.time}
          </div>
        </div>,
        <div key="startPage" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Start Page: </b>
            <br />
            <a
              target="_blank"
              rel="noreferrer"
              href={oneConversation.startPage}
            >
              {oneConversation.startPageTitle}
            </a>
          </div>
        </div>,
        <div key="products" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Products Viewed: </b>
            <br />
            {oneConversation.products ? oneConversation.products : "-"}
          </div>
        </div>,
        <div key="waitTime" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Wait Time:</b>
            <br />
            {convertFromSeconds(oneConversation.waitTime)}
          </div>
        </div>,
        <div key="duration" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Call Duration: </b>
            <br />
            {convertFromSeconds(oneConversation.duration)}
          </div>
        </div>,
        <div key="checkout" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Checkout Items:</b> <br />
            {oneConversation.checkout ? oneConversation.checkout : "-"}
          </div>
        </div>,
        <div key="conversionItems" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Conversion Items:</b>
            <br />
            {oneConversation.conversionItems
              ? oneConversation.conversionItems
              : "-"}
          </div>
        </div>,
        <div
          key="conversionItemsValue"
          className="one-label-conversation-padder"
        >
          <div className="one-label-conversation">
            <b>Conversion Items Value:</b>
            <br />
            {Math.round(oneConversation.conversionItemsValue * 100) / 100}
          </div>
        </div>,
        <div key="variantItems" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Variant Items:</b>
            <br />
            {oneConversation.variantItems ? oneConversation.variantItems : "-"}
          </div>
        </div>,
        <div key="variantItemsValue" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Variant Items Value:</b>
            <br />
            {oneConversation.variantItemsValue}
          </div>
        </div>,
        <div key="similarItems" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Similar Items</b>
            <br />
            {oneConversation.similarItems ? oneConversation.similarItems : "-"}
          </div>
        </div>,
        <div key="similarItemsValue" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Similar Items Value:</b>
            <br />
            {oneConversation.similarItemsValue}
          </div>
        </div>,
      ];

      const intentsHeaders = [
        <div key="callIntent" className="one-label-conversation-padder">
          <div className="one-label-conversation">
            <b>Intent:</b>
            <br />
            <ul>
              {oneConversation.callIntent
                ? oneConversation.callIntent.split(",").map((text, i, arr) => {
                    return (
                      <li key={uuid()}>
                        {text}
                        {i != arr.length - 1 && ","}
                      </li>
                    );
                  })
                : "-"}
            </ul>
          </div>
        </div>,
        <div
          key="callIntentDescription"
          className="one-label-conversation-padder"
        >
          <div className="one-label-conversation">
            <b>Intent Description:</b>
            <br />
            {oneConversation.callIntentDescription || "-"}
          </div>
        </div>,
      ];

      if (!_.isEmpty(clientConfig)) {
        intentsHeaders.push(
          ...Object.entries(clientIntents).map(([key, value]) => {
            let displayName = key;
            if (IntentNameMap.hasOwnProperty(key)) {
              displayName = IntentNameMap[key];
            } else {
              displayName = _.startCase(key);
            }
            if (key.includes("multiselect")) {
              return (
                <div key={key} className="one-label-conversation-padder">
                  <div className="one-label-conversation">
                    <b>{displayName}:</b>
                    <br />
                    <ul>
                      {value.map((text, i, arr) => {
                        return (
                          <li key={uuid()}>
                            {text}
                            {i != arr.length - 1 && ","}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  key="callIntentDescription"
                  className="one-label-conversation-padder"
                >
                  <div className="one-label-conversation">
                    <b>{displayName}:</b>
                    <br />
                    {value || "-"}
                  </div>
                </div>
              );
            }
          })
        );
      }

      return (
        <div className="label-conversation-container">
          {htmlHeaders.map((header) => {
            return header;
          })}
          {intentsHeaders.map((header) => {
            return header;
          })}
        </div>
      );
    }
  };

  return (
    <div className="Conversation">
      <Grid>
        <Grid.Row>
          <Grid.Column width={10}>
            <Segment padded>
              <div className="react-player-and-labels">
                <ReactPlayer
                  className="react-player"
                  url={video}
                  onProgress={onProgress}
                  width="100%"
                  height="480px"
                  style={{ opacity: Number(showPlayer) }}
                  controls={true}
                  ref={ref}
                  onSeek={onSeek}
                  onDuration={(time) => setRecordTime(time)}
                />
                <div className="labels-maker">
                  <div className="timeline">
                    <div className="line">
                      <div
                        className="progress-line"
                        style={{
                          width: progress + "%",
                        }}
                      />
                      <div
                        className="rest-line"
                        style={{
                          width: 100 - progress + "%",
                        }}
                      />
                    </div>
                    {!!video.length && showAllLabels()}
                    {!!video.length && showAllNotesInTimeline()}
                    {oneConversation &&
                      showAllNavigationInTimeline(
                        recordTime || oneConversation.duration
                      )}
                  </div>
                  {oneConversation &&
                  arrayOfNavigation &&
                  !!arrayOfNavigation.length ? (
                    <div className="navgigation-table-section">
                      <Segment compact padded>
                        <h4>Navigation Table</h4>
                        <NavigationsTable
                          navigations={arrayOfNavigation}
                          duration={recordTime || oneConversation.duration}
                          progress={progress}
                        />
                      </Segment>
                    </div>
                  ) : (
                    <div className="space" />
                  )}
                  <AddLabel onClickButtonLabel={onClickButtonLabel} />
                </div>
              </div>
            </Segment>
          </Grid.Column>
          <Grid.Column width={6}>
            <Segment padded>{mapTheConversation(oneConversation)}</Segment>
            {oneConversation?.feedback && (
              <Segment padded>
                <Feedback feedback={oneConversation.feedback} />
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment padded style={{ height: "100%" }}>
              <div className="notes-container">
                <div className="notes-input-and-button">
                  <input
                    className="input-note"
                    onChange={(e) => setNote(e.target.value)}
                    value={note}
                    placeholder="Write a note..."
                    onKeyPress={(e) => checkIfEnter(e)}
                  />
                  <button className="button-note" onClick={onClickButtonNote}>
                    Send
                  </button>
                </div>
                <div className="enterer" />
                <div className="all-notes" ref={refNotes}>
                  {showAllNotes()}
                </div>
              </div>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default Conversation;
