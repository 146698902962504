import React, { useContext, useMemo, useState } from "react";

import { downloadConversationsReport } from "../../../apis/serviceApis";
import ClientContext from "../../../context/ClientContext";
import ConversationsContext from "../../../context/ConversationsContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";
import SimpleCheckbox from "../../../UI-components/SimpleCheckbox/SimpleCheckbox";
import { dateMaker } from "../../Functions/DateFunctions";
import DateSelectors from "../DateSelectors/DateSelectors";
import SearchComponent from "../SearchComponent/SearchComponent";

import ConversationsTable from "./ConversationsTable";

import "./Conversations.css";

const searchOptions = [
  { key: "Agent name", text: "Agent name", value: "Agent name" },
  {
    key: "Session id",
    text: "Session id",
    value: "Session id",
  },
];

const stateOptions = [
  {
    key: dateMaker(),
    text: "Today",
    value: "Today",
  },
  {
    key: dateMaker("Last Week"),
    text: "Last Week",
    value: "Last Week",
  },
  {
    key: dateMaker("Last Month"),
    text: "Last Month",
    value: "Last Month",
  },
];

const allCallStatuses = ["Successful", "Unsuccessful", "Abandoned", "Missed"];
const defaultValues = ["Successful", "Unsuccessful"];
const Conversations = (props) => {
  const env = useContext(EnvContext);
  const { client, localTimezone } = useContext(ClientContext);
  const { loading, fetchConversations, conversations, reFetchConversations } =
    useContext(ConversationsContext);
  const [filteredConversations, setFilteredConversations] = useState([]);
  const { getTokenSilently } = useAuth0();
  const [option] = useState(stateOptions[0].value);
  const [allOptionChecked, setAllOptionChecked] = useState(false);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const [sortStatusOptions, setSortStatusOptions] = useState(defaultValues);
  const [searchInput, setSearchInput] = useState("");

  const sortedConversations = useMemo(() => {
    return conversations.filter(({ callStatus }) =>
      sortStatusOptions.includes(callStatus)
    );
  }, [conversations, sortStatusOptions]);

  const exportConversation = async (fromDate, toDate) => {
    const token = await getTokenSilently();
    const conversionPeriod =
      parseInt(localStorage.getItem("conversionPeriodValue")) || 0;
    const params = {
      conversionPeriod,
      clientName: client,
      startDate: new Date(fromDate).toLocaleDateString("en-UK"),
      endDate: new Date(toDate).toLocaleDateString("en-UK"),
      timeZone: localTimezone,
      statuses: sortStatusOptions.join(","),
    };
    await downloadConversationsReport(params, token, env);
  };

  const ToDateDropdownFunc = async (fromDate, toDate) => {
    setFilteredConversations([]);
    setDateRange({ from: fromDate, to: toDate });
    const startDate = new Date(fromDate).toLocaleDateString("en-UK");
    const endDate = new Date(toDate).toLocaleDateString("en-UK");
    await fetchConversations(startDate, endDate);
  };

  const searchConversations = (input, filterBy = "Agent name") => {
    setSearchInput(input);
    let data;
    if (filterBy === "Agent name") {
      data = sortedConversations.filter((conversation) => {
        if (conversation.agents.length) {
          return conversation.agents
            .join(", ")
            .toLowerCase()
            .includes(input.toLowerCase());
        }
      });
    } else {
      data = sortedConversations.filter((conversation) => {
        if (conversation.sessionId) {
          return conversation.sessionId.includes(input);
        }
      });
    }
    setFilteredConversations(data);
  };

  return (
    <div className="Conversations-Dashboard" style={{ marginTop: "70px" }}>
      <div className="conversations__toolbar">
        <DateSelectors
          clickExporData={exportConversation}
          clickFetchData={ToDateDropdownFunc}
        />
        <div className="search-to-the-right">
          <SearchComponent
            options={searchOptions}
            searchFilter={searchConversations}
            optionExport={option}
          />
        </div>
      </div>

      <div className="conversation__list-options">
        <SimpleCheckbox
          label="All"
          onChange={(_, { checked }) => {
            setAllOptionChecked((prev) => !prev);
            if (checked) {
              setSortStatusOptions(allCallStatuses);
            }
          }}
          checked={allOptionChecked}
        />
        {allCallStatuses.map((status) => (
          <SimpleCheckbox
            key={status}
            label={status}
            onChange={(_, { checked }) => {
              setSortStatusOptions((prevOptions) => {
                if (checked) {
                  return [...prevOptions, status];
                }
                setAllOptionChecked(false);
                return prevOptions.filter((option) => option !== status);
              });
            }}
            checked={sortStatusOptions.includes(status)}
          />
        ))}
      </div>
      <ConversationsTable
        isLoading={loading}
        onConversionPeriodChange={reFetchConversations}
        conversations={
          searchInput ? filteredConversations : sortedConversations
        }
        history={props.history}
        dateRange={dateRange}
      />
      <div className="enterer" />
    </div>
  );
};

export default Conversations;
