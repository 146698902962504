import React, { useEffect, useState } from "react";

import { useAuth0 } from "../../react-auth0-spa";

const ProtectedWrapper = ({ children, permissions = [], roles = [] }) => {
  const [allowed, setAllowed] = useState(false);

  const { loading, isAuthenticated, user } = useAuth0();
  useEffect(() => {
    if (!loading && isAuthenticated) {
      const userPermissions = user.authorization.permissions;
      const userRoles = user.authorization.roles;
      const allowedByUserPermissions = permissions.every((permission) =>
        userPermissions.includes(permission)
      );
      const allowedByUserRoles = roles.every((role) =>
        userRoles.includes(role)
      );
      if (allowedByUserRoles && allowedByUserPermissions) {
        setAllowed(true);
      }
    }
  }, [loading, isAuthenticated, permissions]);

  return allowed && children;
};

export default ProtectedWrapper;
