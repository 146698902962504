import React, { FC } from "react";
import { rangeBetweenTwoNumber } from "../../utils/helpers";
import "./Feedback.css";

type QuestionOptions = "Yes" | "No";

interface Questions {
  [id: string]: QuestionOptions;
}

interface FeedbackProps {
  feedback: {
    rating: number;
    comment: string;
    questions: Questions;
  };
}

const Feedback: FC<FeedbackProps> = ({ feedback }) => {
  const renderStars = (rating) => {
    return (
      <ul className="stars-container">
        {rangeBetweenTwoNumber(1, 5).map((item) => (
          <li
            key={item}
            className={parseInt(rating) >= item ? "active" : "not-active"}
          />
        ))}
      </ul>
    );
  };

  const renderFeedback = () => {
    const toRender = [];

    for (const [key, value] of Object.entries(feedback)) {
      if (key === "rating") {
        toRender.push(
          <div key="rating" className="two-in-column">
            <div className="one-label-conversation">
              <b>Rating</b>
              <br />
              {renderStars(feedback.rating)}
            </div>
          </div>
        );
      } else if (key === "comment") {
        toRender.push(
          <div key="comment" className="two-in-column">
            <div className="one-label-conversation">
              <b>Comment</b>
              <br />
              {value || "-"}
            </div>
          </div>
        );
      } else if (key === "questions") {
        if (value) {
          for (const [question, answer] of Object.entries(value)) {
            toRender.push(
              <div key={`${question}`} className="two-in-column">
                <div className="one-label-conversation">
                  <b>{question}</b>
                  <br />
                  {answer || "-"}
                </div>
              </div>
            );
          }
        }
      }
    }

    return toRender;
  };

  return <div className="label-conversation-container">{renderFeedback()}</div>;
};

export default Feedback;
