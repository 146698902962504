import React, { useState } from "react";
import { Button } from "semantic-ui-react";
import "./ClientShareScreen.css";

const ClientShareScreen = ({ userSupportScreenShare, onEnable, onDisable }) => {
  const [screenShareEnabled, setScreenShareEnabled] = useState(false);

  return (
    <div className="share-screen-container">
      <Button
        color={
          userSupportScreenShare
            ? !screenShareEnabled
              ? "teal"
              : "red"
            : "grey"
        }
        disabled={!userSupportScreenShare}
        onClick={() => {
          setScreenShareEnabled(!screenShareEnabled);
          if (!screenShareEnabled) {
            onEnable();
          } else {
            onDisable();
          }
        }}
      >
        {userSupportScreenShare
          ? `${
              !screenShareEnabled ? "Enable" : "Disable"
            } screen share for user`
          : "User doesn't support screen sharing"}
      </Button>
    </div>
  );
};

export default ClientShareScreen;
