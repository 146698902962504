import React, {
  FC,
  memo,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import uuid from "react-uuid";
import { Checkbox } from "semantic-ui-react";

import ClientContext from "../../../../../context/ClientContext";
import EnvContext from "../../../../../context/EnvContext";
import useLink from "../../../../../hooks/useLink";
import useScript from "../../../../../hooks/useScript";

import {
  voiceableButtonLink,
  voiceableButtonSrcipt,
  voiceableButtonStatesMap,
} from "./constants";
import { VoiceableButtonAttributes, VoiceableButtonConfigState } from "./types";
import { getDataAttributes } from "./utils";

import "./VoiceableButton.css";

interface VoiceableButtonProps {
  state: VoiceableButtonConfigState;
  attributes: VoiceableButtonAttributes;
  show: boolean;
}

const VoiceableButton: FC<VoiceableButtonProps> = ({
  state,
  attributes,
  show,
}) => {
  const { client } = useContext(ClientContext);
  const [showCategoryButton, setShowCategoryButton] = useState(true);

  const env = useContext(EnvContext);
  useScript(voiceableButtonSrcipt[env].replace("{client}", client));
  useLink(voiceableButtonLink[env].replace("{client}", client));

  const onCategoryToggle = () => {
    setShowCategoryButton((show) => !show);
  };

  return (
    <div
      className="settings-voiceable-button-container"
      style={{ display: show ? "block" : "none" }}
    >
      <div
        className="dashboard-configuration-button-place"
        data-state={voiceableButtonStatesMap[state]}
        data-show-category-button={String(showCategoryButton)}
        {...getDataAttributes(attributes, state)}
      />
      <Checkbox
        toggle
        onChange={onCategoryToggle}
        checked={showCategoryButton}
        label="Show category button variant"
      />
    </div>
  );
};

export default memo(VoiceableButton);
