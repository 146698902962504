import React, { FC, memo, useEffect, useState } from "react";
import _ from "lodash";
import { Divider } from "semantic-ui-react";

import { ClientConfigProperty } from "../../../../common/types/clientConfig";
import ModalListEditor from "../../../../components/ModalListEditor/ModalListEditor";
import { normalizeDisplayName } from "../../../../utils/helpers";
import { getArrayFieldHandler } from "../../../../utils/reducerHelpers";
import { ConfigMetadata, DispatchAction } from "../types";

import { voiceableButtonConfigStates } from "./VoiceableButton/constants";
import { VoiceableButtonConfigState } from "./VoiceableButton/types";
import VoiceableButton from "./VoiceableButton/VoiceableButton";
import OptimizedCheckbox from "./OptimizedCheckbox";
import OptimizedInput from "./OptimizedInput";
import { customProperties } from "../constants";
import WorkDaysField from "../../../CreateClientView/CreateClientComponent/Fields/WorkDaysField/WorkDaysField";

interface GeneratorProps {
  subConfig: ClientConfigProperty;
  path: string;
  deep?: number;
  metadata: ConfigMetadata;
  dispatch: React.Dispatch<DispatchAction>;
}

const Generator: FC<GeneratorProps> = ({
  subConfig,
  path = "",
  deep = 0,
  metadata,
  dispatch,
}) => {
  const [cachedComponents, setCachedCopmonents] = useState([]);
  const [lastSubConfig, setLastSubConfig] = useState({});
  const [lastMetadata, setLastMetadata] = useState({});

  const arrayFieldHandler = getArrayFieldHandler(dispatch);

  const handleCustomComponent = (key, value) => {
    let fieldComponent;
    if (key === "workDays") {
      fieldComponent = (
        <WorkDaysField
          key={key}
          fieldKey={key}
          fieldValue={value}
          dispatch={dispatch}
          isLazyDispatch={true}
        />
      );
    }
    return fieldComponent;
  };

  useEffect(() => {
    if (!_.isEqual(subConfig, lastSubConfig)) {
      setLastSubConfig(subConfig);
    } else if (!_.isEqual(metadata, lastMetadata)) {
      setLastMetadata(metadata);
    } else {
      return;
    }
    const components = [];
    for (const [key, value] of Object.entries(subConfig)) {
      let currentPath = key;
      if (customProperties.includes(key)) {
        components.push(handleCustomComponent(key, value));
        setCachedCopmonents(components);
        return;
      }
      const configDisplayName = normalizeDisplayName(key);

      if (path) {
        currentPath = path + "." + currentPath;
      }

      if (_.isObject(value) && !_.isArray(value)) {
        const renderVoiceableButton = voiceableButtonConfigStates.includes(
          currentPath as VoiceableButtonConfigState
        );

        const fieldComponent = (
          <div key={currentPath}>
            <h5 className={"settings-header config-deep-" + deep}>
              {configDisplayName}
            </h5>
            <div className="settings-inputs-subsection">
              <div>
                <Generator
                  dispatch={dispatch}
                  subConfig={value}
                  path={currentPath}
                  metadata={metadata}
                  deep={deep + 1}
                />
              </div>
              {renderVoiceableButton && (
                <VoiceableButton
                  state={key as VoiceableButtonConfigState}
                  show={!!metadata["renderButtons"]}
                  attributes={value as Record<string, string>}
                />
              )}
            </div>
          </div>
        );
        components.push(fieldComponent);
      } else if (_.isArray(value)) {
        const containsString = value.every((item) => _.isString(item));
        if (containsString || value.length === 0) {
          const fieldComponent = (
            <div
              key={currentPath}
              className={"settings-inputs-container config-deep-" + deep}
            >
              <div className="settings-checkbox-block">
                <label>{configDisplayName}</label>
              </div>
              <div>
                <div className="settings-editbox-block">
                  <ModalListEditor
                    items={value}
                    onUpdate={(items) => {
                      arrayFieldHandler(currentPath, items);
                    }}
                    headerText={configDisplayName}
                    buttonText={"Show " + configDisplayName}
                  />
                </div>
              </div>
            </div>
          );
          components.push(fieldComponent);
        } else {
          const fieldComponent = (
            <div key={currentPath}>
              <h5 className={"settings-header config-deep-" + deep}>
                {configDisplayName}
              </h5>
              <div>
                <div>
                  {value.map((item, i) => {
                    return (
                      <div key={i}>
                        <Generator
                          subConfig={item}
                          path={currentPath + `.[${i}]`}
                          deep={deep + 1}
                          metadata={metadata}
                          dispatch={dispatch}
                        />
                        {value.length - 1 !== i && (
                          <Divider className={"config-deep-" + (deep + 1)} />
                        )}
                      </div>
                    );
                  })}
                </div>
                <div></div>
              </div>
            </div>
          );
          components.push(fieldComponent);
        }
      } else if (_.isBoolean(value)) {
        const fieldComponent = (
          <OptimizedCheckbox
            key={currentPath}
            currentPath={currentPath}
            deep={deep}
            title={configDisplayName}
            dispatch={dispatch}
            value={value}
          />
        );
        components.push(fieldComponent);
      } else if (_.isNumber(value) || _.isString(value) || _.isNull(value)) {
        const fieldComponent = (
          <OptimizedInput
            key={currentPath}
            currentPath={currentPath}
            deep={deep}
            title={configDisplayName}
            dispatch={dispatch}
            value={value}
          />
        );

        components.push(fieldComponent);
      }
    }
    setCachedCopmonents(components);
  }, [
    arrayFieldHandler,
    deep,
    dispatch,
    lastSubConfig,
    path,
    subConfig,
    metadata,
  ]);

  return <>{cachedComponents}</>;
};

export default memo(Generator);
