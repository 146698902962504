import { BaseConfig } from "./types";

const configNameToDisplayName = {
  call: "Call Details",
  customer: "Customer Details",
  notfound: "Not found",
  workDays: "Days",
  callFeedbackFields: " ",
};

const sectionsSchemas = [
  {
    title: "Working Days",
    properties: ["workDays"],
  },
  {
    title: "Button Configuration",
    properties: [
      "animation",
      "anchorSelector",
      "assistantPosition",
      "supportButtonOnCategoryPage",
      "supportCallButton",
      "textDirection",
      "showFloatingButton",
      "showFloatingButton",
      "showExplainer2",
      "showTooltip",
      "showButtonOnlyWhenAgentAvailable",
      "insertionType",
      "integrationHideClass",
      "introText",
      "buttonOnCategoryPage",
      "callButtonProps",
      "calling",
      "connected",
      "connecting",
      "initial",
      "notfound",
      "permissionDenied",
      "buttonOnCategoryPage",
      "buttonOnCategoryPage",
      "buttonOnCategoryPage",
    ],
  },
  {
    title: "Agent Console Configuration",
    properties: ["callFeedbackFields"],
  },
];

const baseConfig: BaseConfig = {
  cookieDomain: "",
  contactEmail: "",
  textDirection: "ltr",
  anchorSelector: "",
  insertionType: "after",
  introText: "Speak to an agent",
  ignoreWorkingDays: false,
  supportButtonOnCategoryPage: false,
  supportCallButton: false,
  showSecondaryExplainer: false,
  showFloatingButton: false,
  showTooltip: false,
  initial: {
    header: "Voice call with sales",
    content: "",
  },
  tooltip: {
    header: "Have questions about buying this item?",
    content: "Talk to our experts live",
  },
  calling: {
    header: "Calling an agent...",
    content: "",
  },
  connecting: {
    header: "Connecting",
    content: "",
  },
  connected: {
    header: "Connected",
    content: "is on the call.",
  },
  permissionDenied: {
    header: "<div id='warning-icon'></div> Hello, we can't hear you",
    content: {
      safari: "Enable your mic to continue...",
      other: "Enable your mic to continue...",
    },
    msg: "",
  },
  notfound: {
    header:
      "We’re sorry, no one is available to take the call. Please try us again in a few minutes.",
    content: {
      notFoundMessage: "We’re sorry, our agents are unavailable right now.",
      inputInstructions:
        "Leave us a message and we’ll get back to you shortly.",
      messagePlaceholder: "Type your message...",
      phonePlaceholder: "Your phone number (required)",
      addressPlaceholder: "Your email address",
    },
  },
  features: {
    autoAnswer: false,
    supportAudio: false,
    supportVideo: false,
    recording: true,
    disableContactMeForm: false,
    scheduleSupport: false,
    supportSipCalling: false,
    supportContactFormOnDisconnect: false,
    supportInteractiveButton: false,
    supportClientVideo: false,
    useAgentsAvailability: false,
    supportInactiveButton: false,
    supportTextChat: false,
    singlePageApplication: false,
    supportAnchorAsSecondButton: false,
    supportButtonOnCategoryPage: false,
    supportCallButton: false,
    supportFeedbackForm: false,
    supportOfflineDetailsScreen: false,
    supportShareScreen: false,
    supportAIAssistant: false,
    supportOfflineAIAssistant: false,
  },
  sipConfiguration: {
    sipAddress: "",
    sipAgentName: "Agent",
    sipStartTime: 30,
  },
  callButtonProps: {
    specialClass: "voiceable_call_functionality",
    overrideButtonDisabled: true,
  },
  animation: { nudgeEffect: false, pulseEffect: false },
  callFeedbackFields: {},
  workDays: [
    {
      enabled: true,
      day: 1,
      name: "Monday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
    {
      enabled: true,
      day: 2,
      name: "Tuesday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
    {
      enabled: true,
      day: 3,
      name: "Wednesday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
    {
      enabled: true,
      day: 4,
      name: "Thursday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
    {
      enabled: true,
      day: 5,
      name: "Friday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
    {
      enabled: true,
      day: 6,
      name: "Saturday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
    {
      enabled: true,
      day: 7,
      name: "Sunday",
      startTime: "06:00:00",
      endTime: "18:00:00",
    },
  ],
};
const customProperties = ["workDays"];

export {
  configNameToDisplayName,
  sectionsSchemas,
  customProperties,
  baseConfig,
};
