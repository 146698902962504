import React, { useContext, useEffect, useState } from "react";
import { Header, Loader, Segment, Table } from "semantic-ui-react";

import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";
import { getAgentsStatuses } from "../../Functions/GetAgents";

import AgentStatus from "./AgentStatus";
import StatusTimer from "./StatusTimer";

const AgentsMonitor = (props) => {
  const { client } = useContext(ClientContext);
  const env = useContext(EnvContext);

  const { getTokenSilently } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [agentStatuses, setAgentStatuses] = useState([]);

  const refreshData = () => {
    getAgentsStatuses(getTokenSilently, env, client).then((statuses) => {
      setAgentStatuses(statuses);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    refreshData();

    const interval = setInterval(refreshData, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="AgentsMonitor-Dashboard" style={{ marginTop: "70px" }}>
      <Segment raised padded className="segment-style">
        <Header>Agents Statuses</Header>
        {isLoading ? (
          <Loader active inline="centered">
            Loading...
          </Loader>
        ) : (
          <Table basic="very" textAlign="center" selectable unstackable>
            <Table.Header>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Current status</Table.HeaderCell>
              <Table.HeaderCell>Time in status</Table.HeaderCell>
              <Table.HeaderCell>Reason</Table.HeaderCell>
            </Table.Header>
            {agentStatuses.map((response) => {
              return (
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>{response.name}</Table.Cell>
                    <Table.Cell>
                      {<AgentStatus value={response.status.value} />}
                    </Table.Cell>
                    <Table.Cell>
                      {<StatusTimer value={response.status.since} />}
                    </Table.Cell>
                    <Table.Cell>{response.status.reason}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              );
            })}
          </Table>
        )}
      </Segment>
    </div>
  );
};

export default AgentsMonitor;
