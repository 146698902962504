import React from "react";
import { Dimmer, Loader, Segment } from "semantic-ui-react";

import "./Spinner.css";
const Spinner = ({ text = "Loading..." }) => {
  return (
    <Segment
      style={{
        width: "100vw",
        height: "110vh",
        position: "fixed",
        top: "-5vh",
        left: "0",
        opacity: "0.85",
        zIndex: 999999999,
      }}
    >
      <Dimmer active inverted>
        <Loader size="large" className="loader-text">
          {text}
        </Loader>
      </Dimmer>
    </Segment>
  );
};

export default Spinner;
