import { Config } from "./interfaces";
import { isClientName } from "../../utils/validation";

class ConfigImpl {
  private readonly baseConfig: Config = {
    displayClientName: {
      key: "displayClientName",
      label: "Client Name",
      value: "",
      validation: isClientName,
    },
    postfixes: {
      key: "postfixes",
      label: "Hostname",
      value: [],
    },
  };

  getConfig() {
    return this.baseConfig;
  }

  getInitialState() {
    return this.generateInitialState(this.baseConfig);
  }

  private generateInitialState(config: Config, newObject = {}) {
    for (const key in config) {
      const field = config[key];
      if (typeof field.value === "object" && !Array.isArray(field.value)) {
        newObject[key] = {};
        this.generateInitialState(field.value, newObject[key]);
      } else {
        newObject[key] = field.value;
      }
    }
    return newObject;
  }
}

export default ConfigImpl;
