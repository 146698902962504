import React from "react";

import PostfixesField from "./Fields/PostfixesField/PostfixesField";
import TextField from "./Fields/TextField/TextField";
import FieldWrapper from "./FieldWrapper/FieldWrapper";

import "./CreateClientComponent.css";

const CreateClientComponent = ({ state, dispatch, fieldsToRender }) => {
  const getFieldType = (field) => {
    let fieldType = `${typeof field.value}`;
    if (Array.isArray(field.value)) {
      fieldType = "array";
    }

    if (field.key === "postfixes") {
      fieldType = "postfixes";
    }

    return fieldType;
  };

  const renderField = (field) => {
    const fields = [];
    let fieldType = getFieldType(field);
    switch (fieldType) {
      case "object":
        for (const value of Object.values(field.value)) {
          const field = renderField(value);
          fields.push(field);
        }
        break;
      case "postfixes":
        fields.push(<PostfixesField dispatch={dispatch} key={field.key} />);
        break;
      case "string":
        fields.push(
          <TextField
            placeholder={`Type ${field.label} here...`}
            key={field.key}
            field={field}
            state={state}
            dispatch={dispatch}
            textArea={field.value.length > 50}
          />
        );
        break;
    }

    return (
      <FieldWrapper key={field.key} field={field} state={state}>
        {fields}
      </FieldWrapper>
    );
  };

  return (
    <div className="create-client-component">
      {Object.values(fieldsToRender).map((item) => renderField(item))}
    </div>
  );
};

export default CreateClientComponent;
