import React, {useEffect, useState} from "react";

import "./OutlineDropdown.css";
import OutlineDropdownFilter from "./OutlineDropdownFilter/OutlineDropdownFilter";

const OutlineDropdown = ({
                           options,
                           label,
                           defaultValue,
                           isSupportFilter = false,
                           assistantTab = false,
                           onClick = (e) => {
                           },
                           indexOfLabel = 0,
                           dataAttributes = [],
                         }) => {
  const [isShow, setIsShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");
  const [filteredOptions, setFilteredOptions] = useState(options);

  const handleClick = (e) => {
    onClick(e);
    setSelectedValue(e.target.innerHTML);
    setIsShow(false);
  };

  useEffect(() => {
    if (isSupportFilter) {
      const filtered = options.filter((item, index) => {
        const label = Object.values(options[index])[indexOfLabel];
        return label.includes(selectedValue);
      });
      setFilteredOptions(filtered);
    }
  }, [selectedValue]);

  return (
    <div className="outline-dropdown" onMouseLeave={() => setIsShow(false)}>
      <p className="outline-dropdown__label">{label || ""}</p>
      {isSupportFilter ? (
        <OutlineDropdownFilter
          setIsShow={setIsShow}
          value={selectedValue}
          onChange={(e) => {
            setSelectedValue(e.target.value);
          }}
        />
      ) : (
        <button
          className="outline-dropdown__button"
          onClick={() => setIsShow(!isShow)}
        >
          {selectedValue}
        </button>
      )}

      <div
        className="outline-dropdown__list"
        style={{ display: `${isShow ? "block" : "none"}` }}
      >
        {filteredOptions.map((item, index) => {
          let newAttributes = {};
          dataAttributes.map((attr) => {
            newAttributes = {};
            const key = `data-${attr}`;
            const value = String(item[attr]);
            if (key && value) {
              newAttributes[key] = value;
            }
          });
          const value = assistantTab ? item : Object.values(filteredOptions[index])[indexOfLabel];
          return (
            <a
              key={`${item}-${index}`}
              onClick={handleClick}
              {...newAttributes}
            >
              {value || item}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default OutlineDropdown;
