import moment from "moment";

// 2/17/2009 => 17 Feb
const formatToConvenientDates = (dates, format = "DD/MM/YYYY") => {
  let new_dates = [];
  for (const date of dates) {
    new_dates.push(moment(date, format).format("DD MMM"));
  }
  return new_dates;
};

// new Date(...) => 2/17/2009
const formatToExactDates = (dates) => {
  const newDates = [];
  for (const date of dates) {
    newDates.push(moment(date).format("DD/MM/YYYY"));
  }
  return newDates;
};

const getDaysBetweenDates = (startDate, endDate) => {
  const arr = [];
  let currDate = new Date(startDate);
  while (currDate <= new Date(endDate)) {
    arr.push(new Date(currDate));
    currDate.setDate(currDate.getDate() + 1);
  }
  return arr;
};

const nowUTCDate = () => {
  return new Date(new Date().toISOString().slice(0, -1))
}

export { formatToConvenientDates, formatToExactDates, getDaysBetweenDates, nowUTCDate };
