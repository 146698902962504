import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Dropdown } from "semantic-ui-react";

import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";
import { conversionPeriodOptions } from "../../UI-components/ConversionPeriod/constant";
import { graphHeightWithDropdown } from "../../views/Dashboard/AccountPerformance/constants";

import { getConversionsSchema } from "./ChartsSchema";
import { fetchConversionsStats, getPreparedOptions } from "./utils";

const ConversionsChart = ({ fromDate, toDate, legendPosition }) => {
  const [chartStats, setChartStats] = useState([]);
  const [conversionPeriod, setConversionPeriod] = useState(
    Number(localStorage.getItem("conversionPeriodValue"))
  );
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvContext);
  const { client } = useContext(ClientContext);

  const getChartData = () => {
    const chartData = {
      labels: [],
      datasets: [],
    };

    if (!fromDate || !toDate) {
      return chartData;
    }
    if (chartStats.length) {
      const dateLabels = chartStats.map(({ date }) => date);
      const conversionsStats = chartStats.map(
        ({ convertedSum }) => convertedSum
      );
      const checkoutsStats = chartStats.map(({ checkoutSum }) => checkoutSum);

      chartData.labels = dateLabels;
      chartData.datasets = getConversionsSchema(
        checkoutsStats,
        conversionsStats
      );
    }
    return chartData;
  };

  const onDropdownChange = (e, { value }) => {
    localStorage.setItem("conversionPeriodValue", value);
    setConversionPeriod(value);
  };

  useEffect(() => {
    if (client && fromDate && toDate) {
      fetchConversionsStats(
        client,
        getTokenSilently,
        env,
        fromDate,
        toDate,
        conversionPeriod
      ).then((stats) => {
        setChartStats(stats);
      });
    }
  }, [fromDate, toDate, client, getTokenSilently, env, conversionPeriod]);

  return (
    <>
      <div>
        <Bar
          data={getChartData()}
          height={graphHeightWithDropdown}
          options={{
            title: {
              display: true,
              text: "Conversions & Checkouts",
              fontSize: 20,
            },
            hover: {
              mode: "nearest",
            },
            legend: {
              display: true,
              position: legendPosition,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
            maintainAspectRatio: false,
          }}
        />
      </div>
      <div className="conversion-period-dropdown-wrapper">
        <Dropdown
          fluid
          selection
          onChange={onDropdownChange}
          value={conversionPeriod}
          options={getPreparedOptions(conversionPeriodOptions)}
        />
      </div>
    </>
  );
};

export default ConversionsChart;
