import React, { useRef } from "react";

import "./MultipleSelect.css";

const MultiSelect = ({
  optionList,
  options,
  setOptions,
  label = "Select groups",
}) => {
  const refList = useRef();

  const handleShowHideList = () => {
    if (refList.current.style.display !== "flex") {
      refList.current.style.display = "flex";
    } else {
      refList.current.style.display = "none";
    }
  };

  const handleAddOrRemoveOption = (e) => {
    const optionId = e.target.id;
    if (options.includes(optionId)) {
      e.target.style.backgroundColor = "white";
      setOptions(
        options.filter((item) => {
          return item !== optionId;
        })
      );
    } else {
      e.target.style.backgroundColor = "rgba(0, 0, 0, 0.08)";
      setOptions([...options, optionId]);
    }
  };
  return (
    <div className="multi-selector">
      <div className="multi-selector__content">
        {!options.length ? <p>{label}</p> : ""}
        {options.map((item) => {
          return <span key={item}>{item}</span>;
        })}
      </div>
      <span className="multi-selector__arrow" onClick={handleShowHideList} />
      <div
        ref={refList}
        className="multi-selector__list"
        onMouseLeave={() => (refList.current.style.display = "none")}
      >
        {optionList.map((item) => {
          return (
            <span
              onClick={handleAddOrRemoveOption}
              id={item}
              key={item}
              style={{
                backgroundColor: `${
                  options.includes(item) ? "rgba(0, 0, 0, 0.08)" : "white"
                }`,
              }}
            >
              {item}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export default MultiSelect;
