const getWaitTimeStatsSchema = (answeredStats, declinedStats) => {
  return [
    {
      label: "Answered Calls",
      data: answeredStats,
      fill: false,
      backgroundColor: "rgb(75,192,192)",
      borderColor: "rgb(75,192,192)",
      hoverBackgroundColor: "rgba(76 180 170 0.75)",
      hoverBorderColor: "rgba(76 180 170 0.75)",
    },
    {
      label: "Declined Calls",
      data: declinedStats,
      fill: false,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
  ];
};

export { getWaitTimeStatsSchema };
