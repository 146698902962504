import { VoiceableButtonAttributes } from "./types";

const getDataAttributes = (
  data: VoiceableButtonAttributes,
  prefix = ""
): Record<string, string> => {
  const dataAttrs = {};
  for (const [key, value] of Object.entries(data)) {
    const prefixedKey = prefix ? `${prefix}-${key}` : key;
    if (typeof value === "object") {
      const nestedDataAttrs = getDataAttributes(value, prefixedKey);
      Object.assign(dataAttrs, nestedDataAttrs);
    } else {
      const configPath = prefixedKey;
      dataAttrs[`data-${prefixedKey}`.toLowerCase()] = configPath + "|" + value;
    }
  }
  return dataAttrs;
};

export { getDataAttributes };
