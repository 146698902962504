import React from "react";
import { Button } from "semantic-ui-react";

import "./SupportActionButton.css";

const SupportActionButton = (props) => {
  return <Button {...props} className="supportActionButton" />;
};

export default SupportActionButton;
