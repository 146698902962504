import { useCallback, useContext, useEffect, useState } from "react";

import PermissionNotificationContext from "../context/PermissionNotificationContext";

const useWebNotification = () => {
  const [text, setText] = useState("");

  const { permissionGranted } = useContext(PermissionNotificationContext);

  const triggerNotification = useCallback((text) => {
    setText(text);
  }, []);

  useEffect(() => {
    if (permissionGranted && text) {
      const data = {
        baseUrl: window.location.origin,
        sourceUrl: window.location.href,
      };

      navigator.serviceWorker.ready.then((registration) => {
        registration.showNotification(text, {
          badge: "Voiceable",
          body: "Voiceable",
          tag: text,
          renotify: true,
          icon: "https://storage.voice-able.com/assistant/images/voiceable_icon_gradient.png",
          data,
        });
      });
      setText("");
    }
  }, [text, permissionGranted]);

  return { triggerNotification };
};

export default useWebNotification;
