import React, { useEffect, useState } from "react";

import "./InputIndicator.css";

const indicatorsCount = 10;

const InputIndicator = () => {
  const [stream, setStream] = useState(null);
  const [audioContext, setAudioContext] = useState(null);
  useEffect(() => {
    createAudioContext();
  }, []);

  useEffect(() => {
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [stream]);

  useEffect(() => {
    return () => {
      if (audioContext) {
        audioContext.close();
      }
    };
  }, [audioContext]);

  const fillIncicators = (vol) => {
    const indicators = [...document.querySelectorAll(".indicator")];
    const numberOfindicatorsToColor = Math.round(vol / 10);
    const pidsToColor = indicators.slice(0, numberOfindicatorsToColor);
    for (const indicator of indicators) {
      indicator.style.backgroundColor = "#e6e7e8";
    }
    for (const indicator of pidsToColor) {
      indicator.style.backgroundColor = "#69ce2b";
    }
  };

  const createAudioContext = () => {
    navigator.mediaDevices
      .getUserMedia({
        audio: true,
        video: false,
      })
      .then((stream) => {
        const audioContext = new AudioContext();
        const analyser = audioContext.createAnalyser();
        const microphone = audioContext.createMediaStreamSource(stream);
        const scriptProcessor = audioContext.createScriptProcessor(2048, 1, 1);

        analyser.smoothingTimeConstant = 0.8;
        analyser.fftSize = 1024;

        microphone.connect(analyser);
        analyser.connect(scriptProcessor);
        scriptProcessor.connect(audioContext.destination);

        const onAudioProcess = () => {
          const array = new Uint8Array(analyser.frequencyBinCount);
          analyser.getByteFrequencyData(array);
          const arraySum = array.reduce((a, value) => a + value, 0);
          const average = arraySum / array.length;
          fillIncicators(average);
        };

        scriptProcessor.addEventListener("audioprocess", onAudioProcess);

        const onStateChange = () => {
          if (audioContext.state === "closed") {
            audioContext.removeEventListener("statechange", onStateChange);
            scriptProcessor.removeEventListener("audioprocess", onAudioProcess);
          }
        };

        audioContext.addEventListener("statechange", onStateChange);

        setStream(stream);
        setAudioContext(audioContext);
      })
      .catch((e) => {
        console.error(e);
      });
  };
  return (
    <div className="input-indicator-wrapper">
      {[...Array(indicatorsCount).keys()].map((item) => {
        return <div key={item} className="indicator" />;
      })}
    </div>
  );
};

export default InputIndicator;
