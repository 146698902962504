import React, { useEffect, useState } from "react";
import "./PostfixesField.css";
import Input from "../../../../../UI-components/Input/Input";
import { getArrayFieldHandler } from "../../../../../utils/reducerHelpers";
import { Tags } from "../../../../../UI-components";
import { isHostnameValid } from "../../../../../utils/validation";
import { Icon } from "semantic-ui-react";

const PostfixesField = ({ dispatch }) => {
  const [postfixes, setPostfixes] = useState({});
  const arrayFieldHandler = getArrayFieldHandler(dispatch);

  useEffect(() => {
    arrayFieldHandler("postfixes", postfixes);
  }, [postfixes]);

  const onClickX = (e) => {
    const hostnameToRemove = e.target.dataset.hostname;
    delete postfixes[hostnameToRemove];
    setPostfixes({ ...postfixes });
  };

  return (
    <div className="postfixes-field">
      <Input
        placeholder="Add client hosts"
        validation={isHostnameValid}
        onPressEnter={(hostname) => {
          if (!hostname) {
            return;
          }
          const newPostfix = {};
          newPostfix[hostname] = [];
          setPostfixes({ ...newPostfix, ...postfixes });
        }}
      />

      {Object.keys(postfixes).map((key) => {
        return (
          <div className="postfixes-field__wrapper" key={key}>
            <Icon name="x" data-hostname={key} onClick={onClickX} />
            <Tags
              key={key}
              label={key}
              addItemProps={{
                placeholder: "Add host supported path(s)",
                onEnter: (items) => {
                  postfixes[key] = items;
                  setPostfixes({ ...postfixes });
                },
              }}
              removeItemProps={{
                icon: "trash alternate outline",
                onClick: (items) => {
                  postfixes[key] = items;
                  setPostfixes({ ...postfixes });
                },
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

export default PostfixesField;
