import React, { useState } from "react";
import { Dropdown } from "semantic-ui-react";

import { arrayOfOptions } from "./ArrayOfLabels";

import "./AddLabel.css";

const AddLabel = (props) => {
  const stateOptions = arrayOfOptions.map((value, index) => ({
    key: index,
    text: value.text,
    value: value,
  }));

  const [option, setOption] = useState("");

  return (
    <div className="AddLabel">
      <Dropdown
        placeholder="Choose a text"
        fluid
        search
        selection
        options={stateOptions}
        onChange={(e, data) => setOption(data.value)}
        style={{ fontSize: "16px", width: "300px" }}
      />
      <div className="enterer" />
      <button
        className="submit-button"
        onClick={() => props.onClickButtonLabel(option)}
      >
        Add label
      </button>
      <div className="enterer" />
    </div>
  );
};

export default AddLabel;
