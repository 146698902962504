import React, { useContext, useEffect, useState } from "react";
import {
  Card,
  Grid,
  Icon,
  Loader,
  Segment,
  Statistic,
} from "semantic-ui-react";

import { getConversationsByAgentId, getUsers } from "../../../apis/serviceApis";
import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";
import { getSessionsByDateRange } from "../../Functions/GetAgents";
import {
  convertDataToConversations,
  mapByAgents,
} from "../../Functions/GetCsvFromGs";
import ConversationsTable from "../Conversations/ConversationsTable";

import "./Agent.css";

const Agent = (props) => {
  const [agent, setAgent] = useState(null);
  const env = useContext(EnvContext);
  const [agentloading, setAgentLoading] = useState(true);
  const [conversationLoading, setConversationLoading] = useState(true);
  const [conversations, setConversations] = useState([]);
  const [conversationsOfAgent, setConversationsOfAgent] = useState([]);
  const [statistics, setStatistics] = useState({});
  const { getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);

  useEffect(() => {
    const agentId = `auth0|${props.match.params.agentId}`;

    getTokenSilently()
      .then((token) => {
        return getUsers(token, env, client);
      })
      .then((agents) => {
        const foundAgent = agents.find(({ id }) => id === agentId);
        if (foundAgent) setAgent(foundAgent);
      })
      .finally(() => {
        setAgentLoading(false);
      });

    fetchAgentConversations();

    getSessionsByDateRange(
      getTokenSilently,
      env,
      props.match.params.client,
      "Last Month"
    )
      .then((value) => setConversations(convertDataToConversations(value)))
      .then(() => setConversationLoading(false));
  }, []);

  const fetchAgentConversations = async () => {
    const agentId = `auth0|${props.match.params.agentId}`;
    setConversationLoading(true);
    try {
      const token = await getTokenSilently();
      const conversations = await getConversationsByAgentId(
        client,
        agentId,
        token,
        env
      );
      setConversationsOfAgent(conversations);
    } catch (e) {
      /* empty */
    } finally {
      setConversationLoading(false);
    }
  };

  useEffect(() => {
    if (conversations && conversations.length > 0) {
      // console.log("conversations=", conversations);
      let allSuccessCalls = conversations.filter(
        (conversation) => conversation.duration > 10
      );
      let allTotalValue = allSuccessCalls.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.checkoutValue ? currentValue.checkoutValue : 0),
        0
      );

      let conversationsByAgents = mapByAgents(conversations);
      let conversationByAgent =
        conversationsByAgents[`auth0|${props.match.params.agentId}`] || [];
      let successCalls = conversationByAgent.filter(
        (conversation) => conversation.duration > 10
      );
      let upSells = conversationByAgent.filter(
        (conversation) => parseInt(conversation.upsellAttempt) > 0
      ).length;
      let crossSells = conversationByAgent.filter(
        (conversation) => parseInt(conversation.crosssellAttempt) > 0
      ).length;
      let navigation = conversationByAgent.filter(
        (conversation) => parseInt(conversation.navigationAttempt) > 0
      ).length;
      let proactivity =
        (2 * (upSells > 0 ? 1 : 0) +
          2 * (crossSells > 0 ? 1 : 0) +
          (navigation > 0 ? 1 : 0)) /
        5;
      let convertedCalls = conversationByAgent
        .filter((conversation) => conversation.successCheckout)
        .map((conversation) => {
          return {
            conversionItemsValue: conversation.successCheckout.reduce(
              (accumulator, currentValue) =>
                accumulator +
                currentValue["conversion"] *
                  currentValue.price *
                  currentValue.units,
              0
            ),
            similarItemsValue: conversation.successCheckout.reduce(
              (accumulator, currentValue) =>
                accumulator +
                currentValue["similar"] *
                  currentValue.price *
                  currentValue.units,
              0
            ),
            variantItemsValue: conversation.successCheckout.reduce(
              (accumulator, currentValue) =>
                accumulator +
                currentValue["variant"] *
                  currentValue.price *
                  currentValue.units,
              0
            ),
            upSellItemsValue: conversation.successCheckout.reduce(
              (accumulator, currentValue) =>
                accumulator +
                currentValue["up-sell"] *
                  currentValue.price *
                  currentValue.units,
              0
            ),
            crossSellItemsValue: conversation.successCheckout.reduce(
              (accumulator, currentValue) =>
                accumulator +
                currentValue["cross-sell"] *
                  currentValue.price *
                  currentValue.units,
              0
            ),
          };
        });
      let totalValue = successCalls.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.checkoutValue ? currentValue.checkoutValue : 0),
        0
      );

      let answeredCalls = successCalls.length;
      let misssedCalls = conversationByAgent.length - successCalls.length;
      let stats = {
        totalCalls: conversationByAgent.length,
        answeredCalls: answeredCalls,
        misssedCalls: misssedCalls,
        convertedCalls: convertedCalls.filter(
          (oneCall) => oneCall.conversionItemsValue > 0
        ).length,
        convertedCallsPercent: convertedCalls.length / answeredCalls,
        totalValue: totalValue,
        totalValueAvg:
          convertedCalls.length > 0 ? totalValue / convertedCalls.length : 0,
        allTotalValue: allTotalValue,
        allTotalValueAvg:
          allTotalValue /
          allSuccessCalls.filter((conversation) => conversation.successCheckout)
            .length,
        upSells: upSells,
        crossSells: crossSells,
        navigation: navigation,
        proactivity: proactivity,
      };
      setStatistics(stats);
    }
  }, [conversations]);

  useEffect(() => {
    // console.log("statistics=", statistics);
  }, [statistics]);

  // if(Object.getOwnPropertyNames(agent).length == 0)
  //     return <Spinner/>
  //   let revenueIncrease = null;
  //   if (agent.revenueIncrease > 0)
  //     revenueIncrease = (
  //       <Image
  //         src={increasePosIcon}
  //         className="stats-arrow"
  //         centered
  //         size="mini"
  //       />
  //     );
  //   else if (agent.revenueIncrease < 0)
  //     revenueIncrease = (
  //       <Image
  //         src={decreaseNegIcon}
  //         className="stats-arrow"
  //         centered
  //         size="mini"
  //       />
  //     );
  return (
    <div className="Agent">
      {agentloading ? (
        <Loader active inline="centered" />
      ) : agent && statistics ? (
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Card
                raised
                padded
                style={{
                  width: "100%",
                  fontSize: "1.3em",
                  height: "100%",
                  borderRadius: "15px",
                }}
              >
                <Card.Content>
                  <Icon name="user circle" size="massive" />
                  <div className="enterer" />
                  <Card.Header>{agent.name || agent.username}</Card.Header>
                  <div className="enterer" />
                  {/* <Card.Meta>
                    <span>{agent.agend_id.substring(6)}</span>
                  </Card.Meta> */}
                  {/* <Card.Description style={{ display: "inline-block" }}>
                <Header sub style={{ margin: "10px 0px 0px 0px" }}>
                  Agent Groups:
                </Header>
                <List style={{ marginTop: "5px" }}>
                  {agent.groups.map((group) => (
                    <List.Item>{group}</List.Item>
                  ))}
                </List>
              </Card.Description> */}
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={10}>
              <Segment raised padded>
                <Statistic.Group size="small" widths={4}>
                  <Statistic>
                    <Statistic.Value className="font-size-value">
                      {statistics.totalCalls}
                    </Statistic.Value>
                    <Statistic.Label>Incoming Calls</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      {statistics.answeredCalls}
                    </Statistic.Value>
                    <Statistic.Label>Successful Calls</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      {statistics.convertedCalls}
                    </Statistic.Value>
                    <Statistic.Label>Converted Calls</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      {Math.floor(statistics.convertedCallsPercent * 100)}%
                    </Statistic.Value>
                    <Statistic.Label>Converted Calls Rate</Statistic.Label>
                  </Statistic>
                  {/* <Statistic>
                <Statistic.Value>{agent.upSells} </Statistic.Value>
                <Statistic.Label>Up-Sells</Statistic.Label>
              </Statistic> */}
                  {/* <Statistic>
                <Statistic.Value>{agent.crossSells} </Statistic.Value>
                <Statistic.Label>Cross-Sells</Statistic.Label>
              </Statistic> */}
                  <Statistic>
                    <Statistic.Value>
                      {Math.floor(statistics.totalValueAvg)}
                    </Statistic.Value>
                    <Statistic.Label>AOV</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>{statistics.totalValue}</Statistic.Value>
                    <Statistic.Label>Revenue</Statistic.Label>
                  </Statistic>
                  {/* <Statistic>
										<Statistic.Value>
											{statistics.allTotalValue}
										</Statistic.Value>
										<Statistic.Label>Total AOV</Statistic.Label>
									</Statistic> */}
                  <Statistic>
                    <Statistic.Value>
                      {Math.floor(
                        (statistics.totalValueAvg /
                          statistics.allTotalValueAvg) *
                          100
                      )}
                      %
                    </Statistic.Value>
                    <Statistic.Label>Percent AOV</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>{statistics.upSells}</Statistic.Value>
                    <Statistic.Label>Up-Sell</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>{statistics.crossSells}</Statistic.Value>
                    <Statistic.Label>Cross-Sell</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>{statistics.navigation}</Statistic.Value>
                    <Statistic.Label>Navigation</Statistic.Label>
                  </Statistic>
                  <Statistic>
                    <Statistic.Value>
                      {statistics.proactivity * 10}
                    </Statistic.Value>
                    <Statistic.Label>Proactivity</Statistic.Label>
                  </Statistic>
                  {/* <Statistic>
                <Statistic.Value>
                  {agent.revenueIncrease * 100}% {revenueIncrease}
                </Statistic.Value>
                <Statistic.Label>Revenue Increase</Statistic.Label>
              </Statistic> */}
                </Statistic.Group>
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <ConversationsTable
                isLoading={conversationLoading}
                conversations={conversationsOfAgent}
                onConversionPeriodChange={fetchAgentConversations}
                history={props.history}
                oneAgentOnly
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      ) : (
        <h1>No Agent Found</h1>
      )}
    </div>
  );
};

export default Agent;
