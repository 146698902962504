import React, { FC, useEffect, useState } from "react";

import { SimpleCheckbox } from "../../../../../UI-components";
import { getArrayFieldHandler } from "../../../../../utils/reducerHelpers";

import "./WorkDaysField.css";
import DayField from "./DayField/DayField";
import _ from "lodash";
import {
  nameDays,
  OnDispatchWorkDaysType,
  weekendIndexes,
  WorkDaysFieldProps,
  workingDaysIndexes,
} from "./index";

const WorkDaysField: FC<WorkDaysFieldProps> = ({
  fieldKey,
  dispatch,
  fieldValue,
  isLazyDispatch = false,
}) => {
  const [days, setDays] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [weekendDays, setWeekendDays] = useState([]);

  const [previousTimeout, setPreviousTimeout] = useState(null);
  const [isAllWorkingDays, setIsAllWorkingDays] = useState(false);
  const [isAllWeekendDays, setIsAllWeekendDays] = useState(false);
  const arrayFieldHandler = getArrayFieldHandler(dispatch);

  useEffect(() => {
    setDays(_.cloneDeep(fieldValue));
  }, [fieldValue]);

  useEffect(() => {
    setWeekendDays(days.filter((item) => weekendIndexes.includes(item.day)));
    setWorkingDays(
      days.filter((item) => workingDaysIndexes.includes(item.day))
    );
  }, [days]);

  useEffect(() => {
    if (isAllWorkingDays) {
      onDispatchWorkDays({
        dayIndex: workingDaysIndexes[0],
        startTime: workingDays[0].startTime,
        endTime: workingDays[0].endTime,
      });
    }
  }, [isAllWorkingDays]);

  useEffect(() => {
    if (isAllWeekendDays) {
      onDispatchWorkDays({
        dayIndex: weekendIndexes[0],
        startTime: weekendDays[0].startTime,
        endTime: weekendDays[0].endTime,
      });
    }
  }, [isAllWeekendDays]);

  const onDispatchWorkDays: OnDispatchWorkDaysType = ({
    dayIndex,
    enabled,
    startTime,
    endTime,
  }) => {
    const newValue = days.map((day) => {
      const onlyWorkDays =
        isAllWorkingDays &&
        workingDaysIndexes.includes(day.day) &&
        workingDaysIndexes.includes(dayIndex);

      const onlyWeekendDay =
        isAllWeekendDays &&
        weekendIndexes.includes(day.day) &&
        weekendIndexes.includes(dayIndex);

      if (day.day === +dayIndex || onlyWorkDays || onlyWeekendDay) {
        if (day.day === +dayIndex) {
          if (typeof enabled == "boolean") {
            day.enabled = enabled;
          }
        }

        if (typeof startTime == "string") {
          day.startTime = startTime;
        }
        if (typeof endTime == "string") {
          day.endTime = endTime;
        }
      }
      return day;
    });
    setDays(newValue);

    if (isLazyDispatch) {
      // will be dispatched only if the user doesn't do any action within 1 second
      onLazyDispatch(newValue);
    } else {
      arrayFieldHandler(fieldKey, newValue);
    }
  };

  const onLazyDispatch = (newValue: object[]): void => {
    if (previousTimeout) {
      clearTimeout(previousTimeout);
    }
    const timeout = setTimeout(() => {
      arrayFieldHandler(fieldKey, newValue);
      setPreviousTimeout(null);
      clearTimeout(timeout);
    }, 500);
    setPreviousTimeout(timeout);
  };

  return (
    <>
      <div className="work-days-field">
        <div className="work-days-field__working-days">
          <SimpleCheckbox
            checked={isAllWorkingDays}
            label="Same time during working days (MON-FRI)"
            onChange={(e, { checked }) => {
              setIsAllWorkingDays(checked);
            }}
          />
          {workingDays.map((day) => {
            const disabled =
              isAllWorkingDays && day.day !== workingDaysIndexes[0];
            return (
              <div key={day.day}>
                <p className="work-days-field__label">{nameDays[day.day]}</p>
                <DayField
                  day={day}
                  onDispatchWorkDays={onDispatchWorkDays}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
        <div className="work-days-field__weekend-days">
          <SimpleCheckbox
            checked={isAllWeekendDays}
            label="Same time during weekend days (SAT-SUN)"
            onChange={(e, { checked }) => {
              setIsAllWeekendDays(checked);
            }}
          />
          {weekendDays.map((day) => {
            const disabled = isAllWeekendDays && day.day !== weekendIndexes[0];
            return (
              <div key={day.day}>
                <p className="work-days-field__label">{nameDays[day.day]}</p>
                <DayField
                  day={day}
                  onDispatchWorkDays={onDispatchWorkDays}
                  disabled={disabled}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default WorkDaysField;
