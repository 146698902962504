import React, { useEffect, useState } from "react";

import en from "../language/en.json";
import he from "../language/he.json";

const languagesOptions = {
  en: { code: "en", label: "English", direction: "ltr", json: en },
  he: { code: "he", label: "Hebrew", direction: "rtl", json: he },
};

const LanguageContext = React.createContext({
  language: en,
  languageDirection: "ltr",
  defaultLanguageLabel: "",
});

const LanguageProvider = ({ children, ...props }) => {
  const [languageCode, setLanguageCode] = useState(props.value.languageCode);
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue({
      languageCode: languageCode,
      setLanguageCode: setLanguageCode,
      language: languagesOptions[languageCode]["json"],
      languageDirection: languagesOptions[languageCode]["direction"],
    });
    localStorage.setItem("languageCode", languageCode);
  }, [languageCode]);

  return (
    <LanguageContext.Provider
      value={{
        ...value,
        defaultLanguageLabel:
          languagesOptions[props.value.languageCode]["label"],
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export { LanguageProvider, languagesOptions };

export default LanguageContext;
