import React, { FC, memo } from "react";
import { SimpleCheckbox } from "../../../../../../UI-components";
import "./DayField.css";
import { Icon } from "semantic-ui-react";
import TimePicker from "../../../../../../UI-components/TimePicker/TimePicker";
import { OnDispatchWorkDaysType, WorkDay } from "../index";

interface DayFieldProps {
  day: WorkDay;
  onDispatchWorkDays: OnDispatchWorkDaysType;
  disabled: boolean;
}

const DayField: FC<DayFieldProps> = ({ day, onDispatchWorkDays, disabled }) => {
  return (
    <div className={`day-field`}>
      <div className="day-field__wrapper">
        <SimpleCheckbox
          data-day={day.day}
          checked={day.enabled}
          label="Enabled"
          onChange={(e, { checked }) => {
            const dayIndex = e.target.parentElement.dataset.day;
            onDispatchWorkDays({ dayIndex, enabled: checked });
          }}
        />
        <div
          className={`day-field__time-wrapper${disabled ? " disabled" : ""}`}
        >
          <TimePicker
            onChange={(time) => {
              onDispatchWorkDays({ dayIndex: day.day, startTime: time });
            }}
            defaultTime={day.startTime}
          />
          <Icon name="window minimize outline" />
          <TimePicker
            onChange={(time) => {
              onDispatchWorkDays({ dayIndex: day.day, endTime: time });
            }}
            defaultTime={day.endTime}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(DayField);
