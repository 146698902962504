import React, { useEffect, useState } from "react";

const PermissionNotificationContext = React.createContext({
  permissionGranted: false,
});

const PermissionNotificationProvider = ({ children }) => {
  const [permissionGranted, setPermissionGranted] = useState(false);
  useEffect(() => {
    if (!("serviceWorker" in navigator) || !("PushManager" in window)) {
      // isn't supported on this browser
      setPermissionGranted(false);
      return;
    }
    askPermission();
  }, []);

  const askPermission = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        setPermissionGranted(true);
      }
    });
  };
  return (
    <PermissionNotificationContext.Provider value={{ permissionGranted }}>
      {children}
    </PermissionNotificationContext.Provider>
  );
};
export { PermissionNotificationProvider };
export default PermissionNotificationContext;
