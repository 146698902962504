import React, { useEffect, useRef } from "react";

import Input from "../../../../UI-components/Input/Input";

import "./ToggleList.css";

const ToggleList = ({
  items,
  handleToggle,
  selectedItems,
  headerInputProps = {
    enabled: false,
    placeholder: "",
    onHeaderInputPressEnter: () => {},
  },
}) => {
  const itemsListRef = useRef(null);
  const headerRolesRef = useRef(null);
  const onResize = () => {
    const userRolesHeight = headerRolesRef.current.clientHeight;
    const role = itemsListRef.current.firstChild;
    if (role) {
      const roleHeight = role.clientHeight + role.clientHeight * 0.05;
      const childCount = itemsListRef.current.childElementCount;
      const rolesListHeight = roleHeight * childCount;
      const heightForScroll = `calc(100% - ${userRolesHeight}px)`;

      itemsListRef.current.style.height = `min(${heightForScroll}, ${rolesListHeight}px)`;
    }
  };

  useEffect(() => {
    if (itemsListRef && headerRolesRef) {
      onResize();
      window.addEventListener("resize", onResize);
    }
    return () => window.removeEventListener("resize", onResize);
  }, [selectedItems, itemsListRef, headerRolesRef]);

  return (
    <div className="toggle-list">
      <ul className="toggle-list__header" ref={headerRolesRef}>
        {selectedItems.map((item) => {
          return <li key={item.id || item}>{item.name || item}</li>;
        })}
        {headerInputProps.enabled && (
          <div className="toggle-list__header__input">
            <Input
              onPressEnter={headerInputProps.onHeaderInputPressEnter}
              placeholder={headerInputProps.placeholder}
            />
          </div>
        )}
      </ul>
      <ul ref={itemsListRef} className="toggle-list__list">
        {[...new Set(items)].sort().map((item) => {
          const itemId = item.id || item;
          const isItemActive = selectedItems.some((selectedItem) => {
            const selectedItemId = selectedItem.id || selectedItem;
            return selectedItemId === itemId;
          });
          return (
            <li
              onClick={handleToggle}
              key={`${itemId}`}
              data-id={itemId}
              className={isItemActive ? "active" : ""}
            >
              <p className="toggle-list__list__title" data-id={itemId}>
                {item.name || item}
              </p>
              <p className="toggle-list__list__description" data-id={item.id}>
                {item.description}
              </p>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ToggleList;
