const stateOptions = [
  {
    key: 0,
    text: "One day",
    value: "Today",
  },
  {
    key: 1,
    text: "One Week",
    value: "Last Week",
  },
  {
    key: 2,
    text: "One Month",
    value: "Last Month",
  },
];

export { stateOptions };
