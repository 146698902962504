import React, { useContext, useEffect, useState } from "react";

import { updateUser } from "../../../../../apis/serviceApis";
import ClientContext from "../../../../../context/ClientContext";
import EnvContext from "../../../../../context/EnvContext";
import UsersContext from "../../../../../context/UsersContext";
import { useAuth0 } from "../../../../../react-auth0-spa";
import {
  FieldButton,
  FilledVoiceableButton,
  ListItems,
} from "../../../../../UI-components";

const UpdateUserInfo = () => {
  const [fullName, setFullName] = useState("");
  const [username, setUsername] = useState("");

  const { client } = useContext(ClientContext);
  const { fetchUsers, user, setUser } = useContext(UsersContext);
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvContext);

  const handleUpdateUser = async () => {
    try {
      const token = await getTokenSilently();
      const userGroups = user.metadata.groups || [];
      const body = {
        clientId: client,
        userId: user.id,
        name: fullName,
        username: username,
        groups: userGroups[client] || [],
      };
      const updatedUser = await updateUser(token, env, body);
      setUser(updatedUser);
      await fetchUsers();
    } catch (e) {
      let reason = e?.response?.data?.reason;
      if (!reason) {
        reason = "An error occurred when updating user.";
      }
      console.error(reason, e);
    }
  };

  useEffect(() => {
    if (user) {
      setFullName(user.name);
      setUsername(user.username);
    }
  }, [user]);

  return (
    <ListItems>
      <FieldButton
        label="Full name"
        item={fullName || ""}
        setItem={setFullName}
      />
      <FieldButton
        label="Username"
        item={username || ""}
        setItem={setUsername}
      />
      <FilledVoiceableButton onClick={handleUpdateUser}>
        Update
      </FilledVoiceableButton>
    </ListItems>
  );
};

export default UpdateUserInfo;
