import React from "react";
import { Checkbox } from "semantic-ui-react";

const SimpleCheckbox = ({ label, onChange, checked, ...props }) => {
  return (
    <>
      <Checkbox
        label={label}
        onChange={onChange}
        checked={checked}
        {...props}
      />
    </>
  );
};

export default SimpleCheckbox;
