import React, { FC, memo } from "react";
import _ from "lodash";

import { SimpleCheckbox } from "../../../../UI-components";
import { getCheckboxHandler } from "../../../../utils/reducerHelpers";
import { DispatchAction } from "../types";

interface OptimizedInputProps {
  currentPath: string;
  title: string;
  deep: number;
  value: string;
  dispatch: React.Dispatch<DispatchAction>;
}

const OptimizedCheckbox: FC<OptimizedInputProps> = ({
  currentPath,
  title,
  deep,
  value,
  dispatch,
}) => {
  const checkboxHandler = getCheckboxHandler(dispatch);

  return (
    <div
      key={currentPath}
      className={"settings-inputs-container config-deep-" + deep}
    >
      <div className="settings-checkbox-block">
        <SimpleCheckbox
          onChange={checkboxHandler}
          checked={value}
          label=""
          name={currentPath}
        />
        <label>{title}</label>
      </div>
      <div className="settings-editbox-block" />
    </div>
  );
};

export default memo(OptimizedCheckbox);
