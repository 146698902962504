import moment from "moment";

import { getConversations } from "../../apis/serviceApis";
import {
  getAverageArray,
  getLabelsByWorkHours,
  minTwoDigits,
} from "../../utils/chart";

const fetchWaitTimeStats = async (
  client,
  getTokenSilently,
  env,
  workDays,
  startDate,
  endDate
) => {
  try {
    const token = await getTokenSilently();
    const preparedFromDate = new Date(startDate).toLocaleDateString("en-UK");
    const preparedToDate = new Date(endDate).toLocaleDateString("en-UK");
    const conversations = await getConversations(
      client,
      preparedFromDate,
      preparedToDate,
      token,
      env
    );

    const dateLabels = getDateLabelsBetweenDates(startDate, endDate);
    return getWaitTimeStats(conversations, workDays, dateLabels);
  } catch (e) {
    return [];
  }
};

const getHourlyAverageDatasets = (datasetsSum, countByHours) => {
  return datasetsSum.map((sum, i) => {
    const count = countByHours[i];
    if (count === 0) {
      return 0;
    }
    return sum / count;
  });
};

const getDateLabelsBetweenDates = (startDate, endDate) => {
  const currDate = new Date(startDate);
  const labels = [];
  while (currDate <= endDate) {
    const label = moment(currDate).format("DD MMM");
    labels.push(label);
    currDate.setDate(currDate.getDate() + 1);
  }
  return labels;
};

const getWaitTimeStats = (conversations, workDays, dateLabels) => {
  const conversationsMap = {};
  for (const conversation of conversations) {
    const dateLabel = moment(new Date(conversation.ts)).format("DD MMM");
    if (conversationsMap[dateLabel]) {
      conversationsMap[dateLabel].push(conversation);
    } else {
      conversationsMap[dateLabel] = [conversation];
    }
  }
  const hourlyAnsweredData = [];
  const hourlyDeclinedData = [];
  const averageAnsweredStats = [];
  const averageDeclinedStats = [];
  const hourlyLabels = getLabelsByWorkHours(workDays, 60);
  for (const dateLabel of dateLabels) {
    if (!conversationsMap[dateLabel]) {
      averageAnsweredStats.push(0);
      averageDeclinedStats.push(0);
      continue;
    }
    const conversationsForDay = conversationsMap[dateLabel];
    const {
      hourlyAnwseredStats,
      hourlyDeclinedStats,
      anwseredByDateStats,
      declineByDateStats,
    } = getStatsForDay(conversationsForDay, hourlyLabels);

    hourlyAnsweredData.push(hourlyAnwseredStats);
    hourlyDeclinedData.push(hourlyDeclinedStats);
    averageAnsweredStats.push(anwseredByDateStats);
    averageDeclinedStats.push(declineByDateStats);
  }

  const hourlyAnsweredStats = getAverageArray(hourlyAnsweredData, 2);
  const hourlyDeclinedStats = getAverageArray(hourlyDeclinedData, 2);

  return {
    hourlyAnsweredStats,
    hourlyDeclinedStats,
    averageAnsweredStats,
    averageDeclinedStats,
  };
};

const getStatsForDay = (conversations, labels) => {
  const countAnsweredByHours = new Array(labels.length).fill(0);
  const countDeclinedByHours = new Array(labels.length).fill(0);
  const datasetsAnsweredSum = new Array(labels.length).fill(0);
  const datasetsDeclinedSum = new Array(labels.length).fill(0);

  let answeredWaitTimeSum = 0;
  let declinedWaitTimeSum = 0;
  let answeredCallsCount = 0;
  let declinedCallsCount = 0;

  for (const conversation of conversations) {
    let labelIndex = 0;
    for (const label of labels) {
      const [hours, minutes] = label.split(":");
      const nextTime = minTwoDigits(+hours + 1) + ":" + minTwoDigits(+minutes);
      if (conversation.time < nextTime) {
        if (
          conversation.callStatus === "Successful" ||
          conversation.callStatus === "Unsuccessful"
        ) {
          countAnsweredByHours[labelIndex]++;
          answeredCallsCount++;
          datasetsAnsweredSum[labelIndex] += conversation.waitTime;
          answeredWaitTimeSum += conversation.waitTime;
        } else {
          countDeclinedByHours[labelIndex]++;
          declinedCallsCount++;
          datasetsDeclinedSum[labelIndex] += conversation.waitTime;
          declinedWaitTimeSum += conversation.waitTime;
        }
        break;
      }
      labelIndex++;
    }
  }
  const hourlyAnwseredStats = getHourlyAverageDatasets(
    datasetsAnsweredSum,
    countAnsweredByHours
  );
  const hourlyDeclinedStats = getHourlyAverageDatasets(
    datasetsDeclinedSum,
    countDeclinedByHours
  );
  const anwseredByDateStats =
    answeredCallsCount && (answeredWaitTimeSum / answeredCallsCount).toFixed(2);
  const declineByDateStats =
    declinedCallsCount && (declinedWaitTimeSum / declinedCallsCount).toFixed(2);

  return {
    hourlyAnwseredStats,
    hourlyDeclinedStats,
    anwseredByDateStats,
    declineByDateStats,
  };
};

export { fetchWaitTimeStats, getDateLabelsBetweenDates };
