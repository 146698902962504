const legendLabels = {
  totalViews: {
    label: "Total Views",
    description: "All pages where our code is installed.",
  },
  buttonViews: {
    label: "Voiceable Views",
    description: "All page views that shows the voiceable button.",
  },
  distinctUsers: {
    label: "Voiceable Unique Views",
    description:
      "Will show only unique views, meaning that if the user browsed a page with a voiceable button twice or more during the day it, it will count it once.",
  },
  distinctPages: {
    label: "Unique Page Views",
    description:
      "Will count all the daily views of unique pages. For example a specific bed page that was viewed 100 times, will be count only once.",
  },
};

export { legendLabels };
