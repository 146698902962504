import React, { FC } from "react";
import { Doughnut } from "react-chartjs-2";

import {
  doughnutHeight,
  doughnutWidth,
} from "../../../views/Dashboard/AccountPerformance/constants";
import { guageNeedlePlugin, labelsPlugin, speedLabelPlugin } from "../index";
import { optionChart } from "../types";

interface NetPromoterScoreChartProps {
  optionChartList: optionChart[];
}

const calculateSpeed = (netPromoterPercentages) => {
  let speed = netPromoterPercentages[2] - netPromoterPercentages[0];
  speed = Math.round(speed);
  if (speed < 0) {
    speed = 0;
  }

  if (speed > 100) {
    speed = 100;
  }
  return speed;
};

const NetPromoterScoreChart: FC<NetPromoterScoreChartProps> = ({
  optionChartList,
}) => {
  const netPromoterLabels = optionChartList.map((item) => item.label);
  const netPromoterColors = optionChartList.map((item) => item.color);
  const netPromoterData = optionChartList.map((item) => item.value);
  const netPromoterPercentages = optionChartList.map((item) => item.per);
  const speed = calculateSpeed(netPromoterPercentages);
  const guageSize = 10;

  const data = {
    labels: netPromoterLabels,
    datasets: [
      {
        data: netPromoterData,
        backgroundColor: netPromoterColors,
        display: true,
        borderWidth: 0,
      },
    ],
  };

  const options = {
    speed: speed,
    guageSize: guageSize,
    cutoutPercentage: "70",
    responsive: false,
    title: {
      display: true,
      text: "Net Promoter Score",
      fontSize: 20,
    },
    legend: {
      display: true,
      position: "right",
    },
    rotation: Math.PI,
    circumference: Math.PI,
  };

  return (
    <>
      <Doughnut
        plugins={[speedLabelPlugin(), labelsPlugin, guageNeedlePlugin()]}
        height={doughnutHeight}
        width={doughnutWidth}
        data={data}
        options={options}
      />
    </>
  );
};

export default NetPromoterScoreChart;
