import { useEffect } from "react";

const useScript = (src: string) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = src;
    script.type = "text/javascript";
    script.async = true;

    const existingScript = document.querySelector(`script[src="${src}"]`);

    if (!existingScript) {
      document.body.appendChild(script);
    }

    return () => {
      if (existingScript) {
        return;
      }

      document.body.removeChild(script);
    };
  }, [src]);
};

export default useScript;
