import React from "react";
import {isMobile} from "react-device-detect";
import {BrowserRouter, Switch} from "react-router-dom";

import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import Spinner from "./components/Spinner/Spinner";
import ClientSelection from "./views/ClientSelection/ClientSelection";
import CreateClientView from "./views/CreateClientView/CreateClientView";
import Invite from "./views/Invite/Invite";
import LiveSupport from "./views/LiveSupport/LiveSupport";
import MobileLiveSupport from "./views/LiveSupport/MobileLiveSupport/MobileLiveSupport.jsx";
import WaitRoom from "./views/WaitRoom/WaitRoom";
import App from "./App";
import history from "./history";
import {useAuth0} from "./react-auth0-spa";
import Assistant from "./views/Assistant/Assistant";

const Router = () => {
    const {loading} = useAuth0();

    if (loading) {
        return <Spinner/>;
    }

    return (
        <BrowserRouter history={history}>
            <Switch>
                <PrivateRoute path="/:client/dashboard" component={App}/>
                <PrivateRoute
                    roles={["Admin"]}
                    path="/register"
                    component={CreateClientView}
                />
                <PrivateRoute
                    roles={["Admin"]}
                    path="/assistant"
                    component={Assistant}
                />
                <PrivateRoute
                    exact
                    path="/:client/support/invite/:userId/:sessionId"
                    component={Invite}
                />
                <PrivateRoute
                    exact
                    path="/:client/support/waitroom/:meetToken"
                    component={WaitRoom}
                />
                <PrivateRoute
                    exact
                    path="/:client/support/:userId/:session"
                    component={isMobile ? MobileLiveSupport : LiveSupport}
                />
                <PrivateRoute
                    exact
                    path="/:client/support"
                    component={isMobile ? MobileLiveSupport : LiveSupport}
                />
                <PrivateRoute
                    path="/:client"
                    component={isMobile ? MobileLiveSupport : LiveSupport}
                />
                <PrivateRoute exact path="/" component={ClientSelection}/>
            </Switch>
        </BrowserRouter>
    );
};

export default Router;
