import React, { useContext, useState } from "react";

import { getProducts } from "../apis/serviceApis";
import { useAuth0 } from "../react-auth0-spa";
import ProductsList from "../views/Dashboard/ProductsList/ProductsList";

import ClientContext from "./ClientContext";
import EnvContext from "./EnvContext";

const ProductsListContext = React.createContext({
  loading: false,
  products: [],
  fetchProducts: async (startDate, endDate) => {},
  reFetchProducts: async () => {},
});

const ProductsListProvider = () => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const { getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);
  const env = useContext(EnvContext);

  const fetchProducts = async (startDate, endDate) => {
    setDateRange({ from: startDate, to: endDate });
    setLoading(true);
    const token = await getTokenSilently();
    const conversations = await getProducts(
      client,
      startDate,
      endDate,
      token,
      env
    );

    setProducts(conversations);
    setLoading(false);
  };

  const reFetchProducts = async () => {
    if (dateRange.from && dateRange.to) {
      await fetchProducts(dateRange.from, dateRange.to);
    }
  };

  return (
    <ProductsListContext.Provider
      value={{
        loading,
        products,
        fetchProducts,
        reFetchProducts,
      }}
    >
      <ProductsList />
    </ProductsListContext.Provider>
  );
};
export { ProductsListProvider };
export default ProductsListContext;
