import React from "react";

import { OutlineDropdown, OutlineInput } from "../../index";

import "./PaginationPageSettings.css";

const pageSizeOptions = [
  { label: 10 },
  { label: 20 },
  { label: 50 },
  { label: 100 },
];

const PaginationPageSettings = ({
  items,
  pageNumber,
  pageSize,
  setPageNumber,
  setPageSize,
}) => {
  return (
    <div className="pagination-page-settings">
      <div>
        <OutlineDropdown
          options={pageSizeOptions}
          label="Show entries"
          defaultValue={pageSizeOptions[0].label}
          onClick={(e) => {
            const currentPageSize = Number(e.target.innerHTML);
            const countOfPage = Math.ceil(items.length / currentPageSize);
            if (pageNumber > countOfPage) {
              setPageNumber(1);
            }
            setPageSize(currentPageSize);
          }}
        />
      </div>
      <div>
        <OutlineInput
          label="Page Number"
          item={pageNumber}
          onChange={(e) => {
            const value = Number(e.target.value);
            const countOfPage = Math.ceil(items.length / pageSize);
            if (Number.isInteger(value) && value >= 0 && value <= countOfPage) {
              setPageNumber(value);
            } else {
              setPageNumber(countOfPage);
            }
          }}
        />
      </div>
    </div>
  );
};

export default PaginationPageSettings;
