import React, { FC, memo, useEffect, useRef } from "react";
import _ from "lodash";
import { Input, TextArea } from "semantic-ui-react";

import {
  getNumberFieldHandler,
  getTextFieldHandler,
} from "../../../../utils/reducerHelpers";
import { DispatchAction } from "../types";

interface OptimizedInputProps {
  currentPath: string;
  title: string;
  deep: number;
  value: string;
  dispatch: React.Dispatch<DispatchAction>;
}

const OptimizedInput: FC<OptimizedInputProps> = ({
  currentPath,
  title,
  deep,
  value,
  dispatch,
}) => {
  const type = _.isNumber(value) ? "number" : "text";
  const textareaRef = useRef(null);

  const textFieldHandler = getTextFieldHandler(dispatch);
  const numberFieldHandler = getNumberFieldHandler(dispatch);

  useEffect(() => {
    if (textareaRef.current) {
      const textareaEl = textareaRef.current.ref.current;
      textareaEl.style.height = "auto";

      textareaEl.style.height = textareaEl.scrollHeight + "px";
    }
  }, [value]);

  const inputProps = {
    placeholder: "Type text here...",
    name: currentPath,
    type: type,
    value: value,
    "data-name": currentPath,
    onChange: type === "number" ? numberFieldHandler : textFieldHandler,
  };

  const getCountOfRows = () => {
    if (!textareaRef.current) {
      return 0;
    }
    const textareaEl = textareaRef.current.ref.current;
    const lineHeight = parseInt(getComputedStyle(textareaEl).lineHeight);
    const contentHeight = textareaEl.scrollHeight - lineHeight;
    return Math.ceil(contentHeight / lineHeight);
  };

  return (
    <div
      key={currentPath}
      className={"settings-inputs-container config-deep-" + deep}
    >
      <div className="settings-checkbox-block">
        <label>{title}</label>
      </div>
      <div className="settings-editbox-block">
        {type === "text" ? (
          <TextArea
            rows={1}
            className={getCountOfRows() >= 15 ? "big-field" : ""}
            ref={textareaRef}
            {...inputProps}
          />
        ) : (
          <Input {...inputProps} />
        )}
      </div>
    </div>
  );
};

export default memo(OptimizedInput);
