import React, { ChangeEventHandler, FC, useState } from "react";
import "./Input.css";

type SizeOptions = "large" | "medium" | "small";

type PressEnter = (e: string) => void;
type Validation = (e: any) => boolean;

interface InputProps {
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onPressEnter?: PressEnter;
  validation?: Validation;
  placeholder?: string;
  defaultValue?: string;
  bottomLine?: boolean;
  size?: SizeOptions;
}

const Input: FC<InputProps> = ({
  onChange = () => {},
  onPressEnter = () => {},
  validation = () => true,
  placeholder = "",
  defaultValue = "",
  bottomLine = true,
  size = "medium",
  ...props
}) => {
  const [valid, setValid] = useState(true);
  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      if (valid) {
        onPressEnter(e.target.value);
        e.target.value = "";
      }
    }
  };

  const addClasses = () => {
    let classes = "";
    classes += " " + size;

    if (bottomLine) {
      classes += " bottom-line";
    }
    if (!valid) {
      classes += " not-valid";
    }

    return classes;
  };

  const handleOnChange = (e) => {
    onChange(e);
    const value = e.target.value;
    if (value) {
      setValid(validation(value));
    }
  };

  return (
    <input
      className={`input${addClasses()}`}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
      type="search"
      onChange={handleOnChange}
      defaultValue={defaultValue}
      {...props}
    />
  );
};

export default Input;
