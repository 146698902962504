import React, {useEffect, useState} from "react";
import {Button, Message} from "semantic-ui-react";

import useAudio from "../../hooks/useAudio";
import notificationSound from "../../assets/audio/incomming_call_ringtone.mp3";

import "./Invite.css";

const Invite = ({visible, onAccept, onDecline}) => {
    const [isVisible, setIsVisible] = useState(visible);
    const {play, pause} = useAudio(notificationSound, {
            loop: true,
            autoPlay: true
        }
    );

    useEffect(() => {
        setIsVisible(visible);
    }, [visible]);

    useEffect(() => {
        if (isVisible) {
            play();
        } else {
            pause();
        }
    }, [isVisible]);

    const handleOnAccept = () => {
        setIsVisible(false);
        onAccept();
    };

    const handleOnDecline = () => {
        setIsVisible(false);
        onDecline();
    };
    return (
        <Message
            hidden={!isVisible}
            size="big"
            id="invite"
            onDismiss={handleOnDecline}
        >
            <Message.Content>
                <Message.Header className="ui teal header small" color="teal">
                    You have been invited to join a call
                </Message.Header>
                <Button
                    inverted
                    floated="left"
                    color="teal"
                    id="accept-invite"
                    onClick={handleOnAccept}
                >
                    Accept
                </Button>
                <Button inverted floated="right" color="red" onClick={handleOnDecline}>
                    Decline
                </Button>
            </Message.Content>
        </Message>
    );
};

Invite.defaultProps = {
    visible: false,
    onDismiss: () => {
    },
    onAccept: () => {
    },
    OnDecline: () => {
    },
};

export default Invite;
