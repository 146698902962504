import { useEffect } from "react";

const useLink = (href: string) => {
  useEffect(() => {
    const link = document.createElement("link");
    link.href = href;
    link.rel = "stylesheet";
    link.type = "text/css";

    const existingLink = document.querySelector(`link[href="${href}"]`);

    if (!existingLink) {
      document.head.appendChild(link);
    }

    return () => {
      if (existingLink) {
        return;
      }

      document.head.removeChild(link);
    };
  }, [href]);
};

export default useLink;
