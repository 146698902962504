import React from "react";

import { UpdateMethods } from "../index";

import ResetPassword from "./ResetPassword/ResetPassword";
import UpdateGroups from "./UpdateGroups/UpdateGroups";
import UpdateRoles from "./UpdateRoles/UpdateRoles";
import UpdateUserInfo from "./UpdateUserInfo/UpdateUserInfo";

const UpdateUser = ({ updateMethod }) => {
  const renderElement = () => {
    if (updateMethod === UpdateMethods.changeInfo.key) {
      return <UpdateUserInfo />;
    } else if (updateMethod === UpdateMethods.resetPassword.key) {
      return <ResetPassword />;
    } else if (updateMethod === UpdateMethods.updateRoles.key) {
      return <UpdateRoles />;
    } else if (updateMethod === UpdateMethods.updateGroups.key) {
      return <UpdateGroups />;
    }
  };

  return <>{renderElement()}</>;
};

export default UpdateUser;
