import React from "react";
import { Divider, Icon, List } from "semantic-ui-react";

const connectedIndicatorColors = {
  connected: "green",
  disconnected: "grey",
  inbetween: "yellow",
};

const ConversationItem = ({ date, selected, onClick, status }) => {
  let connectedIndicator = connectedIndicatorColors.disconnected;
  if (status === "Active") {
    connectedIndicator = connectedIndicatorColors.connected;
  } else {
    connectedIndicator = connectedIndicatorColors.disconnected;
  }
  return (
    <>
      <List.Item
        onClick={onClick}
        className={
          "conversation " +
          (selected ? "selected-conversation " : " ") +
          (status === "Missed" ? "missed-conversation" : "")
        }
      >
        <List.Content>
          <List.Description as="h4">
            {date}
            <Icon
              name="circle"
              style={{ float: "right" }}
              color={connectedIndicator}
            />
          </List.Description>
        </List.Content>
      </List.Item>
      <Divider />
    </>
  );
};

export default React.memo(ConversationItem);
