import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import _ from "lodash";
import { Button, Checkbox, Icon } from "semantic-ui-react";

import notificationSound from "../../../assets/audio/incomming_call_ringtone.mp3";
import CallEndedForm from "../../../components/CallEnded/CallEndedForm";
import IncomingCallScreen from "../../../components/IncomingCallScreen/IncomingCallScreen";
import ModalAvailability from "../../../components/ModalAvailability/ModalAvailability";
import ModalWelcomePage from "../../../components/ModalWelcomePage/ModalWelcomePage";
import OpenTokenCall from "../../../components/OpenTokCall/OpenTokenCall";
import ScheduleSession from "../../../components/ScheduleSession/ScheduleSession";
import Spinner from "../../../components/Spinner/Spinner";
import SupportActionButton from "../../../components/SupportActionButton/SupportActionButton";
import config from "../../../config.json";
import { ClientProvider } from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import useAudio from "../../../hooks/useAudio";
import useWebNotification from "../../../hooks/useWebNotification";
import { useAuth0 } from "../../../react-auth0-spa";

import "./MobileLiveSupport.css";
import ShareScreen from "../../../components/ShareScreen/ShareScreen";
import ClientShareScreen from "../../../components/ClientShareScreen/ClientShareScreen";

let socketErrorsCount = 0;

const MobileLiveSupport = (props) => {
  const [state, setstate] = useState({
    client: null,
    activeUserId: null,
    activeSession: null,
    socket: null,
    conversations: {},
    callActive: false,
    invite: null,
    intent: null,
    agentIsAvailable: false,
    loggedInOut: false,
    reason: "",
    currentSession: {
      latestPage: null,
    },
    callTime: 0,
    room: null,
  });
  const [openModal, setOpenModal] = useState(false);
  const [clientName, setClientName] = useState("");
  const [navWindow, setNavWindow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [ringtoneOutputId, setRingtoneOutputId] = useState("");
  const [answerLoading, setAnswerLoading] = useState(false);
  const [headsetInputId, setHeadsetInputId] = useState("");
  const [headsetOutputId, setHeadsetOutputId] = useState("");
  const [room, setRoom] = useState(null);
  const [clientConfigFeatures, setClientConfigFeatures] = useState({
    autoAnswer: false,
    supportAudio: false,
    recording: true,
    supportShareScreen: true,
  });
  const { triggerNotification } = useWebNotification();
  const { play, pause, setSinkId } = useAudio(notificationSound);

  const navWindowRef = useRef();
  navWindowRef.current = navWindow;

  const { user, getTokenSilently, logout } = useAuth0();

  const env = useContext(EnvContext);

  const supportAPI = axios.create({
    baseURL: config["liveSupport"][env].replace("ws", "http"),
  });

  const stateRef = useRef();
  stateRef.current = state;

  useEffect(() => {
    const fetchClient = async () => {
      const token = await getTokenSilently();
      const response = await axios.get(
        `client/clients/${props.match.params.client}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setClientName(response.data.clientName);
    };

    fetchClient();

    if (!stateRef.current.loggedInOut) {
      setState({ loggedInOut: true });
    }

    checkIfAgentAvailable().then((data) => {
      setState({ agentIsAvailable: !!data });
    });
  }, []);

  useEffect(() => {
    if (env !== null && stateRef.current.client !== null) {
      initSocket();
    }
  }, [env, stateRef.current.client]);

  useEffect(() => {
    if (
      !stateRef.current.notifyUserId ||
      !stateRef.current.conversations[stateRef.current.notifyUserId] ||
      stateRef.current.conversations[stateRef.current.notifyUserId].finishedAt
    ) {
      stopNotify();
    }
  }, [stateRef.current.notifyUserId, stateRef.current.conversations]);

  useEffect(() => {
    const { client, userId, session } = props.match.params;
    if (stateRef.current.client == null && client) {
      setState({ client });
    }

    if (
      userId &&
      (stateRef.current.activeUserId == null ||
        stateRef.current.activeUserId !== userId)
    ) {
      setState({ activeUserId: userId });
    } else if (stateRef.current.activeUserId !== null && !userId) {
      setState({ activeUserId: null });
    }
    if (
      session &&
      (stateRef.current.activeSession == null ||
        stateRef.current.activeSession !== session)
    ) {
      setState({ activeSession: session });
    } else if (stateRef.current.activeSession != null && !session) {
      setState({ activeSession: null });
    }
  }, [props]);

  const setState = (newState) => {
    setstate((prevState) => {
      return { ...prevState, ...newState };
    });
  };

  const notify = (userId) => {
    if (!stateRef.current.callActive && stateRef.current.agentIsAvailable) {
      setSinkId(ringtoneOutputId === "default" ? "" : ringtoneOutputId);
      triggerNotification("Ringing ...");
      play();

      setState({ notifyUserId: userId });
    }
  };

  const stopNotify = () => {
    triggerNotification("");
    pause();
  };

  const markAsRead = async (userId) => {
    let lastSeenByRep = new Date();

    let body = {
      lastSeenByRep,
      userId,
      session: stateRef.current.conversations[userId].session,
    };
    const token = await getTokenSilently();
    supportAPI.post(`/chat/${stateRef.current.client}/updateLastSeen/`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    let utcLastSeenByRep = new Date();
    utcLastSeenByRep.setHours(utcLastSeenByRep.getUTCHours());
    stateRef.current.conversations[userId].lastSeenByRep = utcLastSeenByRep;
  };

  const updateIntent = async (userId, intentBody) => {
    let body = {
      userId,
      session: stateRef.current.conversations[userId].session,
      intentBody,
    };
    const token = await getTokenSilently();
    supportAPI.post(`/chat/${stateRef.current.client}/updateIntent/`, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    stateRef.current.conversations[userId].intentBody = intentBody;

    setState({ intent: userId });
  };

  const handleAgentInviteGroupSelected = (group) => {
    let payload = {
      messageType: "invite",
      group,
      agentName: user.metadata.fullName,
      session: stateRef.current.activeSession,
      userId: stateRef.current.activeUserId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const handleAgentInviteCancel = () => {
    let payload = {
      messageType: "cancelInvite",
      agentName: user.metadata.fullName,
      session: stateRef.current?.invite?.session,
      userId: stateRef.current?.invite?.userId,
    };

    sendMessage(JSON.stringify(payload));
  };

  const handleCallEnd = () => {
    onSendAction([{ name: "callEnd", actionType: "callEnd", parameters: "" }]);
    setState({ callActive: false, callType: null, viewerToken: null });
    const conversationsFlitered = _.omit(
      { ...stateRef.current.conversations },
      [stateRef.current.activeUserId]
    );
    const oldConvesation =
      stateRef.current.conversations[stateRef.current.activeUserId];
    const newConversation = {
      finishedAt: new Date().toLocaleString(),
      ...oldConvesation,
    };
    setState({
      conversations: {
        [stateRef.current.activeUserId]: newConversation,
        ...conversationsFlitered,
      },
    });
    stopNotify();
  };

  const onSendAction = (actions) => {
    let page =
      stateRef.current.conversations[stateRef.current.activeUserId].latestPage;

    let payload = {
      messageType: "actions",
      actions: actions,
      userId: stateRef.current.activeUserId,
      agentName: user.metadata.fullName,
      agentId: user.sub,
      session: stateRef.current.activeSession,
    };

    let action = actions.filter((a) => a.actionType === "Navigation");
    if (action.length > 0) {
      action = action[0];
    } else {
      action = null;
    }

    if (action && action.actionType === "Navigation") {
      payload.page = action.parameters;
      payload.origin = page;
    } else {
      payload.page = page;
    }

    sendMessage(JSON.stringify(payload));
    setTimeout(() => {
      markAsRead(stateRef.current.activeUserId);
    }, 2000);
  };

  const onCallAnswer = async (type) => {
    setAnswerLoading(true);
    const payload = await takeCall(
      stateRef.current.activeUserId,
      stateRef.current.activeSession
    );
    sendMessage(payload);

    const viewerToken = await getChatToken(
      user.sub + "Viewer",
      stateRef.current.activeSession,
      "Viewer",
      "Viewer"
    );
    stopNotify();
    setState({ callType: type, viewerToken });
  };

  const onCallDecline = () => {
    setAnswerLoading(true);
    let payload = {
      messageType: "callDeclined",
      userId: stateRef.current.activeUserId,
      agentName: user.metadata.fullName,
      session: stateRef.current.activeSession,
    };
    sendMessage(JSON.stringify(payload));
    stopNotify();
  };

  const takeCall = async (userId, sessionId) => {
    const token = await getChatToken(userId, sessionId, "Customer", "Customer");
    let payload = {
      messageType: "takeCall",
      userId: userId,
      actions: [
        {
          name: "callStart",
          actionType: "callStart",
          parameters: JSON.stringify({ token: token, roomName: sessionId }),
        },
      ],
      agentName: user.metadata.fullName,
      session: sessionId,
    };

    return JSON.stringify(payload);
  };

  const getChatToken = async (userId, sessionId, role, name) => {
    const client = stateRef.current.client;
    const token = await getTokenSilently();
    const res = await supportAPI.get("video/token", {
      params: {
        identity: JSON.stringify({ username: userId, role, client, name }),
        roomName: sessionId,
        recording: clientConfigFeatures.recording,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    return res.data.token;
  };

  const fetchConversation = (userId, session, force = false) => {
    return new Promise(async (resolve, reject) => {
      try {
        // console.log('Fetching conversations: ', stateRef.current.conversations[userId], session)
        if (
          !force &&
          stateRef.current.conversations[userId] &&
          stateRef.current.conversations[userId].session === session
        ) {
          resolve();
          return;
        }
        const token = await getTokenSilently();
        const conversation = (
          await supportAPI.get(`/chat/${stateRef.current.client}/session/`, {
            params: { userId, session },
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
        ).data;
        let newConversations = {};
        newConversations[userId] = conversation;
        let conversationsFlitered = _.omit(
          { ...stateRef.current.conversations },
          [userId]
        );
        setState({
          conversations: { ...newConversations, ...conversationsFlitered },
        });
        resolve();
      } catch (e) {
        console.error("An error happened during fetching conversations", e);
        reject(e);
      }
    });
  };

  const fetchConversations = async () => {
    try {
      const token = await getTokenSilently();
      const data = (
        await supportAPI.get(`/chat/${stateRef.current.client}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;

      const messages = {};

      data.forEach((element) => {
        messages[element["userId"]] = element;
      });
      setState({ conversations: messages });
    } catch (e) {
      console.log(e);
    }
    setLoading(false);
  };

  const onMessage = (e) => {
    const message = JSON.parse(e.data);
    const handleCustomerMessage = (msg) => {
      switch (msg.messageType) {
        case "ping":
          if (msg.source === "client") {
            if (stateRef.current.conversations[msg.userId]) {
              stateRef.current.conversations[msg.userId].customerLastSeen =
                msg["ts"];
            }
          }
          break;
        case "clientConnected":
          fetchConversation(msg.userId, msg.session)
            .then(() => {
              if (!stateRef.current.callActive) {
                props.history.push(
                  `/${stateRef.current.client}/support/${msg.userId}/${msg.session}`
                );
                notify(msg.userId);
              }
            })
            .catch(() => {
              console.log("Failed to fetch session", msg);
            });
          break;
        case "navigation":
        case "actions":
        case "message":
          stateRef.current.conversations[msg.userId].messages.push(msg);
          stateRef.current.conversations[msg.userId].latestMessageTS = msg.ts;
          stateRef.current.conversations[msg.userId].latestPageTitle =
            msg.pageTitle;

          if (msg.userId === stateRef.current.activeUserId) {
            markAsRead(msg.userId);
          }

          stateRef.current.conversations[msg.userId].latestPage = msg.page;
          if (msg.userId === stateRef.current.activeUserId) {
            setState({
              currentSession: stateRef.current.conversations[msg.userId],
            });
          }

          break;
        default:
          break;
      }
    };

    const handleAgentMessage = (msg) => {
      switch (msg.messageType) {
        case "IncommingCall":
          if (message.call_type === "invites") {
            setState({ invite: msg });
          } else {
            fetchConversation(msg.userId, msg.session)
              .then(() => {
                if (!stateRef.current.callActive) {
                  if (window.ReactNativeWebView) {
                    window.ReactNativeWebView.postMessage("incomingCall");
                  }
                  props.history.push(
                    `/${stateRef.current.client}/support/${msg.userId}/${msg.session}`
                  );
                  // console.log('IncommingCall:fetchConversation => stateRef.current.callActive', stateRef.current.callActive)
                  notify(msg.userId);
                }
              })
              .catch(() => {
                console.log("Failed to fetch sessions", msg);
                stopNotify();
              });
          }
          break;
        case "cancelInvite":
        case "callDeclined":
        case "RouteTimedOut":
          setAnswerLoading(false);
          stopNotify();
          if (message.call_type === "invites") {
            setState({ invite: null });
          } else {
            setState({
              conversations: _.omit({ ...stateRef.current.conversations }, [
                msg.userId,
              ]),
            });
          }

          if (msg.messageType !== "cancelInvite")
            props.history.push(`/${stateRef.current.client}/support/`);
          break;
        case "takeCall":
        case "acceptInvite":
          stopNotify();
          if (user.sub === msg.agentId) {
            if (msg.success) {
              fetchConversation(msg.userId, msg.session)
                .then(() => {
                  setState({ callActive: true });

                  props.history.push(
                    `/${stateRef.current.client}/support/${msg.userId}/${msg.session}`
                  );
                })
                .finally(() => {
                  setState({ invite: null });
                });
            } else {
              //TODO: notify couldnt take call
              props.history.push(`/${stateRef.current.client}/support/`);
            }
          }

          break;
        case "callEnded":
          stopNotify();

          fetchConversation(msg.userId, msg.session, true).then(() => {
            if (msg.userId === stateRef.current.activeUserId) {
              setState({ callActive: false });
              markAsRead(msg.userId);
              if (navWindow) {
                navWindow.close();
                setNavWindow(null);
              }
            }
          });
          break;
        case "unavailable":
          // console.log("unavailable");
          break;
        default:
          break;
      }
    };
    if (message.source === "support") {
      handleAgentMessage(message);
    } else {
      handleCustomerMessage(message);
    }
  };

  const initSocket = async () => {
    console.log("connecting socket");
    if (stateRef.current.socket) {
      stateRef.current.socket.onclose = null;
      stateRef.current.socket.close();
    }
    const token = await getTokenSilently();
    try {
      let connectionSocket = new WebSocket(
        `${config["liveSupport"][env]}/ws/${stateRef.current.client}/RR?token=${token}`
      );
      connectionSocket.onmessage = onMessage;
      connectionSocket.onerror = (e) => {
        logToMongo({
          process: "Socket Login Error",
          description:
            "Socket Error " + JSON.stringify(e, Object.getOwnPropertyNames(e)),
        });

        if (socketErrorsCount % 20 === 0) {
          triggerNotification(
            "Connection is lost, please reload the page and make sure you have the Internet access."
          );
        }
        socketErrorsCount++;
        console.error("Socket eror:\n" + e);
        setTimeout(initSocket, 500);
      };
      connectionSocket.onclose = (e) => setTimeout(initSocket, 500);
      connectionSocket.onopen = (e) => {
        fetchConversations();
      };
      setState({ socket: connectionSocket });
    } catch (error) {
      logToMongo({
        process: "Socket Login Error",
        description:
          "Socket Error " +
          JSON.stringify(error, Object.getOwnPropertyNames(error)),
      });
      window.location.reload();
    }
  };

  const availableAgent = async () => {
    let payload = {
      messageType: "available",
      agentName: user.metadata.fullName,
    };

    sendMessage(JSON.stringify(payload));
    let data = await checkIfAgentAvailable();
    if (data === 1) {
      setState({ agentIsAvailable: true, reason: "" });
    }
  };

  const unavailableAgent = async (reason) => {
    let payload = {
      messageType: "unavailable",
      agentName: user.metadata.fullName,
      reason: reason,
    };
    sendMessage(JSON.stringify(payload));
    let data = await checkIfAgentAvailable();
    if (data === 0) {
      setState({ agentIsAvailable: false, reason: reason });
    }
  };

  const sendMessage = (msg) => {
    if (
      stateRef.current.socket &&
      stateRef.current.socket.readyState == WebSocket.OPEN
    ) {
      stateRef.current.socket.send(msg);
    } else {
      setTimeout(() => sendMessage(msg), 100);
    }
  };

  const openNavigationWindow = () => {
    // let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,
    // width=400,height=600,left=${window.screen.width * 0.25},top=200`;
    if (navWindow) navWindow.close();
    let page = stateRef.current.currentSession.latestPage;
    if (stateRef.current.client === "sleepenvie") {
      let url = new URL(page);
      url.searchParams.set("geolizr", "off");
      page = url.href;
    }

    let newWindow = window.open(page + "#voiceableConsole", "_blank");

    setNavWindow(newWindow);
  };

  const checkIfAgentAvailable = async () => {
    try {
      const token = await getTokenSilently();
      return (
        await supportAPI.get(
          `/chat/${stateRef.current.client}/checkIfAgentAvailable`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
      ).data;
    } catch (e) {
      console.log(e);
      return 2;
    }
  };

  const logToMongo = async (params) => {
    let body = {
      ...params,
    };
    const token = await getTokenSilently();
    const res = await supportAPI.post(
      `/chat/${stateRef.current.client}/log/`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const onClickLogout = () => {
    logToMongo({
      process: "Logged out",
      description: "User logged out",
    });
    setState({ loggedInOut: false });
    logout();
  };

  return (
    <ClientProvider value={{ client: stateRef.current.client }}>
      <ModalWelcomePage />
      <section className="mobile">
        <div className="logo-wrapper">
          <div className="logo" />
          {clientName && <span className="client-name">{clientName}</span>}
        </div>
        <div className="calls">
          {stateRef.current.activeUserId !== null &&
            stateRef.current.conversations[stateRef.current.activeUserId] &&
            !stateRef.current.conversations[stateRef.current.activeUserId]
              .finishedAt && (
              <div className="navigation">
                {stateRef.current.conversations[
                  stateRef.current.activeUserId
                ] && (
                  <>
                    <h3 className="navigation_heading">
                      {`Customer is at: ` +
                        stateRef.current.conversations[
                          stateRef.current.activeUserId
                        ].latestPageTitle}
                    </h3>
                    <SupportActionButton
                      icon="compass"
                      onClick={openNavigationWindow}
                      content="Open customer page and navigation panel"
                      id="current_page_nav"
                    />
                  </>
                )}
              </div>
            )}
          {stateRef.current.activeUserId !== null &&
            stateRef.current.conversations[stateRef.current.activeUserId] &&
            !stateRef.current.conversations[stateRef.current.activeUserId]
              .finishedAt && (
              <div className="chat">
                {answerLoading && <Spinner />}
                {!stateRef.current.conversations[stateRef.current.activeUserId]
                  .finishedAt &&
                  stateRef.current.callActive && (
                    <div className="call-actions">
                      <OpenTokenCall
                        headsetInputId={headsetInputId}
                        ringtoneOutputId={ringtoneOutputId}
                        headsetOutputId={headsetOutputId}
                        onInvite={handleAgentInviteGroupSelected}
                        onInviteCancel={handleAgentInviteCancel}
                        roomName={stateRef.current.activeSession}
                        videoEnabled={stateRef.current.callType === "video"}
                        onChatEnd={handleCallEnd}
                        setAnswerLoading={setAnswerLoading}
                        key={`support-controller-${stateRef.current.activeUserId}-${stateRef.current.activeSession}`}
                        supportRecording={clientConfigFeatures.recording}
                        room={room}
                        setRoom={setRoom}
                      />
                    </div>
                  )}
                {<ShareScreen room={room} desktop={false}/>}
                {!stateRef.current.conversations[stateRef.current.activeUserId]
                  .finishedAt &&
                  !stateRef.current.callActive &&
                  !answerLoading && (
                    <IncomingCallScreen
                      onCallAnswer={onCallAnswer}
                      onCallDecline={onCallDecline}
                      supportAutoAnswer={clientConfigFeatures.autoAnswer}
                    />
                  )}
              </div>
        )}
          {stateRef.current.activeUserId !== null &&
            stateRef.current.conversations[stateRef.current.activeUserId] &&
            stateRef.current.conversations[stateRef.current.activeUserId]
              .finishedAt && (
              <CallEndedForm
                setIntent={(intent) =>
                  updateIntent(stateRef.current.activeUserId, intent)
                }
                params={
                  stateRef.current.activeUserId && {
                    ...stateRef.current.conversations[
                      stateRef.current.activeUserId
                    ],
                    clientId: stateRef.current.client,
                  }
                }
              />
            )}
        </div>
        <div className="controls">
          <Button onClick={onClickLogout} icon inverted color="red">
            <Icon name="sign-out" />
          </Button>
          <ScheduleSession />
          {stateRef.current.agentIsAvailable ? (
            <span className="available">Available</span>
          ) : (
            <span className="unavailable">Unavailable</span>
          )}
          <Checkbox
            toggle
            onChange={async (e) => {
              const checked = e.target.previousSibling.checked;
              setState({ agentIsAvailable: checked });
              checked ? await availableAgent() : setOpenModal(true);
            }}
            checked={!stateRef.current.agentIsAvailable}
          />
          <ModalAvailability
            openModal={openModal}
            setOpenModal={setOpenModal}
            unavailableAgent={unavailableAgent}
          />
        </div>
      </section>
    </ClientProvider>
  );
};

export default MobileLiveSupport;
