import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Icon, Modal } from "semantic-ui-react";

import LanguageContext from "../../../context/LanguageContext";
import { SimpleCheckbox } from "../../../UI-components";
import InputIndicator from "../../../UI-components/InputIndicator/InputIndicator";
import LanguageSwitcher from "../../../UI-components/LanguageSwitcher/LanguageSwitcher";
import SimpleDropdown from "../../../UI-components/SimpleDropdown/SimpleDropdown";
import SpeakerTest from "../../../UI-components/SpeakerTest/SpeakerTest";

import "./SupportAudioOutputs.css";
import ScheduleSession from "../../../components/ScheduleSession/ScheduleSession";
import useVideoSettings from "../../../hooks/useVideoSettings";

const SupportAudioOutputs = ({
  setRingtoneOutputId,
  setHeadsetOutputId,
  setHeadsetInputId,
  setShowMissed,
  ringtoneOutputId,
  headsetOutputId,
  headsetInputId,
  showMissed,
  supportAudio,
}) => {
  const [open, setOpen] = useState(false);
  const [outputDevices, setOutputDevices] = useState([]);
  const [inputDevices, setInputDevices] = useState([]);
  const { render } = useVideoSettings();
  const { language, languageDirection } = useContext(LanguageContext);

  useEffect(() => {
    handleDevices();
  }, []);

  async function handleDevices() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const validDevices = devices.filter(({ deviceId }) => deviceId);
    setOutputDevices(validDevices.filter(({ kind }) => kind === "audiooutput"));
    setInputDevices(validDevices.filter(({ kind }) => kind === "audioinput"));
  }

  const getDeviceById = (devices, deviceId) => {
    const device = devices.filter((item) => item.deviceId === deviceId);

    if (device.length) {
      return device[0];
    } else if (devices.length) {
      let defaultDevice = devices.filter(
        (item) => item.deviceId === "default"
      )[0];
      if (!defaultDevice) {
        return devices[0];
      }
      return defaultDevice;
    } else {
      return { label: "Default device" };
    }
  };

  const {
    defaultRingtoneOutputDevice,
    defaultOutputDevice,
    defaultInputDevice,
  } = useMemo(() => {
    const defaultRingtoneOutputDevice = getDeviceById(
      outputDevices,
      ringtoneOutputId
    );

    const defaultOutputDevice = getDeviceById(outputDevices, headsetOutputId);

    const defaultInputDevice = getDeviceById(inputDevices, headsetInputId);

    return {
      defaultRingtoneOutputDevice,
      defaultOutputDevice,
      defaultInputDevice,
    };
  }, [
    headsetInputId,
    headsetOutputId,
    inputDevices,
    outputDevices,
    ringtoneOutputId,
  ]);

  return (
    <div id="console-settings">
      <Modal
        closeIcon
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => {
          handleDevices();
          setOpen(true);
        }}
        open={open}
        trigger={
          <Icon
            size="big"
            id="console-settings-btn"
            name="setting"
            style={{ direction: languageDirection }}
            title={language.console.sidebar.settings.header}
          />
        }
      >
        <Modal.Header>{language.console.sidebar.settings.header}</Modal.Header>
        <Modal.Content>
          <LanguageSwitcher />
          {supportAudio && (
            <div className="support-audio">
              <div className="support-audio__item">
                <SimpleDropdown
                  items={outputDevices}
                  label={language.console.sidebar.settings.ringingOutput}
                  defaultValue={defaultRingtoneOutputDevice.label}
                  dataAttributes={["deviceId"]}
                  onClick={(e) => {
                    setRingtoneOutputId(e.target.dataset.deviceid);
                  }}
                />
                <SpeakerTest
                  sinkId={ringtoneOutputId || defaultRingtoneOutputDevice}
                />
              </div>
              <div className="support-audio__item">
                <SimpleDropdown
                  items={outputDevices}
                  label={language.console.sidebar.settings.headsetOutput}
                  defaultValue={defaultOutputDevice.label}
                  dataAttributes={["deviceId"]}
                  onClick={(e) => {
                    setHeadsetOutputId(e.target.dataset.deviceid);
                  }}
                />
                <SpeakerTest sinkId={headsetOutputId || defaultOutputDevice} />
              </div>
              <SimpleDropdown
                items={inputDevices}
                label={language.console.sidebar.settings.headsetInput}
                defaultValue={defaultInputDevice.label}
                dataAttributes={["deviceId"]}
                onClick={(e) => {
                  setHeadsetInputId(e.target.dataset.deviceid);
                }}
              />
              <InputIndicator />
              <div className="support-audio-checkbox-item">
                <SimpleCheckbox
                  label={"Show missed calls"}
                  onChange={() => setShowMissed((show) => !show)}
                  checked={showMissed}
                />
              </div>
            </div>
          )}
          {render()}
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpen(false)}>
            {language.common.close}
          </Button>
        </Modal.Actions>
      </Modal>
      <ScheduleSession />
    </div>
  );
};

export default SupportAudioOutputs;
