import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Icon } from "semantic-ui-react";
import { safeJsonParse } from "../utils/helpers";
import ClientContext from "../context/ClientContext";
import { SimpleCheckbox } from "../UI-components";
import background from "../assets/background.jpg";
import office from "../assets/office.jpg";
import Video from "twilio-video";
import VideoProcessors from "@twilio/video-processors";

const imageBackgroundsInitial = [
  {
    key: 1,
    src: background,
  },
  {
    key: 2,
    src: office,
  },
];

const UseVideoSettings = () => {
  const [blurEnabled, setBlurEnabled] = useState(
    safeJsonParse(localStorage.getItem("blurEnabled"), false)
  );
  const [backgroundImageEnabled, setBackgroundImageEnabled] = useState(
    safeJsonParse(localStorage.getItem("backgroundImageEnabled"), false)
  );
  const [selectedImageKey, setSelectedImageKey] = useState(
    safeJsonParse(localStorage.getItem("selectedImageKey"), null)
  );
  const [imageBackgrounds, setImageBackgrounds] = useState(
    safeJsonParse(localStorage.getItem("imageBackgrounds"), false) ||
      imageBackgroundsInitial
  );

  const [blurBackgroundProcessor, setBlurBackgroundProcessor] = useState(null);
  const [backgroundImageProcessor, setBackgroundImageProcessor] =
    useState(null);

  const { localVideoTrack: videoTrack } = useContext(ClientContext);

  const fileChange = async (event) => {
    const file = event.target.files[0];
    if (file.size > 2000000) {
      alert("Max image size: 2MB");
      return;
    }
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => {
        setImageBackgrounds([
          ...imageBackgrounds,
          { key: file.lastModified, src: reader.result },
        ]);
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const addBlurBackground = async (
    maskBlurRadius = 10,
    blurFilterRadius = 5
  ) => {
    if (videoTrack) {
      const bg = new Twilio.VideoProcessors.GaussianBlurBackgroundProcessor({
        assetsPath: "/virtualbackground",
        maskBlurRadius,
        blurFilterRadius,
      });
      await bg.loadModel();
      await videoTrack.addProcessor(bg);
      setBlurBackgroundProcessor(bg);
    }
  };

  const addBackgroundImage = async (imageSrc) => {
    if (videoTrack) {
      let img = new Image();
      img.src = imageSrc;
      img.onload = async () => {
        const bg = new Twilio.VideoProcessors.VirtualBackgroundProcessor({
          assetsPath: "/virtualbackground",
          backgroundImage: img,
          maskBlurRadius: 2,
        });
        await bg.loadModel();
        await videoTrack.addProcessor(bg);
        setBackgroundImageProcessor(bg);
      };
    }
  };

  const removeBlurBackground = async () => {
    if (blurBackgroundProcessor && videoTrack) {
      await videoTrack.removeProcessor(blurBackgroundProcessor);
    }
    setBlurBackgroundProcessor(null);
  };

  const removeBackgroundImage = async () => {
    if (backgroundImageProcessor && videoTrack) {
      await videoTrack.removeProcessor(backgroundImageProcessor);
    }
    setBackgroundImageProcessor(null);
  };

  const renderImageBackgrounds = () => {
    return (
      <>
        <ul className="video-settings__background-image">
          {imageBackgrounds.map((item) => {
            return (
              <li
                className={`${
                  +item.key === +selectedImageKey ? "selected" : ""
                }`}
                key={item.key}
                data-key={item.key}
                onClick={(e) => {
                  setSelectedImageKey(+e.target.dataset.key);
                  if (e.target.dataset.key !== `${selectedImageKey}`) {
                    removeBackgroundImage();
                  }
                }}
              >
                <img data-key={item.key} src={`${item.src}`} alt="" />
                <p
                  className="remove-image"
                  data-key={item.key}
                  onClick={(e) => {
                    e.stopPropagation();
                    const key = +e.target.dataset.key;
                    setImageBackgrounds(
                      imageBackgrounds.filter((image) => image.key !== key)
                    );
                    if (selectedImageKey === key) {
                      setSelectedImageKey(null);
                    }
                  }}
                >
                  Remove
                </p>
              </li>
            );
          })}
        </ul>
        <Form>
          <Form.Field>
            <Button as="label" htmlFor="file" type="button">
              Upload image
            </Button>
            <input type="file" id="file" hidden onChange={fileChange} />
          </Form.Field>
        </Form>
      </>
    );
  };

  useEffect(() => {
    localStorage.setItem("imageBackgrounds", JSON.stringify(imageBackgrounds));
  }, [imageBackgrounds]);

  useEffect(() => {
    localStorage.setItem("selectedImageKey", JSON.stringify(selectedImageKey));
  }, [selectedImageKey]);

  useEffect(() => {
    localStorage.setItem("blurEnabled", JSON.stringify(blurEnabled));
  }, [blurEnabled]);

  useEffect(() => {
    localStorage.setItem(
      "backgroundImageEnabled",
      JSON.stringify(backgroundImageEnabled)
    );
  }, [backgroundImageEnabled]);

  useEffect(() => {
    if (blurEnabled) {
      addBlurBackground();
    } else {
      removeBlurBackground();
    }
  }, [blurEnabled, videoTrack]);

  useEffect(() => {
    if (backgroundImageEnabled) {
      if (selectedImageKey && imageBackgrounds.length) {
        const selectedImage = imageBackgrounds.filter(
          (item) => item.key === selectedImageKey
        )[0];

        if (selectedImage) {
          addBackgroundImage(selectedImage.src);
        } else {
          setSelectedImageKey(null);
        }
      }
    } else {
      removeBackgroundImage();
    }
  }, [selectedImageKey, backgroundImageEnabled, videoTrack]);

  const render = () => (
    <div className="video-settings">
      <SimpleCheckbox
        label="Add Blur"
        onChange={() => {
          setBlurEnabled(!blurEnabled);
          setBackgroundImageEnabled(false);
        }}
        checked={blurEnabled}
      />
      <SimpleCheckbox
        label="Add Image"
        onChange={() => {
          setBackgroundImageEnabled(!backgroundImageEnabled);
          setBlurEnabled(false);
        }}
        checked={backgroundImageEnabled}
      />
      {backgroundImageEnabled && renderImageBackgrounds()}
    </div>
  );

  return { render };
};

export default UseVideoSettings;
