import React, { FC } from "react";
import { Grid, Segment } from "semantic-ui-react";

import { calculatePercentages } from "../../utils/helpers";

import NetPromoterScoreChart from "./NetPromoterScoreChart/NetPromoterScoreChart";
import NetPromoterScoreList from "./NetPromoterScoreChart/NetPromoterScoreList/NetPromoterScoreList";
import { netPromoter, optionChart } from "./types";

import "./FeedbackCharts.css";

interface FeedbackChartsProps {
  netPromoterScore: netPromoter;
}

const FeedbackCharts: FC<FeedbackChartsProps> = ({ netPromoterScore }) => {
  const sum = Object.values(netPromoterScore).reduce(function (a, b) {
    return a + b;
  }, 0);

  const optionChartList: optionChart[] = [
    {
      id: 0,
      color: "#d8374f",
      label: "Detractors",
      value: netPromoterScore.detractors,
      per: calculatePercentages(netPromoterScore.detractors, sum),
    },
    {
      id: 1,
      color: "#ffb377",
      label: "Passives",
      value: netPromoterScore.passives,
      per: calculatePercentages(netPromoterScore.passives, sum),
    },
    {
      id: 2,
      color: "#16d794",
      label: "Promoters",
      value: netPromoterScore.promoters,
      per: calculatePercentages(netPromoterScore.promoters, sum),
    },
  ];

  return (
    <>
      <Grid.Column>
        <Segment>
          <div className="feedback-chart__new-promoter">
            <NetPromoterScoreChart optionChartList={optionChartList} />
            <NetPromoterScoreList optionChartList={optionChartList} />
          </div>
        </Segment>
      </Grid.Column>
    </>
  );
};

export default FeedbackCharts;
