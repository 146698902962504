const legendTooltips = {
  "Total Calls": "Number of all calls",
  "Answered Calls":
    "Number of calls that were answered (agent pressed answer button)",
  "Successful Calls":
    "Number of answered calls in which the duration of the call was more that 10 secs",
  "Unsuccessful Calls":
    "Number of answered calls in which the duration of the call was less that 10 secs",
  "Missed Calls": "All calls that were not answered after 60 secs",
  "Abandoned Calls":
    "Number of calls that were disconnected by the user before they were answered",
  "Declined Calls": "Equals to Missed Calls + Abandoned Calls",
  "Today's Answered Calls":
    "Number of today's calls that were answered (agent pressed answer button)",
  "Today's Declined Calls":
    "Equals to Today's Missed Calls + Today's Abandoned Calls",
  "Average Answered Calls":
    "Average number of calls that were answered (agent pressed answer button)",
  "Average Declined Calls": "Average number of Missed Calls + Abandoned Calls",
};

const graphsLayoutPatterns = {
  smallScreen: {
    row1: { columns: 1, legendPosition: "right" },
    row2: { columns: 1, legendPosition: "right" },
    row3: { columns: 1, legendPosition: "right" },
    row4: { columns: 1, legendPosition: "right" },
  },
  bigScreen: {
    row1: { columns: 1, legendPosition: "right" },
    row2: { columns: 2, legendPosition: "bottom" },
    row3: { columns: 2, legendPosition: "bottom" },
    row4: { columns: 2, legendPosition: "bottom" },
  },
};

const doughnutHeight = 350;
const doughnutWidth = 450;
const graphHeight = 250;
const graphHeightWithCheckbox = graphHeight - 17;
const graphHeightWithDropdown = graphHeight - 38;
export {
  legendTooltips,
  graphsLayoutPatterns,
  graphHeight,
  graphHeightWithCheckbox,
  graphHeightWithDropdown,
  doughnutWidth,
  doughnutHeight,
};
