import { rangeBetweenTwoNumber } from "../utils/helpers";

const { useMemo } = require("react");

export const usePagination = ({
  totalItemCount,
  pageSize,
  currentPage,
  siblingCount = 1,
}) => {
  return useMemo(() => {
    const space = "...";
    const totalPageCount = Math.ceil(totalItemCount / pageSize);
    const totalPageRange = rangeBetweenTwoNumber(1, totalPageCount);
    const firstPage = totalPageRange[0];
    const lastPage = totalPageRange[totalPageRange.length - 1];

    const middleRange = rangeBetweenTwoNumber(
      currentPage - siblingCount,
      currentPage + siblingCount
    ).filter((item) => {
      if (firstPage <= item && lastPage >= item) {
        return item;
      }
    });

    if (firstPage === lastPage) {
      return [];
    }

    // siblingCount + lastPage + firstPage + dots
    if (siblingCount + 3 >= totalPageCount) {
      return [...totalPageRange];
    }

    if (firstPage === middleRange[0]) {
      return [...middleRange, space, lastPage];
    }

    if (lastPage === middleRange[middleRange.length - 1]) {
      return [firstPage, space, ...middleRange];
    }

    return [firstPage, space, ...middleRange, space, lastPage];
  }, [totalItemCount, pageSize, siblingCount, currentPage]);
};
