const getCheckboxHandler =
  (dispatch) =>
  (e, { name, checked }) => {
    dispatch({
      type: "inputField",
      payload: {
        name,
        value: checked,
      },
    });
  };

const getTextFieldHandler =
  (dispatch) =>
  (e, { name, value }) => {
    dispatch({
      type: "inputField",
      payload: {
        name,
        value,
      },
    });
  };

const getArrayFieldHandler = (dispatch) => (name, value) => {
  dispatch({
    type: "inputField",
    payload: {
      name,
      value,
    },
  });
};

const getNumberFieldHandler =
  (dispatch) =>
  (e, { name, value }) => {
    dispatch({
      type: "inputField",
      payload: {
        name,
        value: Number(value),
      },
    });
  };

export {
  getCheckboxHandler,
  getTextFieldHandler,
  getNumberFieldHandler,
  getArrayFieldHandler,
};
