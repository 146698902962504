import React, { useContext, useEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import _ from "lodash";
import { Icon } from "semantic-ui-react";

import {
  getClientConfig,
  getStateConfig,
  updateClientConfig as updateClientConfigService,
} from "./apis/serviceApis.js";
import Sidebar from "./components/Sidebar/Sidebar.js";
import { withTracker } from "./components/withTracker/withTracker";
import { ClientProvider } from "./context/ClientContext";
import { ConversationsProvider } from "./context/ConversationsContext";
import EnvContext from "./context/EnvContext";
import { ProductsListProvider } from "./context/ProductsListContext";
import { UsersProvider } from "./context/UsersContext";
import AccountPerformance from "./views/Dashboard/AccountPerformance/AccountPerformance";
import Agent from "./views/Dashboard/Agent/Agent";
import AgentsMonitor from "./views/Dashboard/AgentMonitor/AgentsMonitor";
import AgentsList from "./views/Dashboard/AgentsList/AgentsList";
import Attendance from "./views/Dashboard/Attandance/Attendance";
import Conversation from "./views/Dashboard/Conversation/Conversation";
import Settings from "./views/Dashboard/Settings/Settings";
import Experiment from "./views/Dashboard/Experiment/Experiment";
import TeamComparison from "./views/Dashboard/TeamComparison/TeamComparison";
import Users from "./views/Dashboard/Users/Users";
import { useAuth0 } from "./react-auth0-spa";

import "./App.css";

const createSideBarItems = (client) => [
  {
    text: "Account Performance",
    permissions: ["read:analytics", "read:account-performance"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/chart`,
    extraPaths: [`/${client}/dashboard`], // icon: <i style={{backgroundImage: `url(${barGraph})` }}  className="icon sidebar-icon"/>
    icon: <Icon name="chart bar" />,
    greyout: false,
    routingPath: `/:client/dashboard`,
    component: AccountPerformance,
  },
  {
    text: "Conversations",
    permissions: ["read:analytics", "read:conversations"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/conversations`, // icon: <i style={{backgroundImage: `url(${barGraph})` }}  className="icon sidebar-icon"/>
    icon: <Icon name="phone" />,
    greyout: false,
  },
  {
    text: "Products",
    permissions: ["read:analytics", "read:products"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/products`, // icon: <i style={{backgroundImage: `url(${barGraph})` }}  className="icon sidebar-icon"/>
    icon: <Icon name="cart" />,
    greyout: false,
  },
  {
    text: "Agents",
    permissions: ["read:analytics", "read:agents"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/agents`, // icon: <i style={{backgroundImage: `url(${barGraph})` }}  className="icon sidebar-icon"/>
    icon: <Icon name="user" />,
    greyout: false,
  },
  {
    text: "Team Comparison",
    permissions: ["read:analytics", "read:team-comparison"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/teamComparison`, // icon: <i style={{backgroundImage: `url(${barGraph})` }}  className="icon sidebar-icon"/>
    icon: <Icon name="users" />,
    greyout: false,
  },
  {
    text: "Attendance",
    permissions: ["read:analytics", "read:attendance"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/attendance`, // icon: <i style={{backgroundImage: `url(${barGraph})` }}  className="icon sidebar-icon"/>
    icon: <Icon name="calendar alternate" />,
    greyout: false,
    routingPath: `/:client/dashboard/attendance`,
    component: Attendance,
  },
  {
    text: "Agents Monitoring",
    permissions: ["read:analytics", "read:agents-monitoring"],
    roles: ["Dashboard-Admin"],
    path: `/${client}/dashboard/agents-monitoring`,
    icon: <Icon name="clock outline" />,
    greyout: false,
    routingPath: `/:client/dashboard/agents-monitoring`,
    component: AgentsMonitor,
  },
  {
    text: "Users",
    permissions: ["write:user-config"],
    roles: ["Users-Manager"],
    path: `/${client}/dashboard/users`,
    icon: <Icon name="user outline" />,
    greyout: false,
    component: Users,
  },
  {
    text: "Settings",
    permissions: [],
    roles: ["Settings-Manager"],
    path: `/${client}/dashboard/settings`,
    icon: <Icon name="setting" />,
    greyout: false,
    routingPath: `/:client/dashboard/settings`,
    component: Settings,
  },
  {
    text: "Experiment",
    permissions: [],
    roles: ["Settings-Manager"],
    path: `/${client}/dashboard/experiment`,
    icon: <Icon name="setting" />,
    greyout: false,
    routingPath: `/:client/dashboard/experiment`,
    component: Experiment,
  },
];
const routesItems = [
  {
    exact: true,
    path: `/:client/dashboard/products`,
    permissions: ["read:analytics", "read:account-products"],
    roles: ["Dashboard-Admin"],
    component: withTracker(ProductsListProvider),
  },
  {
    exact: true,
    path: `/:client/dashboard/agents/:agentId`,
    permissions: ["read:analytics", "read:agents", "read:agent-details"],
    roles: ["Dashboard-Admin"],
    component: withTracker(Agent),
  },
  {
    exact: true,
    path: `/:client/dashboard/agents`,
    permissions: ["read:analytics", "read:agents"],
    roles: ["Dashboard-Admin"],
    component: withTracker(AgentsList),
  },
  {
    exact: true,
    path: `/:client/dashboard/conversations/:conversationId`,
    permissions: [
      "read:analytics",
      "read:conversations",
      "read:conversation-details",
    ],
    roles: ["Dashboard-Admin"],
    component: withTracker(Conversation),
  },
  {
    exact: true,
    path: `/:client/dashboard/conversations`,
    permissions: ["read:analytics", "read:conversations"],
    roles: ["Dashboard-Admin"],
    component: withTracker(ConversationsProvider),
  },
  {
    exact: true,
    path: `/:client/dashboard/attendance`,
    permissions: ["read:analytics", "read:attendance"],
    roles: ["Dashboard-Admin"],
    component: withTracker(Attendance),
  },
  {
    exact: true,
    path: `/:client/dashboard/agents-monitoring`,
    permissions: [
      "read:analytics",
      "read:monitoring",
      "read:agents-monitoring",
    ],
    roles: ["Dashboard-Admin"],
    component: withTracker(AgentsMonitor),
  },
  {
    exact: true,
    path: `/:client/dashboard/teamComparison`,
    permissions: ["read:analytics", "read:team-comparison"],
    roles: ["Dashboard-Admin"],
    component: withTracker(TeamComparison),
  },
  {
    exact: true,
    path: `/:client/dashboard/chart`,
    permissions: ["read:analytics", "read:account-performance"],
    roles: ["Dashboard-Admin"],
    component: withTracker(AccountPerformance),
  },
  {
    exact: true,
    path: `/:client/dashboard/users`,
    permissions: ["write:user-config"],
    roles: ["Dashboard-Admin"],
    component: withTracker(UsersProvider),
  },
  {
    exact: true,
    permissions: ["write:user-config"],
    roles: ["Dashboard-Admin"],
    path: `/:client/dashboard/settings`,
    component: Settings,
  },
  {
    exact: true,
    permissions: ["write:user-config"],
    roles: ["Dashboard-Admin"],
    path: `/:client/dashboard/experiment`,
    component: Experiment,
  },
];
const defaultRoutes = [
  {
    exact: true,
    path: `/:client/dashboard`,
    component: withTracker(AccountPerformance),
  },
  {
    exact: false,
    path: "*",
    component: withTracker(AccountPerformance),
  },
];
const App = (props) => {
  const { loading, isAuthenticated, user, getTokenSilently } = useAuth0();
  const [userPermissions, setUserPermissions] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [clientConfig, setClientConfig] = useState({});
  const [stateConfig, setStateConfig] = useState({});
  const [localTimezone, setLocalTimezone] = useState();
  const [client, setClient] = useState("");

  const env = useContext(EnvContext);

  const filteredItems = useMemo(() => {
    return createSideBarItems(client).filter((item) => {
      const requiredRoles = item.roles;
      if (requiredRoles.length !== 0) {
        return requiredRoles.some((requiredRole) =>
          userRoles.includes(requiredRole)
        );
      } else {
        const neededPermissions = item.permissions;
        return neededPermissions.some((neededPermission) =>
          userPermissions.includes(neededPermission)
        );
      }
    });
  }, [client, userPermissions]);

  const filteredRoutes = useMemo(() => {
    return routesItems.filter((item) => {
      const requiredRoles = item.roles;
      if (requiredRoles.length !== 0) {
        return requiredRoles.some((requiredRole) =>
          userRoles.includes(requiredRole)
        );
      } else {
        const neededPermissions = item.permissions;
        return neededPermissions.some((neededPermission) =>
          userPermissions.includes(neededPermission)
        );
      }
    });
  }, [userPermissions]);

  useEffect(() => {
    setClient(props.match.params.client);
    setLocalTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC");
  }, []);

  useEffect(() => {
    if (client) {
      fetchClientConfig().then((data) => {
        setClientConfig(data);
      });
      fetchStateConfig().then((data) => {
        setStateConfig(data);
      });
    }
  }, [client]);

  const fetchClientConfig = async () => {
    const token = await getTokenSilently();

    return getClientConfig(token, env, client);
  };

  const fetchStateConfig = async () => {
    const token = await getTokenSilently();

    return getStateConfig(token, env, client);
  };

  useEffect(() => {
    if (!loading && isAuthenticated) {
      const permissions = user.authorization.permissions;
      setUserPermissions(permissions);
      setUserRoles(user.authorization.roles);
    }
  }, [loading, isAuthenticated]);

  if (!props.match.params.client) {
    return <Redirect to="/" />;
  }

  const updateClientConfig = async (newConfig) => {
    setClientConfig(_.cloneDeep(newConfig));
    const token = await getTokenSilently();
    await updateClientConfigService(token, env, newConfig, client);
  };

  return (
    <ClientProvider
      value={{
        client,
        stateConfig,
        clientConfig,
        updateClientConfig,
        localTimezone,
      }}
    >
      <Sidebar items={filteredItems} />
      <div id={"app-content"}>
        <Switch>
          {filteredRoutes.map((item, index) => (
            <Route
              key={index}
              exact={item.exact}
              path={item.path}
              component={item.component}
            />
          ))}
          {defaultRoutes.map((item, index) => (
            <Route
              key={index}
              exact={item.exact}
              path={item.path}
              component={item.component}
            />
          ))}
        </Switch>
      </div>
    </ClientProvider>
  );
};

export default App;
