import React, { useEffect, useState } from "react";

import "./SimpleDropdown.css";

const SimpleDropdown = ({
  items,
  label,
  defaultValue,
  onClick,
  placeholder,
  dataAttributes = [],
}) => {
  const [isShow, setIsShow] = useState(false);
  const [selectedValue, setSelectedValue] = useState(defaultValue || "");

  const handleClick = (e) => {
    onClick(e);
    setSelectedValue(e.target.innerHTML);
    setIsShow(false);
  };

  return (
    <div
      className="simple-dropdown"
      onMouseLeave={() => {
        setIsShow(false);
      }}
    >
      <p>{label || ""}</p>
      <button
        placeholder={placeholder}
        className="simple-dropdown__title"
        onClick={() => {
          setIsShow(!isShow);
        }}
      >
        {placeholder ? (
          <span className="simple-dropdown__placeholder">{placeholder}</span>
        ) : (
          selectedValue
        )}
      </button>
      <div
        className="simple-dropdown__list"
        style={{ display: `${isShow ? "block" : "none"}` }}
      >
        {items.map((item, index) => {
          let newAttribute = {};
          dataAttributes.map((attr) => {
            newAttribute = {};
            const key = `data-${attr}`.toLowerCase();
            const value = item[attr];
            if (key && value) {
              newAttribute[key] = value;
            }
          });
          return (
            <a key={`${item}-${index}`} {...newAttribute} onClick={handleClick}>
              {item.label || item || "Empty"}
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default SimpleDropdown;
