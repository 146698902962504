import React, { useContext, useEffect } from "react";

import notificationSound from "../../assets/audio/incomming_call_ringtone.mp3";
import LanguageContext from "../../context/LanguageContext";
import useAudio from "../../hooks/useAudio";

import "./SpeakerTest.css";

const SpeakerTest = ({ sinkId }) => {
  const { language } = useContext(LanguageContext);
  const { play, pause, playing, setSinkId } = useAudio(notificationSound, {
    loop: false,
    autoPlay: false,
    duration: 6,
  });

  useEffect(() => {
    setSinkId(sinkId);
  }, [sinkId]);

  const handleOnClick = () => {
    if (sinkId) {
      if (playing) {
        pause();
      } else {
        play();
      }
    }
  };

  return (
    <div className="speaker-test" onClick={handleOnClick}>
      {playing
        ? language.console.testSpeaker.stop
        : language.console.testSpeaker.title}
    </div>
  );
};

export default SpeakerTest;
