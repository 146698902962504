const supportedLanguages = {
  Afrikaans: [["South Africa", "af-ZA"]],
  Arabic: [
    ["Algeria", "ar-DZ"],
    ["Bahrain", "ar-BH"],
    ["Egypt", "ar-EG"],
    ["Israel", "ar-IL"],
    ["Iraq", "ar-IQ"],
    ["Jordan", "ar-JO"],
    ["Kuwait", "ar-KW"],
    ["Lebanon", "ar-LB"],
    ["Morocco", "ar-MA"],
    ["Oman", "ar-OM"],
    ["Palestinian Territory", "ar-PS"],
    ["Qatar", "ar-QA"],
    ["Saudi Arabia", "ar-SA"],
    ["Tunisia", "ar-TN"],
    ["UAE", "ar-AE"],
  ],
  Basque: [["Spain", "eu-ES"]],
  Bulgarian: [["Bulgaria", "bg-BG"]],
  Catalan: [["Spain", "ca-ES"]],
  "Chinese Mandarin": [
    ["China (Simp.)", "cmn-Hans-CN"],
    ["Hong Kong SAR (Trad.)", "cmn-Hans-HK"],
    ["Taiwan (Trad.)", "cmn-Hant-TW"],
  ],
  "Chinese Cantonese": [["Hong Kong", "yue-Hant-HK"]],
  Croatian: [["Croatia", "hr_HR"]],
  Czech: [["Czech Republic", "cs-CZ"]],
  Danish: [["Denmark", "da-DK"]],
  English: [
    ["Australia", "en-AU"],
    ["Canada", "en-CA"],
    ["India", "en-IN"],
    ["Ireland", "en-IE"],
    ["New Zealand", "en-NZ"],
    ["Philippines", "en-PH"],
    ["South Africa", "en-ZA"],
    ["United Kingdom", "en-GB"],
    ["United States", "en-US"],
  ],
  Farsi: [["Iran", "fa-IR"]],
  French: [["France", "fr-FR"]],
  Filipino: [["Philippines", "fil-PH"]],
  Galician: [["Spain", "gl-ES"]],
  German: [["Germany", "de-DE"]],
  Greek: [["Greece", "el-GR"]],
  Finnish: [["Finland", "fi-FI"]],
  Hebrew: [["Israel", "he-IL"]],
  Hindi: [["India", "hi-IN"]],
  Hungarian: [["Hungary", "hu-HU"]],
  Indonesian: [["Indonesia", "id-ID"]],
  Icelandic: [["Iceland", "is-IS"]],
  Italian: [
    ["Italy", "it-IT"],
    ["Switzerland", "it-CH"],
  ],
  Japanese: [["Japan", "ja-JP"]],
  Korean: [["Korea", "ko-KR"]],
  Lithuanian: [["Lithuania", "lt-LT"]],
  Malaysian: [["Malaysia", "ms-MY"]],
  Dutch: [["Netherlands", "nl-NL"]],
  Norwegian: [["Norway", "nb-NO"]],
  Polish: [["Poland", "pl-PL"]],
  Portuguese: [
    ["Brazil", "pt-BR"],
    ["Portugal", "pt-PT"],
  ],
  Romanian: [["Romania", "ro-RO"]],
  Russian: [["Russia", "ru-RU"]],
  Serbian: [["Serbia", "sr-RS"]],
  Slovak: [["Slovakia", "sk-SK"]],
  Slovenian: [["Slovenia", "sl-SI"]],
  Spanish: [
    ["Argentina", "es-AR"],
    ["Bolivia", "es-BO"],
    ["Chile", "es-CL"],
    ["Colombia", "es-CO"],
    ["Costa Rica", "es-CR"],
    ["Dominican Republic", "es-DO"],
    ["Ecuador", "es-EC"],
    ["El Salvador", "es-SV"],
    ["Guatemala", "es-GT"],
    ["Honduras", "es-HN"],
    ["México", "es-MX"],
    ["Nicaragua", "es-NI"],
    ["Panamá", "es-PA"],
    ["Paraguay", "es-PY"],
    ["Perú", "es-PE"],
    ["Puerto Rico", "es-PR"],
    ["Spain", "es-ES"],
    ["Uruguay", "es-UY"],
    ["United States", "es-US"],
    ["Venezuela", "es-VE"],
  ],
  Swedish: [["Sweden", "sv-SE"]],
  Thai: [["Thailand", "th-TH"]],
  Turkish: [["Turkey", "tr-TR"]],
  Ukrainian: [["Ukraine", "uk-UA"]],
  Vietnamese: [["Viet Nam", "vi-VN"]],
  Zulu: [["South Africa", "zu-ZA"]],
};

const languages = [];
const sampleRates = [8000, 16000, 24000, 32000, 40000, 44100];
const encoding = ['LINEAR16', 'FLAC'];

Object.entries(supportedLanguages).map(([language, countries]) => {
  countries.forEach((country) => {
    const newObj = {label: `${language} (${country[0]})`, key: country[1]};
    languages.push(newObj);
  });
});

const getValueByKey = (key) => {
  let label = "";
  languages.forEach((item) => {
    if (item.key === key) {
      label = item.label;
    }
  });

  return label;
};

export {supportedLanguages, languages, sampleRates, encoding, getValueByKey};
