const getAvailabilityStatsSchema = (availabilityStats, onCallStats) => {
  return [
    {
      label: "Average On Call",
      data: onCallStats,
      fill: false,
      backgroundColor: "rgb(54,162,235)",
      borderColor: "rgb(54,162,235)",
      hoverBackgroundColor: "rgba(52 150 243 0.75)",
      hoverBorderColor: "rgba(52 150 243 0.75)",
    },
    {
      label: "Average Available",
      data: availabilityStats,
      fill: false,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
  ];
};

const getTodaysAvailabilityStatsSchema = (
  availabilityStats,
  onCallStats,
  todayAvailabilityStats,
  todayOnCallStats
) => {
  return [
    {
      label: "Today On Call",
      data: todayOnCallStats,
      fill: false,
      backgroundColor: "rgb(75,192,192)",
      borderColor: "rgb(75,192,192)",
      hoverBackgroundColor: "rgba(76 180 170 0.75)",
      hoverBorderColor: "rgba(76 180 170 0.75)",
    },
    {
      label: "Today Available",
      data: todayAvailabilityStats,
      fill: false,
      backgroundColor: "rgb(255,205,86)",
      borderColor: "rgb(255,205,86)",
      hoverBackgroundColor: "rgba(52 150 223 0.75)",
      hoverBorderColor: "rgba(52 150 223 0.75)",
    },
    {
      label: "Average On Call",
      data: onCallStats,
      fill: false,
      backgroundColor: "rgb(54,162,235)",
      borderColor: "rgb(54,162,235)",
      hoverBackgroundColor: "rgba(52 150 243 0.75)",
      hoverBorderColor: "rgba(52 150 243 0.75)",
    },
    {
      label: "Average Available",
      data: availabilityStats,
      fill: false,
      backgroundColor: "rgb(255,99,132)",
      borderColor: "rgb(255,99,132)",
      hoverBackgroundColor: "rgba(255 116 116 0.75)",
      hoverBorderColor: "rgba(255 116 116 0.75)",
    },
  ];
};

export { getAvailabilityStatsSchema, getTodaysAvailabilityStatsSchema };
