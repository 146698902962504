import React from "react";
import { Icon } from "semantic-ui-react";

const AWAY = "AWAY";
const ON_CALL = "ON_CALL";
const RINGING = "RINGING";
const AVAILABLE = "AVAILABLE";

const AgentStatus = ({ value }) => {
  return (
    <>
      {value === ON_CALL && <Icon name="call" color={"green"} />}
      {value === RINGING && <Icon name="phone volume" color={"green"} />}
      {value === AVAILABLE && <Icon name="check circle" color={"green"} />}
      {(value === AWAY || !value) && <Icon name="times circle" color={"red"} />}
    </>
  );
};

export default AgentStatus;
