import React from "react";

export const sortConversations = (
  conversations,
  filter = "Date",
  setFilteredConversations,
  setOrderedByArrow
) => {
  let sortedConversations = null;
  let firstWord = null;
  const filterOption = filter.split(" ")[0];
  switch (filter) {
    case "returningUser up":
      sortedConversations = [...conversations].sort(function (a, b) {
        if (a[filterOption] < b[filterOption]) {
          return 1;
        }
        if (a[filterOption] > b[filterOption]) {
          return -1;
        }
        return 0;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(`${filterOption} up`);
      break;
    case "returningUser":
      sortedConversations = [...conversations].sort(function (a, b) {
        if (a[filterOption] < b[filterOption]) {
          return -1;
        }
        if (a[filterOption] > b[filterOption]) {
          return 1;
        }
        return 0;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filterOption);
      break;
    case "agentName up":
      sortedConversations = [...conversations].sort(function (a, b) {
        const aName = a.agents.join(", ");
        const bName = b.agents.join(", ");
        if (aName < bName) {
          return 1;
        }
        if (aName > bName) {
          return -1;
        }
        return 0;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(`${filterOption} up`);
      break;
    case "agentName":
      sortedConversations = [...conversations].sort(function (a, b) {
        const aName = a.agents.join(", ");
        const bName = b.agents.join(", ");

        if (aName < bName) {
          return -1;
        }
        if (aName > bName) {
          return 1;
        }
        return 0;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filterOption);
      break;
    case "waitTime up":
      sortedConversations = [...conversations].sort(
        (a, b) => a.waitTime - b.waitTime
      );
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filter);
      break;
    case "waitTime":
      sortedConversations = [...conversations].sort(
        (b, a) => a.waitTime - b.waitTime
      );
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filter);
      break;
    case "totalCalls up":
    case "answeredCalls up":
    case "convertedCalls up":
    case "convertedCallsPercent up":
    case "totalValueAvg up":
    case "convertedValue up":
    case "upSells up":
    case "crossSells up":
    case "navigation up":
    case "proactivity up":
    case "duration up":
    case "checkoutValue up":
    case "perOfAov up":
      firstWord = filter.split(" ")[0];
      sortedConversations = [...conversations].sort(function (a, b) {
        let aModified = parseInt(a[firstWord]);
        aModified = isNaN(aModified) ? 9999999 : aModified;
        let bModified = parseInt(b[firstWord]);
        bModified = isNaN(bModified) ? 9999999 : bModified;
        return bModified - aModified;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filter);
      break;
    case "totalCalls":
    case "answeredCalls":
    case "convertedCalls":
    case "convertedCallsPercent":
    case "totalValueAvg":
    case "convertedValue":
    case "upSells":
    case "crossSells":
    case "navigation":
    case "proactivity":
    case "duration":
    case "checkoutValue":
    case "perOfAov":
      sortedConversations = [...conversations].sort(function (a, b) {
        let aModified = parseInt(a[filter]);
        aModified = isNaN(aModified) ? 9999999 : aModified;
        let bModified = parseInt(b[filter]);
        bModified = isNaN(bModified) ? 9999999 : bModified;
        return aModified - bModified;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filter);
      break;
    case "converted up":
      sortedConversations = [...conversations].sort(function (a, b) {
        if (a[filterOption] < b[filterOption]) {
          return 1;
        }
        if (a[filterOption] > b[filterOption]) {
          return -1;
        }
        return 0;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(`${filterOption} up`);
      break;
    case "converted":
      firstWord = filter.split(" ")[0];
      sortedConversations = [...conversations].sort(function (a, b) {
        if (a[filterOption] < b[filterOption]) {
          return -1;
        }
        if (a[filterOption] > b[filterOption]) {
          return 1;
        }
        return 0;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filterOption);
      break;
    case "Date up":
      sortedConversations = [...conversations].sort((a, b) => {
        const [aDay, aMonth, aYear] = a.date.split("/");
        const [aHours, aMinutes, aSeconds] = a.time.split(":");
        const fullDateA = new Date(
          aYear,
          aMonth,
          aDay,
          aHours,
          aMinutes,
          aSeconds
        );
        const [bDay, bMonth, bYear] = b.date.split("/");
        const [bHours, bMinutes, bSeconds] = b.time.split(":");
        const fullDateB = new Date(
          bYear,
          bMonth,
          bDay,
          bHours,
          bMinutes,
          bSeconds
        );
        return fullDateA < fullDateB ? 1 : fullDateA === fullDateB ? 0 : -1;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow("Date up");
      break;
    case "Date":
    default:
      sortedConversations = [...conversations].sort((a, b) => {
        const [aDay, aMonth, aYear] = a.date.split("/");
        const [aHours, aMinutes, aSeconds] = a.time.split(":");
        const fullDateA = new Date(
          aYear,
          aMonth,
          aDay,
          aHours,
          aMinutes,
          aSeconds
        );
        const [bDay, bMonth, bYear] = b.date.split("/");
        const [bHours, bMinutes, bSeconds] = b.time.split(":");
        const fullDateB = new Date(
          bYear,
          bMonth,
          bDay,
          bHours,
          bMinutes,
          bSeconds
        );
        return fullDateA < fullDateB ? -1 : fullDateA === fullDateB ? 0 : 1;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow("Date");
  }
};

export const sortProducts = (
  conversations,
  filter = "item",
  setFilteredConversations,
  setOrderedByArrow
) => {
  let sortedConversations;
  let firstWord = null;
  switch (filter) {
    case "itemId up":
    case "customers up":
    case "price up":
    case "quantity up":
    case "totalPrice up":
    case "conversions up":
    case "variants up":
    case "similar up":
      firstWord = filter.split(" ")[0];
      sortedConversations = [...conversations].sort((a, b) => {
        let aModified = parseInt(a[firstWord]);
        aModified = isNaN(aModified) ? -1 : aModified;
        let bModified = parseInt(b[firstWord]);
        bModified = isNaN(bModified) ? -1 : bModified;
        return bModified - aModified;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filter);
      break;
    case "itemId":
    case "customers":
    case "price":
    case "quantity":
    case "totalPrice":
    case "conversions":
    case "variants":
    case "similar":
    default:
      sortedConversations = [...conversations].sort((a, b) => {
        let aModified = parseInt(a[filter]);
        aModified = isNaN(aModified) ? 9999999 : aModified;
        let bModified = parseInt(b[filter]);
        bModified = isNaN(bModified) ? 9999999 : bModified;
        return aModified - bModified;
      });
      setFilteredConversations(sortedConversations);
      setOrderedByArrow(filter);
      break;
  }
};
