import React, { useEffect, useState } from "react";
import { Dropdown, Input } from "semantic-ui-react";

import "./SearchComponent.css";

const SearchComponent = (props) => {
  const [searchOption, setSearchOption] = useState(props.options[0].value);
  const [searchInput, setSearchInput] = useState("");

  const onChangeSearchOption = (e, data) => {
    props.searchFilter(searchInput, data.value);
    setSearchOption(data.value);
  };

  const handleChangeSearch = (e) => {
    setSearchInput(e.target.value);
    props.searchFilter(e.target.value, searchOption);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  };

  //option export is the option which change the date of the csv. when option of export change, we must clear our option of search
  useEffect(() => {
    setSearchInput("");
  }, [props.optionExport]);

  return (
    <div className="SearchComponent">
      <Input
        action={
          <Dropdown
            button
            basic
            floating
            defaultValue="page"
            onChange={onChangeSearchOption}
            options={props.options}
            value={searchOption}
          />
        }
        icon="search"
        iconPosition="left"
        placeholder="Search..."
        onChange={handleChangeSearch}
        onKeyDown={(e) => handleKeyDown(e)}
        value={searchInput}
      />
    </div>
  );
};

export default SearchComponent;
