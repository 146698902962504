import React from "react";

import { EnvironmentType } from "../common/types/environment";

type EnvContextType = EnvironmentType;

const EnvContext = React.createContext<EnvContextType>("dev");

export const EnvProvider = EnvContext.Provider;
export const EnvConsumer = EnvContext.Consumer;
export default EnvContext;
