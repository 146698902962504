import React from "react";

import "./OutlineInput.css";

const OutlineInput = ({ label, item, onChange }) => {
  const handleOnChange = (e) => {
    onChange(e);
  };

  return (
    <div className="outline-input">
      <lable className="outline-input__label">{label}</lable>
      <input
        type="text"
        className="outline-input__input"
        value={item}
        onChange={handleOnChange}
      />
    </div>
  );
};

export default OutlineInput;
