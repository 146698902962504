import React, { useContext, useEffect, useState } from "react";

import person from "../../assets/img/Humaaans Space.svg";
import phoneTube from "../../assets/img/phone tube.svg";
import camera from "../../assets/img/video icon.svg";
import LanguageContext from "../../context/LanguageContext";

import "./IncomingCallScreen.css";
import ClientContext from "../../context/ClientContext";

const IncomingCallScreen = ({
  onCallAnswer,
  onCallDecline,
  supportAutoAnswer
}) => {
  const [isAutoAnswerReady, setIsAutoAnswerReady] = useState(true);
  const [countDownTimer, setCountDownTimer] = useState(5);
  const { language, languageDirection } = useContext(LanguageContext);
  const { clientConfig, client } = useContext(ClientContext);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countDownTimer > 0) {
        setCountDownTimer(() => countDownTimer - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownTimer]);

  useEffect(() => {
    if (supportAutoAnswer) {
      const timer = setTimeout(() => {
        handleOnCallAnswer();
        setIsAutoAnswerReady(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, []);

  const handleOnCallAnswer = (type) => {
    if (isAutoAnswerReady) {
      onCallAnswer(type).then(() => {
        setIsAutoAnswerReady(false);
      });
    }
  };

  const handleOnCallDecline = () => {
    onCallDecline().then(() => {
      setIsAutoAnswerReady(false);
    });
  };

  const renderCallWithVideoButton = () => {
    if (clientConfig?.features?.supportVideo || client === "ikeauae") {
      return (
        <div
          className="answer-call-btn"
          onClick={() => handleOnCallAnswer("video")}
          style={{ direction: languageDirection }}
        >
          <img src={camera} alt="camera" />
          {language.console.incommingCallScreen.answerCallWithVideo}
        </div>
      );
    }

    return [];
  };

  window.onmessage = (e) => {
    e.data === "callDeclined" && handleOnCallDecline();
    e.data === "callAnswered" && handleOnCallAnswer();
  };

  return (
    <div id="incomming-call-container">
      <img src={person} alt="person" />
      <span id="incoming-call-header" style={{ direction: languageDirection }}>
        {language.console.incommingCallScreen.header}
      </span>
      {supportAutoAnswer ? (
        <h3 style={{ direction: languageDirection }}>
          {language.console.incommingCallScreen.autoAnswerTitle} 0
          {countDownTimer}
        </h3>
      ) : (
        ""
      )}
      <div
        className="answer-call-btn"
        onClick={() => handleOnCallAnswer("voice")}
        style={{ direction: languageDirection }}
      >
        <img src={phoneTube} alt="phone" />
        {language.console.incommingCallScreen.answerCall}
      </div>
      {renderCallWithVideoButton()}
      <div
        id="incoming-call-decline-btn"
        onClick={handleOnCallDecline}
        style={{ direction: languageDirection }}
      >
        {language.console.incommingCallScreen.unavailable}
      </div>
    </div>
  );
};

export default IncomingCallScreen;
