import React, { useContext, useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";
import {
  Button,
  Header,
  Input,
  Message,
  Modal,
  Table,
} from "semantic-ui-react";

import config from "../../config.json";
import ClientContext from "../../context/ClientContext";
import EnvContext from "../../context/EnvContext";
import LanguageContext from "../../context/LanguageContext";
import { useAuth0 } from "../../react-auth0-spa";

import "./ScheduleSession.css";

const createToken = (length) => {
  const hashTable = [
    "a",
    "b",
    "c",
    "d",
    "e",
    "f",
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
  ];
  let uuid = [];
  for (let i = 0; i < length; i++) {
    uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)];
  }

  return uuid.join("");
};

const ScheduleSession = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [error, setError] = useState("");
  const [links, setLinks] = useState(null);
  const [scheduledSessions, setScheduledSessions] = useState([]);
  const env = useContext(EnvContext);
  const { user, getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);
  const { language, languageDirection } = useContext(LanguageContext);

  const apiService = axios.create({baseURL: config["api-service"][env]});
  const fetchScheduledSessions = async () => {
    try {
      const token = await getTokenSilently();
      const data = (
        await apiService.get(`/sessions/${client}/scheduled`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
      ).data;
      setScheduledSessions(data);
    } catch (e) {
      setError(e);
    }
  };

  const saveScheduledSession = async () => {
    try {
      const token = await getTokenSilently();
      await apiService.post(`/sessions/${client}/scheduled`, links, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchScheduledSessions();
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    if (links) {
      saveScheduledSession();
    }
  }, [links]);

  useEffect(() => {
    if (user) {
      fetchScheduledSessions();
    }
  }, [user]);
  const generateLinks = () => {
    if (url === "") {
      setError("Please enter a valid URL");
      return;
    }

    setError("");
    const meetToken = createToken(16);
    let parsedUrl = url;
    if (!url.startsWith("http")) {
      parsedUrl = "https://" + url;
    }

    try {
      parsedUrl = new URL(parsedUrl);
      parsedUrl.searchParams.append("voiceableMeetToken", meetToken);
      setLinks({
        agentLink: `https://${window.location.hostname}/${client}/support/waitroom/${meetToken}`,
        clientLink: parsedUrl.toString(),
      });
    } catch (e) {
      setError("Invalid URL");
    }
  };

  return (
    <Modal
      size="large"
      id="schedule-session-modal"
      dimmer={"inverted"}
      open={modalOpen}
      closeIcon
      onClose={() => setModalOpen(false)}
      trigger={
        <Button
          style={{ direction: languageDirection }}
          color="teal"
          onClick={() => setModalOpen(true)}
        >
          {language.console.sidebar.scheduling.button}
        </Button>
      }
    >
      <Modal.Header style={{ direction: languageDirection }}>
        {language.console.sidebar.scheduling.header}
      </Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <Header style={{ direction: languageDirection }}>
            {language.console.sidebar.scheduling.enterURL}
          </Header>
          <Input
            style={{ width: "65%", marginRight: "15px" }}
            label="https://"
            onChange={(e) => setUrl(e.target.value)}
            value={url}
          />
          <Button
            style={{ marginBottom: "25px", direction: languageDirection }}
            onClick={() => generateLinks()}
            color="teal"
          >
            {language.console.sidebar.scheduling.generateLinksButton}
          </Button>
          {error && (
            <Message negative onDismiss={() => setError(null)}>
              <Message.Header>{error}</Message.Header>
            </Message>
          )}

          {links && (
            <div>
              <Header size="small" style={{ margin: 0 }}>
                Agent Link
              </Header>
              <a target="_blank" href={links.agentLink} rel="noreferrer">
                {links.agentLink}
              </a>
              <CopyToClipboard text={links.agentLink}>
                <Button style={{ marginLeft: "15px" }} icon="copy" />
              </CopyToClipboard>

              <Header size="small" style={{ margin: 0 }}>
                User Link
              </Header>
              <a target="_blank" href={links.clientLink} rel="noreferrer">
                {links.clientLink}
              </a>
              <CopyToClipboard text={links.clientLink}>
                <Button style={{ marginLeft: "15px" }} icon="copy" />
              </CopyToClipboard>
            </div>
          )}
        </Modal.Description>
        <Modal.Description>
          <Table fixed id="scheduledSessionsTable">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{ direction: languageDirection }}>
                  {language.console.sidebar.scheduling.agentLink}
                </Table.HeaderCell>
                <Table.HeaderCell style={{ direction: languageDirection }}>
                  {language.console.sidebar.scheduling.clientLink}
                </Table.HeaderCell>
                <Table.HeaderCell style={{ direction: languageDirection }}>
                  {language.console.sidebar.scheduling.createdAt}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {scheduledSessions.map((session) => (
                <Table.Row key={session}>
                  <Table.Cell>
                    <a href={session.agentLink}>{session.agentLink}</a>
                  </Table.Cell>
                  <Table.Cell>
                    <a href={session.clientLink}>{session.clientLink}</a>
                  </Table.Cell>
                  <Table.Cell>
                    {new Date(session.ts + "Z").toLocaleString()}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

export default ScheduleSession;
