import React, { Component } from "react";
import { Button, Container, Modal } from "semantic-ui-react";
class ModalExampleCloseConfig extends Component {
  state = { open: this.props.open || false };

  close = () => this.setState({ open: false });

  componentDidUpdate(prevProps) {
    if (prevProps.open !== this.props.open) {
      this.setState({ open: this.props.open });
    }
  }
  onYesClick = (e) => {
    if (this.props.onYesClick) this.props.onYesClick();
    this.close();
  };

  onNoClick = (e) => {
    if (this.props.onNoClick) this.props.onNoClick();
    this.close();
  };
  render() {
    const { open } = this.state;

    return (
      <Container>
        <Modal
          style={{
            height: "auto",
            marginLeft: "auto",
            marginRight: "auto",
            position: "initial",
          }}
          open={open}
          onClose={this.onNoClick}
          centered
        >
          <Modal.Header>{this.props.header}</Modal.Header>
          <Modal.Content>
            <p>{this.props.content}</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.onNoClick} negative>
              No
            </Button>
            <Button
              onClick={this.onYesClick}
              positive
              labelPosition="right"
              icon="checkmark"
              content="Yes"
            />
          </Modal.Actions>
        </Modal>
      </Container>
    );
  }
}

export default ModalExampleCloseConfig;
