import React, { FC, useState } from "react";
import "./Tags.css";
import Input from "../Input/Input";
import { Icon, Label } from "semantic-ui-react";
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic";

interface TagsProps {
  label: string;
  addItemProps: {
    placeholder: string;
    onEnter: (items: object) => void;
  };
  removeItemProps: {
    icon: SemanticICONS;
    onClick: (items: object) => void;
  };
}

const Tags: FC<TagsProps> = ({ label, addItemProps, removeItemProps }) => {
  const [items, setItems] = useState([]);

  const handleDeleteItem = (e) => {
    const itemToRemove = e.target.dataset.item;
    const newItems = items.filter((item) => item !== itemToRemove);
    setItems(newItems);
    removeItemProps.onClick(newItems);
  };

  return (
    <div className="tags">
      <Label.Group>
        <p className="tags__label">{label}</p>
        {items.map((item, index) => {
          return (
            <Label as="a" key={`${item}-${index}`} onClick={handleDeleteItem}>
              {item}
              <Icon name="close" data-item={item} />
            </Label>
          );
        })}
        <Input
          placeholder={addItemProps.placeholder}
          onPressEnter={(newItem) => {
            if (!items.includes(newItem) && newItem) {
              setItems([...items, newItem]);
              addItemProps.onEnter([...items, newItem]);
            }
          }}
          bottomLine={false}
        />
      </Label.Group>
      <span className="tags-line" />
    </div>
  );
};
export default Tags;
