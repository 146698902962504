import React, { useContext } from "react";

import { addRoles, deleteRoles } from "../../../../../apis/serviceApis";
import EnvContext from "../../../../../context/EnvContext";
import UsersContext from "../../../../../context/UsersContext";
import { useAuth0 } from "../../../../../react-auth0-spa";
import ToggleList from "../../ToggleList/ToggleList";

const UpdateRoles = () => {
  const { getTokenSilently } = useAuth0();
  const { roles, user, userRoles, setUserRoles } = useContext(UsersContext);
  const env = useContext(EnvContext);

  const handleAddRole = async (role) => {
    try {
      const token = await getTokenSilently();

      await addRoles(token, env, user.id, [role.id]);
    } catch (e) {
      let reason = e?.response?.data?.reason;
      if (!reason) {
        reason = "An error occurred when assigning roles to user.";
      }
      console.error(reason);
    }
  };

  const handleRemoveRole = async (role) => {
    try {
      const token = await getTokenSilently();

      await deleteRoles(token, env, user.id, [role.id]);
    } catch (e) {
      let reason = e?.response?.data?.reason;
      if (!reason) {
        reason = "An error occurred when removing roles from user.";
      }
      console.error(reason);
    }
  };

  const handleToggleRole = async (e) => {
    let id = e.target.dataset.id;

    const isUserIncludeRole = userRoles.some(({ id: roleId }) => roleId === id);
    const selectedRole = roles.filter((item) => item.id === id)[0];

    if (isUserIncludeRole) {
      setUserRoles(userRoles.filter(({ id: roleId }) => roleId !== id));
      await handleRemoveRole(selectedRole);
    } else {
      setUserRoles([...userRoles, selectedRole]);
      await handleAddRole(selectedRole);
    }
  };

  return (
    <ToggleList
      selectedItems={userRoles}
      items={roles}
      handleToggle={handleToggleRole}
    />
  );
};

export default UpdateRoles;
