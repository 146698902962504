import _ from "lodash";

import { configNameToDisplayName } from "../views/Dashboard/Settings/constants";

const rangeBetweenTwoNumber = (start, end) =>
  Array.from({ length: end - start + 1 }, (_, i) => i + start);

const safeJsonParse = (data, fallback = {}) => {
  try {
    return JSON.parse(data);
  } catch (ex) {
    return fallback;
  }
};

function getPropertyValueByPath(obj, path, fallback = {}) {
  const keys = path.split(".");
  let value = _.cloneDeep(obj);
  try {
    for (const key of keys) {
      if (key.startsWith("[") && key.endsWith("]")) {
        const index = parseInt(key.substring(1, key.length - 1), 10);
        if (Array.isArray(value)) {
          value = value[index];
        } else {
          throw new Error(
            `Cannot access index ${index} of non-array value ${value}`
          );
        }
      } else {
        value = value[key];
      }
    }
  } catch (err) {
    value = fallback;
  }

  return !_.isUndefined(value) ? value : fallback;
}

const getPreparedPath = (stringPath) => {
  return stringPath.split(".").map((name) => {
    const isArrayIndex = name[0] === "[" && name[name.length - 1] === "]"; // example [10]
    if (isArrayIndex) {
      try {
        const arrayIndex = Number(name.slice(1, -1));
        if (Number.isNaN(arrayIndex)) {
          throw new Error();
        }
        return arrayIndex;
      } catch (e) {
        /* empty */
      }
      return name;
    }
    return name;
  });
};

const modifyNestedObject = (nestedObject, path, value) => {
  const modifiedObj = _.cloneDeep(nestedObject);
  try {
    const splittedPath = getPreparedPath(path);
    if (splittedPath.length === 1) {
      modifiedObj[splittedPath[0]] = value;
      return modifiedObj;
    }

    let lastNestedObj = modifiedObj;
    let num = 0;
    while (num < splittedPath.length - 1) {
      const key = splittedPath[num];
      if (_.isUndefined(lastNestedObj[key])) {
        const nextKey = splittedPath[num + 1];
        lastNestedObj[key] = _.isNumber(nextKey) ? [] : {};
      }
      lastNestedObj = lastNestedObj[key];
      num++;
    }
    lastNestedObj[splittedPath[num]] = value;
    return modifiedObj;
  } catch (e) {
    return modifiedObj;
  }
};

const mergeConfigs = (mandatoryProperties, config) => {
  const configClone = _.cloneDeep(config);

  return _.mergeWith(
    configClone,
    mandatoryProperties,
    (targetValue, sourceValue, key) => {
      if (_.isObject(targetValue) && _.isObject(sourceValue)) {
        return mergeConfigs(sourceValue, targetValue);
      }
      return _.isUndefined(targetValue) ? sourceValue : targetValue;
    }
  );
};

const normalizeDisplayName = (name) => {
  if (configNameToDisplayName[name]) {
    return configNameToDisplayName[name];
  }
  return _.startCase(_.camelCase(name));
};

const calculatePercentages = (value, sum, round = false) => {
  let res = (value / sum) * 100;
  if (round) {
    res = Math.round(res);
  }
  return res || 0;
};

export {
  rangeBetweenTwoNumber,
  safeJsonParse,
  modifyNestedObject,
  mergeConfigs,
  normalizeDisplayName,
  getPropertyValueByPath,
  calculatePercentages,
};
