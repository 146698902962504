import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Button, Loader } from "semantic-ui-react";

import DateCalendar from "./DateCalendar/DateCalendar";

import "./FromToDate.css";

const FromToDate = ({ onClickExport, fromDateDefault, toDateDefault }) => {
  const today = moment().format("yyyy-MM-DD");

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    if (fromDateDefault) {
      const fromDatetimeDefaultISO = new Date(
        fromDateDefault.getTime() - fromDateDefault.getTimezoneOffset() * 60000
      ).toISOString();
      setFromDate(fromDatetimeDefaultISO.split("T")[0]);
    }

    if (toDateDefault) {
      const toDatetimeDefaultISO = new Date(
        toDateDefault.getTime() - toDateDefault.getTimezoneOffset() * 60000
      ).toISOString();
      setToDate(toDatetimeDefaultISO.split("T")[0]);
    }
  }, [fromDateDefault, toDateDefault]);

  const onClickButton = () => {
    if (fromDate && toDate) setLoadingData(true);
    onClickExport(fromDate, toDate).finally(() => {
      setLoadingData(false);
    });
  };

  return (
    <div className="FromToDate">
      <div className="from-to-date__calendaer">
        <DateCalendar
          maxDate={today}
          onChange={(e) => setFromDate(e.target.value)}
          value={fromDate}
          label="From:"
        />
        <DateCalendar
          maxDate={today}
          onChange={(e) => setToDate(e.target.value)}
          value={toDate}
          label="To:"
        />
      </div>
      <div className="spacer" />
      {!loadingData ? (
        <Button color="teal" onClick={() => onClickButton()}>
          Export
        </Button>
      ) : (
        <Button color="teal">
          <Loader active inline="centered" size="tiny" />
        </Button>
      )}
    </div>
  );
};

export default FromToDate;
