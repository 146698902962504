import React, { useContext, useReducer } from "react";
import { Button, Container, Grid, Header, Segment } from "semantic-ui-react";

import { createClient } from "../../apis/serviceApis";
import EnvContext from "../../context/EnvContext";
import { useAuth0 } from "../../react-auth0-spa";
import { modifyNestedObject } from "../../utils/helpers";

import CreateClientComponent from "./CreateClientComponent/CreateClientComponent";
import ConfigImpl from "./utils.ts";

import "./CreateClientView.css";

const reducer = (state, action) => {
  switch (action.type) {
    case "textField":
      return { ...state, [action.payload.name]: action.payload.value };
    case "inputField": {
      return modifyNestedObject(
        state,
        action.payload.name,
        action.payload.value
      );
    }
    default:
      throw new Error();
  }
};

const config = new ConfigImpl();

const CreateClientView = () => {
  const [state, dispatch] = useReducer(reducer, config.getInitialState());
  const { getTokenSilently } = useAuth0();
  const env = useContext(EnvContext);

  const handleCreateClient = async (newClient) => {
    const token = await getTokenSilently();
    await createClient(token, env, newClient);
  };

  const handleClickUpdate = async () => {
    await handleCreateClient(state);
  };

  return (
    <div className="create-client-view">
      <Container>
        <Segment className="create-button">
          <Button color="teal" onClick={handleClickUpdate}>
            Create
          </Button>
        </Segment>
        <Segment>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column>
                <Header as="h2">Create New Client</Header>
                <CreateClientComponent
                  state={state}
                  dispatch={dispatch}
                  fieldsToRender={config.getConfig()}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </Container>
    </div>
  );
};

export default CreateClientView;
