import axios from "axios";

import config from "../config.json";

const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const baseUrls = config["api-service"];

const urls = {
  accountPerformance: "/account-performance",
  accountPerformanceCallRates: "/account-performance/call-rates",
  accountPerformanceExport: "/account-performance/export",
  conversations: "/conversations",
  conversationByAgentId: "/conversations/{clientName}/{agentId}",
  conversions: "/conversions",
  conversationsExport: "/conversations/export",
  products: "/products",
  productsExport: "/products/export",
  users: "/users/{clientName}",
  usersByGroups: "/users/{clientName}/groups",
  addUser: "/users",
  updateUser: "/users",
  deleteUser: "/users/{userId}",
  roles: "/roles",
  userRoles: "/roles/{userId}",
  addRole: "/roles/{userId}",
  removeRole: "/roles/{userId}",
  resetPassword: "/users/password/reset",
  events: "/statistics",
  clientConfig: "/configurations",
  groups: "/groups",
  createClient: "/configurations/clients/register",
  stateConfig: "/configurations/state",
  assistants: "/configurations/assistants",
  chatHistory: "/sessions/{clientName}/chat",
  agentAttendanceExport: "/agents/activity/export",
};

const downloadFile = (data, fileName) => {
  const href = URL.createObjectURL(data);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${fileName}.xlsx`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

const callService = async (params, token, env, url) => {
  const api = axios.create({
    baseURL: baseUrls[env],
  });

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await api.get(url, {
    params,
    headers,
  });
  return await response.data;
};

const deleteService = async (token, env, url, body = {}) => {
  const api = axios.create({
    baseURL: baseUrls[env],
  });

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await api.delete(url, {
    data: body,
    headers,
  });
  return await response.data;
};

const createService = async (token, env, url, body = {}, params = {}) => {
  const api = axios.create({
    baseURL: baseUrls[env],
  });

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await api.post(url, body, { headers, params });
  return await response.data;
};

const updateService = async (token, env, url, body = {}) => {
  const api = axios.create({
    baseURL: baseUrls[env],
  });

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const response = await api.put(url, body, { headers });
  return await response.data;
};

const getAccountPerformanceStats = async (
  clientName,
  startDate,
  endDate,
  token,
  env
) => {
  const params = {
    clientName,
    startDate,
    endDate,
    timeZone,
  };

  return await callService(params, token, env, urls.accountPerformance);
};

const getAccountPerformanceCallRates = async (
  clientName,
  startDate,
  endDate,
  token,
  env
) => {
  const params = {
    clientName,
    startDate,
    endDate,
    timeZone,
  };

  return await callService(
    params,
    token,
    env,
    urls.accountPerformanceCallRates
  );
};

const getConversions = async (
  clientName,
  startDate,
  endDate,
  conversionPeriod,
  token,
  env
) => {
  const params = {
    clientName,
    startDate,
    endDate,
    timeZone,
    conversionPeriod,
  };

  return await callService(params, token, env, urls.conversions);
};

const getConversationsByAgentId = async (clientName, agentId, token, env) => {
  const conversionPeriod =
    parseInt(localStorage.getItem("conversionPeriodValue")) || 0;
  const params = {
    timeZone,
    conversionPeriod,
  };
  const url = urls.conversationByAgentId
    .replace("{clientName}", clientName)
    .replace("{agentId}", agentId);

  return await callService(params, token, env, url);
};

const getConversations = async (clientName, startDate, endDate, token, env) => {
  const conversionPeriod =
    parseInt(localStorage.getItem("conversionPeriodValue")) || 0;
  const params = {
    clientName,
    startDate,
    endDate,
    timeZone,
    conversionPeriod,
  };

  return await callService(params, token, env, urls.conversations);
};

const getConversationById = async (sessionId, token, env, clientName) => {
  const params = {
    clientName,
    timeZone,
    conversionPeriod:
      parseInt(localStorage.getItem("conversionPeriodValue")) || 0,
  };

  return await callService(
    params,
    token,
    env,
    `${urls.conversations}/${sessionId}`
  );
};

const getProducts = async (clientName, startDate, endDate, token, env) => {
  const conversionPeriod =
    parseInt(localStorage.getItem("conversionPeriodValue")) || 0;
  const params = {
    clientName,
    startDate,
    endDate,
    timeZone,
    conversionPeriod,
  };

  return await callService(params, token, env, urls.products);
};

const downloadReport = async (params, token, env, fileName, url) => {
  const api = axios.create({
    baseURL: baseUrls[env],
  });

  const headers = {
    "Content-Type": "application/octet-stream",
    Authorization: `Bearer ${token}`,
  };

  const response = await api.get(url, {
    params,
    headers,
    responseType: "blob",
  });

  const data = await response.data;
  downloadFile(data, fileName);
};

const downloadConversationsReport = async (params, token, env) => {
  await downloadReport(
    params,
    token,
    env,
    "Conversations",
    urls.conversationsExport
  );
};

const downloadAccountPerformanceReport = async (params, token, env) => {
  await downloadReport(
    params,
    token,
    env,
    "AccountPerformance",
    urls.accountPerformanceExport
  );
};

const downloadProductsReport = async (params, token, env) => {
  await downloadReport(params, token, env, "Products", urls.productsExport);
};

const downloadAgentActivityReport = async (params, token, env) => {
  await downloadReport(params, token, env, "Attendance", urls.agentAttendanceExport);
};

const getUsers = async (token, env, clientName) => {
  return await callService(
    {},
    token,
    env,
    urls.users.replace("{clientName}", clientName)
  );
};

const getUsersByGroup = async (token, env, clientName) => {
  return await callService(
    {},
    token,
    env,
    urls.usersByGroups.replace("{clientName}", clientName)
  );
};

const getRoles = async (token, env) => {
  return await callService({}, token, env, urls.roles);
};

const getUserRoles = async (token, env, userId) => {
  return await callService(
    {},
    token,
    env,
    urls.userRoles.replace("{userId}", userId)
  );
};

const deleteUser = async (token, env, userId) => {
  return await deleteService(
    token,
    env,
    urls.deleteUser.replace("{userId}", userId)
  );
};

const addUser = async (token, env, body) => {
  return await createService(token, env, urls.addUser, body);
};

const updateUser = async (token, env, body) => {
  return await updateService(token, env, urls.updateUser, body);
};

const resetPassword = async (token, env, body) => {
  return await createService(token, env, urls.resetPassword, body);
};

const deleteRoles = async (token, env, userId, body) => {
  return await deleteService(
    token,
    env,
    urls.removeRole.replace("{userId}", userId),
    body
  );
};

const addRoles = async (token, env, userId, body) => {
  return await createService(
    token,
    env,
    urls.addRole.replace("{userId}", userId),
    body
  );
};

const getStatistics = async (token, env, clientName, startDate, endDate) => {
  return await callService(
    { clientName, startDate, endDate },
    token,
    env,
    urls.events
  );
};

const getClientConfig = async (token, env, clientName) => {
  return await callService({ clientName }, token, env, urls.clientConfig);
};

const updateClientConfig = async (token, env, newConfig, clientName) => {
  const params = {
    clientName,
  };

  return await createService(token, env, urls.clientConfig, newConfig, params);
};

const getGroups = async (token, env, clientName) => {
  return await callService({ clientName }, token, env, urls.groups);
};

const addGroups = async (token, env, clientName, newGroups) => {
  return await createService(token, env, urls.groups, newGroups, {
    clientName,
  });
};

const createClient = async (token, env, newClient) => {
  return await createService(token, env, urls.createClient, newClient);
};

const getStateConfig = async (token, env, clientName) => {
  return await callService({ clientName }, token, env, urls.stateConfig);
};

const savePageForAssistant = async (token, env, body) => {
  return await createService(token, env, urls.assistants, body);
};

const getPageForAssistant = async (token, env) => {
  return await callService({}, token, env, urls.assistants);
};

const removeAssistantPage = async (token, env, id) => {
  return await deleteService(token, env, urls.assistants + `/${id}`);
};

const getChatHistory = async (token, env, clientName, sessionId) => {
  return await callService(
    {sessionId},
    token,
    env,
    urls.chatHistory.replace("{clientName}", clientName)
  );
};

export {
  downloadConversationsReport,
  downloadAccountPerformanceReport,
  downloadProductsReport,
  downloadAgentActivityReport,
  getAccountPerformanceStats,
  getAccountPerformanceCallRates,
  getConversations,
  getConversationById,
  getProducts,
  getConversions,
  getUsers,
  getUsersByGroup,
  getRoles,
  deleteUser,
  addUser,
  resetPassword,
  deleteRoles,
  addRoles,
  getUserRoles,
  updateUser,
  getConversationsByAgentId,
  getStatistics,
  getClientConfig,
  updateClientConfig,
  getGroups,
  addGroups,
  createClient,
  getStateConfig,
  savePageForAssistant,
  getPageForAssistant,
  removeAssistantPage,
  getChatHistory
};
