import React, { useContext, useEffect, useRef, useState } from "react";
import Video from "twilio-video";

import hangUpButton from "../../../assets/img/hang up button.svg";
import micEnableImg from "../../../assets/img/opacity button microphone on.svg";
import micDisableImg from "../../../assets/img/opacity button mute.svg";
import toggleCameraButton from "../../../assets/img/opacity button toggle camera.svg";
import cameraDisableImg from "../../../assets/img/opacity button video off.svg";
import cameraImg from "../../../assets/img/opacity button video on.svg";
import ClientContext from "../../../context/ClientContext";
import Spinner from "../../Spinner/Spinner";

import "../Participant/Participant.css";

const LocalParticipant = ({ ...props }) => {
  const { setLocalVideoTrack, localVideoTrack } = useContext(ClientContext);

  const [audioEnabled, setAudioEnabled] = useState(props.audioEnabled);
  const [videoEnabled, setVideoEnabled] = useState(props.videoEnabled);
  const { clientConfig, client } = useContext(ClientContext);

  const [supportToggleCamera, setSupportToggleCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const videoRef = useRef();
  const audioRef = useRef();

  useEffect(() => {
    props.participant.on("trackSubscribed", trackSubscribed);
    props.participant.on("trackUnsubscribed", trackUnsubscribed);
    props.participant.on("trackPublished", handlePublication);

    return () => {
      props.participant.removeAllListeners();
    };
  }, [props.participant]);

  useEffect(() => {
    isSupportToggleCamera().then((isSupported) => {
      setSupportToggleCamera(isSupported && videoEnabled);
    });
  }, [videoEnabled]);

  useEffect(() => {
    handleAudioTrack(audioEnabled);
  }, [audioEnabled]);

  useEffect(() => {
    handleVideoTrack();
  }, [videoEnabled]);

  const handleAudioTrack = async (audioEnabled) => {
    let audioTrack;
    console.log('audioEnabled', audioEnabled, props.participant.audioTracks.values())
    if (audioEnabled) {
      disableAudio();
      audioTrack = await Video.createLocalAudioTrack({
        noiseSuppression: true,
        echoCancellation: true
      });
      audioRef.current.appendChild(audioTrack.attach());
      await props.participant.publishTrack(audioTrack);
    } else {
      disableAudio();
    }
  };

  const disableAudio = () => {
    var tracks = props.participant.audioTracks.values()
    console.log('disable audio, tracks: ', tracks)
    Array.from(tracks).forEach(
        (publication) => {
          publication.track.detach().forEach((element) => element.remove());
          publication.track.stop();
          publication.unpublish();
        }
    );
  };

  const trackSubscribed = (track) => {
    if (track.kind === "video") {
      if (track.name !== "screenshare") {
        videoRef.current.appendChild(track.attach());
      }
    } else {
      audioRef.current.appendChild(track.attach());
    }
  };

  const handlePublication = (publication) => {
    if (publication.isSubscribed || publication.track) {
      trackSubscribed(publication.track);
    }
  };

  const trackUnsubscribed = (track) => {
    track.detach().forEach((element) => element.remove());
  };

  const getVideoDevices = async () => {
    try {
      return (await navigator.mediaDevices.enumerateDevices()).filter(
        (device) => device.kind === "videoinput"
      );
    } catch {
      return [];
    }
  };

  const getVideoTrack = async (newDevice = "") => {
    let videoTrack;
    if (newDevice) {
      console.log('createLocalVideoTrack local participant 1')
      videoTrack = await Video.createLocalVideoTrack({
        deviceId: { exact: newDevice },
      });
    } else {
      console.log('createLocalVideoTrack local participant 2')
      videoTrack = await Video.createLocalVideoTrack();
    }
    return videoTrack;
  };

  const isSupportToggleCamera = async () => {
    const devicesLength = (await getVideoDevices()).length;
    return devicesLength > 1;
  };

  const disableVideo = () => {
    setLocalVideoTrack(null);
    Array.from(props.participant.videoTracks.values()).forEach(
      (publication) => {
        if (publication.trackName !== "screenshare") {
          publication.track.detach().forEach((element) => element.remove());
          publication.track.stop();
          publication.unpublish();
        }
      }
    );
  };

  const toggleCamera = async () => {
    const devices = await getVideoDevices();
    const currentDeviceLabel = localVideoTrack.mediaStreamTrack.label;
    let nextDeviceId = "";

    devices.forEach((item, index, arr) => {
      if (item.label === currentDeviceLabel) {
        const nextIndex = index + 1;
        if (arr.length > nextIndex) {
          nextDeviceId = devices[nextIndex].deviceId;
        } else {
          nextDeviceId = devices[0].deviceId;
        }
      }
    });

    await handleVideoTrack(nextDeviceId);
  };

  const handleVideoTrack = async (nextDeviceId = "") => {
    setLoading(true);
    if (videoEnabled || nextDeviceId) {
      if (nextDeviceId) {
        disableVideo();
      }
      const newVideoTrack = await getVideoTrack(nextDeviceId);
      setLocalVideoTrack(newVideoTrack);
      await props.participant.publishTrack(newVideoTrack);
    } else {
      disableVideo();
    }
    setLoading(false);
  };

  const renderCameraImage = () => {
    if (clientConfig?.features?.supportVideo || client === "ikeauae") {
      return (
        <img
          className="image_button"
          src={videoEnabled ? cameraImg : cameraDisableImg}
          onClick={() => setVideoEnabled(!videoEnabled)}
          alt="Camera"
        />
      );
    }
    return [];
  };

  return (
    <div id={props.id} className="local-participant participant">
      {loading && <Spinner />}
      {props.children}
      <h2>{JSON.parse(props.participant.identity).name}</h2>
      <div id="call-buttons-container">
        {renderCameraImage()}
        <img
          className="image_button"
          onClick={props.onDisconnect}
          src={hangUpButton}
        />
        <img
          className="image_button"
          src={audioEnabled ? micEnableImg : micDisableImg}
          onClick={() => setAudioEnabled(!audioEnabled)}
        />

        {supportToggleCamera && (
          <img
            className="image_button"
            src={toggleCameraButton}
            onClick={toggleCamera}
            alt="toggle camera"
          />
        )}
      </div>
      <div ref={videoRef} className="participant__video" />
      <div ref={audioRef} className="participant__audio" />
    </div>
  );
};

LocalParticipant.defaultProps = {
  videoEnabled: true,
  audioEnabled: true,
  onDisconnect: () => {},
};
export default React.memo(LocalParticipant);
