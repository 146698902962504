import React, { useContext } from "react";

import { deleteUser } from "../../../../apis/serviceApis";
import EnvContext from "../../../../context/EnvContext";
import UsersContext from "../../../../context/UsersContext";
import { useAuth0 } from "../../../../react-auth0-spa";
import { FilledVoiceableButton } from "../../../../UI-components";

const DeleteUser = () => {
  const { user, fetchUsers, setUser } = useContext(UsersContext);
  const env = useContext(EnvContext);
  const { getTokenSilently } = useAuth0();

  const handleRemoveUser = async () => {
    if (window.confirm("Are you sure?")) {
      try {
        const token = await getTokenSilently();

        await deleteUser(token, env, user.id);
        setUser(undefined);
        await fetchUsers();
      } catch (e) {
        let reason = e?.response?.data?.reason;
        if (!reason) {
          reason = "An error occurred when removing user.";
        }
        console.error(reason);
      }
    }
  };

  return (
    <FilledVoiceableButton
      style={{ backgroundColor: "#ff6969" }}
      onClick={handleRemoveUser}
    >
      Delete
    </FilledVoiceableButton>
  );
};

export default DeleteUser;
