import React, { useContext, useEffect, useState } from "react";

import UsersContext from "../../../context/UsersContext";
import { FilledVoiceableButton, UserTable } from "../../../UI-components";

import CopyUser from "./CopyUser/CopyUser";
import DeleteUser from "./DeleteUser/DeleteUser";
import UpdateUser from "./UpdateUser/UpdateUser";
import { UpdateMethods } from "./index";

import "./Users.css";

const Users = () => {
  const [updateMethod, setUpdateMethod] = useState("");
  const { user, users, setShowAddUserPage, setIsCopyUser } =
    useContext(UsersContext);

  useEffect(() => {
    if (updateMethod === UpdateMethods.copyUser.key) {
      setUpdateMethod("");
      setShowAddUserPage(true);
      setIsCopyUser(true);
    }
  }, [users, updateMethod]);

  useEffect(() => {
    if (user) {
      setUpdateMethod(UpdateMethods.changeInfo.key);
    } else {
      setUpdateMethod("");
    }
  }, [user]);

  const handleUpdateMethods = (e) => {
    const currentUpdateMethod = e.target.dataset.action;
    setUpdateMethod(currentUpdateMethod);
  };

  return (
    <section className="users">
      <div className="users__inner">
        <div className="users__left">
          <UserTable />
        </div>
        <div className="users__right">
          {user && (
            <>
              <div className="users__right__header">
                <h3 className="user__right__header__title">{user.name}</h3>
                <div className="users__right__header__left">
                  <CopyUser setUpdateMethod={setUpdateMethod} />
                  <DeleteUser />
                </div>
              </div>
              <div className="users__right__action">
                {Object.values(UpdateMethods).map(
                  (item) =>
                    !item.ignore && (
                      <FilledVoiceableButton
                        key={item.key}
                        disabled={!user}
                        data-action={item.key}
                        onClick={handleUpdateMethods}
                        isActive={item.key === updateMethod}
                      >
                        {item.text}
                      </FilledVoiceableButton>
                    )
                )}
              </div>
            </>
          )}
          <UpdateUser updateMethod={updateMethod} />
        </div>
      </div>
    </section>
  );
};

export default Users;
