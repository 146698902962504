import React, { useContext, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";
import { Icon, Loader, Segment, Table } from "semantic-ui-react";

import ClientContext from "../../../context/ClientContext";
import EnvContext from "../../../context/EnvContext";
import { useAuth0 } from "../../../react-auth0-spa";
import {
  fetchAgentsAttendances,
  getSessionsByDateRange,
  getSessionsWithPages,
} from "../../Functions/GetAgents";
import {
  convertDataToConversations,
  mapByAgents,
} from "../../Functions/GetCsvFromGs";
import { sortConversations } from "../../Functions/SortFunctions";
import DateSelectors from "../DateSelectors/DateSelectors";

import "./TeamComparison.css";

const TeamComparison = (props) => {
  const [agents, setAgents] = useState([]); // Remove agentsDemo when implementing
  const [conversations, setConversations] = useState([]);
  const [statistics, setStatistics] = useState([]);
  const [statisticsToCsv, setStatisticsToCsv] = useState([]);
  const [totalStatistics, setTotalStatistics] = useState({});
  const [orderedByArrow, setOrderedByArrow] = useState(null);
  const [loading, setLoading] = useState(true);
  const ref = useRef(null);
  const env = useContext(EnvContext);
  const stateOptions = [
    {
      key: 0,
      text: "Today",
      value: "Today",
    },
    {
      key: 1,
      text: "Last Week",
      value: "Last Week",
    },
    {
      key: 2,
      text: "Last Month",
      value: "Last Month",
    },
  ];
  const [option, setOption] = useState(stateOptions[1].value);
  const { user, getTokenSilently, logout } = useAuth0();

  function helperToSumTotal(Total, stats) {
    if (JSON.stringify(Total) === JSON.stringify({})) {
      Total.totalCalls = stats.totalCalls;
      Total.answeredCalls = stats.answeredCalls;
      Total.misssedCalls = stats.misssedCalls;
      Total.convertedCalls = stats.convertedCalls;
      // Total.perOfAov = stats.perOfAov;
      Total.convertedValue = stats.convertedValue;
      Total.convertedCallsPercent = stats.convertedCallsPercent;
      Total.totalValue = stats.totalValue;
      Total.totalValueAvg = stats.totalValueAvg;
      Total.allTotalValue = stats.allTotalValue;
      Total.allTotalValueAvg = stats.allTotalValueAvg;
      Total.upSells = stats.upSells;
      Total.crossSells = stats.crossSells;
      Total.navigation = stats.navigation;
      Total.proactivity = stats.proactivity;
    } else {
      Total.totalCalls += stats.totalCalls;
      Total.answeredCalls += stats.answeredCalls;
      Total.misssedCalls += stats.misssedCalls;
      Total.convertedCalls += stats.convertedCalls;
      // Total.perOfAov += stats.perOfAov;
      Total.convertedValue += stats.convertedValue;
      Total.convertedCallsPercent += stats.convertedCallsPercent;
      Total.totalValue += stats.totalValue;
      Total.totalValueAvg += stats.totalValueAvg;
      Total.allTotalValue += stats.allTotalValue;
      Total.allTotalValueAvg += stats.allTotalValueAvg;
      Total.upSells += stats.upSells;
      Total.crossSells += stats.crossSells;
      Total.navigation += stats.navigation;
      Total.proactivity += stats.proactivity;
    }
    return Total;
  }

  const statisticsMaker = (conversations, agents, setStatistics) => {
    if (
      conversations &&
      conversations.length > 0 &&
      agents &&
      agents.length > 0
    ) {
      // console.log('statisticsMaker.conversations=', conversations)
      let allSuccessCalls = conversations.filter(
        (conversation) => conversation.duration > 10
      );
      let allTotalValue = allSuccessCalls.reduce(
        (accumulator, currentValue) =>
          accumulator +
          (currentValue.checkoutValue ? currentValue.checkoutValue : 0),
        0
      );

      let conversationsByAgents = mapByAgents(conversations);
      let staticticsByAgents = [];
      let totalStatsAll = {};
      for (const [key, conversationByAgent] of Object.entries(
        conversationsByAgents
      )) {
        let successCalls = conversationByAgent.filter(
          (conversation) => conversation.duration > 10
        );
        let upSells = conversationByAgent.filter(
          (conversation) => parseInt(conversation.upsellAttempt) > 0
        ).length;
        let crossSells = conversationByAgent.filter(
          (conversation) => parseInt(conversation.crosssellAttempt) > 0
        ).length;
        let navigation = conversationByAgent.filter(
          (conversation) => parseInt(conversation.navigationAttempt) > 0
        ).length;
        let proactivity =
          (2 * (upSells > 0 ? 1 : 0) +
            2 * (crossSells > 0 ? 1 : 0) +
            (navigation > 0 ? 1 : 0)) /
          5;
        let convertedCalls = conversationByAgent
          .filter((conversation) => conversation.successCheckout)
          .map((conversation) => {
            return {
              conversionItemsValue: conversation.successCheckout.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue["conversion"] ? 1 : 0) * currentValue.price,
                0
              ),
              similarItemsValue: conversation.successCheckout.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue["similar"] ? 1 : 0) * currentValue.price,
                0
              ),
              variantItemsValue: conversation.successCheckout.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue["variant"] ? 1 : 0) * currentValue.price,
                0
              ),
              upSellItemsValue: conversation.successCheckout.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue["up-sell"] ? 1 : 0) * currentValue.price,
                0
              ),
              crossSellItemsValue: conversation.successCheckout.reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue["cross-sell"] ? 1 : 0) * currentValue.price,
                0
              ),
            };
          });
        let totalValue = successCalls.reduce(
          (accumulator, currentValue) =>
            accumulator +
            (currentValue.checkoutValue ? currentValue.checkoutValue : 0),
          0
        );

        let convertedValue = convertedCalls.reduce(
          (accumulator, currentValue) =>
            accumulator +
            currentValue.conversionItemsValue +
            currentValue.similarItemsValue +
            currentValue.variantItemsValue,
          0
        );

        let answeredCalls = successCalls.length;
        let missedCalls = conversationByAgent.length - successCalls.length;
        let agent = agents.find(
          (agent) => agent.agend_id === conversationByAgent[0].agentId
        );
        if (agent === undefined) {
          agent = {
            agent_name: "N/A",
            agent_id: "N/A",
          };
          // console.log(conversationByAgent);
        }
        let convertedCallsLength = convertedCalls.filter(
          (oneCall) => oneCall.conversionItemsValue > 0
        ).length;
        let convertedCallsPercent = Math.floor(
          ((convertedCallsLength / answeredCalls) * 100).toFixed(2)
        );
        let stats = {
          agentName: agent.agent_name,
          agentId: agent.agend_id,
          totalCalls: conversationByAgent.length,
          answeredCalls: answeredCalls,
          missedCalls: missedCalls,
          convertedCalls: convertedCallsLength,
          convertedValue: convertedValue,
          convertedCallsPercent: convertedCallsPercent,
          totalValue: totalValue,
          totalValueAvg:
            convertedCallsLength > 0
              ? convertedValue / convertedCallsLength
              : 0,
          allTotalValue: allTotalValue,
          allTotalValueAvg:
            allTotalValue /
            allSuccessCalls.filter(
              (conversation) => conversation.successCheckout
            ).length,
          upSells: upSells,
          crossSells: crossSells,
          navigation: navigation,
          proactivity: proactivity * 10,
        };
        totalStatsAll = helperToSumTotal(totalStatsAll, stats);
        staticticsByAgents.push(stats);
      }
      if (setStatistics) {
        setTotalStatistics(totalStatsAll);
        setStatistics(staticticsByAgents);
      } else {
        return staticticsByAgents;
      }
    }
  };

  const functionForFromToDate = async (fromDate, toDate) => {
    let conversations = [];
    let maxPerPage = 500;
    let total = null;
    let current = 0;
    while (current < total / maxPerPage || total === null) {
      let value = await getSessionsWithPages(
        getTokenSilently,
        env,
        props.match.params.client,
        current,
        maxPerPage,
        fromDate,
        toDate
      );
      conversations = conversations.concat(
        convertDataToConversations(value.data)
      );
      total = !total ? value.metadata[0].total : total;
      current++;
    }
    const statsToCsv = statisticsMaker(conversations, agents, null);
    setStatisticsToCsv(statsToCsv);
    ref.current.link.click();
  };

  useEffect(() => {
    if (!loading) {
      statisticsMaker(conversations, agents, setStatistics);
    }
  }, [loading]);

  const arrowFunctionHandler = (sortOption, products) => {
    return orderedByArrow !== sortOption
      ? sortConversations(
          statistics,
          sortOption,
          setStatistics,
          setOrderedByArrow
        )
      : sortConversations(
          statistics,
          sortOption + " up",
          setStatistics,
          setOrderedByArrow
        );
  };

  const caretIconHandler = (sortOption) => {
    return orderedByArrow === sortOption ? (
      <Icon name="caret up" />
    ) : orderedByArrow === sortOption + " up" ? (
      <Icon name="caret down" />
    ) : null;
  };

  const { client } = useContext(ClientContext);
  const [searchTerm, setSearchTerm] = useState("");
  const renderRows = () => {
    if (statistics.length > 0) {
      return statistics.map((statistic) => {
        if (
          !statistic.agentName.toLowerCase().includes(searchTerm.toLowerCase())
        )
          return "";
        return statistic.agentId ? (
          <Table.Row
            style={{
              cursor: "pointer",
              position: "relative",
              transform: "rotate(0)",
            }}
          >
            <Table.Cell>
              <Icon name="user circle" size="large" />
            </Table.Cell>
            <Table.Cell>{statistic.agentName}</Table.Cell>
            <Table.Cell>{statistic.totalCalls}</Table.Cell>
            <Table.Cell>{statistic.answeredCalls}</Table.Cell>
            <Table.Cell>{statistic.convertedCalls}</Table.Cell>
            <Table.Cell>{statistic.convertedCallsPercent}%</Table.Cell>
            <Table.Cell>{Math.floor(statistic.totalValueAvg)}</Table.Cell>
            {/* <Table.Cell>{statistic.perOfAov}%</Table.Cell> */}
            <Table.Cell>{Math.floor(statistic.convertedValue)}</Table.Cell>
            <Table.Cell>{statistic.upSells}</Table.Cell>
            <Table.Cell>{statistic.crossSells}</Table.Cell>
            {/* if crossSells or upsell happend  */}
            <Table.Cell>{statistic.navigation}</Table.Cell>
            <Table.Cell>{statistic.proactivity}</Table.Cell>
            <Link
              className="link"
              to={`/${client}/dashboard/agents/${statistic.agentId.substring(
                6
              )}`}
            />
          </Table.Row>
        ) : null;
      });
    }
  };

  const renderRow = () => {
    return (
      <Table.Row
        style={{
          cursor: "pointer",
          position: "relative",
          transform: "rotate(0)",
        }}
      >
        <Table.Cell></Table.Cell>
        <Table.Cell style={{ fontWeight: "bold" }}>{"Total"}</Table.Cell>
        <Table.Cell>{totalStatistics.totalCalls}</Table.Cell>
        <Table.Cell>{totalStatistics.answeredCalls}</Table.Cell>
        <Table.Cell>{totalStatistics.convertedCalls}</Table.Cell>
        <Table.Cell>{totalStatistics.convertedCallsPercent}%</Table.Cell>
        <Table.Cell>{Math.floor(totalStatistics.totalValueAvg)}</Table.Cell>
        {/* <Table.Cell>{totalStatistics.perOfAov}%</Table.Cell> */}
        <Table.Cell>{Math.floor(totalStatistics.convertedValue)}</Table.Cell>
        <Table.Cell>{totalStatistics.upSells}</Table.Cell>
        <Table.Cell>{totalStatistics.crossSells}</Table.Cell>
        <Table.Cell>{totalStatistics.navigation}</Table.Cell>
        <Table.Cell>{totalStatistics.proactivity}</Table.Cell>
      </Table.Row>
    );
  };

  const onChangeOption = (e, data) => {
    setStatistics([]);
    setTotalStatistics({});
    setConversations([]);
    setAgents([]);
    setOption(data.value);
  };

  const ToDateDropdownFunc = async (fromDate, toDate) => {
    setStatistics([]);
    setTotalStatistics({});
    setConversations([]);
    setAgents([]);
    setLoading(true);
    const sessionsByDateRange = await getSessionsByDateRange(
      getTokenSilently,
      env,
      props.match.params.client,
      null,
      fromDate,
      toDate
    );
    const agentsAttendances = await fetchAgentsAttendances(
      getTokenSilently,
      env,
      props.match.params.client,
      null,
      fromDate,
      toDate
    );

    setConversations(convertDataToConversations(sessionsByDateRange));
    setAgents(
      agentsAttendances.filter((v, i, a) => {
        let obj = a.find((x) => x.agend_id === v.agend_id);
        return a.indexOf(obj) === i;
      })
    );

    setLoading(false);
  };

  return (
    <div className="TeamComparison">
      <div className="team-comparison__toolbar">
        <DateSelectors
          clickExporData={functionForFromToDate}
          clickFetchData={ToDateDropdownFunc}
        />
      </div>
      <Segment padded raised className="segment-style flipped">
        <div className="flipped">
          <Table
            basic="very"
            style={{ fontSize: "1.2em" }}
            textAlign="center"
            padded
          >
            <Table.Header>
              <Table.HeaderCell />
              <Table.HeaderCell
                style={{ width: "200px" }}
                className="tooltip"
                onClick={() => arrowFunctionHandler("agentName", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("agentName")}
                  Agent
                </div>
                <span className="tooltiptext">The name of the agent</span>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("totalCalls", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("totalCalls")}
                  Incoming Calls
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() =>
                  arrowFunctionHandler("answeredCalls", statistics)
                }
              >
                <div className="align-same-row">
                  {caretIconHandler("answeredCalls")}
                  Successful Calls
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() =>
                  arrowFunctionHandler("convertedCalls", statistics)
                }
              >
                <div className="align-same-row">
                  {caretIconHandler("convertedCalls")}
                  Converted Calls
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() =>
                  arrowFunctionHandler("convertedCallsPercent", statistics)
                }
              >
                <div className="align-same-row">
                  {caretIconHandler("convertedCallsPercent")}
                  Conversion Rate
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() =>
                  arrowFunctionHandler("totalValueAvg", statistics)
                }
              >
                <div className="align-same-row">
                  {caretIconHandler("totalValueAvg")}
                  AOV
                </div>
              </Table.HeaderCell>
              {/* <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("perOfAov", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("perOfAov")}
                  Percentage of Average AOV
                </div>
              </Table.HeaderCell> */}
              <Table.HeaderCell
                className="tooltip"
                onClick={() =>
                  arrowFunctionHandler("convertedValue", statistics)
                }
              >
                <div className="align-same-row">
                  {caretIconHandler("convertedValue")}
                  Revenue
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("upSells", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("upSells")}
                  Up-Sell
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("crossSells", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("crossSells")}
                  Cross-Sell
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("navigation", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("navigation")}
                  Navigation
                </div>
              </Table.HeaderCell>
              <Table.HeaderCell
                className="tooltip"
                onClick={() => arrowFunctionHandler("proactivity", statistics)}
              >
                <div className="align-same-row">
                  {caretIconHandler("proactivity")}
                  Proactivity
                </div>
              </Table.HeaderCell>
            </Table.Header>
            <Table.Body>{renderRows()}</Table.Body>
            <Table.Body>
              {JSON.stringify(totalStatistics) !== JSON.stringify({}) &&
                renderRow()}
            </Table.Body>
          </Table>
          {loading && <Loader active inline="centered" />}
          {!loading && statistics && statistics.length === 0 && (
            <div style={{ textAlign: "center", fontSize: "20px" }}>No data</div>
          )}
        </div>
      </Segment>
      <CSVLink
        data={statisticsToCsv}
        filename="team_comparison.csv"
        ref={ref}
      />
    </div>
  );
};

export default TeamComparison;
