import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Button, Icon, Input, List, Modal } from "semantic-ui-react";

import "./ModalListEditor.css";

function ModalListEditor({ items = [], buttonText, headerText, onUpdate }) {
  const [open, setOpen] = useState(false);
  const [newItems, setNewItems] = useState([]);
  const [newItem, setNewItem] = useState("");

  useEffect(() => {
    setNewItems(items);
  }, [items]);

  const getDeleteItemHandler = (index) => () => {
    setNewItems((oldItems) =>
      oldItems.filter((item, itemIndex) => index != itemIndex)
    );
  };

  const resetChanges = () => {
    setNewItems(items);
  };

  const handleChangeNewItem = (e, { value }) => {
    setNewItem(value);
  };

  const handleAddItem = () => {
    setNewItems((oldItems) => [...oldItems, newItem]);
    setNewItem("");
  };

  const cancelModal = () => {
    setNewItems(items);
    setNewItem("");
    setOpen(false);
  };

  const saveChanges = () => {
    onUpdate(newItems);
    setNewItem("");
    setOpen(false);
  };

  return (
    <Modal
      onClose={cancelModal}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button>{buttonText}</Button>}
    >
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content>
        <Modal.Description>
          <div className="modal-list-add-item-section">
            <h4>Add new</h4>
            <div className="modal-list-add-item-input-block">
              <Input value={newItem} onChange={handleChangeNewItem} />
              <Button disabled={!newItem} onClick={handleAddItem} color="teal">
                Add
              </Button>
            </div>
          </div>
          <List
            className="modal-list-editor"
            selection
            celled
            verticalAlign="middle"
          >
            {newItems.map((text, index) => (
              <List.Item key={text}>
                <List.Content>
                  <List.Header>{text}</List.Header>
                </List.Content>
                <Icon
                  name="trash alternate"
                  color="red"
                  onClick={getDeleteItemHandler(index)}
                />
              </List.Item>
            ))}
          </List>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions className="modal-editor-actions">
        <div className="modal-editor-reset-section">
          <Button onClick={resetChanges} disabled={_.isEqual(items, newItems)}>
            Reset changes
          </Button>
        </div>
        <Button onClick={cancelModal}>Cancel</Button>
        <Button
          color="teal"
          disabled={_.isEqual(items, newItems)}
          onClick={saveChanges}
        >
          Save
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default ModalListEditor;
