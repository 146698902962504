import React, { useEffect, useRef } from "react";

import "./Participant.css";
import { useShareScreen } from "../../../hooks/useShareScreen";

const Participant = React.memo((props) => {
  const videoRef = useRef();
  const screenShareRef = useRef();
  const audioRef = useRef();
  const { getShareScreenVideoContainer, removeShareScreenContainer } =
    useShareScreen();

  const trackSubscribed = (track) => {
    if (track.kind === "video") {
      if (track.name === "screenshare") {
        const container = getShareScreenVideoContainer();
        container.appendChild(track.attach());
      } else {
        videoRef.current.replaceChildren(track.attach());
      }
    } else {
      audioRef.current.appendChild(track.attach());
    }
  };

  const handlePublication = (publication) => {
    if (publication.isSubscribed || publication.track) {
      trackSubscribed(publication.track);
    }
  };

  const trackUnsubscribed = (track) => {
    track.detach().forEach((element) => element.remove());
    removeShareScreenContainer();
  };

  useEffect(() => {
    props.participant.on("trackSubscribed", trackSubscribed);
    props.participant.on("trackUnsubscribed", trackUnsubscribed);
    props.participant.on("trackPublished", handlePublication);

    return () => {
      props.participant.removeAllListeners();
    };
  }, [props.participant]);

  return (
    <div className="participant">
      {props.children}
      <div ref={videoRef} className="participant__video" />
      <div ref={screenShareRef} className="participant__screen-share" />
      <div ref={audioRef} className="participant__audio" />
    </div>
  );
});

Participant.defaultProps = {
  videoEnabled: true,
};
export default Participant;
