import React, { useEffect, useState } from "react";

import { getPropertyValueByPath } from "../../../../utils/helpers";

import "./FieldWrapper.css";

const FieldWrapper = ({ children, field, state }) => {
  const [linkedTo, setLinkedTo] = useState("");

  useEffect(() => {
    if (field.linkedTo) {
      setLinkedTo(getPropertyValueByPath(state, field.linkedTo));
    }
  }, [state]);

  const addAdditionalClass = () => {
    if (typeof linkedTo === "boolean" && linkedTo === false) {
      return " disabled";
    }
    return "";
  };

  return (
    <div className={`create-client__field${addAdditionalClass()}`}>
      <p className="create-client__label">{field.label}</p>
      {children}
    </div>
  );
};

export default FieldWrapper;
