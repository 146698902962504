export const arrayOfOptions = [
  { text: "Product information", color: "#68BBF7" },
  { text: "Stock availability Online", color: "#59D4CC" },
  { text: "Stock availability Store", color: "#6EEBA2" },
  { text: "Home delivery", color: "#68D459" },
  { text: "Assembly", color: "#D7FA66" },
  { text: "Cash on delivery", color: "#AD4AF7" },
  { text: "Click and collect", color: "#4A3FD4" },
  { text: "Online Payment", color: "#5292EB" },
  { text: "Website information", color: "#3FC5D4" },
  { text: "Store information", color: "#48FAB1" },
  { text: "Loyalty Program Online", color: "#F79240" },
  { text: "Loyalty Program store", color: "#D45337" },
  { text: "Offers Online", color: "#EB487A" },
  { text: "Offers Store", color: "#C137D4" },
  { text: "Online Order", color: "#863EFA" },
  { text: "In-store Order", color: "#2896F7" },
  { text: "No communication/ Blank Call", color: "#22D4D1" },
  { text: "Call Disconnected", color: "#32EB89" },
  { text: "Connectivity issues", color: "#2DD422" },
  { text: "Customer intent", color: "#557049" },
  { text: "Agent offers product", color: "#67BD42" },
  { text: "Price discussion", color: "#3D7027" },
  { text: "Delivery and assembly", color: "#B7F29D" },
  { text: "Product discussion", color: "#83F053" },
];
