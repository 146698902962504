import React, { useContext, useState } from "react";

import { getConversations } from "../apis/serviceApis";
import { useAuth0 } from "../react-auth0-spa";
import Conversations from "../views/Dashboard/Conversations/Conversations";

import ClientContext from "./ClientContext";
import EnvContext from "./EnvContext";

const ConversationsContext = React.createContext({
  loading: false,
  conversations: [],
  fetchConversations: async (startDate, endDate) => {},
  reFetchConversations: async () => {},
});

const ConversationsProvider = () => {
  const [loading, setLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [dateRange, setDateRange] = useState({ from: null, to: null });
  const { getTokenSilently } = useAuth0();
  const { client } = useContext(ClientContext);
  const env = useContext(EnvContext);

  const fetchConversations = async (startDate, endDate) => {
    setDateRange({ from: startDate, to: endDate });
    setLoading(true);
    const token = await getTokenSilently();
    const conversations = await getConversations(
      client,
      startDate,
      endDate,
      token,
      env
    );

    setConversations(conversations);
    setLoading(false);
  };

  const reFetchConversations = async () => {
    if (dateRange.from && dateRange.to) {
      await fetchConversations(dateRange.from, dateRange.to);
    }
  };

  return (
    <ConversationsContext.Provider
      value={{
        loading,
        conversations,
        fetchConversations,
        reFetchConversations,
      }}
    >
      <Conversations />
    </ConversationsContext.Provider>
  );
};
export { ConversationsProvider };
export default ConversationsContext;
