import moment from "moment";

import { getConversions } from "../../apis/serviceApis";

import "./ConversionsChart.css";

const fetchConversionsStats = async (
  client,
  getTokenSilently,
  env,
  startDate,
  endDate,
  conversionPeriod
) => {
  try {
    const token = await getTokenSilently();
    startDate = new Date(startDate).toLocaleDateString("en-UK");
    endDate = new Date(endDate).toLocaleDateString("en-UK");
    const stats = await getConversions(
      client,
      startDate,
      endDate,
      conversionPeriod,
      token,
      env
    );
    return stats
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((dayStats) => {
        const date = moment(new Date(dayStats.date)).format("DD MMM");
        return { ...dayStats, date };
      });
  } catch (e) {
    return [];
  }
};

const getPreparedOptions = (options) => {
  return options.map((option) => {
    return { ...option, text: option.label, key: option.label, label: null };
  });
};

export { fetchConversionsStats, getPreparedOptions };
