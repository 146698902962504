import {
  groupDateByWorkingHours,
  helperParseDate,
} from "../views/Functions/DateFunctions";

import { formatToExactDates, getDaysBetweenDates } from "./formatDate";
import { convertDateFormat } from "./formatDatetime";

const groupCallsByDate = (calls, dateRange = {}) => {
  let groupedCalls = {};

  let days = [];
  if (dateRange?.from && dateRange?.to) {
    days = formatToExactDates(
      getDaysBetweenDates(dateRange.from, dateRange.to)
    );
  }
  days.forEach((day) => (groupedCalls[day] = []));

  calls.map((value) => {
    if (!(value.date in groupedCalls)) {
      groupedCalls[value.date] = [];
    }
    groupedCalls[value.date].push(value);
  });

  groupedCalls = Object.keys(groupedCalls)
    .sort((a, b) => helperParseDate(b.date) - helperParseDate(a.date))
    .reduce((obj, key) => {
      obj[key] = groupedCalls[key];
      return obj;
    }, {});

  return groupedCalls;
};

const groupCallsForStatistics = (conversations, dateRange) => {
  const groupedCallsByDate = groupCallsByDate(conversations, dateRange);

  let calls = {
    totalCalls: [],
    answeredCalls: [],
    successfulCalls: [],
    unsuccessfulCalls: [],
    missedCalls: [],
    abandonedCalls: [],
  };

  let statsToPush = [];
  for (const [key, value] of Object.entries(groupedCallsByDate)) {
    let answered = value.filter((conversation) => conversation.agentId);
    let notAnswered = value.filter((conversation) => !conversation.agentId);
    let notAnsweredOverMinute = notAnswered.filter(
      (conversation) => conversation.duration >= 59
    );
    let abandoned = notAnswered.filter(
      (conversation) => conversation.duration < 59
    );
    let answeredAndSuccessful = answered.filter(
      (conversation) => parseInt(conversation.duration) > 10
    );
    let waitTimeNotAnswered = notAnswered.reduce(
      (accumulator, currentValue) =>
        accumulator +
        parseInt(currentValue.duration >= 59 ? 60 : currentValue.duration),
      0
    );
    let waitTimeAnswered = answered.reduce(
      (accumulator, currentValue) => accumulator + currentValue.waitTime,
      0
    );
    let durationAnswered = answered
      .filter((value) => value.duration < 60 * 60)
      .reduce(
        (accumulator, currentValue) => accumulator + currentValue.duration,
        0
      );
    let transferredCalls = answered.filter((value) => value.transferedCall > 0);
    let agentsCount = answered.map((value) => value.agentId);

    let calculateUnsuccessfulCalls = answered.filter((element) => {
      const res = answeredAndSuccessful.filter(
        (sElement) => element.fullDate === sElement.fullDate
      );
      return !res.length;
    });

    calls.totalCalls.push(value);
    calls.answeredCalls.push(answered);
    calls.successfulCalls.push(answeredAndSuccessful);
    calls.missedCalls.push(notAnsweredOverMinute);
    calls.abandonedCalls.push(abandoned);
    calls.unsuccessfulCalls.push(calculateUnsuccessfulCalls);

    statsToPush.push({
      date: key,
      agentsCount: [...new Set(agentsCount)].length,
      transferredCalls: transferredCalls.length,
      totalCalls: value.length,
      answeredCalls: answered.length,
      successfulCalls: answeredAndSuccessful.length,
      unsuccessfulCalls: answered.length - answeredAndSuccessful.length,
      missedCalls: notAnsweredOverMinute.length,
      abandonedCalls: notAnswered.length - notAnsweredOverMinute.length,
      declinedCalls: notAnswered.length,
      percentServiced: (answered.length / value.length).toFixed(2),
      percentNotServiced: (notAnswered.length / value.length).toFixed(2),
      waitTimeAnswered: (waitTimeAnswered / answered.length).toFixed(2),
      waitTimeNotAnswered: (waitTimeNotAnswered / notAnswered.length).toFixed(
        2
      ),
      handleTime: (durationAnswered / answered.length).toFixed(2),
    });
  }

  let collectedCalls = {};
  let collectedLengthOfCalls = {};
  for (let [key, value] of Object.entries(calls)) {
    collectedCalls[key] = value.flat();
    collectedLengthOfCalls[key] = value.map((item) => item.length);
  }
  return {
    labels: Object.keys(groupedCallsByDate),
    callsForChart: collectedLengthOfCalls,
    calls: collectedCalls,
    datatoCsv: statsToPush,
  };
};

const groupDailyCallStats = (callStats, dateRange) => {
  const days = getDaysBetweenDates(dateRange.from, dateRange.to);

  const groupedDailyCallState = {};
  for (const day of days) {
    groupedDailyCallState[day.toLocaleDateString("en-UK")] = {};
  }

  callStats.dailyCallStats.map((item) => {
    const [year, month, day] = item["date"].split("-");
    const suitableDate = `${day}/${month}/${year}`;
    groupedDailyCallState[suitableDate] = {
      totalCalls: item["totalCalls"],
      successfulCalls: item["statuses"]["Successful"],
      unsuccessfulCalls: item["statuses"]["Unsuccessful"],
      abandonedCalls: item["statuses"]["Abandoned"],
      missedCalls: item["statuses"]["Missed"],
      averageWaitTime: item["averageWaitTime"],
    };
  });
  return groupedDailyCallState;
};

const collectConversationToExport = (conversations, clientConfig) => {
  if (conversations.length === 0) {
    return [];
  }
  const conversationsToExport = [];

  conversations.map((conversation) => {
    const takeCallEvent = conversation.messages.filter(
      (message) => message.messageType === "takeCall"
    );
    const acceptInviteEvent = conversation.messages.filter(
      (message) => message.messageType === "acceptInvite"
    );
    const navigationEvent = conversation.messages.filter(
      (message) => message.messageType === "navigation"
    );
    const details = conversation.details || {
      conversionItems: [],
      crossSellAttempt: [],
      upSellAttempt: [],
      similar: [],
      variant: [],
      conversion: [],
      upSell: [],
      crossSell: [],
      checkoutQuantity: 0,
      proactivityScore: "",
    };
    const [date, time] = convertDateFormat(conversation.startedAt);
    const waitTime =
      (new Date(takeCallEvent[0]?.ts) - new Date(conversation.startedAt)) /
        1000 || 0;
    const duration =
      (new Date(conversation.finishedAt) - new Date(conversation.startedAt)) /
        1000 || 0;
    const productListIds = details.conversionItems
      .filter((item) => item.id !== -1)
      .map((item) => item.id)
      .join(", ");

    const workingHours = clientConfig.workDays;
    const checkoutValue = details.conversion.reduce((total, item) => {
      const price = item.price || 0;
      return price + total;
    }, 0);
    const newConversation = {
      fullDate: conversation.startedAt,
      groupDate: groupDateByWorkingHours(conversation.startedAt, workingHours),
      date: date,
      time: time,
      agentName: takeCallEvent[0]?.agentName,
      agentId: takeCallEvent[0]?.agentId,
      userId: conversation._va_id,
      sessionId: conversation.session,
      waitTime: waitTime,
      duration: duration,
      callStatus: conversation.callStatus,
      intent: conversation["intent"],
      transferedCall: acceptInviteEvent.length,
      navigationAttempt: navigationEvent.length,
      productList: productListIds,
      upsellAttempt: details.upSellAttempt.join(", "),
      crossSellAttempt: details.crossSellAttempt.join(", "),
      similar: details.similar.join(", "),
      variant: details.variant.join(", "),
      converted: !!details.conversion.length,
      successCheckout: details.conversion.join(", "),
      upsell: details.upSell.join(", "),
      crossSell: details.crossSell.join(", "),
      checkoutQuantity: details.checkoutQuantity,
      checkoutValue: checkoutValue,
      proactivityScore: details.proactivityScore,
    };
    conversationsToExport.push(newConversation);
  });

  return conversationsToExport;
};

export {
  groupCallsForStatistics,
  groupCallsByDate,
  collectConversationToExport,
  groupDailyCallStats,
};
