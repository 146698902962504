import _ from "lodash";
import { baseConfig } from "./constants";

function getPropertyByPath(obj, path, fallback) {
  const keys = path.split(".");
  let value = obj;

  try {
    for (const key of keys) {
      if (key.startsWith("[") && key.endsWith("]")) {
        const index = parseInt(key.substring(1, key.length - 1), 10);
        if (Array.isArray(value)) {
          value = value[index];
        } else {
          throw new Error(
            `Cannot access index ${index} of non-array value ${value}`
          );
        }
      } else {
        value = value[key];
      }
    }
  } catch (err) {
    value = fallback;
  }

  return !_.isUndefined(value) ? value : fallback;
}

let rootKey = "";
const updateBaseConfigByClientConfig = (
  config,
  clientConfig,
  newObject = {}
) => {
  for (const key in config) {
    const valueByKey = config[key];
    if (typeof valueByKey === "object" && !Array.isArray(valueByKey)) {
      newObject[key] = {};
      rootKey = `${rootKey}${key}.`;
      updateBaseConfigByClientConfig(valueByKey, clientConfig, newObject[key]);
    } else {
      newObject[key] = getPropertyByPath(
        _.cloneDeep(clientConfig),
        rootKey + key,
        valueByKey
      );
      if (Object.keys(config).at(-1) === key) {
        rootKey = "";
      }
    }
  }
  return newObject;
};

const sortFeatures = (config) => {
  if (config.features) {
    const sortedFeatures = Object.keys(config.features)
      .sort()
      .reduce((obj, key) => {
        obj[key] = config.features[key];
        return obj;
      }, {});

    config.features = sortedFeatures;
  }
  return config;
};

const prepareClientConfig = (clientConfig) => {
  if (_.isEmpty(clientConfig)) {
    return {};
  }
  let config = updateBaseConfigByClientConfig(
    _.cloneDeep(baseConfig),
    _.cloneDeep(clientConfig)
  );
  config = sortFeatures(_.cloneDeep(config));
  return config;
};

const getSectionConfigs = (sections, clientConfig, generalConfigTitle) => {
  const usedProperties = new Set();

  const sectionConfigs = sections.map(({ title }) => {
    return { title, sectionConfig: {} };
  });

  const generalConfig = {
    title: generalConfigTitle,
    sectionConfig: {},
  };

  for (const [key, value] of Object.entries(
    prepareClientConfig(clientConfig)
  )) {
    if (usedProperties.has(key)) {
      continue;
    }
    let selectIndex = 0;
    for (const { properties } of sections) {
      if (properties.includes(key)) {
        sectionConfigs[selectIndex].sectionConfig[key] = value;
        usedProperties.add(key);
      }
      if (usedProperties.has(key)) {
        break;
      }
      selectIndex++;
    }

    if (!usedProperties.has(key)) {
      generalConfig.sectionConfig[key] = value;
    }
  }
  sectionConfigs.unshift(generalConfig);
  return sectionConfigs;
};

export { getPropertyByPath, getSectionConfigs };
