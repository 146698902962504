import React, { FC, useEffect, useRef, useState } from "react";
import { Button, TextArea } from "semantic-ui-react";

import { ReactComponent as ChatIcon } from "../../../assets/img/chat-send-icon.svg";

import { Message } from "./types";

import "./Chat.css";
import {isMessageValid} from "../../../utils/validation";

interface ChatProps {
  chatActive: boolean;
  handleChatStatus: () => void | null;
  sendMessage: (msg: Message) => void | null;
  messages: Message[];
}

const Chat: FC<ChatProps> = ({
  chatActive,
  messages,
  handleChatStatus,
  sendMessage,
}) => {
  const [inputText, setInputText] = useState("");

  const [messageValid, setMessageValid] = useState(true);

  const textareaRef = useRef(null);
  const messageListRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current && getCountOfRows() < 6) {
      const textareaEl = textareaRef.current.ref.current;
      textareaEl.style.height = "auto";

      textareaEl.style.height = textareaEl.scrollHeight + "px";
    }
  }, [inputText]);

  const getCountOfRows = () => {
    if (!textareaRef.current) {
      return 0;
    }
    const textareaEl = textareaRef.current.ref.current;
    const lineHeight = parseInt(getComputedStyle(textareaEl).lineHeight);
    const contentHeight = textareaEl.scrollHeight - lineHeight;
    return Math.ceil(contentHeight / lineHeight);
  };

  const handleInputChange = (
    event: React.FormEvent<HTMLTextAreaElement>,
    data: { value: string }
  ) => {
    setMessageValid(isMessageValid(data.value))
    setInputText(data.value);
  };

  const handleSendMessage = () => {
    if (inputText === "" || !messageValid) {
      return;
    }
    const currentDate = new Date();

    const message: Message = {
      id: currentDate.toISOString(),
      text: inputText,
      date: currentDate.toISOString(),
      from: "agent",
    };

    sendMessage(message);
    setInputText("");
    const messageList = messageListRef.current;

    setTimeout(() => {
      messageList.scrollTo({ top: messageList.scrollHeight });
    }, 0);
  };

  useEffect(() => {
    const messageList = messageListRef.current;
    if (!messageList) {
      return;
    }
    setTimeout(() => {
      messageList.scrollTo({ top: messageList.scrollHeight });
    }, 0);
  }, [messages]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  const chatBlockClass = `chat-block ${chatActive ? "" : "chat-disabled"}`;
  return (
    <div className="text-button-chat-container">
      {
        handleChatStatus && (
          <Button
            icon="comments"
            onClick={handleChatStatus}
            color={chatActive ? "red" : "teal"}
            content={chatActive ? "Stop chat" : "Start chat"}
          />
        )
      }

      <div className={chatBlockClass}>
        <div ref={messageListRef} className="chat-messages-section">
          {messages.map((message) => (
            <div
              key={message.id}
              className={"chat-message-container " + message.from}
            >
              <div className={"chat-message " + message.from}>
                {message.text}
              </div>
            </div>
          ))}
        </div>
        {sendMessage && (
          <div className="chat-input-section">
            <div className="chat-input-field-wrapper">
              <TextArea
                onKeyDown={handleKeyDown}
                placeholder="Type here..."
                ref={textareaRef}
                onChange={handleInputChange}
                value={inputText}
                rows={1}
              />
            </div>
            <div className="chat-send-button-block">
              <ChatIcon
                onClick={handleSendMessage}
                className="chat-send-button"
              />
            </div>
          </div>
        )}
        <p className="message-not-valid">{!messageValid && "Message contains not valid characters"}</p>
      </div>
    </div>
  );
};

export default Chat;
