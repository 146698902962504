import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { Button, Dropdown, Loader } from "semantic-ui-react";

import { toDateMaker } from "../../Functions/DateFunctions";

import DateCalendar from "./DateCalendar/DateCalendar";
import { stateOptions } from "./utils";

import "./ToDateDropdown.css";

const ToDateDropdown = ({
  fetchDataByDate,
  setToDateDropdownRange,
  defaultDatePeriod,
}) => {
  const today = moment().format("yyyy-MM-DD");

  const [toDate, setToDate] = useState(today);
  const [loadingData, setLoadingData] = useState(false);
  const [option, setOption] = useState(defaultDatePeriod);

  useEffect(() => {
    const fromLocalDatetime = toDateMaker(toDate, option);
    const toLocalDatetime = moment(toDate, "yyyy-MM-DD").toDate();
    fetchDataByDate(fromLocalDatetime, toLocalDatetime);
  }, []);

  useEffect(() => {
    const fromLocalDatetime = toDateMaker(toDate, option);
    const toLocalDatetime = moment(toDate, "yyyy-MM-DD").toDate();
    setToDateDropdownRange({ from: fromLocalDatetime, to: toLocalDatetime });
  }, [option, toDate]);

  const onClickButton = async () => {
    setLoadingData(true);
    if (option && toDate) {
      const fromLocalDatetime = toDateMaker(toDate, option);
      const toLocalDatetime = moment(toDate, "yyyy-MM-DD").toDate();
      await fetchDataByDate(fromLocalDatetime, toLocalDatetime, option);
    }
    setLoadingData(false);
  };

  return (
    <div className="ToDateDropdown">
      <div className="to-date-dropdown__calendar">
        <Dropdown
          placeholder="Choose a date"
          selection
          onChange={(e, data) => setOption(data.value)}
          options={stateOptions}
          value={option}
        />

        <DateCalendar
          maxDate={today}
          onChange={(e) => setToDate(e.target.value)}
          value={toDate}
          label="Ending on:"
        />
      </div>
      {!loadingData ? (
        <Button color="teal" onClick={() => onClickButton()}>
          &nbsp;Show&nbsp;
        </Button>
      ) : (
        <Button color="teal">
          <Loader active inline="centered" size="tiny" />
        </Button>
      )}
    </div>
  );
};

export default ToDateDropdown;
