import axios from "axios";

import config from "../../config.json";

import { dateForParams } from "./DateFunctions";

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const fetchAgentsAttendances = async (
  getTokenSilently,
  env,
  clientId,
  stringOption,
  fromDate = null,
  toDate = null
) => {
  const token = await getTokenSilently();
  const apiService = axios.create({ baseURL: config["api-service"][env] });
  let params;
  if (stringOption) {
    params = dateForParams(stringOption);
  } else {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    params = {
      startDate: startDate.toLocaleDateString("en-UK"),
      endDate: endDate.toLocaleDateString("en-UK"),
      clientId: clientId,
      timeZone: localTimezone
    };
  }
  const agentDetails = await apiService.get(
    `agents/activity`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    }
  );
  if (Array.isArray(agentDetails.data) && agentDetails.data.length > 0) {
    return agentDetails.data;
  }
  return [];
};

export const getAgentsStatuses = async (getTokenSilently, env, clientId) => {
  const agentAPI = axios.create({baseURL: config["agent-api"][env]});
  const token = await getTokenSilently();
  const response = await agentAPI.get(`/agents/availability/${clientId}/statuses`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (Array.isArray(response.data) && response.data.length > 0) {
    return response.data;
  }
  return [];
};

export const getCurrentAgentStatus = async (
  getTokenSilently,
  env,
  clientId
) => {
  const agentAPI = axios.create({baseURL: config["agent-api"][env]});
  const token = await getTokenSilently();
  const response = await agentAPI.get(`/agents/availability/${clientId}/status`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const getSessionsByDateRange = async (
  getTokenSilently,
  env,
  clientId,
  stringOption,
  fromDate = new Date(),
  toDate = new Date()
) => {
  const token = await getTokenSilently();
  const supportAPI = axios.create({
    baseURL: config["api"][env],
  });
  let endDate;
  let startDate;
  let params;
  if (stringOption) {
    params = dateForParams(stringOption);
  } else {
    startDate = new Date(fromDate);
    endDate = new Date(toDate);
    params = {
      startDate: startDate.toLocaleDateString("en-UK"),
      endDate: endDate.toLocaleDateString("en-UK"),
      timeZone: localTimezone,
    };
  }
  const mongoData = await supportAPI.get(`/client/${clientId}/sessions`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: params,
  });
  if (Array.isArray(mongoData.data) && mongoData.data.length > 0) {
    return mongoData.data;
  }
  return [];
};

export const getSessionsWithPages = async (
  getTokenSilently,
  env,
  clientId,
  page,
  limit,
  fromDate,
  toDate
) => {
  const token = await getTokenSilently();
  const supportAPI = axios.create({ baseURL: config["api"][env] });
  let startDate = new Date(fromDate);
  let endDate = new Date(toDate);
  const params = {
    startDate: startDate.toLocaleDateString("en-UK"),
    endDate: endDate.toLocaleDateString("en-UK"),
    page: page,
    limit: limit,
  };
  const mongoData = await supportAPI.get(
    `/client/${clientId}/paging/sessions`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: params,
    }
  );
  if (Array.isArray(mongoData.data)) {
    return mongoData.data[0];
  }
  return [];
};
