import React from "react";

import "./FilledVoiceableButton.css";

const FilledVoiceableButton = ({ children, isActive, ...props }) => {
  return (
    <button
      {...props}
      type="submit"
      className={`filled-voiceable_button${isActive ? " active" : ""}`}
    >
      {children}
    </button>
  );
};

export default FilledVoiceableButton;
