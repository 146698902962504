import _ from "lodash";

const legendTooltipOnHover =
  (ref, legendTooltips, legendPosition = "right") =>
  (event, legendItem) => {
    if (ref.current.hovering) {
      return;
    }
    ref.current.style.opacity = 1;
    ref.current.style.visibility = "visible";
    ref.current.hovering = true;
    ref.current.innerHTML = legendTooltips[legendItem.text];
    if (legendPosition === "right") {
      ref.current.style.left = event.target.clientWidth - 150 + "px";
      ref.current.style.top = event.offsetY - ref.current.clientHeight + "px";
    } else if (legendPosition === "bottom") {
      // 14 this is padding of parent element
      const leftOffset = event.offsetX - ref.current.clientWidth / 2 + 14;
      const topOffset =
        event.target.clientHeight - ref.current.clientHeight - 20;
      ref.current.style.left = `${leftOffset}px`;
      ref.current.style.top = `${topOffset}px`;
    }
  };

const legendTooltipOnLeave = (ref) => () => {
  ref.current.hovering = false;
  ref.current.style.opacity = 0;
  ref.current.style.visibility = "hidden";
};

const minTwoDigits = (num) => (num < 10 ? "0" + num : num);

const getNormalizedTime = (hours, minutes) => {
  const minutesSign = 1 - 2 * (minutes < 0);
  let additionalHours = Math.trunc(minutes / 60) * minutesSign;
  let finalMinutes = minutes;
  if (minutesSign === 1 && minutes >= 60) {
    finalMinutes = Math.abs(minutes) - additionalHours * 60;
  } else if (minutesSign === -1) {
    finalMinutes = 60 - (Math.abs(minutes) - additionalHours * 60);
    additionalHours -= 1;
  }
  return (
    minTwoDigits(hours + additionalHours) + ":" + minTwoDigits(finalMinutes)
  );
};

const getMinMaxWorkHours = (workHours = [], timeOffset = "00:00") => {
  const additionalHours = +timeOffset.split(":")[0];
  const additionalMinutes = +timeOffset.split(":")[1];

  let max = "00:00";
  let min = "23:59";
  workHours.forEach(({ startTime, endTime }) => {
    if (startTime < min) {
      min = startTime;
    }
    if (endTime > max) {
      max = endTime;
    }
  });
  var hoursOffset = -(new Date().getTimezoneOffset() / 60);
  let minHours = +min.split(":")[0] - additionalHours + hoursOffset;
  let minMinutes = +min.split(":")[1] - additionalMinutes;

  let maxHours = +max.split(":")[0] + additionalHours + hoursOffset;
  let maxMinutes = +max.split(":")[1] + additionalMinutes;

  max = getNormalizedTime(maxHours, maxMinutes);
  min = getNormalizedTime(minHours, minMinutes);
  if (min < "00:00") {
    min = "00:00";
  }
  if (max > "23:59") {
    max = "23:59";
  }

  return [min, max];
};

const getLabelsByWorkHours = (
  workHours,
  minutesStep,
  additionalTime = "00:00"
) => {
  const labels = [];
  if (!workHours || _.isEmpty(workHours)) {
    return labels;
  }
  const [startWorkTime, endWorkTime] = getMinMaxWorkHours(
    workHours,
    additionalTime
  );

  let currentWorkTime = startWorkTime;
  let currentHours = +startWorkTime.split(":")[0];
  let currentMinutes = +startWorkTime.split(":")[1];
  while (endWorkTime >= currentWorkTime) {
    labels.push(currentWorkTime);
    currentMinutes += minutesStep;
    if (currentMinutes >= 60) {
      currentMinutes = 0;
      currentHours += 1;
    }
    if (currentHours >= 24) {
      break;
    }
    currentWorkTime =
      minTwoDigits(currentHours) + ":" + minTwoDigits(currentMinutes);
  }
  return labels;
};

const getAverageArray = (arrayOfArrays, numbersAfterComma = 2) => {
  let averageAvailabilityData = [];
  for (const dayData of arrayOfArrays) {
    dayData.forEach((value, index) => {
      if (!averageAvailabilityData[index]) {
        averageAvailabilityData[index] = 0;
      }
      averageAvailabilityData[index] += value;
    });
  }
  averageAvailabilityData = averageAvailabilityData.map(
    (sum) => sum / arrayOfArrays.length
  );

  return averageAvailabilityData.map((num) => num.toFixed(numbersAfterComma));
};

export {
  legendTooltipOnHover,
  legendTooltipOnLeave,
  getMinMaxWorkHours,
  getLabelsByWorkHours,
  getAverageArray,
  minTwoDigits,
};
